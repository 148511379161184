import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FiHome, FiMenu } from 'react-icons/fi';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { AiOutlineClose } from 'react-icons/ai';
import { useStore } from 'react-context-hook';
import {
  ApplyOverflow,
  Container,
  Content,
  HeaderMobile,
  HeaderNavbar,
  ItemHeaderNavbar,
  Logo,
  Menu,
  Mobile,
  Navbar,
  Wrapper,
} from '../default/styles';
import { ConvertedTheme } from '../../App';

export const RelatoriosLayout: React.FC = ({ children }) => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [theme] = useStore<ConvertedTheme>('theme');

  const handleOpenMenu = () => {
    setVisible(true);
  };

  const handleCloseMenu = () => {
    setVisible(false);
  };

  useEffect(() => {
    function handleClickOverlay(event: MouseEvent): void {
      if (
        navbarRef.current
        && event.target instanceof Node
        && !navbarRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    }

    if (visible) {
      document.addEventListener('mouseup', handleClickOverlay);
    }
  }, [visible]);

  return (
    <Container>
      <Menu visible={visible}>
        <Mobile>
          <button type="button" onClick={handleOpenMenu}>
            <FiMenu size={28} />
          </button>

          <div className="d-flex align-items-center justify-content-center">
            <h4 className="text-white ml-3">{theme.slogan}</h4>
          </div>
        </Mobile>

        <Navbar ref={navbarRef} visible={visible}>
          <ApplyOverflow className="d-flex justify-content-between flex-column h-100">
            <div>
              <HeaderMobile className="justify-content-between align-items-center">
                <h5 className="text-white">{theme.slogan}</h5>
                <button type="button" onClick={handleCloseMenu}>
                  <AiOutlineClose size={25} />
                </button>
              </HeaderMobile>

              <Logo
                className="align-items-center justify-content-center"
                style={{ background: 'white' }}
              >
                <img
                  src={theme.imgLogo}
                  alt={process.env.REACT_APP_COMPANY_NAME}
                  width="130"
                />
              </Logo>
              <ul>
                <li>
                  <NavLink
                    to="/relatorios/trilha"
                    onClick={handleCloseMenu}
                    activeClassName="selected"
                  >
                    <FiHome />
                    <span>Relatório de Trilha</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/relatorios/cursos"
                    onClick={handleCloseMenu}
                    activeClassName="selected"
                  >
                    <FiHome />
                    <span>Relatório de Cursos</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/relatorios/questionarios"
                    onClick={handleCloseMenu}
                    activeClassName="selected"
                  >
                    <FiHome />
                    <span>Relatório de Questionários</span>
                  </NavLink>
                </li>
              </ul>
            </div>

            <ul>
              <li>
                <NavLink
                  to="/"
                  onClick={handleCloseMenu}
                  activeClassName="selected"
                >
                  <RiLogoutCircleRLine size={20} />
                  <span>Voltar</span>
                </NavLink>
              </li>
            </ul>
          </ApplyOverflow>
        </Navbar>
      </Menu>

      <HeaderNavbar className=" d-flex align-items-center justify-content-between">
        <ItemHeaderNavbar>
          <div className="d-flex align-items-center w-100 ">
            {/* <img src={logo} alt={process.env.REACT123_APP_COMPANY_NAME} width="100" /> */}
            <p className="text-light">{theme.slogan}</p>
          </div>
        </ItemHeaderNavbar>
      </HeaderNavbar>

      <Wrapper visible={visible}>
        <Content>{children}</Content>
      </Wrapper>
    </Container>
  );
};
