import React from 'react';
import { HtmlVerticalAlignCenter } from './styles';

interface HTMLDisplayToTextProps {
  htmlContent: string;
}

const HTMLDisplayToText: React.FC<HTMLDisplayToTextProps> = ({ htmlContent }) => {
  const isHTML = (str: string) => /<\/?[a-z][\s\S]*>/i.test(str);

  return (
    isHTML(htmlContent)
      ? <HtmlVerticalAlignCenter dangerouslySetInnerHTML={{ __html: htmlContent }} />
      : <HtmlVerticalAlignCenter>{htmlContent}</HtmlVerticalAlignCenter>
  );
};

export default HTMLDisplayToText;
