import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import theme from '../../styles/theme';
import { Button } from '../Button';
import { Container, TitleHeader } from './styles';

interface Props {
  onNewItem?: () => void;
  title: string | JSX.Element[];
  text?: string;
  secondButtonLabel?: string;
  onSecondButtonClick?: () => void;
  exportUser?: boolean;
  exportBtn?: boolean;
  onExportButtonClick?: () => void;
}

export const HeaderTitle = ({
  onNewItem,
  title,
  text,
  secondButtonLabel,
  onSecondButtonClick,
  exportUser,
  exportBtn,
  onExportButtonClick,
}: Props) => {
  const history = useHistory();

  const NewItemBtn = () => (
    <Button
      type="button"
      background={theme.colors.main}
      color="#fff"
      onClick={onNewItem}
      className="ml-2"
      style={{ padding: '0 1.5rem' }}
    >
      Adicionar
    </Button>
  );

  const SecondBtn = () => (
    <Button
      type="button"
      background="#fff"
      color="#fff"
      onClick={onSecondButtonClick}
      style={{ marginRight: '1rem', padding: '0 1.5rem' }}
    >
      {secondButtonLabel}
    </Button>
  );

  const ExportBtn = () => (
    <Button
      type="button"
      background="#fff"
      color="#fff"
      onClick={onExportButtonClick}
      style={{ marginLeft: '1rem', padding: '0 1.5rem' }}
    >
      Exportar
    </Button>
  );

  const baseURL = process.env.REACT_APP_ENDPOINT;
  const exportUrl = exportUser ? '/usuario/export' : '/unidade-empresa/export';
  const exportDownloadUrl = baseURL + exportUrl;

  return (
    <Container className="mt-3">
      <Row>
        <Col xs={12} md={6} className={`d-flex align-items-center ${secondButtonLabel && 'mb-3'}`}>
          <TitleHeader>{title}</TitleHeader>
        </Col>

        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-end"
        >
          <div className="btn-area d-flex">
            {secondButtonLabel && <SecondBtn />}
            {onNewItem && <NewItemBtn />}
            {exportBtn && <ExportBtn />}
          </div>
        </Col>

        <Col xs={12} className="border-dotted-top">
          <p>
            {text}
          </p>
        </Col>
      </Row>
    </Container>
  );
};
