import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import { ImTree } from 'react-icons/im';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { ICourseCategory } from '../../../../interfaces/ICourseCategory';
import { IEmpresa } from '../../../../interfaces/IEmpresa';

interface SubmitProps {
  nome: string;
  descricao: string;
}

interface ModalFormProps {
  onCloseModal: () => void;
  category?: ICourseCategory;
  showModal: boolean;
}

const schema = yup.object({
  nome: yup.string().required('O nome é obrigatório'),
  descricao: yup.string().nullable(),
});

export const ModalForm = ({
  category,
  onCloseModal,
  showModal,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);
  const [company] = useStore<IEmpresa>('company');

  const handleSubmit = useCallback(
    async ({ nome, descricao }: SubmitProps) => {
      try {
        setLoading(true);
        if (category) {
          await api.put(`/curso/categoria/${category.id}`, {
            id: category.id,
            nome,
            descricao,
            empresaId: company.id,
          });
        } else {
          await api.post('/curso/categoria', {
            nome,
            descricao,
            empresaId: company.id,
          });
        }

        onCloseModal();
        toast.success('Grupo adicionado com sucesso');
      } catch (err) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar grupo');
      } finally {
        setLoading(false);
      }
    },
    [onCloseModal, category],
  );

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title={category ? 'Editar Categoria' : 'Adicionar Categoria'}
      icon={ImTree}
      size="lg"
    >
      <h6>Preencha todos os dados solicitados.</h6>
      <Formik
        initialValues={category || { nome: '', descricao: '' }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="text" name="nome" label="Nome" isRequerid />
          <Input type="textarea" name="descricao" label="Descrição" />
          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
