import { HiOutlineMail } from 'react-icons/all';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import Switch from 'react-switch';
import { ModalComponent } from '../../../../components/Modal';
import { IEmailSetting } from '../../../../interfaces/IEmailSetting';
import { Input } from '../../../../components/Input';
import api from '../../../../services/api';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';

interface ModalEmpresaProps {
  onSetEmailSetting: (data: any, update?: boolean) => void;
  emailSetting?: IEmailSetting;
  onCloseModal: () => void;
  showModal: boolean;
}

const initialData : IEmailSetting = {
  ativo: false,
  defaultMessage: '',
};

export const ModalEmailSetting = ({
  onSetEmailSetting,
  emailSetting,
  onCloseModal,
  showModal,
}: ModalEmpresaProps) => {
  const [checked, setChecked] = useState(false);

  const handleSubmit = useCallback(async (requestData: IEmailSetting) => {
    const requestDataPost = { ...requestData, ativo: checked };
    const { data } = await api.post('empresa/email-setting', requestDataPost);
    onSetEmailSetting(data.data);
  }, [checked]);

  useEffect(() => {
    if (emailSetting) {
      setChecked(emailSetting.ativo);
    }
  }, [setChecked, emailSetting]);

  return (
    <ModalComponent
      title="Configurações de Email"
      icon={HiOutlineMail}
      onClose={onCloseModal}
      modalVisible={showModal}
    >
      <Formik
        initialValues={emailSetting || initialData}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="textarea" name="defaultMessage" label="Mensagem Padrão" />
          <Row>
            <Col xs={12} md={12} className="d-flex flex-column mb-3">
              <strong style={{ fontSize: '0.8rem', color: '#333', paddingLeft: '4px' }} className="mb-2">
                Ativar Notificações
              </strong>
              <Switch checked={checked} onChange={setChecked} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>

    </ModalComponent>
  );
};
