import styled from 'styled-components';

export const Label = styled.label`
  font-size: 0.8rem;
  font-weight: 600;
  background: #fff;
  pointer-events: none;
  overflow: hidden;
  width: auto;
  display: inline-block;
  position: relative;
  top: 2px;
  left: 4px;
  color: #333333;
`;

export const ErrorContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 19.1875px;
  line-height: 24px;
  margin: 4px 0 0;
  padding: 0;
  pointer-events: auto;
  text-size-adjust: 100%;
  unicode-bidi: isolate;
  width: 581px;
  -webkit-box-align: center;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: red;
  gap: 4px;

  span {
    font-size: 12px;
  }
`;
