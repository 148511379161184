/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';

interface ModalFormProps {
  onCloseModal: () => void;
  showModal: boolean;
  subtema?: any;
  selectedChecklistId?: number;
}

export const ModalFormSubtema = ({
  onCloseModal,
  showModal,
  subtema,
  selectedChecklistId,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);

  const initialValues: any = {
    titulo: '',
    descricao: '',
  };

  const PERSIST_SUBTEMA_URL = `/checklistunidade/${selectedChecklistId}/subtema`;

  const validationSchema = yup.object().shape({
    titulo: yup.string().required('Campo obrigatório'),
    descricao: yup.string(),
  });

  const handleSubmit = async (dataForm: any) => {
    const isCreateOperation = !subtema;
    const id = subtema?.id ?? undefined;

    const requestData = {
      ...dataForm,
      id,
      ordem: subtema?.ordem,
      concluido: subtema?.concluido,
    };
    console.log('data', requestData);

    try {
      const sendRequest = isCreateOperation ? api.post : api.put;
      await sendRequest(PERSIST_SUBTEMA_URL, requestData).then(() => {
        toast.success('Operação realizada com sucesso');
      });
      onCloseModal();
    } catch (error: any) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error('Erro ao salvar Subtema de Checagem');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalComponent
        onClose={() => onCloseModal()}
        modalVisible={showModal}
        title={subtema ? 'Alterar Item de Subtema' : 'Incluir Subtema'}
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={subtema || initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Row>
              <Col xs={12} md={12}>
                <Input name="titulo" label="Título:" isRequerid />
                <Input name="descricao" label="Descrição:" />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  type="submit"
                  loading={loading}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      </ModalComponent>
    </>
  );
};
