/* eslint-disable max-len */
import { SiCircle } from 'react-icons/si';
import { ImHome, ImPhone, ImTree } from 'react-icons/im';
import { BsCheckAll } from 'react-icons/bs';
import { FaCog, FaUsers } from 'react-icons/fa';
import { BiDiamond } from 'react-icons/bi';
import { AiOutlineLineChart } from 'react-icons/ai';
import { useStore } from 'react-context-hook';
import { useAuth } from '../../hooks/auth';
import { HeaderTitle } from '../../components/HeaderTitle';
import { IEmpresa } from '../../interfaces/IEmpresa';
import { HomeItem } from './HomeItem';

export const Home = () => {
  const { user } = useAuth();
  const [company] = useStore<IEmpresa>('company');

  const FuncaoSistemaMapping: { order: number, name: string, component: JSX.Element }[] = [
    {
      order: 1,
      name: 'AdminConteudos',
      component: (
        <>
          <HomeItem
            nome="Conteúdos"
            icon={SiCircle}
            link="/categories"
          />
        </>
      ),
    },
    {
      order: 2,
      name: 'AdminUnidadesEmpresa',
      component: (
        <>
          <HomeItem
            nome="Unidades da Empresa"
            icon={ImTree}
            link="/unidades"
          />
        </>
      ),
    },
    {
      order: 3,
      name: 'AdminPesquisas',
      component: (
        <>
          <HomeItem
            nome="Pesquisas"
            icon={ImHome}
            link="/pesquisas"
          />
        </>
      ),
    },
    {
      order: 4,
      name: 'AdminComunicados',
      component: (
        <>
          <HomeItem
            nome="Comunicados"
            icon={ImPhone}
            link="/comunicados"
          />
        </>
      ),
    },
    {
      order: 5,
      name: 'AdminChecklist',
      component: (
        <>
          <HomeItem
            nome="Checklist"
            icon={BsCheckAll}
            link="/categoria-checklist"
          />
        </>
      ),
    },
    {
      order: 6,
      name: 'AdminGrupos',
      component: (
        <>
          <HomeItem
            nome="Grupos"
            icon={ImTree}
            link="/grupos"
          />
        </>
      ),
    },
    {
      order: 7,
      name: 'AdminUsuarios',
      component: (
        <>
          <HomeItem
            nome="Usuários"
            icon={FaUsers}
            link="/usuarios"
          />
        </>
      ),
    },
    {
      order: 8,
      name: 'AdminMinhaEmpresa',
      component: (
        <>
          <HomeItem
            nome="Minha Empresa"
            icon={BiDiamond}
            link="/gamificacao"
          />
        </>
      ),
    },
    {
      order: 9,
      name: 'AdminPainelBi',
      component: (
        <>
          <HomeItem
            nome="Painel BI"
            icon={AiOutlineLineChart}
            link={company?.linkBi ?? ''}
          />
        </>
      ),
    },
    {
      order: 10,
      name: 'AdminSuperAdmin',
      component: (
        <>
          <HomeItem
            nome="Administração"
            icon={FaCog}
            link="/super-admin"
          />
        </>
      ),
    },
    {
      order: 11,
      name: 'AdminValidacaoChecklist',
      component: (
        <>
          <HomeItem
            nome="Validação de Checklist"
            icon={ImTree}
            link="/validacoes"
          />
        </>
      ),
    },
  ];

  function RolesMenu({ rolesToMap }: any) {
    if (typeof rolesToMap === 'string') {
      const roleValue = FuncaoSistemaMapping.find((mapping) => mapping.name === rolesToMap)?.component ?? null;
      return roleValue;
    }
    const orderedRoles = [...rolesToMap].sort((a, b) => {
      const orderA = FuncaoSistemaMapping.find((mapping) => mapping.name === a)?.order || 0;
      const orderB = FuncaoSistemaMapping.find((mapping) => mapping.name === b)?.order || 0;

      if (orderA === orderB) {
        return 0;
      }
      return orderA - orderB;
    });

    return (
      <>
        {orderedRoles.map((role) => {
          const roleValue = FuncaoSistemaMapping.find((mapping) => mapping.name === role)?.component;
          if (roleValue === undefined) {
            return null;
          }
          return roleValue;
        })}
      </>
    );
  }

  return (
    <>
      <HeaderTitle
        title="Inicio"
        text="Selecione uma funcionalidade para começar.."
      />

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '16px', paddingTop: '16px' }}>
        <RolesMenu rolesToMap={user?.roles} />
      </div>
    </>
  );
};
