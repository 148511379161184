/* eslint-disable react-hooks/exhaustive-deps */
import { ComponentType, useEffect, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiBarcode, BiTrash } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { IconType } from 'react-icons/lib';
import { Content } from '../../styles/components';
import theme from '../../styles/theme';
import { Actions, Category, Container, Description } from './styles';
import { useAuth } from '../../hooks/auth';

interface AdditionalOptions {
  icon: IconType,
  label: string,
  onClick: (id: number) => void,
}

interface Props {
  category: string;
  icon: ComponentType<IconBaseProps>;
  link?: string;
  title?: string;
  nome?: string;
  descricao?: string;
  id: number;
  onClick?: (id: number) => void;
  onFormEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
  seeStatus?: boolean;
  status?: string;
  additionalOptions?: AdditionalOptions[];
  titleWithId?: boolean;
  handleQRCode?: (e: any) => any;
}

export const ItemList = ({
  category,
  icon: Icon,
  onFormEdit, onDelete, onClick,
  title,
  descricao,
  nome,
  seeStatus,
  status,
  id,
  titleWithId,
  additionalOptions,
  handleQRCode,
  link }: Props) => {
  const { user } = useAuth();
  const [npsPermission, setNpsPermission] = useState<boolean>();

  const handleClick = () => {
    const fn = onClick || onFormEdit;
    fn && fn(id);
  };

  const handleSolvePermissions = () => {
    if (typeof user.roles === 'string') {
      setNpsPermission(user?.roles === 'AdminNps');
    } else {
      setNpsPermission(!!user?.roles?.find((role: string) => role === 'AdminNps'));
    }
  };

  useEffect(() => handleSolvePermissions(), [user]);

  return (
    <Content>
      <Container>
        {link ? (
          <Link to={link} className="d-flex align-items-center h-100">
            <Category className="d-flex flex-column align-items-center">
              <h6>{category}</h6>
              <Icon size={24} />
            </Category>
            <Description className="w-100">
              <h6>{nome || title}</h6>
              <p>{descricao}</p>
              {seeStatus && (
                <p>
                  <strong>Status: </strong>
                  {status}
                </p>
              )}
            </Description>
          </Link>
        ) : (
          <button
            type="button"
            onClick={handleClick}
            className="d-flex align-items-center h-100 bg-transparent border-0"
          >
            <Category className="d-flex flex-column align-items-center">
              <h6>{category}</h6>
              <Icon size={24} />
            </Category>
            <Description className="w-100">
              <h6 className="text-start">{nome || title}</h6>
              {titleWithId && <p className="text-start" style={{ fontWeight: 700, marginTop: 2, marginLeft: 2 }}>{`ID - ${id}`}</p>}
              <p className="text-start">{descricao || 'Descrição'}</p>
            </Description>
          </button>
        )}

        <Actions>
          {onFormEdit && (
            <button type="button" onClick={() => onFormEdit(id)}>
              <AiOutlineEdit size={20} color={theme.colors.main} />
            </button>
          )}

          {npsPermission && handleQRCode ? (
            <button type="button" onClick={handleQRCode ? () => handleQRCode(id) : undefined}>
              <BiBarcode size={20} color={theme.colors.main} />
            </button>
          ) : null}

          {additionalOptions && additionalOptions.map((option, index) => (
            <button type="button" onClick={() => option.onClick(id)} key={index}>
              <option.icon size={20} color={theme.colors.main} />
            </button>
          ))}

          {onDelete && (
            <button type="button" onClick={() => onDelete(id)}>
              <BiTrash size={19} color={theme.colors.main} />
            </button>
          )}
        </Actions>
      </Container>
    </Content>
  );
};
