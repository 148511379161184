/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik, Form, Field } from 'formik';
import { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { IoScanCircleOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Switch from 'react-switch';
import { FaHourglassEnd } from 'react-icons/fa';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { ISelect } from '../../../../interfaces/ISelect';
import { ReactSelect } from '../../../../components/ReactSelect';
import { IFase } from '../../../../interfaces/IFase';
import handleErrors from '../../../../utils/handle.errors';

const fileInitialData = {
  id: undefined,
  url: '',
  titulo: '',
  descricao: '',
};

const videoInitialData = {
  id: undefined,
  tipoMidiaId: 1,
  url: '',
  titulo: '',
  descricao: '',
};

const initialData: IFase = {
  id: 0,
  cursoId: undefined,
  ordem: undefined,
  titulo: '',
  icone: '',
  iconeBloqueado: '',
  arquivo: fileInitialData,
  video: videoInitialData,
};

interface ModalFormCursoProps {
  onSetFase: (data: IFase, update?: boolean) => void;
  onCloseModal: () => void;
  cursoId: number;
  fase?: IFase;
  showModalFormCurso: boolean;
}

export const ModalFormFase = ({
  onSetFase,
  showModalFormCurso,
  cursoId,
  fase,
  onCloseModal,
}: ModalFormCursoProps) => {
  const [loading, setLoading] = useState(false);
  const [typesMedia, setTypesMedia] = useState<ISelect[]>([]);
  const [includeVideo, setIncludeVideo] = useState<boolean>(false);
  const [includeFile, setIncludeFile] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  const videoSchema = yup.object({
    titulo: yup.string().nullable(),
    tipoMidiaId: yup.lazy((value) => (typeof value === 'object'
      ? yup.object().nullable()
      : yup.string())),
    url: yup.string().required('A URL é obrigatória'),
    descricao: yup.string().nullable(),
  }).nullable();

  const fileSchema = yup.object({
    url: yup.string().required('A URL é obrigatória'),
    titulo: yup.string().nullable(),
    descricao: yup.string().nullable(),
  }).nullable();

  const schema = yup.object({
    titulo: yup.string().required('O titulo é obrigatório'),
    video: includeVideo ? videoSchema : yup.object().nullable(),
    arquivo: includeFile ? fileSchema : yup.object().nullable(),
  });

  useEffect(() => {
    api
      .get('tipomidia')
      .then(({ data }) => {
        setTypesMedia(
          data.data.map((item: { id: number, opcao: string }) => ({
            label: item.opcao,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de tipos de mídia');
      });

    return () => {
      setTypesMedia([]);
    };
  }, []);

  useEffect(() => {
    if (fase) {
      setIncludeVideo(!!fase?.video);
      setIncludeFile(!!fase?.arquivo);
      setIsBlocked(!!fase?.isBlocked);
    }

    return () => {
      setIncludeVideo(false);
      setIncludeFile(false);
      setIsBlocked(false);
    };
  }, [fase, cursoId]);

  const handleSubmit = useCallback(
    async (dataForm: IFase) => {
      try {
        setLoading(true);

        const dataFormatted = {
          ...dataForm,
          video: includeVideo && dataForm.video ? {
            ...dataForm.video,
            tipoMidiaId: dataForm.video?.tipoMidiaId?.value || dataForm.video?.tipoMidiaId || 1,
          } : null,
          arquivo: includeFile ? dataForm.arquivo : null,
          isBlocked,
        };

        if (fase) {
          const { data } = await api.put(`${cursoId}/fase/${fase.id}`, {
            ...dataFormatted,
            id: fase.id,
            cursoId,
          });
          onSetFase(data.data, true);
        } else {
          const { data } = await api.post(`${cursoId}/fase`, {
            ...dataFormatted,
            id: undefined,
            cursoId,
          });
          onSetFase(data.data);
        }

        toast.success('Fase salva com sucesso');
        onCloseModal();
      } catch (err) {
        handleErrors(err);
      } finally {
        setLoading(false);
        setIncludeVideo(false);
        setIncludeFile(false);
        setIsBlocked(false);
      }
    },
    [cursoId, onSetFase, onCloseModal, fase, includeVideo, includeFile, isBlocked],
  );
  const getFaseData = (faseParam: IFase) => ({
    ...faseParam,
    video: faseParam.video == null ? videoInitialData : faseParam.video,
  });

  return (
    <>
      <ModalComponent
        onClose={onCloseModal}
        modalVisible={showModalFormCurso}
        title={fase ? 'Alterar Fase' : 'Adicionar Fase'}
        icon={IoScanCircleOutline}
        size="lg"
      >
        <p>
          Aqui você irá criar fases/módulos/etapas para seu curso.
          Isto será importante para que seus jogadores possam ver progresso
          no desenvolvimento. Você pode inserir um vídeo introdutório ao módulo
          e uma apostila em .PDF também.
          {' '}
          {`${includeFile}`}
        </p>
        <h6>Preencha todos os dados solicitados.</h6>

        <Formik
          initialValues={fase ? getFaseData(fase) : initialData}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          <Form>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Input type="text" name="titulo" label="Qual será o titulo da Fase?" isRequerid />
                  </Col>

                </Row>

                {/* <Input type="textarea" name="descricao" label="Descrição" isRequerid /> */}

                <Row className="mt-3">
                  <div className="d-flex flex-column mb-3">
                    <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                      Bloqueada?
                    </strong>
                    <Switch
                      onChange={() => setIsBlocked((old) => !old)}
                      checked={isBlocked}
                      height={22}
                      width={53}
                    />

                  </div>

                  <div className="d-flex flex-column mb-3">
                    <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                      Incluir Arquivo?
                    </strong>
                    <Switch
                      onChange={() => setIncludeFile((old) => !old)}
                      checked={includeFile}
                      height={22}
                      width={53}
                    />

                  </div>
                  {includeFile
                    && (
                      <div className="d-flex flex-column mb-3">
                        <Col md={12}>
                          <Input type="text" name="arquivo.titulo" label="Titulo do arquivo" />
                        </Col>

                        <Col md={12}>
                          <Input type="text" name="arquivo.url" label="URL do arquivo" isRequerid />
                        </Col>

                        <Col md={12}>
                          <Input type="textarea" name="arquivo.descricao" label="Descrição" />
                        </Col>
                      </div>
                    )
                  }
                  <div className="d-flex flex-column mb-3">
                    <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                      Incluir Vídeo?
                    </strong>
                    <Switch
                      onChange={() => setIncludeVideo((old) => !old)}
                      checked={includeVideo}
                      height={22}
                      width={53}
                    />

                  </div>

                  {includeVideo
                    && (
                      <>
                        <Col md={12}>
                          <Input type="text" name="video.titulo" label="Titulo do vídeo" />
                        </Col>

                        <Col md={4} className="mt-2">
                          <Field
                            name="video.tipoMidiaId"
                            label="Tipo de mídia"
                            component={ReactSelect}
                            options={typesMedia}
                          />
                        </Col>
                        <Col md={8} className="mt-2">
                          <Input type="text" name="video.url" label="URL do vídeo" isRequerid />
                        </Col>

                        <Col md={12} className="mt-2">
                          <Input
                            type="textarea"
                            name="video.descricao"
                            label="Coloque aqui o conteúdo por escrito"
                          />
                        </Col>
                      </>
                    )
                  }
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  loading={loading}
                  className="text-uppercase"
                  type="submit"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      </ModalComponent>
    </>
  );
};
