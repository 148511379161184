import { ImCopy } from 'react-icons/im';
import { AiOutlineQrcode } from 'react-icons/ai';
import QRCode from 'react-qr-code';
import { useStore } from 'react-context-hook';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaDownload, FaPrint } from 'react-icons/fa';
import { ModalComponent } from '../../../../components/Modal';
import { ConvertedTheme } from '../../../../App';
import {
  ButtonOnlyQrCode,
  Container,
  Description,
  LinkBottom,
  Logo,
  QRBody,
  QRCodeContainer,
} from './styles';
import { IQuestionario } from '../../../../interfaces/IQuestionario';
import api from '../../../../services/api';
import { Button } from '../../../../components/Button';

interface ModalQRCodeQuestionarioProps {
  isOpen: boolean;
  onClose: () => void;
  questionario?: IQuestionario;
}

export const ModalQRCodeQuestionario = (props: ModalQRCodeQuestionarioProps) => {
  const { isOpen, onClose, questionario } = props;
  const [theme] = useStore<ConvertedTheme>('theme');
  const [linkExternoUrl, setLinkExternoUrl] = useState('');

  const printContainer = () => {
    window.print();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(linkExternoUrl);
    toast.success('Link copiado com sucesso');
  };

  useEffect(() => {
    if (props?.questionario?.id !== undefined) {
      api.get(`/questionario-empresa/${props.questionario.id}/link-externo`)
        .then(({ data }) => {
          setLinkExternoUrl(data.data);
        })
        .catch(() => {
          console.log('Erro ao recuperar link');
        });
    }
  }, [isOpen]);

  return (
    <ModalComponent title="Pesquisa Externa" icon={AiOutlineQrcode} onClose={onClose} modalVisible={isOpen}>
      <Container className="only-qr-code" id="contentToPrint">
        <ButtonOnlyQrCode
          type="button"
          onClick={() => {
            printContainer();
          }}
        >
          <FaDownload size={24} />
        </ButtonOnlyQrCode>
        <Logo>
          <img src={theme.imgLogo} alt={process.env.REACT_APP_COMPANY_NAME} width="130" />
        </Logo>
        <QRBody className="qr-code">
          <Description>
            <p>{questionario?.titulo}</p>
          </Description>
          <QRCodeContainer className="qr-code">
            <QRCode size={192} value={linkExternoUrl} />
            <QRCode className="qr-code" size={300} value={linkExternoUrl} />
          </QRCodeContainer>
        </QRBody>
        <LinkBottom>
          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '400px', display: 'inline-block' }}>
            <a href={linkExternoUrl}>{linkExternoUrl}</a>
          </span>
        </LinkBottom>
        <Button
          type="button"
          color="#fff"
          onClick={copyToClipboard}
        >
          Copiar Link
          {' '}
          <ImCopy />
        </Button>
      </Container>
    </ModalComponent>
  );
};
