import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { AiOutlineEdit } from 'react-icons/ai';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { VscChecklist } from 'react-icons/vsc';
import Swal from 'sweetalert2';
import { HeaderTitle } from '../../../components/HeaderTitle';
import { ItemList } from '../../../components/ItemList';
import { ModalFormChecklistCategory } from '../ModalFormChecklistCategory';
import api from '../../../services/api';

export const ChecklistCategoryList = () => {
  const param: {id: string} = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [showModalForm, setShowModalForm] = useState(false);
  const [checklistCategoriesList, setChecklistCategoriesList] = useState<any[]>([]);
  const [selectedChecklistCategory, setSelectedChecklistCategory] = useState<any>(undefined);
  const [paiId, setPaiId] = useState<string>('');

  const CHECKLIST_CATEGORY_BASE_URL = '/checklist-unidade';

  const builderHistoryTitle: () => JSX.Element[] = () => {
    const link = '/categoria-checklist';
    const result: JSX.Element[] = [
      <Link to={link} key={link}>
        Categorias do Checklist
      </Link>,
    ];

    return result;
  };

  const getCategoriasChecklist = (id: any) => {
    if (paiId) {
      api.get(`${CHECKLIST_CATEGORY_BASE_URL}/categorias/pai/${id}`)
        .then(({ data }) => {
          setChecklistCategoriesList(data.data);
        })
        .catch(() => {
          toast.error('Erro ao buscar lista de categorias');
        });
    }
  };

  useEffect(() => {
    setPaiId(param.id.split('/').pop() ?? '');
  }, [param]);

  useEffect(() => {
    try {
      getCategoriasChecklist(paiId);
    } catch (error: any) {
      toast.error(error.message);
    }
  }, [paiId]);

  const handleFormClose = () => {
    setShowModalForm(false);
    getCategoriasChecklist(paiId);
  };

  const handleNewItem = () => {
    setSelectedChecklistCategory(undefined);
    setShowModalForm(true);
  };

  const handleOpenChecklist = (id: number) => {
    history.push(`/categoria-checklist/checklist/${id}`);
  };

  const handleOpenChecklistCategory = (id: number) => {
    history.push(`${pathname}/${id}`);
  };

  const handleEditChecklistCategory = (id: number) => {
    const tempSelectedChecklistCategory = checklistCategoriesList
      .find((c) => c.checklistUnidadeCategoriaId === id);

    setSelectedChecklistCategory(tempSelectedChecklistCategory);
    setShowModalForm(true);
  };

  const handleDelete = (id: number) => {
    api.delete(`${CHECKLIST_CATEGORY_BASE_URL}/categorias/${id}`)
      .then(() => {
        toast.success('Categoria de Checklist excluída com sucesso');
        getCategoriasChecklist(paiId);
      })
      .catch((error) => {
        if (String(error).includes('403')) return toast.error('Você não tem permissão para essa operação. Consulte seu RH.');
        return toast.error('Não foi possível excluir a categoria');
      });
  };

  const handleDeleteCategory = (id: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir essa categoria de checklist?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          handleDelete(id);
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao remover categoria de checklist');
        }
      }
    });
  };

  const additionalOptions = [
    {
      icon: AiOutlineEdit,
      label: 'Editar Checklist',
      onClick: (id: number) => handleEditChecklistCategory(id),
    },
    {
      icon: VscChecklist,
      label: 'Checklists de Rotina',
      onClick: (id: number) => handleOpenChecklist(id),
    },
  ];

  return (
    <>
      <ModalFormChecklistCategory
        onClose={handleFormClose}
        isOpen={showModalForm}
        checklistCategory={selectedChecklistCategory}
        categoriaPaiId={paiId}
        isSuperAdmin
      />

      <HeaderTitle
        onNewItem={handleNewItem}
        title={builderHistoryTitle()}
        text="Nesta aba você pode ver as categorias dos checklists. Clique para ver as subcategorias."
      />

      <Row>
        {checklistCategoriesList.map((checklist, index) => (
          <Col key={index} md={6} className="mt-3">
            <ItemList
              {...checklist}
              id={checklist?.checklistUnidadeCategoriaId || 0}
              icon={BsCheckAll}
              category="Categoria"
              title={checklist.nome}
              onClick={() => handleOpenChecklistCategory(checklist.checklistUnidadeCategoriaId)}
              onDelete={() => handleDeleteCategory(checklist.checklistUnidadeCategoriaId)}
              additionalOptions={additionalOptions}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
