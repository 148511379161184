import { Formik, Form, Field } from 'formik';
import { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CPF from 'cpf-check';
import * as yup from 'yup';
import { useStore } from 'react-context-hook';
import iconUser from '../../assets/icons/user.svg';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import api from '../../services/api';
import { Content } from '../../styles/components';
import theme from '../../styles/theme';
import { Profile } from './styles';
import { useAuth } from '../../hooks/auth';
import { ReactSelect } from '../../components/ReactSelect';
import { ISelect } from '../../interfaces/ISelect';
import handleErrors from '../../utils/handle.errors';
import { IEmpresa } from '../../interfaces/IEmpresa';
import { CheckAndUncheckBtn } from '../Users/styles';

const dataInitial = {
  nome: '',
  email: '',
  cpf: '',
  apelido: '',
  filialIds: [],
  unidadesIds: [],
  novaSenha: '',
  confirmarNovaSenha: '',
};

interface SubmitProps {
  nome: string;
  email: string;
  cpf: string;
  apelido: string;
  novaSenha: string;
  confirmarNovaSenha: string;
  filialIds: Array<ISelect>;
  unidadesIds: Array<ISelect>;
}

const schema = yup.object({
  nome: yup.string().required('Campo obrigatório'),
  apelido: yup.string().nullable(),
  email: yup.string().email().required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('Digite seu cpf')
    .test('cpf invalido', 'CPF Inválido', (value) => CPF.validate(value)),
  filialIds: yup
    .array()
    .required('Campo obrigatório')
    .min(1, 'Selecione pelo menos 1'),
  unidadesIds: yup
    .array()
    .required('Campo obrigatório')
    .min(1, 'Selecione pelo menos 1'),
  novaSenha: yup.string(),
  confirmarNovaSenha: yup
    .string()
    .when('novaSenha', (password, field) => (password ? field.required('Confirme sua senha') : field))
    .oneOf([yup.ref('novaSenha')], 'Confirmação inválida'),
});

export const Perfil = () => {
  const [perfil, setPerfil] = useState<SubmitProps>();
  const [filiais, setFiliais] = useState<ISelect[]>([]);
  const [loading, setLoading] = useState(false);
  const { user, updateUser } = useAuth();
  const [company] = useStore<IEmpresa>('company');
  const [units, setUnits] = useState<ISelect[]>([]);

  useEffect(() => {
    api
      .get(`usuario/${user.UsuarioId}`)
      .then(({ data }) => setPerfil({
        ...data.data,
        filialIds: data.data.filiais.map(
          (p: { id: number; nome: string }) => ({
            value: p.id,
            label: p.nome,
          }),
        ),
        unidadesIds: data.data.unidades.map(
          (p: { id: number; nome: string }) => ({
            value: p.id,
            label: p.nome,
          }),
        ) || [],
      }))
      .catch((err) => handleErrors(err));
  }, [user]);

  useEffect(() => {
    api
      .get('filial')
      .then(({ data }) => setFiliais(
        data.data.map((item: { id: number; nome: string }) => ({
          label: item.nome,
          value: item.id,
        })),
      ))
      .catch(() => {
        toast.error('Erro ao buscar lista de grupos');
      });
  }, []);

  useEffect(() => {
    api
      .get('unidade-empresa')
      .then(({ data }) => setUnits(
        data.data.map((item: { id: number; nome: string }) => ({
          label: item.nome,
          value: item.id,
        })),
      ))
      .catch(() => {
        toast.error('Erro ao buscar lista de Grupos');
      });
  }, []);

  const handleSubmit = async ({
    novaSenha,
    confirmarNovaSenha,
    apelido,
    cpf,
    filialIds,
    unidadesIds,
    nome,
    email,
  }: SubmitProps) => {
    try {
      setLoading(true);
      await api.put(`usuario/${user.UsuarioId}`, {
        id: user.UsuarioId,
        perfilId: user.PerfilId,
        empresaId: company.id,
        cpf: cpf.replace(/[-.]/g, ''),
        filialIds: filialIds.map((p) => +p.value).concat([]),
        unidadesIds: unidadesIds.map((p) => +p.value).concat([]),
        nome,
        apelido,
        email,
      });
      updateUser({ ...user, unique_name: nome, email: [email] });
      if (novaSenha && confirmarNovaSenha) {
        await api.put(`usuario/${user.UsuarioId}/senha`, {
          id: user.UsuarioId,
          novaSenha,
          confirmarNovaSenha,
        });
      }
      toast.success('Perfil atualizado com sucesso');
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col md={12} className="mt-3">
        <Profile>
          <img src={iconUser} alt={perfil?.nome || ''} />
          <div>
            <h5>{user.unique_name}</h5>
            <span>{user.email[0]}</span>
          </div>
        </Profile>
      </Col>
      {/*
        <Col md={7} className="mt-3">
          <Content className="d-flex justify-content-between">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h6>Acessos</h6>
              <RiUser6Fill size={36} />
            </div>
            <Acessos>
              <span>Em breve</span>

            </Acessos>
            <Media>
              <h6>Média mensal</h6>
              <h3>-</h3>
              <span>Em breve</span>
            </Media>
          </Content>
        </Col>
      */}
      <Col md={12} className="mt-4">
        <Content>
          <h4>Seus dados</h4>
          <p className="text-secondary mb-0">
            Este é o seu perfil. Mantenha seus dados sempre atualizados.
          </p>

          <Row className="mt-3">
            <Col>
              <Formik
                initialValues={perfil || dataInitial}
                enableReinitialize
                validationSchema={schema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <Row>
                      <Col md={12} className="border-dotted-top">
                        <h6 className="text-secondary">Dados pessoais</h6>
                      </Col>
                      <Col md={6} className="mt-2">
                        <Input type="text" name="nome" label="Nome" isRequerid />
                      </Col>
                      <Col md={6} className="mt-2">
                        <Input type="text" name="apelido" label="Apelido" />
                      </Col>
                      <Col md={6} className="mt-2">
                        <Input
                          type="text"
                          name="cpf"
                          label="CPF"
                          mask="999.999.999-99"
                          isRequerid
                        />
                      </Col>
                      <Col md={6} className="mt-2">
                        <Input
                          type="email"
                          name="email"
                          label="Email"
                          isRequerid
                        />
                      </Col>
                      <Col md={6} className="mt-2">
                        <Field
                          name="filialIds"
                          label="Grupo"
                          isRequerid
                          isMulti
                          component={ReactSelect}
                          options={filiais}
                        />
                        <CheckAndUncheckBtn
                          href="#"
                          onClick={() => setFieldValue('filialIds', filiais, false)}
                        >
                          Marcar Todos

                        </CheckAndUncheckBtn>
                        <CheckAndUncheckBtn
                          href="#"
                          onClick={() => setFieldValue('filialIds', [], false)}
                        >
                          Desmarcar Todos

                        </CheckAndUncheckBtn>
                      </Col>
                      <Col md={6} className="mt-2">
                        <Field
                          name="unidadesIds"
                          isRequerid
                          label="Unidades (pode ser mais de 1)"
                          isMulti
                          component={ReactSelect}
                          options={units}
                        />
                        <CheckAndUncheckBtn
                          href="#"
                          onClick={() => setFieldValue('unidadesIds', units, false)}
                        >
                          Marcar Todos

                        </CheckAndUncheckBtn>
                        <CheckAndUncheckBtn
                          href="#"
                          onClick={() => setFieldValue('unidadesIds', [], false)}
                        >
                          Desmarcar Todos

                        </CheckAndUncheckBtn>
                      </Col>

                      <Col md={12} className="border-dotted-top">
                        <h6 className="text-secondary">Segurança</h6>
                      </Col>
                      <Col md={3} className="mt-2">
                        <Input
                          name="novaSenha"
                          label="Nova senha"
                          type="password"
                        />
                      </Col>
                      <Col md={3} className="mt-2">
                        <Input
                          name="confirmarNovaSenha"
                          label="Confirmar senha"
                          type="password"
                        />
                      </Col>

                      <Col xs={{ span: 4, offset: 4 }} className="mt-3">
                        <Button
                          type="submit"
                          background={theme.colors.main}
                          color="#fff"
                          loading={loading}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Content>
      </Col>

      {/*
      <Col md={4} className="mt-4">
        <Tutorial>
          <Content className="d-flex align-items-center">
            <div className="text-center">
              <h6>Tutoriais</h6>
              <BsCollectionPlay />
            </div>
            <div className="ms-3">
              <h5>Em Breve</h5>
              <p className="text-secondary mb-0">Em breve</p>
            </div>
          </Content>
        </Tutorial>

        <Tutorial>
          <Content className="d-flex align-items-center">
            <div className="text-center">
              <h6>Tutoriais</h6>
              <BsCollectionPlay />
            </div>
            <div className="ms-3">
              <h5>Em Breve</h5>
              <p className="text-secondary mb-0">Em breve</p>
            </div>
          </Content>
        </Tutorial>

        <Tutorial>
          <Content className="d-flex align-items-center">
            <div className="text-center">
              <h6>Tutoriais</h6>
              <BsCollectionPlay />
            </div>
            <div className="ms-3">
              <h5>Em Breve</h5>
              <p className="text-secondary mb-0">Em breve</p>
            </div>
          </Content>
        </Tutorial>

        <Tutorial>
          <Content className="d-flex align-items-center">
            <div className="text-center">
              <h6>Tutoriais</h6>
              <BsCollectionPlay />
            </div>
            <div className="ms-3">
              <h5>Em Breve</h5>
              <p className="text-secondary mb-0">Em breve</p>
            </div>
          </Content>
        </Tutorial>

        <Tutorial>
          <Content className="d-flex align-items-center">
            <div className="text-center">
              <h6>Tutoriais</h6>
              <BsCollectionPlay />
            </div>
            <div className="ms-3">
              <h5>Em Breve</h5>
              <p className="text-secondary mb-0">Em breve</p>
            </div>
          </Content>
        </Tutorial>
      </Col>
      */}
    </Row>
  );
};
