import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const Question = styled.p`
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const QuestionRow = styled(Row)`
  background-color: #ececec; 
  border-radius: 5px; 
  margin-right: .1em; 
  margin-left: .1em;
  border-top-left-radius: 8px; 
  border-bottom-left-radius: 8px;
`;
