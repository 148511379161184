/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useStore } from 'react-context-hook';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { IEmpresa } from '../../../../interfaces/IEmpresa';
import api from '../../../../services/api';
import { convertToPtBrDateTime } from '../../../../utils/shared-functions';
import { LabelStyled } from '../../../Users/ModalImport/styles';
import HtmlEditor from '../Html-Editor/editor';

type ComponentProps = {
  isEscopoEmpresa?: boolean
}

const initialData = {
  titulo: '',
};

export const FormUseTerms = ({ isEscopoEmpresa = false }: ComponentProps) => {
  const [newContent, setNewContent] = useState<string>();
  const [currentUseTerms, setCurrentUseTerms] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [company] = useStore<IEmpresa>('company');
  const [isLoadingTerms, setIsLoadingTerms] = useState<boolean>(true);

  useEffect(() => {
    setIsLoadingTerms(true);
    api
      .get(`termos/super-admin/ultimo?isEscopoEmpresa=${isEscopoEmpresa}`)
      .then((data: any) => {
        setCurrentUseTerms(data.data.data);
        setNewContent(data.data.data.conteudo || '');
      })
      .finally(() => {
        setIsLoadingTerms(false);
      });
  }, [isEscopoEmpresa]);

  const handleOnHtmlEditorChange = (content: string) => {
    setNewContent(content);
  };

  const handleSubmit = (formValues: {titulo?: string, conteudo?: string, empresaId?: number}) => {
    if (!formValues.titulo || !newContent) {
      toast.warning('Preencha os campos vazios');
      return;
    }

    setIsLoading(true);

    const formattedData: any = {
      Titulo: formValues.titulo,
      Conteudo: newContent,
    };

    if (isEscopoEmpresa) {
      formattedData.EmpresaId = company.id;
    }

    api.post(`termos/super-admin?isEscopoEmpresa=${isEscopoEmpresa}`, formattedData)
      .then(() => {
        setNewContent(undefined);
        toast.success('Novo termo salvo com sucesso');

        api.get(`termos/super-admin/ultimo?isEscopoEmpresa=${isEscopoEmpresa}`).then((data: any) => {
          setCurrentUseTerms(data.data.data);
          setNewContent(data.data.data.conteudo || ''); // Update editor content after saving
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Formik
      initialValues={currentUseTerms || initialData}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          {isLoadingTerms ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
              }}
            >
              <>
                <Spinner animation="border" role="status" />
              </>
            </div>
          ) : (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <Input type="text" name="titulo" label="Título" />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs={12}>
                  <LabelStyled>
                    Conteúdo (Para citar o nome da empresa, usar ###NOME_EMPRESA###)
                  </LabelStyled>

                  <HtmlEditor
                    placeholder="Escreva algo..."
                    value={newContent}
                    onChange={(content) => {
                      handleOnHtmlEditorChange(content);
                      setFieldValue('conteudo', content);
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={{ span: 4, offset: 4 }}>
                  <Button
                    background="transparent"
                    color="#fff"
                    loading={isLoading}
                    className="text-uppercase"
                    type="submit"
                    disabled={!newContent}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  Atualizado em:
                  {' '}
                  {convertToPtBrDateTime(currentUseTerms.dataCadastro)}
                </Col>
              </Row>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
