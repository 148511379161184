import { Col, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ImShare2 } from 'react-icons/im';
import { Content, Table } from '../../../styles/components';
import { Container, ContentTable } from './styles';
import { ModalEmpresasInstaladas } from './components/ModalEmpresasInstaladas';
import { ICurso } from '../../../interfaces/ICurso';
import api from '../../../services/api';

export const SuperAdminCourses = () => {
  const [cursos, setCursos] = useState<ICurso[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<ICurso | undefined>(undefined);
  const [isOpenModal, setOpenModal] = useState(false);

  const handleOnCompanyListClick = (curso: ICurso) => {
    setSelectedCourse(curso);
    setOpenModal(true);
  };

  const fetchCourses = useCallback(async () => {
    api
      .get('curso/super-admin')
      .then(({ data }) => setCursos(data.data))
      .catch(() => toast.error('Erro ao buscar cursos'));
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  return (
    <Container>
      <ModalEmpresasInstaladas
        onCloseModal={() => setOpenModal(false)}
        isOpen={isOpenModal}
        course={selectedCourse}
      />

      <Row>
        <Col md={12} className="mt-3">
          <Content>
            <ContentTable>
              <h4>Compartilhamento de Cursos</h4>
              <p className="mt-3 mb-3">
                Clique no ícone do curso para gerenciar as empresas
                que estão compartilhando este curso.
              </p>
              <Table responsive borderless>
                <thead>
                  <tr style={{ lineHeight: 'unset' }}>
                    <th>Nome</th>
                    <th className="d-none d-sm-table-cell">Descrição</th>
                    <th className="d-none d-sm-table-cell">Empresa Pai</th>
                    <th>Compartilhamentos</th>
                  </tr>
                </thead>
                <tbody>
                  {cursos?.map((curso: ICurso, idx: number) => (
                    <tr key={idx} style={{ lineHeight: 'unset' }}>
                      <td>{curso.nome}</td>
                      <td className="d-none d-sm-table-cell">{curso.descricao}</td>
                      <td className="d-none d-sm-table-cell">{curso.nomeEmpresaPai}</td>
                      <td className="d-flex align-items-center">
                        <button type="button" onClick={() => handleOnCompanyListClick(curso)}>
                          <ImShare2 />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </ContentTable>
          </Content>

        </Col>

      </Row>
    </Container>
  );
};
