import { IconBaseProps } from 'react-icons/lib';
import Swal from 'sweetalert2';
import theme from '../../styles/theme';
import { Modal } from './styles';

interface ModalComponentProps {
  children: React.ReactNode;
  title: string;
  icon: React.ComponentType<IconBaseProps>;
  onClose: () => void;
  modalVisible: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  closeModal?: boolean;
}

export const ModalComponent = ({
  children,
  title,
  onClose,
  modalVisible,
  icon: Icon,
  size,
  closeModal = false,
}: ModalComponentProps) => (
  <Modal
    show={modalVisible}
    onHide={() => {
      if (closeModal) onClose();
      if (!closeModal) {
        Swal.fire({
          title: 'Deseja realmente fechar? ',
          text: 'Você perderá os dados alterados não salvos...',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ef5350',
          cancelButtonColor: '#757575',
          confirmButtonText: 'Sim, fechar',
          cancelButtonText: 'Cancelar',
          denyButtonColor: '#757575',
        }).then(async (result) => {
          if (result.isConfirmed) {
            onClose();
          }
        });
      }
    }}
    size={size}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <div className="d-flex align-items-center">
        <Icon color={theme.colors.main} size={24} />
        <h5>{title}</h5>
      </div>
    </Modal.Header>
    <Modal.Body>
      {children}
    </Modal.Body>
  </Modal>
);
