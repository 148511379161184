import styled from 'styled-components';

export const Container = styled.div`
  p {
    color: ${({ theme }) => theme.colors.text};
  }

  h4 {
    color: ${({ theme }) => theme.colors.main};
  }
`;

export const ContentTable = styled.div`
  .accordion-item{
    border-radius: 50px !important;
  }

  .accordion-button{
    padding: 1rem 0 !important;

    &:not(.collapsed) {
      background-color: inherit !important;
      > h4{
        color: ${({ theme }) => theme.colors.main};
      }
    }

    &:focus{
      border: none !important;
      box-shadow: none !important;
    }

  }


  .accordion-body{
    flex-direction: column;
  }

`;

export const HeaderNewItem = styled.div`
  p {
    padding-right: 2rem;
    margin-right: 2rem;
    margin-bottom: 0;
    border-right: 2px dotted ${({ theme }) => theme.colors.text};
  }

  @media (max-width: 768px) {
    p {
      padding-right: 0;
      margin-right: 0;
      border: 0;
    }
  }
`;
