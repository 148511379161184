import { ComponentType } from 'react';
import { IconBaseProps } from 'react-icons';
import { Link } from 'react-router-dom';
import { Category, Container, Description } from './styles';
import { Content } from '../../../styles/components';

interface Props {
  icon: ComponentType<IconBaseProps>;
  link?: string;
  title?: string;
  nome?: string;
}

export const HomeItem = ({
  icon: Icon,
  title,
  nome,
  link }: Props) => (
    <Content>
      <Container>
        <Link to={link ?? '#'} className="d-flex align-items-center h-100" style={{ cursor: link ? 'pointer' : 'inherit' }}>
          <Category className="d-flex flex-column align-items-center">
            <Icon size={24} />
          </Category>
          <Description className="d-flex flex-column justify-content-center w-100 h-100">
            <h6>{nome || title}</h6>
          </Description>
        </Link>
      </Container>
    </Content>
);
