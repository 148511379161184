import styled from 'styled-components';

export const CheckboxCircleComponent = styled.label`
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
  }

  input:checked + span {
    background-color: #1e7cb6; /* Cor de fundo quando selecionado */
    color: #fff;
  }
`;
