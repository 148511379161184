import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { Col, Row } from 'react-bootstrap';
import { ImQuestion, ImTree } from 'react-icons/im';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { Container } from './styles';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ItemList } from '../../components/ItemList';
import { IBehaviorQuestion } from '../../interfaces/IBehaviourQuestion';
import { ModalFormBehaviorQuestion } from './components/ModalFormBehaviorQuestion';

export const BehaviorQuestions = () => {
  const [behaviorQuestions, setBehaviorQuestions] = useState<IBehaviorQuestion[]>([]);
  const [questionToEdit, setQuestionToEdit] = useState<IBehaviorQuestion>();
  const [showModalForm, setShowModalForm] = useState(false);

  const fetchQuestions = () => {
    api
      .get('/perguntacomportamental')
      .then(({ data }) => setBehaviorQuestions(data.data))
      .catch(() => {
        toast.error('Erro ao buscar lista de Perguntas da Plataforma');
      });
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleOnCloseModal = () => {
    setShowModalForm(false);
    fetchQuestions();
    setQuestionToEdit(undefined);
  };

  const handleUpdateBehaviorQuestion = (id: number) => {
    const selectedQuestion = behaviorQuestions.find(
      (question: IBehaviorQuestion) => question.id === id,
    );
    if (selectedQuestion) {
      setQuestionToEdit(
        { ...selectedQuestion, qtdeAlternativas: selectedQuestion.alternativas.length },
      );
      setShowModalForm(true);
    }
  };

  const handleDeleteBehaviorQuestion = useCallback(async (behaviorQuestionId: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/perguntacomportamental/${behaviorQuestionId}`);
          fetchQuestions();
          toast.success('Pergunta Comportamental removida com sucesso');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir Pergunta Comportamental');
        }
      }
    });
  }, []);

  return (
    <Container>
      <ModalFormBehaviorQuestion
        showModal={showModalForm}
        onCloseModal={handleOnCloseModal}
        questionToEdit={questionToEdit}
      />

      <HeaderTitle
        onNewItem={() => setShowModalForm(true)}
        title="Perguntas da Plataforma"
        text="Nesta aba você deve cadastrar as Perguntas da Plataforma que serão exibidas de forma aleatória enquanto o jogador responde um quiz. Estas perguntas são apresentadas para todos os clientes / empresas"
      />

      <Row>
        {behaviorQuestions.map((item) => (
          <Col key={item.id} md={6} className="mt-3">
            <ItemList
              {...item}
              id={item?.id || 0}
              icon={ImQuestion}
              category="Pergunta"
              title={item.pergunta}
              onFormEdit={(id: number) => handleUpdateBehaviorQuestion(id)}
              onDelete={(id: number) => handleDeleteBehaviorQuestion(id)}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
