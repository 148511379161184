import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => lighten(0.7, theme.colors.main)};
  box-shadow: 0 0 6px 0 #bdbdbd;
  padding: 1rem;
  border-radius: 8px;

  & + div {
    margin-top: 1rem;
  }

  h6 {
    color: ${({ theme }) => lighten(0.2, theme.colors.main)};
    font-weight: 600;
    margin-left: 0.5rem;
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
`;

export const Icon = styled.div`
  background: ${({ theme }) => lighten(0.2, theme.colors.main)};
  border-radius: 8px;
  width: 2.7rem;
  height: 2.7rem;

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => lighten(0.7, theme.colors.main)};
  }
`;
