/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { FaCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { HeaderTitle } from '../../../../components/HeaderTitle';
import { StyledButton, StyledP } from '../../components/ChecklistCard/styles';
import { Container, CustomDiv, FlexRow } from './styles';
import api from '../../../../services/api';
import { NewCheckbox } from '../../../../components/NewCheckbox';

const GET_CHECKLIST_BY_ID = 'checklistunidade/auditoria';
const PUT_CHECKLIST = 'checklistunidade/auditoria-finalizada';

interface ActionPlanInterface {
  descricaoResolucao: string;
  nomeResponsavelResolucao: string;
  dataLimiteResolucao: string;
}

export interface TestItem {
  id: number;
  checklistUnidadeItem?: { titulo?: string };
  isEfetivo: boolean;
  planoAcaoChecklistUnidade?: ActionPlanInterface;
  dataResposta?: string;
  auditorias: any[];
  observacao?: string;
  urlFotoResposta?: string;
  urlFotoRespostaList?: string[];
  state: boolean | undefined;
}

function ValidationForm() {
  const [answers, setAnswers] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [checklistTitle, setChecklistTitle] = useState();
  const [observation, setObservation] = useState('');
  const [temporaryNoItem, setTemporaryNoItem] = useState<any>();
  const [temporaryNoIndex, setTemporaryNoIndex] = useState<any>();
  const [fetchAgain, setFetchAgain] = useState<boolean>(false);
  const [canFinish, setCanFinish] = useState<boolean>(false);

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const location: { state: { modePreview: boolean } } = useLocation();
  const modePreview = location.state ? location.state.modePreview : history.push('/');

  const PUT_CHECKLIST_ITEM = 'checklistunidade/salvar-auditoria';

  useEffect(() => {
    api
      .get(`${GET_CHECKLIST_BY_ID}/${id}`)
      .then(({ data }) => {
        setAnswers(data?.data?.respostas);
        setChecklistTitle(data?.data?.checklistUnidade?.titulo);
      })
      .catch(() => {
        toast.error('Erro ao buscar Checklist.');
      });
  }, [id]);

  const builderHistoryTitle: () => string = () => `Validação do checklist: ${checklistTitle}`;

  const headers = ['Pergunta', 'Resposta', 'Observação', 'Foto Associada', 'Plano de Ação'];

  if (!modePreview) {
    headers.push('Aceitar?');
  }

  const returnToValidatios = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    try {
      api.put(`checklistunidade/finalizar-auditoria/${id}`);
      history.push('/validacoes');
      setFetchAgain(true);
    } catch (e) {
      toast.error('Erro ao finalizar dupla verificação.');
    }
  };

  const handleFinalizeWithNo = () => {
    setShow(false);
    setObservation('');
    setFetchAgain(true);
  };

  const handleSubmitYes = async (item: any) => {
    const requestData = {
      id: 0,
      checklistUnidadeId: item?.checklistUnidadeItem?.checklistUnidadeId,
      checklistUnidadeItemRespostaId: item?.id,
      aprovadoAuditor: true,
      auditorId: 0,
      dataCadastro: new Date(),
    };
    try {
      api.post(PUT_CHECKLIST_ITEM, requestData);
      setFetchAgain(true);
      setCanFinish(true);
    } catch (e) {
      toast.error('Erro ao enviar item checklist.');
    }
  };

  const handleSubmitNo = async () => {
    if (observation.length < 1) {
      toast.warning('Preencha uma observação!');
    } else {
      const requestData = {
        id: 0,
        checklistUnidadeId: temporaryNoItem?.checklistUnidadeItem?.checklistUnidadeId,
        checklistUnidadeItemRespostaId: temporaryNoItem?.id,
        justificativaAuditor: observation,
        aprovadoAuditor: false,
        auditorId: 0,
        dataCadastro: new Date(),
      };
      try {
        api.post(PUT_CHECKLIST_ITEM, requestData);
        handleFinalizeWithNo();
        setCanFinish(true);
      } catch (e) {
        toast.error('Erro ao enviar item checklist.');
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setObservation('');
    const updatedAnswers = [...answers];

    updatedAnswers[temporaryNoIndex].state = undefined;

    setAnswers(updatedAnswers);
    setFetchAgain(true);
  };

  const handleCheckboxChange = (index: number, newState: boolean | undefined, item: any) => {
    if (newState === false) {
      setShow(true);
      setTemporaryNoItem(item);
      setTemporaryNoIndex(index);
      const updatedAnswers = [...answers];

      updatedAnswers[index].state = newState;

      setAnswers(updatedAnswers);
    } else {
      const updatedAnswers = [...answers];

      updatedAnswers[index].state = newState;

      setAnswers(updatedAnswers);
      handleSubmitYes(item);
    }
  };

  const handleFormatDate = (date: any) => {
    const data = new Date(date);

    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();

    const dataFormatada = `${dia}/${mes}/${ano}`;
    return dataFormatada;
  };

  return (
    <>
      <HeaderTitle
        title={builderHistoryTitle()}
        exportUser={false}
        text="Nesta aba você pode validar os itens do checklist Teste. Vamos lá?"
      />
      <Table
        responsive
        striped
        bordered
        hover
        style={{ marginTop: '16px' }}
      >
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {answers?.map((item: TestItem, rowIndex) => (
            <tr key={rowIndex}>
              <td><CustomDiv>{item?.checklistUnidadeItem?.titulo}</CustomDiv></td>
              <td>
                <CustomDiv>
                  {item.isEfetivo === true ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                      <FaCircle style={{ color: 'green', margin: '5px 5px 0px 0px' }} />
                      <p>Conforme</p>
                    </div>
                  ) : item.isEfetivo === false ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                      <FaCircle style={{ color: 'red', margin: '5px 5px 0px 0px' }} />
                      <p>Não conforme</p>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                      <FaCircle style={{ color: 'grey', margin: '5px 5px 0px 0px' }} />
                      <p>Não se aplica</p>
                    </div>
                  )}
                </CustomDiv>
              </td>
              <td>
                {item?.observacao ? (
                  <CustomDiv>{item?.observacao}</CustomDiv>
                ) : (
                  'Este item não possui observação.'
                )}
              </td>
              <td>
                {item?.urlFotoRespostaList && item?.urlFotoRespostaList.length > 0 ? (
                  <>
                    {item?.urlFotoRespostaList.map((url: string, idx: number) => (<img key={idx} src={url} alt="Foto" style={{ marginTop: '2px', width: '100px', height: '100px' }} />))}
                  </>
                ) : (
                  'Este item não possui foto.'
                )}
              </td>
              <td>
                {item?.planoAcaoChecklistUnidade ? (
                  <CustomDiv>
                    <StyledP><b>Descrição</b>: {item?.planoAcaoChecklistUnidade?.descricaoResolucao}</StyledP>
                    <StyledP><b>Responsável</b>: {item?.planoAcaoChecklistUnidade?.nomeResponsavelResolucao}</StyledP>
                    <StyledP><b>Data de resolução</b>: {handleFormatDate(item?.planoAcaoChecklistUnidade?.dataLimiteResolucao)}</StyledP>
                  </CustomDiv>
                ) : (
                  'Este item não possui plano de ação.'
                )}
              </td>
              {!modePreview && (
                <td>
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: ' 16px' }}>
                    <NewCheckbox
                      label="Sim"
                      checked={item.state === true}
                      onChange={() => handleCheckboxChange(rowIndex, true, item)}
                    />
                    <NewCheckbox
                      label="Não"
                      checked={item.state === false}
                      onChange={() => handleCheckboxChange(rowIndex, false, item)}
                    />
                  </div>
                </td>
              )
              }
            </tr>
          ))}
        </tbody>
      </Table>
      {!modePreview ? (
        <Container>
          <StyledButton onClick={handleSubmit} disabled={!canFinish}>
            Finalizar Dupla Verificação
          </StyledButton>
        </Container>
      ) : (
        <Container>
          <StyledButton onClick={returnToValidatios}>
            Voltar
          </StyledButton>
        </Container>
      )}
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar observação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            placeholder="Adicionar observação"
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
            style={{ width: '100%', height: 150, resize: 'none', padding: 5 }}
          />
        </Modal.Body>
        <Modal.Footer>
          <StyledButton onClick={handleSubmitNo}>
            Adicionar
          </StyledButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ValidationForm;
