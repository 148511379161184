import axios, { AxiosError } from 'axios';

const baseURL = process.env.REACT_APP_ENDPOINT;
const api = axios.create({
  baseURL,
});

export default api;

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('@Ent-games:token');
      localStorage.removeItem('@Ent-games:user');
      delete api.defaults.headers.Authorization;
      window.location.reload();
    }
    return Promise.reject(error);
  },
);
