import { CheckboxWrapper } from './styles';

export function NewCheckbox({ label, checked, onChange }: any) {
  return (
    <>
      <CheckboxWrapper>
        <input
          name={label}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <div className="custom-checkbox" />
        <span>{label}</span>
      </CheckboxWrapper>
    </>
  );
}
