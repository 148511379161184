import React from 'react';
import { CheckboxCircleComponent } from './styles';

export interface CheckboxCircleProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

export const CheckboxCircle = ({ checked, onChange, label }: CheckboxCircleProps) => (
  <CheckboxCircleComponent>
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <span>{label}</span>
  </CheckboxCircleComponent>
);
