import { Col, Row } from 'react-bootstrap';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { useRef } from 'react';
import { Button } from '../../components/Button';
import { Content, Table } from '../../styles/components';
import theme from '../../styles/theme';
import { Container, HeaderNewItem, ContentTable } from './styles';
import { Report } from '../../components/Report';
import { ModalHandles } from '../../interfaces/ModalHandles';
import ModalForm from './components/ModalForm';

export const Courses = () => {
  const modalFormRef = useRef<ModalHandles>(null);

  const handleShowModal = () => {
    modalFormRef.current?.onShowModal();
  };

  return (
    <Container>
      <ModalForm
        ref={modalFormRef}
      />
      <Content>
        <HeaderNewItem className="d-flex align-items-center">
          <Row>
            <Col md={9}>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
              </p>
            </Col>

            <Col md={3} className="d-flex align-items-center">
              <Button
                type="button"
                background={theme.colors.main}
                color="#fff"
                onClick={handleShowModal}
              >
                Incluir novo curso
              </Button>
            </Col>
          </Row>

        </HeaderNewItem>
      </Content>

      <Row>
        <Col md={9} className="mt-3">
          <Content>
            <ContentTable>
              <h4>Cursos</h4>
              <p className="mt-3 mb-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
              </p>
              <Table responsive borderless>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Tipo</th>
                    <th>Ativo</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Vendas</td>
                    <td>Lorem ipsum dolor sit amet, consectetur</td>
                    <td>Geral</td>
                    <td>Sim</td>
                    <td className="d-flex align-items-center">
                      <button type="button">
                        <FaEdit />
                      </button>
                      <button type="button">
                        <FaTrashAlt />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </ContentTable>
          </Content>

        </Col>

        <Col md={3} className="mt-3">
          <Report
            title="Pontos por cliente"
            link="/"
            description="Lorem ipsum dolor sit amet quaieç adipiscing elit"
          />

          <Report
            title="Pontos por cliente"
            link="/"
            description="Lorem ipsum dolor sit amet quaieç adipiscing elit"
          />

          <Report
            title="Pontos por cliente"
            link="/"
            description="Lorem ipsum dolor sit amet quaieç adipiscing elit"
          />

          <Report
            title="Pontos por cliente"
            link="/"
            description="Lorem ipsum dolor sit amet quaieç adipiscing elit"
          />

          <Report
            title="Pontos por cliente"
            link="/"
            description="Lorem ipsum dolor sit amet quaieç adipiscing elit"
          />
        </Col>
      </Row>
    </Container>
  );
};
