import { ISelect } from './ISelect';

export interface IUsuario {
  apelido: string;
  cpf: string;
  email: string;
  celular: string;
  empresaId: string;
  id: number;
  nome: string;
  perfilId: any;
  filialIds: Array<ISelect>;
  unidadesIds: Array<ISelect>;
  usuarioEscolaridadeId?: any;
  perfil?: {
    descricao: string;
    id: number;
    nome: string;
  };
  dataAdmissao: string;
  dataNascimento: string;
  sexo: any;
  uf: ISelect;
  cargoUsuarioId: any;
  nomeSocial: string;
  matricula?: string;
  imagem?: string;
  unidadePrincipalId: ISelect;
  filialPrincipalId: any ;
}

// eslint-disable-next-line no-shadow
export enum GENDER {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

export const Usuarios = new Map([
  [false, 'Inativos'],
  [true, 'Ativos'],
  [null, 'Todos'],
]);

export const UFs = new Map([
  ['AC', 'Acre'],
  ['AL', 'Alagoas'],
  ['AP', 'Amapá'],
  ['AM', 'Amazonas'],
  ['BA', 'Bahia'],
  ['CE', 'Ceará'],
  ['DF', 'Distrito Federal'],
  ['ES', 'Espírito Santo'],
  ['GO', 'Goiás'],
  ['MA', 'Maranhão'],
  ['MT', 'Mato Grosso'],
  ['MS', 'Mato Grosso do Sul'],
  ['MG', 'Minas Gerais'],
  ['PA', 'Pará'],
  ['PB', 'Paraíba'],
  ['PR', 'Paraná'],
  ['PE', 'Pernambuco'],
  ['PI', 'Piauí'],
  ['RJ', 'Rio de Janeiro'],
  ['RN', 'Rio Grande do Norte'],
  ['RS', 'Rio Grande do Sul'],
  ['RO', 'Rondônia'],
  ['RR', 'Roraima'],
  ['SC', 'Santa Catarina'],
  ['SP', 'São Paulo'],
  ['SE', 'Sergipe'],
  ['TO', 'Tocantins'],
]);
