import { useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { SiCircle } from 'react-icons/si';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { CgShare } from 'react-icons/cg';
import { useQuery } from 'react-query';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ItemList } from '../../components/ItemList';
import api from '../../services/api';
import { ModalForm } from './components/ModalForm';
import { ICourseCategory } from '../../interfaces/ICourseCategory';
import { ViewOnlyListItem } from '../../components/ViewOnlyListItem';
import { FlexRow } from '../Checklist-Validation/Kanbam/FormValidation/styles';

export const Categories = () => {
  const [showModalForm, setShowModalForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ICourseCategory>();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: categories, isLoading, refetch } = useQuery(['cursos-por-categoria'], async () => {
    try {
      const response = await api.get('curso/categoria');
      return response?.data?.data;
    } catch (err: any) {
      toast.error(err.response.message || 'Erro ao carregar os conteúdos!');
      return null;
    }
  });

  const handleDeleteCategory = useCallback((categoryId: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      setLoading(true);
      if (result.isConfirmed) {
        try {
          await api.delete(`curso/categoria/${categoryId}`);
          refetch();
          setLoading(false);
          toast.success('A categoria foi removido');
        } catch (err: any) {
          if (err.response.data.message) {
            setLoading(false);
            toast.error(err.response.data.message);
            return;
          }
          setLoading(false);
          toast.error('Erro ao excluir categoria');
        }
      }
    });
  }, []);

  const handleOnModalFormCategoryClose = () => {
    setShowModalForm(false);
    setSelectedCategory(undefined);
    refetch();
  };

  const handleOnEditCategory = (categoryId: number) => {
    api
      .get(`curso/categoria/${categoryId}`)
      .then(({ data }) => {
        setSelectedCategory(data.data);
        setShowModalForm(true);
      })
      .catch(() => toast.error('Erro ao buscar categoria para editar'));
  };

  return (
    <>
      <ModalForm
        showModal={showModalForm}
        category={selectedCategory}
        onCloseModal={handleOnModalFormCategoryClose}

      />
      <HeaderTitle
        onNewItem={() => setShowModalForm(true)}
        title="Conteúdos"
        text=" Este é seu ambiente de criação dos conteúdos que aparecerão sob a forma de jogos para seus usuários. Clique em adicionar para transformar seu curso em um jogo ou gerencie abaixo os que já foram criados."
      />

      <Row>
        {!isLoading || !loading
          ? (
            <>
              {categories?.map((item: any) => (
                <Col key={item.id} lg={4} md={4} sm={4} className="mt-3">
                  <ItemList
                    {...item}
                    icon={SiCircle}
                    category="Categoria"
                    link={`/categories/${item.id}/cursos`}
                    onFormEdit={(itemId: number) => handleOnEditCategory(itemId)}
                    onDelete={() => handleDeleteCategory(item.id)}
                  />
                </Col>
              ))}

              <Col lg={4} md={4} sm={4} className="mt-3">
                <ViewOnlyListItem
                  nome="Cursos Instalados"
                  icon={CgShare}
                  link="/categories/cursos-instalados"
                />
              </Col>
            </>
          )
          : (
            <FlexRow style={{ marginLeft: '46%', alignItems: 'center', width: '70vw', height: '50vh' }}>
              <Spinner animation="grow" />
            </FlexRow>
          )
        }
      </Row>
    </>
  );
};
