/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Col, Row } from 'react-bootstrap';
import { ImTree } from 'react-icons/im';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FaMapMarkedAlt } from 'react-icons/all';
import { IGrupo } from '../../interfaces/IGrupo';
import api from '../../services/api';
import { ModalForm } from './components/ModalForm';
import { Container } from './styles';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ItemList } from '../../components/ItemList';

export const Grupos = () => {
  const { ids } = useParams<{ ids: string }>();
  const [grupos, setFiliais] = useState<IGrupo[]>([]);
  const [hierarchyGroup, setHierarchyGroup] = useState<IGrupo>();
  const [filialEdit, setFilialEdit] = useState<IGrupo>();
  const [showModalForm, setShowModalForm] = useState(false);
  const history = useHistory();

  const isSubGroup = (ids !== undefined);
  const path = isSubGroup ? (`${ids}/`) : '';
  const gruposIDs = isSubGroup ? ids.split('/') : [];

  const handleSetFilial = useCallback((data: IGrupo, update?: boolean) => {
    if (update) {
      setFiliais((old) => [...old].map((object) => {
        if (object.id === data.id) {
          return data;
        }
        return object;
      }));
    } else {
      setFiliais((old) => [...old, data]);
    }
  }, []);

  const listHierarchy: () => IGrupo[] = () => {
    const list: IGrupo[] = [];

    let nextHierarchy = hierarchyGroup;
    let lastHierarchy = hierarchyGroup;

    gruposIDs.forEach((id) => {
      if (nextHierarchy && `${nextHierarchy?.id}` === id) {
        lastHierarchy = nextHierarchy;
        list.push(lastHierarchy);

        if (lastHierarchy.gruposFilhas.length > 0) {
          [nextHierarchy] = lastHierarchy.gruposFilhas;
        }
      }
    });

    return list;
  };

  const builderHistoryTitle: () => JSX.Element[] = () => {
    const list = listHierarchy();

    let link = '/grupos';
    const result: JSX.Element[] = [
      <Link to={link} key={link}>
        Grupos
      </Link>,
    ];
    for (let i = 0; i < list.length; i += 1) {
      link += `/${list[i].id}`;

      result.push(<span>{' > '}</span>);
      result.push(
        <Link to={link}>
          {list[i].nome}
        </Link>,
      );
    }

    return result;
  };

  useEffect(() => {
    try {
      if (hierarchyGroup === undefined) return;

      const listHierarchies = listHierarchy();
      const lastHierarchy = listHierarchies[listHierarchies.length - 1];
      setFiliais(lastHierarchy?.gruposFilhas || []);
    } catch (error) {
      toast.error(error.message);
    }
  }, [hierarchyGroup]);

  useEffect(() => {
    if (isSubGroup) {
      api
        .get(`filial/hierarchy/${ids}`)
        .then(({ data }) => setHierarchyGroup(data.data))
        .catch(() => {
          toast.error('Erro ao buscar lista de grupos');
        });
    } else {
      setHierarchyGroup(undefined);
      api
        .get('filial/hierarchy/father')
        .then(({ data }) => { console.log('🚀 ~ file: index.tsx ~ line 111 ~ useEffect ~ data', data); setFiliais(data.data); })
        .catch(() => {
          toast.error('Erro ao buscar lista de grupos');
        });
    }
  }, [ids]);

  const handleCloseModalFormFilial = () => {
    setShowModalForm(false);
    setFilialEdit(undefined);
  };

  const handleDeleteFilial = useCallback(async (filialId: number | string) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`filial/${filialId}`);
          setFiliais((old) => [...old.filter((item) => item.id !== filialId)]);
          toast.success('Grupo removido com sucesso');
        } catch (err) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir grupo');
        }
      }
    });
  }, []);

  return (
    <Container>
      <ModalForm
        showModal={showModalForm}
        onCloseModal={handleCloseModalFormFilial}
        grupoPai={gruposIDs[gruposIDs.length - 1]}
        grupo={filialEdit}
        onSetFilial={handleSetFilial}
      />

      <HeaderTitle
        onNewItem={() => setShowModalForm(true)}
        title={builderHistoryTitle()}
        text="Nesta aba você deve cadastrar os setores, funções, cargos, níveis hierárquicos, grupos ou grupos de interesse de sua empresa. Estas informações serão úteis para vincular seus usuários às trilhas de aprendizado que você irar criar com os cursos. Vamos lá?"
      />

      <Row>
        {grupos.map((item) => (
          <Col key={item.id} md={4} className="mt-3">
            <ItemList
              {...item}
              icon={ImTree}
              category="Grupo"
              onClick={() => history.push(`/grupos/${path}${item.id}`)}
              onDelete={() => handleDeleteFilial(item.id)}
              onFormEdit={() => {
                setFilialEdit(item);
                setShowModalForm(true);
              }}
              additionalOptions={[{
                icon: FaMapMarkedAlt,
                label: 'Mapa',
                onClick: () => history.push(`/grupo/${item.id}`),
              }]}
              titleWithId
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
