import { AiOutlineStar } from 'react-icons/ai';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import * as Icons from 'react-icons/all';
import * as yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import { toast } from 'react-toastify';
import { ModalComponent } from '../../../../components/Modal';
import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { ModalIconsComponent } from './ModalIconsComponent';
import api from '../../../../services/api';
// import all from 'react-icons/all'

export type IModuloPlayer = {
  id?: number;
  nome: string;
  titulo: string;
  descricao: string;
  link: string;
  icone: string;
}

type ModalFormModuloProps = {
  showModal: boolean;
  onCloseModal: () => void;
  onSetModule: (module: IModuloPlayer, update?: boolean) => void;
  module?: IModuloPlayer;
};

const schema = yup.object({
  nome: yup.string().required('O nome do modulo deve ser informado!'),
  titulo: yup.string().required('O titulo do modulo deve ser informado!'),
  link: yup.string().required('O link do modulo deve ser informado!'),
});

const IconSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #f0f0f0;

  span {
    margin-top: 5px;
    font-style: italic;
  }
`;

export const ModalFormModulo = (
  {
    onCloseModal,
    showModal,
    module,
    onSetModule,
  }: ModalFormModuloProps,
) => {
  const [loading, setLoading] = useState(false);
  const [showModalIcons, setShowModalIcons] = useState<boolean>(false);
  const [iconSelected, setIconSelected] = useState<string>('');

  const handleCloseModal = () => {
    onCloseModal();
  };

  const onSelectedIcon = (icon: string) => {
    setIconSelected(icon);
    setShowModalIcons(false);
  };

  const getIcon = (icon: string) => {
    const IconSelected: IconType = Object.entries(Icons)
      .filter(([key]) => key === icon).map(([, value]) => value)[0];

    if (!IconSelected) return null;

    return (<IconSelected size={40} />);
  };

  useEffect(() => {
    if (module) {
      setIconSelected(module.icone);
    } else {
      setIconSelected('');
    }
  }, [module]);

  const handleSubmit = useCallback(async (dataModuloPlayer: IModuloPlayer) => {
    try {
      if (module) {
        const request = {
          ...dataModuloPlayer,
          icone: iconSelected,
        };

        const { data } = await api.put(`modulo-jogador/${module.id}`, request);

        onSetModule(data.data, true);
      } else {
        const request = {
          ...dataModuloPlayer,
          icone: iconSelected,
        };

        const { data } = await api.post('modulo-jogador', request);

        onSetModule(data.data);
      }

      onCloseModal();
      toast.success('Modulo adicionado com sucesso');
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        return;
      }
    } finally {
      setLoading(false);
    }
  }, [iconSelected, onCloseModal, setIconSelected]);

  return (
    <ModalComponent
      title="Adicionar Modulo Dinamico"
      icon={AiOutlineStar}
      onClose={handleCloseModal}
      modalVisible={showModal}
    >
      <Formik
        initialValues={module || {
          nome: '',
          titulo: '',
          link: '',
          descricao: '',
          icone: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        <Form>
          <Input type="text" name="nome" label="Nome do Modulo:" isRequerid />
          <Input type="text" name="titulo" label="Titulo do Modulo:" isRequerid />
          <Input type="text" name="descricao" label="Descrição do Modulo:" isRequerid />
          <Input type="text" name="link" label="Link do Modulo:" isRequerid />
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                onClick={() => setShowModalIcons(true)}
                type="button"
              >
                Selecionar Icone
              </Button>
            </Col>
          </Row>
          <IconSelectedContainer>
            {iconSelected && (
            <>
              {getIcon(iconSelected)}
              <span>{iconSelected}</span>
            </>
            )}
          </IconSelectedContainer>
          <Row />
          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                type="submit"
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
      <ModalIconsComponent
        onSelectIcon={onSelectedIcon}
        onCloseModal={() => setShowModalIcons(false)}
        showModal={showModalIcons}
      />
    </ModalComponent>
  );
};
