import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledCard = styled(Card)`
  border-left: 5px solid ${(({ theme }) => theme.colors.main)};
`;
export const StyledButton = styled(Button)`
  background: ${(({ theme }) => theme.colors.main)};
  border-color: ${(({ theme }) => theme.colors.main)};
  height: 50px;

  &:hover {
    background: ${(({ theme }) => theme.colors.main)};
    filter: brightness(0.9);
    border-color: ${(({ theme }) => theme.colors.main)};
  }

  &:focus {
    background: ${(({ theme }) => theme.colors.main)};
    filter: brightness(0.8);
  }

  &:disabled {
    background: gray;
    border-color: gray;
  }
`;
export const StyledP = styled.p`
  margin-bottom: 0;
`;
