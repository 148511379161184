import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiCircle } from 'react-icons/si';
import { IoScanCircleOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import { BoxItemGame } from '../../../components/BoxItemGame';
import { ModalFormCurso } from '../components/ModalFormCurso';
import { ICurso } from '../../../interfaces/ICurso';
import { Header } from './styles';
import api from '../../../services/api';
import { IGrupo } from '../../../interfaces/IGrupo';
import { IEmpresa } from '../../../interfaces/IEmpresa';

export const Kandan = () => {
  const { id } = useParams<{ id: string }>();
  const [company] = useStore<IEmpresa>('company');
  const [showModalFormCurso, setShowModalFormCurso] = useState(false);
  const [showModalFormCursoSequencial, setShowModalFormCursoSequencial] = useState(false);
  const [cursos, setCursos] = useState<ICurso[]>([]);
  const [cursosSequenciais, setCursosSequenciais] = useState<ICurso[]>([]);
  const [grupo, setGrupo] = useState<IGrupo>();
  // const [showModalForm, setShowModalForm] = useState(false);
  // const [filialEdit, setFilialEdit] = useState<IGrupo>();

  const fetchGrupos = useCallback(() => {
    api.get(`/filial/${id}`).then(({ data }) => setGrupo(data.data));
  }, [id]);

  const fetchCursos = useCallback(() => {
    api.get(`/curso/grupo/${id}`).then(({ data }) => setCursos(data.data.map(
      (curso: ICurso) => (
        {
          ...curso,
          nome: curso?.empresaPaiId !== company?.id ? `${curso.nome} (Instalado)` : curso.nome,
        }),
    )));
  }, [id, company]);

  const fetchCursosSequenciais = useCallback(() => {
    api.get(`/curso/grupo/sequenciais/${id}`).then(({ data }) => setCursosSequenciais(data.data));
  }, [id]);

  useEffect(() => {
    fetchGrupos();
    fetchCursos();
    fetchCursosSequenciais();
  }, [fetchGrupos, fetchCursos, fetchCursosSequenciais]);

  const handleCloseModalFormCurso = () => {
    setShowModalFormCurso(false);
    setShowModalFormCursoSequencial(false);
    fetchCursos();
    fetchCursosSequenciais();
  };

  const handleNewItem = () => {
    setShowModalFormCurso(true);
  };

  const handleNewItemSequencial = () => {
    setShowModalFormCursoSequencial(true);
  };

  const handleDeleteCurso = useCallback(async (dataId: number | string) => {
    try {
      const data = {
        FilialId: id,
        CursosId: [{
          CursoId: dataId,
        }],
      };
      await api.delete('/filial/remove-cursos', { data });
      // eslint-disable-next-line no-shadow
      fetchCursos();
      toast.success('Removido com sucesso');
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        return;
      }
      toast.error('Erro ao remover');
    }
  }, [id, fetchCursos]);

  const handleDeleteCursoSequencial = useCallback(async (dataId: number | string) => {
    try {
      const data = {
        FilialId: id,
        CursosId: [{
          CursoId: dataId,
        }],
      };
      await api.delete('/filial/remove-cursos-sequenciais', { data });
      // eslint-disable-next-line no-shadow
      fetchCursosSequenciais();
      toast.success('Removido com sucesso');
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        return;
      }
      toast.error('Erro ao remover');
    }
  }, [id, fetchCursosSequenciais]);

  // const handleCloseModalFormFilial = () => {
  //   setShowModalForm(false);
  //   setFilialEdit(undefined);
  // };

  const handleOnDragEndCursos = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setCursos((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordernacaoCursos = cursos.map((p, index) => ({
        id: p.id,
        ordem: index,
      })).concat([]);
      try {
        await api.put(`filial/${id}/ordenar`, ordernacaoCursos);
      } catch {
        toast.error('Erro ao ordernar fases');
      }
    }, [cursos, id],
  );

  const handleOnDragEndCursosSequenciais = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setCursosSequenciais((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });

      const ordernacaoCursos = cursosSequenciais.map((p, index) => ({
        id: p.id,
        ordem: index,
      })).concat([]);

      try {
        await api.put(`filial/${id}/ordenar-sequenciais`, ordernacaoCursos);
      } catch {
        toast.error('Erro ao ordernar fases');
      }
    }, [cursosSequenciais, id],
  );

  const handleDisplayTitle = (item: any) => <span>{item?.nome ?? item.titulo}</span>;

  return (
    <>
      <ModalFormCurso
        includeInstalados
        idAssociado={id}
        postEndpointUrl="filial/add-cursos"
        reqAttrName="FilialId"
        selectedCursos={cursos.concat(cursosSequenciais)}
        onCloseModal={handleCloseModalFormCurso}
        showModalFormCurso={showModalFormCurso}
      />

      <ModalFormCurso
        includeInstalados
        idAssociado={id}
        postEndpointUrl="filial/add-cursos-sequenciais"
        reqAttrName="FilialId"
        selectedCursos={cursos.concat(cursosSequenciais)}
        onCloseModal={handleCloseModalFormCurso}
        showModalFormCurso={showModalFormCursoSequencial}
      />

      <Header className="mt-3">
        <Row>
          <Col md={12} className="col-spacing d-flex align-items-center">
            <SiCircle size={45} />
            <div>
              <h5>
                Trilha de aprendizado -
                <b>{` ${grupo?.nome}`}</b>
              </h5>
            </div>
          </Col>
        </Row>
      </Header>

      <Row>
        <Col md={6}>
          <BoxItemGame
            header="Cursos"
            messageEmpty="Selecione o curso para incluir uma fase"
            icon={IoScanCircleOutline}
            items={cursos}
            onDragEnd={handleOnDragEndCursos}
            onShowFormModal={handleNewItem}
            onDeleteItem={handleDeleteCurso}
            superiorSelecionado
            displayTitle={handleDisplayTitle}
          />
        </Col>
        <Col md={6}>
          <BoxItemGame
            header="Cursos Sequenciais"
            messageEmpty="Selecione o curso para incluir uma fase"
            icon={IoScanCircleOutline}
            items={cursosSequenciais}
            onDragEnd={handleOnDragEndCursosSequenciais}
            onShowFormModal={handleNewItemSequencial}
            onDeleteItem={handleDeleteCursoSequencial}
            superiorSelecionado
            displayTitle={handleDisplayTitle}
          />
        </Col>
      </Row>
    </>
  );
};
