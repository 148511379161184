import { HeaderTitle } from '../../components/HeaderTitle';
import { FormUseTerms } from './components/form-use-terms';
import { Container } from './styles';

export const UseTermsPage = () => (

  <Container>

    <HeaderTitle
      title="Termos de Uso"
      text="Ao alterar os Termos de Uso e salvar, automaticamente nova versão é apresentada para todos de sua empresa no próximo login"
    />

    <FormUseTerms isEscopoEmpresa />
  </Container>
);
