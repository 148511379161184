import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useState } from 'react';
import { useStore } from 'react-context-hook';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import api from '../../../services/api';

const dataInitial = {
  email: '',
};

const schema = yup.object({
  email: yup
    .string()
    .email('Digite um email válido')
    .required('Digite seu email'),
});

export const ForgortPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [domain] = useStore('domain');

  const handleSubmit = async (dataForm: { email: string }) => {
    try {
      setLoading(true);
      await api.post('auth/forgotPassword', { ...dataForm, empresaIdentificador: domain });
      toast.success('Senha enviada pra seu email');
      history.push('/');
    } catch {
      toast.error('Erro ao solicitar alteração de senha');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={dataInitial}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form>
        <div className="mb-3">
          <button
            type="button"
            className="bg-transparent border-0 mb-5"
            onClick={() => history.push('/')}
          >
            <MdArrowBack size={24} />
          </button>
          <h5>Podemos ajudar?</h5>
          <p>
            Preencha seu email abaixo e enviaremos sua nova senha.
            <br />
            Caso não encontre na caixa de entrada, verifique no SPAM.
          </p>
        </div>
        <Input type="email" name="email" label="Email" />

        <Row>
          <Col md={{ span: 8, offset: 2 }} className="mt-5">
            <Button
              background={theme.colors.main}
              color="#fff"
              loading={loading}
              className="w-100 text-uppercase"
              type="submit"
            >
              Recuperar Senha
            </Button>
          </Col>
        </Row>

        {/* <div className="mt-5 d-flex flex-column align-items-center">
          <p className="mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.
          </p>
        </div> */}
      </Form>
    </Formik>
  );
};
