import styled from 'styled-components';
import { darken, lighten } from 'polished';

interface ButtonProps {
  background?: string;
  color: string;
}

export const Container = styled.button<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid transparent;
  text-align: center;
  height: 2.6rem;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8rem;
  width: 100%;
  background-color: ${(({ theme }) => theme.colors.main)};
  color: ${({ color }) => color};
  transition: all 0.4s linear;
  white-space: pre-wrap;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => darken(0.06, theme.colors.main)};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => lighten(0.3, theme.colors.main)};
  }

  span {
    margin-left: 3px;
  }

  @media (max-width: 768px) {
    span {
      display: none;
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
