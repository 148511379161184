/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { SiCircle } from 'react-icons/si';
import { FaCog, FaUsers } from 'react-icons/fa';
import { BiDiamond } from 'react-icons/bi';
import { ImPhone, ImHome, ImTree } from 'react-icons/im';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { FaRegShareSquare } from 'react-icons/fa';
import { AiOutlineClose, AiOutlineLineChart } from 'react-icons/ai';
import { useStore } from 'react-context-hook';
import { BsCheckAll } from 'react-icons/bs';
import {
  ApplyOverflow,
  Container,
  Content,
  HeaderMobile,
  HeaderNavbar,
  ItemHeaderNavbar,
  Logo,
  Menu,
  Mobile,
  Navbar,
  Wrapper,
} from './styles';
import { Profile } from './Profile';
import { useAuth } from '../../hooks/auth';
import { ConvertedTheme } from '../../App';
import { IEmpresa } from '../../interfaces/IEmpresa';

export const DefaultLayout: React.FC = ({ children }) => {
  const { user, signOut } = useAuth();
  const navbarRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [company] = useStore<IEmpresa>('company');
  const [theme] = useStore<ConvertedTheme>('theme');

  const handleOpenMenu = () => {
    setVisible(true);
  };

  const handleCloseMenu = () => {
    setVisible(false);
  };

  useEffect(() => {
    function handleClickOverlay(event: MouseEvent): void {
      if (
        navbarRef.current
        && event.target instanceof Node
        && !navbarRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    }

    if (visible) {
      document.addEventListener('mouseup', handleClickOverlay);
    }
  }, [visible]);

  const generatePainelLink: () => string = () => {
    const originUrl = window.location.origin.replace('admin', 'jogador');
    const clientIdentify = window.location.pathname.split('/')[1];
    return `${originUrl}/${clientIdentify}`;
  };

  const FuncaoSistemaMapping: { order: number, name: string, component: JSX.Element }[] = [
    {
      order: 1,
      name: 'AdminConteudos',
      component: (
        <>
          <li>
            <NavLink to="/categories" onClick={handleCloseMenu} activeClassName="selected">
              <SiCircle />
              <span>Conteúdos</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 2,
      name: 'AdminUnidadesEmpresa',
      component: (
        <>
          <li>
            <NavLink to="/unidades" onClick={handleCloseMenu} activeClassName="selected">
              <ImTree />
              <span>Unidades da Empresa</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 3,
      name: 'AdminPesquisas',
      component: (
        <>
          <li>
            <NavLink to="/pesquisas" onClick={handleCloseMenu} activeClassName="selected">
              <ImHome />
              <span>Pesquisas</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 4,
      name: 'AdminComunicados',
      component: (
        <>
          <li>
            <NavLink to="/comunicados" onClick={handleCloseMenu} activeClassName="selected">
              <ImPhone />
              <span>Comunicados</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 5,
      name: 'AdminChecklist',
      component: (
        <>
          <li>
            <NavLink to="/categoria-checklist" onClick={handleCloseMenu} activeClassName="selected">
              <BsCheckAll />
              <span>Checklist</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 6,
      name: 'AdminGrupos',
      component: (
        <>
          <li>
            <NavLink to="/grupos" onClick={handleCloseMenu} activeClassName="selected">
              <ImTree />
              <span>Grupos</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 7,
      name: 'AdminUsuarios',
      component: (
        <>
          <li>
            <NavLink to="/usuarios" onClick={handleCloseMenu} activeClassName="selected">
              <FaUsers />
              <span>Usuários</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 8,
      name: 'AdminMinhaEmpresa',
      component: (
        <>
          <li>
            <NavLink to="/gamificacao" onClick={handleCloseMenu} activeClassName="selected">
              <BiDiamond />
              <span>Minha Empresa</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 9,
      name: 'AdminPainelBi',
      component: (
        <>
          <li>
            <NavLink to="/dashboard" onClick={handleCloseMenu} activeClassName="selected">
              <AiOutlineLineChart />
              <span>Painel BI</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 10,
      name: 'AdminSuperAdmin',
      component: (
        <>
          <li>
            <NavLink to="/super-admin" onClick={handleCloseMenu} activeClassName="selected">
              <FaCog />
              <span>Administração</span>
            </NavLink>
          </li>
        </>
      ),
    },
    {
      order: 11,
      name: 'AdminValidacaoChecklist',
      component: (
        <>
          <li>
            <NavLink to="/validacoes" onClick={handleCloseMenu} activeClassName="selected">
              <ImTree />
              <span>Validação de Checklist</span>
            </NavLink>
          </li>
        </>
      ),
    },
  ];

  function RolesMenu({ rolesToMap }: any) {
    if (typeof rolesToMap === 'string') {
      const roleValue = FuncaoSistemaMapping.find((mapping) => mapping.name === rolesToMap)?.component ?? null;
      return roleValue;
    }
    const orderedRoles = [...rolesToMap].sort((a, b) => {
      const orderA = FuncaoSistemaMapping.find((mapping) => mapping.name === a)?.order || 0;
      const orderB = FuncaoSistemaMapping.find((mapping) => mapping.name === b)?.order || 0;

      if (orderA === orderB) {
        return 0;
      }

      return orderA - orderB;
    });

    return (
      <>
        {orderedRoles.map((role) => {
          const roleValue = FuncaoSistemaMapping.find((mapping) => mapping.name === role)?.component;
          if (roleValue === undefined) {
            return null;
          }
          return roleValue;
        })}
      </>
    );
  }

  return (
    <Container>
      <Menu visible={visible}>
        <Mobile>
          <button type="button" onClick={handleOpenMenu}>
            <FiMenu size={28} />
          </button>

          <div className="d-flex align-items-center justify-content-center">
            <h4 className="text-white ml-3">{theme.slogan}</h4>
          </div>
        </Mobile>

        <Navbar ref={navbarRef} visible={visible}>
          <ApplyOverflow className="d-flex justify-content-between flex-column h-100">
            <div>
              <HeaderMobile className="justify-content-between align-items-center">
                <h5 className="text-white">{theme.slogan}</h5>
                <button type="button" onClick={handleCloseMenu}>
                  <AiOutlineClose size={25} />
                </button>
              </HeaderMobile>

              <Logo
                className="align-items-center justify-content-center"
                style={{ background: 'white' }}
              >
                <img src={theme.imgLogo} alt={process.env.REACT_APP_COMPANY_NAME} width="130" />
              </Logo>
              <ul>
                <li>
                  <NavLink to="/inicio" onClick={handleCloseMenu} activeClassName="selected">
                    <ImHome />
                    <span>Inicio</span>
                  </NavLink>
                </li>
                <RolesMenu rolesToMap={user?.roles} />

                <li>
                  <button type="button" onClick={() => { window.location.href = generatePainelLink(); }}>
                    <FaRegShareSquare size={20} />
                    Ir para Versão Usuário
                  </button>
                </li>

                <li>
                  <button type="button" onClick={signOut}>
                    <RiLogoutCircleRLine size={20} />
                    Sair
                  </button>
                </li>
              </ul>
            </div>

          </ApplyOverflow>
        </Navbar>
      </Menu>

      <HeaderNavbar className=" d-flex align-items-center justify-content-between">
        <ItemHeaderNavbar>
          <div className="d-flex align-items-center w-100 ">
            <p className="text-light">{theme.slogan}</p>
          </div>
        </ItemHeaderNavbar>

        <div className="d-flex algin-items-center">
          <Profile />
        </div>
      </HeaderNavbar>

      <Wrapper visible={visible}>
        <Content>{children}</Content>
      </Wrapper>
    </Container>
  );
};
