/* eslint-disable max-len */
import { useEffect, useState, useCallback } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import moment from 'moment';
import { Button } from '../../components/Button';
import { INivel } from '../../interfaces/INivel';
import api from '../../services/api';
import { Content, Table } from '../../styles/components';
import theme from '../../styles/theme';
import { ContentTable } from '../Dashboard/styles';
import { ModalFormNivel } from './components/ModalFormNivel';
import { Header } from './styles';
import { IBonus } from '../../interfaces/IBonus';
import { ModalFormBonus } from './components/ModalFormBonus';
import { ModalFormCargo } from './components/ModelFormCargo';
import { EnergyConfigForm } from './components/EnergyConfigForm';
import { IEmpresa } from '../../interfaces/IEmpresa';
import { AdicionarPontuacaoConfigForm } from './components/AdicionarPontuacaoConfigForm';
import { ICargo } from '../../interfaces/ICargo';
import { ModalFormIcones } from './components/ModalFormIcones';
import { IIcone } from '../../interfaces/IIcone';
import { BackgroundTrilhaConfigForm } from './components/BackgroundTrilhaConfigForm';
import { ModalFormPerfil } from './components/ModalFormPerfil';
import { useAuth } from '../../hooks/auth';
import { BackgroundLoginCadastroForm } from './components/BackgroundLoginCadastro';
import { IModuloPlayer, ModalFormModulo } from './components/ModalFormModulo';
import { ModalEmailSetting } from './components/ModalEmailSetting';
import { IEmailSetting } from '../../interfaces/IEmailSetting';

export const Game = () => {
  const [showModalFormNivel, setShowModalFormNivel] = useState(false);
  const [showModalFormBonus, setShowModalFormBonus] = useState(false);
  const [showModalIcones, setShowModalIcones] = useState(false);
  const [showModalFormCargo, setShowModalFormCargo] = useState(false);
  const [showModalPerfil, setShowModalPerfil] = useState(false);
  const [showModalFormModulo, setShowModalFormModulo] = useState(false);
  const [nivelEdit, setNivelEdit] = useState<INivel>();
  const [niveis, setNiveis] = useState<INivel[]>([]);
  const [bonus, setBonus] = useState<IBonus[]>([]);
  const [bonusEdit, setBonusEdit] = useState<IBonus>();
  const [company, setCompany] = useStore<IEmpresa>('company');
  const [cargos, setCargos] = useState<ICargo[]>([]);
  const [cargosEdit, setCargosEdit] = useState<ICargo>();
  const [IconeEdit, setIconeEdit] = useState<IIcone>();
  const [icones, setIcones] = useState<IIcone[]>([]);
  const [perfis, setPerfis] = useState<any[]>([]);
  const [perfilEdit, setPerfilEdit] = useState<any>();
  const [modules, setModules] = useState<IModuloPlayer[]>([]);
  const [moduleEdit, setModuleEdit] = useState<IModuloPlayer>();
  const [showModalEmailSetting, setShowModalEmailSetting] = useState(false);
  const [emailSettingEdit, setEmailSetting] = useState<IEmailSetting>();
  const [delayNotification, setDelayNotification] = useState('');
  const [enabledNotification, setEnabledNotification] = useState(false);
  const { user } = useAuth();

  const fetchCompany = async () => {
    api
      .get(`/empresa/${company?.id}`)
      .then(({ data }) => {
        setCompany(data.data);
        setEmailSetting(data.data?.empresaMailSettings);
      }).catch(() => {
        toast.error('Erro ao buscar empresa');
      });
  };

  useEffect(() => {
    api
      .get('nivel')
      .then(({ data }) => setNiveis(data.data))
      .catch(() => toast.error('Erro ao buscar níveis'));
  }, []);

  useEffect(() => {
    api
      .get('bonus')
      .then(({ data }) => setBonus(data.data))
      .catch(() => toast.error('Erro ao buscar bônus'));
  }, []);

  useEffect(() => {
    if (company) {
      api
        .get(`cargo/${company?.id}`)
        .then(({ data }) => setCargos(data.data))
        .catch(() => toast.error('Erro ao buscar cargo'));
    }
  }, [company]);

  const fetchIcones = () => {
    if (company) {
      api
        .get(`icone/${company?.id}`)
        .then(({ data }) => setIcones(data.data))
        .catch(() => toast.error('Erro ao buscar Icones'));
    }
  };
  useEffect(() => {
    fetchIcones();
  }, [company]);

  useEffect(() => {
    api
      .get('perfis')
      .then(({ data }) => setPerfis(data.data))
      .catch((err) => toast.error(err || 'Erro ao buscar perfis'));
  }, []);

  useEffect(() => {
    api
      .get('modulo-jogador')
      .then(({ data }) => setModules(data.data))
      .catch((err) => toast.error(err || 'Erro ao buscar módulos'));
  }, []);

  useEffect(() => {
    api
      .get('empresa/email-setting')
      .then(({ data }) => setEmailSetting(data.data))
      .catch((err) => toast.error(err || 'Erro ao buscar configuração de email'));
  }, []);

  const handleCloseModalFormNivel = () => {
    setShowModalFormNivel(false);
    setNivelEdit(undefined);
  };

  const handleCloseModalFormBonus = () => {
    setShowModalFormBonus(false);
    setBonusEdit(undefined);
  };

  const handleCloseModalFormCargo = () => {
    setShowModalFormCargo(false);
    setCargosEdit(undefined);
  };

  const handleCloseModalIcone = () => {
    setShowModalIcones(false);
    setIconeEdit(undefined);
  };

  const handleCloseModalPerfil = () => {
    setShowModalPerfil(false);
    setPerfilEdit(undefined);
  };

  const handleCloseModalModulo = () => {
    setShowModalFormModulo(false);
    setModuleEdit(undefined);
  };

  const handleCloseModalEmailSetting = () => {
    setShowModalEmailSetting(false);
  };

  const handleSetNivelEdit = (data: INivel) => {
    setNivelEdit(data);
    setShowModalFormNivel(true);
  };

  const handleSetBonusEdit = (data: IBonus) => {
    setBonusEdit(data);
    setShowModalFormBonus(true);
  };

  const handleSetCargoEdit = (data: ICargo) => {
    setCargosEdit(data);
    setShowModalFormCargo(true);
  };

  const handleSetIconeEdit = (data: IIcone) => {
    setIconeEdit(data);
    setShowModalIcones(true);
  };

  const handleSetPerfilEdit = (data: IIcone) => {
    setPerfilEdit(data);
    setShowModalPerfil(true);
  };

  const handleSetNivel = (data: INivel, update?: boolean) => {
    if (update) {
      setNiveis((old) => [...old].map((object) => {
        if (object.id === data.id) {
          return data;
        }
        return object;
      }));
    } else {
      setNiveis((old) => [...old, data]);
    }
  };

  const handleSetBonus = (data: IBonus, update?: boolean) => {
    if (update) {
      setBonus((old) => [...old].map((object) => {
        if (object.id === data.id) {
          return data;
        }
        return object;
      }));
    } else {
      setBonus((old) => [...old, data]);
    }
  };

  const handleSetCargo = (data: ICargo, update?: boolean) => {
    if (update) {
      setCargos((old) => [...old].map((object) => {
        if (object.id === data.id) {
          return data;
        }
        return object;
      }));
    } else {
      setCargos((old) => [...old, data]);
    }
  };

  const handleSetIcone = useCallback(
    (data: IIcone, update?: boolean) => {
      if (update) {
        const oldIcones = [...icones];
        const findedIndexIcone = oldIcones.findIndex((u) => u.id === data.id);
        if (findedIndexIcone > -1) {
          oldIcones[findedIndexIcone] = {
            ...data,
          };

          setIcones([...oldIcones]);
        }
      } else {
        setIcones((old) => [...old, data]);
      }
    },
    [icones],
  );

  const handleSetPerfil = useCallback(
    (data: any, update?: boolean) => {
      if (update) {
        const oldPerfis = [...perfis];
        const findedIndexPerfil = oldPerfis.findIndex((u) => u.id === data.id);
        if (findedIndexPerfil > -1) {
          oldPerfis[findedIndexPerfil] = {
            ...data,
          };

          setPerfis([...oldPerfis]);
        }
      } else {
        setPerfis((old) => [...old, data]);
      }
    },
    [perfis],
  );

  const handleDeleteNivel = useCallback((nivelId?: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`nivel/${nivelId}`);
          setNiveis((old) => [...old.filter((item) => item.id !== nivelId)]);
          toast.success('O nível foi removido com sucesso');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir nível');
        }
      }
    });
  }, []);

  const handleDeleteBonus = useCallback((bonusId?: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`bonus/${bonusId}`);
          setBonus((old) => [...old.filter((item) => item.id !== bonusId)]);
          toast.success('O bônus foi removido com sucesso');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir bônus');
        }
      }
    });
  }, []);

  const handleDeleteCargo = useCallback((cargoId?: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`cargo/${cargoId}`);
          setCargos((old) => [...old.filter((item) => item.id !== cargoId)]);
          toast.success('O cargo foi removido com sucesso');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir esse cargo!');
        }
      }
    });
  }, []);

  const handleDeleteIcone = useCallback((iconeId?: number) => {
    if (iconeId === 0) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`icone/${iconeId}`);
          setIcones((old) => [...old.filter((item) => item.id !== iconeId)]);
          toast.success('O Icone foi removido com sucesso');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir esse icone!');
        }
      }
    });
  }, []);

  const handleDeletePerfil = useCallback((perfilId?: number) => {
    if (perfilId === 0) {
      return;
    }
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`perfis/${perfilId}`);
          setPerfis((old) => [...old.filter((item) => item.id !== perfilId)]);
          toast.success('O Perfil foi removido com sucesso');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir esse perfil!');
        }
      }
    });
  }, []);

  const handleSetModulo = useCallback(async (data: IModuloPlayer, update?: boolean) => {
    if (update) {
      const oldModules = [...modules];
      const findedIndexModule = oldModules.findIndex((u) => u.id === data.id);
      if (findedIndexModule > -1) {
        oldModules[findedIndexModule] = {
          ...data,
        };

        setModules([...oldModules]);
      }
    } else {
      setModules((old) => [...old, data]);
    }
  }, [modules]);

  const handleDeleteModulo = useCallback((moduleId?: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`modulo-jogador/${moduleId}`);
          setModules((old) => [...old.filter((item) => item.id !== moduleId)]);
          toast.success('O Módulo foi removido com sucesso');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir esse módulo!');
        }
      }
    });
  }, []);

  const handleSetModuloEdit = useCallback((data: IModuloPlayer) => {
    setModuleEdit(data);
    setShowModalFormModulo(true);
  }, []);

  const handleSetEmailSetting = useCallback((data: IEmailSetting) => {
    setEmailSetting(data);
    setShowModalEmailSetting(false);
  }, []);

  const sendNotification = useCallback(async () => {
    const data = await api.get('empresa/send-notification');
    setEmailSetting(data.data?.data);
  }, []);

  const getNotificationDate = useCallback(() => {
    if (emailSettingEdit?.sendAt) {
      return moment(emailSettingEdit.sendAt).format('DD/MM/YYYY HH:mm');
    }

    return '';
  }, [emailSettingEdit]);

  // const getDelay = useCallback(() => {
  //   if (emailSettingEdit?.sendAt) {
  //     // wait 5 minutes
  //     // countdown
  //     const now = moment();
  //     const sendAt = moment(emailSettingEdit.sendAt);
  //
  //     const diff = sendAt.diff(now);
  //     const duration = moment.duration(diff);
  //
  //     return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  //   }
  //   return null;
  // }, []);

  useEffect(() => {
    if (emailSettingEdit?.sendAt) {
      const intervalId = setInterval(() => {
        const now = moment();
        const sendAt = moment(emailSettingEdit.sendAt).add(5, 'minutes');

        const diff = sendAt.diff(now);
        const duration = moment.duration(diff);

        if (duration.asSeconds() <= 0) {
          setEnabledNotification(true);
          setDelayNotification('');
        } else {
          setEnabledNotification(false);
          setDelayNotification(`${duration.minutes()}m ${duration.seconds()}s`);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
    setEnabledNotification(true);
    setDelayNotification('');

    return () => null;
  }, [emailSettingEdit, delayNotification]);

  const FuncaoSistemaMapping: { order: number, name: string, component: any }[] = [
    {
      order: 1,
      name: 'AdminRanking',
      component: (
        <>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <h4>Pontuação Bônus</h4>
            </Accordion.Header>
            <Accordion.Body>
              <p className="mt-3 mb-3 text-secondary">
                Escolha a Unidade e depois escolha o usuário que quer bonificar.
                Para retirar pontuação, basta escolher uma pontuação negativa.
              </p>
              <AdicionarPontuacaoConfigForm />
            </Accordion.Body>
          </Accordion.Item>
        </>
      ),
    },
  ];

  function RolesMenu({ rolesToMap }: any) {
    const orderedRoles = [...rolesToMap].sort((a, b) => {
      const orderA = FuncaoSistemaMapping.find((mapping: any) => mapping.name === a)?.order || 0;
      const orderB = FuncaoSistemaMapping.find((mapping: any) => mapping.name === b)?.order || 0;
      return orderA - orderB;
    });

    return (
      <>
        {orderedRoles.map((role) => {
          const roleValue = FuncaoSistemaMapping.find((mapping: any) => mapping.name === role)?.component;
          if (roleValue === undefined) {
            return null;
          }
          return roleValue;
        })}
      </>
    );
  }

  return (
    <>
      <ModalFormNivel
        showModal={showModalFormNivel}
        onCloseModal={handleCloseModalFormNivel}
        nivel={nivelEdit}
        onSetNivel={handleSetNivel}
      />

      <ModalFormBonus
        showModal={showModalFormBonus}
        onCloseModal={handleCloseModalFormBonus}
        bonus={bonusEdit}
        onSetBonus={handleSetBonus}
      />

      <ModalFormCargo
        showModal={showModalFormCargo}
        onCloseModal={handleCloseModalFormCargo}
        cargo={cargosEdit}
        onSetCargo={handleSetCargo}
      />

      <ModalFormIcones
        showModal={showModalIcones}
        onCloseModal={handleCloseModalIcone}
        icone={IconeEdit}
        onSetIcone={handleSetIcone}
      />

      <ModalFormPerfil
        showModal={showModalPerfil}
        onCloseModal={handleCloseModalPerfil}
        perfil={perfilEdit}
        onSetPerfil={handleSetPerfil}
      />

      <ModalFormModulo
        showModal={showModalFormModulo}
        onCloseModal={handleCloseModalModulo}
        module={moduleEdit}
        onSetModule={handleSetModulo}
      />

      <ModalEmailSetting
        showModal={showModalEmailSetting}
        onCloseModal={handleCloseModalEmailSetting}
        emailSetting={emailSettingEdit}
        onSetEmailSetting={handleSetEmailSetting}
      />

      <Row>
        <Col md={12} className="mt-3">
          <Header>
            <Row>
              <Col md={5} className="d-flex align-items-center">
                <h4 className="mb-0">Minha Empresa</h4>
              </Col>
              <Col xs={12} />

              <Col md={12} className="border-dotted-top">
                <p className="text-secondary">
                  Cadastre aqui as regras de gamificação que motivarão seu time.
                </p>
              </Col>
            </Row>
          </Header>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="mt-3">
          <Content>
            <ContentTable>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><h4>Níveis</h4></Accordion.Header>
                  <Accordion.Body>
                    <Button
                      type="button"
                      background={theme.colors.main}
                      color="#fff"
                      className="mt-1"
                      onClick={() => setShowModalFormNivel(true)}
                    >
                      Incluir nível
                    </Button>

                    <p className="mt-3 mb-3 text-secondary">
                      Cadastre aqui o nome e a quantidade de pontos que seus usuários
                      precisarão atingir por meio das tarefas para chegar nestes níveis.
                    </p>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Qtd. de pontos</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {niveis.map((item) => (
                          <tr key={item.id}>
                            <td>{item.nome}</td>
                            <td>{item.pontos}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleSetNivelEdit(item)}
                              >
                                <AiOutlineEdit />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteNivel(item.id)}
                              >
                                <BiTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h4>Bônus</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Button
                      type="button"
                      background={theme.colors.main}
                      color="#fff"
                      className="mt-1"
                      onClick={() => setShowModalFormBonus(true)}
                    >
                      Incluir bônus
                    </Button>
                    <p className="mt-3 mb-3 text-secondary">
                      Cadastre aqui o que seus usuários
                      ganharão ao finalizar etapas que você definir.
                    </p>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>Título</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bonus.map((item) => (
                          <tr key={item.id}>
                            <td>{item.titulo}</td>
                            <td>
                              <button type="button" onClick={() => handleSetBonusEdit(item)}>
                                <AiOutlineEdit />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteBonus(item.id)}
                              >
                                <BiTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h4>Energias Diárias</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mt-3 mb-3 text-secondary">
                      Escolha a quantidade de energias que o usuário irá ganhar diariamente
                      e clique em Salvar.
                    </p>
                    <EnergyConfigForm company={company} handleFetchCompany={fetchCompany} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h4>Background Trilha</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mt-3 mb-3 text-secondary">
                      Escolha o background da Trilha, confira e clique em Salvar.
                    </p>

                    <BackgroundTrilhaConfigForm />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    <h4>Background Login e Cadastro</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mt-3 mb-3 text-secondary">
                      Escolha o background do Login e Cadastro, confira e clique em Salvar.
                    </p>

                    <BackgroundLoginCadastroForm />
                  </Accordion.Body>
                </Accordion.Item>

                <RolesMenu rolesToMap={user?.roles} />

                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <h4>Cargos</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mt-3 mb-3 text-secondary">
                      Cadastre aqui os cargos que os seus funcionarios irão ocupar.
                    </p>
                    <Button
                      type="button"
                      background={theme.colors.main}
                      color="#fff"
                      className="mt-1"
                      onClick={() => setShowModalFormCargo(true)}
                    >
                      Incluir Cargos
                    </Button>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cargos.map((item: ICargo) => (
                          <tr key={item.id}>
                            <td>{`ID ${item.id}-${item.nome}`}</td>
                            <td>
                              <button type="button" onClick={() => handleSetCargoEdit(item)}>
                                <AiOutlineEdit />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteCargo(item.id)}
                              >
                                <BiTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <h4>Icones da Empresa</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {icones.length < 2 ? (
                      <Button
                        type="button"
                        background={theme.colors.main}
                        color="#fff"
                        className="mt-1"
                        onClick={() => setShowModalIcones(true)}
                      >
                        Incluir Icone
                      </Button>
                    ) : (
                      <Button
                        disabled
                        type="button"
                        background={theme.colors.main}
                        color="#fff"
                        className="mt-1"
                        onClick={() => setShowModalIcones(true)}
                      >
                        Incluir Icone
                      </Button>
                    )}

                    <p className="mt-3 mb-3 text-secondary">
                      Cadastre aqui seus icones que serão mostradados
                      nas tarefas e fases das suas trilhas!
                    </p>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {icones.map((item) => (
                          <tr key={item.id}>
                            <td>{item.nome}</td>
                            <td><img src={item.link} style={{ padding: '20px', minWidth: '25px', minHeight: '25px', height: '10%', width: '10%' }} alt="icone-figura" /></td>
                            <td>
                              <button type="button" onClick={() => handleSetIconeEdit(item)}>
                                <AiOutlineEdit />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteIcone(item.id)}
                              >
                                <BiTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <h4>Perfis de Acesso</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Button
                      type="button"
                      background={theme.colors.main}
                      color="#fff"
                      className="mt-1"
                      onClick={() => setShowModalPerfil(true)}
                    >
                      Incluir Perfil
                    </Button>
                    <p className="mt-3 mb-3 text-secondary">
                      Cadastre aqui os perfis que serão usados por seus usuários!
                    </p>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {perfis.map((item) => (
                          <tr key={item.id}>
                            <td>
                              {item.id}
                              {' '}
                              -
                              {' '}
                              {item.nome}
                            </td>
                            <td>
                              <button type="button" onClick={() => handleSetPerfilEdit(item)}>
                                <AiOutlineEdit />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeletePerfil(item.id)}
                              >
                                <BiTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    <h4>Modulos Dinâmicos</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mt-3 mb-3 text-secondary">
                      Cadastre aqui os modulos que serão usados por seus usuários!
                    </p>
                    <Button color="#fff" background={theme.colors.main} className="mt-1" onClick={() => setShowModalFormModulo(true)}>
                      Incluir Modulo
                    </Button>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modules.map((item) => (
                          <tr key={item.id}>
                            <td>{item.nome}</td>
                            <td>
                              <button type="button" onClick={() => handleSetModuloEdit(item)}>
                                <AiOutlineEdit />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteModulo(item?.id)}
                              >
                                <BiTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    <h4>Configuração de Email</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mt-3 mb-3 text-secondary">
                      Configure aqui os emails que serão enviados para seus usuários!
                    </p>
                    <Button color="#fff" background={theme.colors.main} className="mt-1" onClick={() => setShowModalEmailSetting(true)}>
                      Configurar Email
                    </Button>
                    <Button
                      color="#fff"
                      background={theme.colors.main}
                      className="mt-1"
                      onClick={() => sendNotification()}
                      disabled={!enabledNotification}
                    >
                      {delayNotification !== '' && `(${delayNotification}) `}
                      Enviar Notificações
                    </Button>
                    <p className="mt-3 mb-3 text-secondary">
                      {`Último envio manual: ${getNotificationDate()}`}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </ContentTable>
          </Content>
        </Col>
      </Row>
    </>
  );
};
