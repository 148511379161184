import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ImTree } from 'react-icons/im';
import { BiBarcode, BiDollar } from 'react-icons/bi';
import { VscChecklist } from 'react-icons/vsc';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { HeaderTitle } from '../../components/HeaderTitle';
import { IBusinessUnit, IBusinessUnitWithChildrens } from '../../interfaces/IBusinessUnit';
import { ItemList } from '../../components/ItemList';
import api from '../../services/api';
import { ModalFormUnit } from './components/ModalFormUnit';
import { ModalQRCode } from './components/ModalQRCode';
import { ModalUnitImport } from './components/ModalImport';
import { IEmpresa } from '../../interfaces/IEmpresa';
import { downloadExcelFromResponseBinary, getDataCorrentePtBr } from '../../utils/shared-functions';

export const BusinessUnit = () => {
  const { ids } = useParams<{ ids: string }>();

  const history = useHistory();
  const [units, setUnits] = useState<IBusinessUnitWithChildrens[] | IBusinessUnit[]>([]);
  const [hierarchyUnit, setHierarchyUnit] = useState<IBusinessUnitWithChildrens>();
  const [unitSelected, setUnitSelected]
    = useState<IBusinessUnitWithChildrens | IBusinessUnit | undefined>(undefined);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenModalQRCode, setOpenModalQRCode] = useState(false);
  const [isOpenModalVendas, setOpenModalVendas] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [empresa] = useStore<IEmpresa>('company');

  const isSubUnidade = (ids !== undefined);
  const path = isSubUnidade ? (`${ids}/`) : '';
  const unidadesIDs = isSubUnidade ? ids.split('/') : [];

  const listHierarchy: () => IBusinessUnitWithChildrens[] = () => {
    const list: IBusinessUnitWithChildrens[] = [];

    let nextHierarchy = hierarchyUnit;
    let lastHierarchy = hierarchyUnit;

    unidadesIDs.forEach((id) => {
      if (nextHierarchy && `${nextHierarchy?.id}` === id) {
        lastHierarchy = nextHierarchy;
        list.push(lastHierarchy);

        if (lastHierarchy.unidadesFilhas?.length > 0) {
          [nextHierarchy] = lastHierarchy.unidadesFilhas;
        }
      }
    });

    return list;
  };

  const builderHistoryTitle: () => JSX.Element[] = () => {
    const list = listHierarchy();

    let link = '/unidades';
    const result: JSX.Element[] = [
      <Link to={link} key={link}>
        Unidades da Empresa
      </Link>,
    ];

    for (let i = 0; i < list.length; i += 1) {
      link += `/${list[i].id}`;

      result.push(<span>{' > '}</span>);
      result.push(
        <Link to={link}>
          {list[i].nome}
        </Link>,
      );
    }

    return result;
  };

  useEffect(() => {
    try {
      if (hierarchyUnit === undefined) return;

      const listHierarchies = listHierarchy();
      const lastHierarchy = listHierarchies[listHierarchies.length - 1];

      setUnits(lastHierarchy?.unidadesFilhas || []);
    } catch (error: any) {
      toast.error(error.message);
    }
  }, [hierarchyUnit]);

  useEffect(() => {
    if (isSubUnidade) {
      api
        .get(`unidade-empresa/hierarchy/${ids}`)
        .then(({ data }) => setHierarchyUnit(data.data))
        .catch(() => {
          toast.error('Erro ao buscar lista de unidades');
        });
    } else {
      setHierarchyUnit(undefined);
      api
        .get('unidade-empresa/hierarchy/father')
        .then(({ data }) => setUnits(data.data))
        .catch(() => {
          toast.error('Erro ao buscar lista de unidades');
        });
    }
  }, [ids]);

  const handleFormEdit = (id: number) => {
    const unit = units.find((u) => u.id === id);
    setUnitSelected(unit);
    setOpenModal(true);
  };

  const handleDelete = (id: number) => {
    api.delete(`unidade-empresa/${id}`).then(() => {
      const newUnits = units.filter((unit) => unit.id !== id);
      setUnits(newUnits);
    });
  };

  const handleDeletarUnidade = (id: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir essa unidade?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',

    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          handleDelete(id);
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao remover Unidade');
        }
      }
    });
  };

  const handleQRCode = (id: number) => {
    const unit = units.find((u) => u.id === id);
    setUnitSelected(unit);
    setOpenModalQRCode(true);
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
  };

  const handleUsuariosVendas = (id: number) => {
    const unit = units.find((u) => u.id === id);
    setUnitSelected(unit);
    setOpenModalVendas(true);
  };

  // const handleExportButtonClick = () => {
  //   window.open(
  //     `${process.env.REACT_APP_ENDPOINT}/unidade-empresa/export/${empresa.id}`,
  //     '_blank',
  //     'noreferrer',
  //   );
  // };

  const handleExportButtonClick = () => {
    api({
      url: `${process.env.REACT_APP_ENDPOINT}/unidade-empresa/export/${Number(empresa?.id)}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response: any) => {
      toast.success('Planilha de unidades exportada com êxito');
      downloadExcelFromResponseBinary(
        response.data,
        `planilha-de-unidades-${getDataCorrentePtBr()}.xlsx`,
      );
    }).catch((err: any) => toast.error('Erro ao exportar planilha de usuários'));
  };

  const additionalOptions = [
    {
      icon: FaMapMarkedAlt,
      label: 'Mapa',
      onClick: (id: number) => history.push(`/unidades/trilha/${id}`),
    },
  ];

  const lastUrlPathParam = ids?.split('/').reduce((lastValue: string, currentValue: string) => currentValue);
  const getUnidadeEmpresaPaiId = lastUrlPathParam ? parseInt(lastUrlPathParam, 10) : undefined;

  return (
    <>
      {unitSelected && (
        <ModalQRCode
          isOpen={isOpenModalQRCode}
          onClose={() => { setUnitSelected(undefined); setOpenModalQRCode(false); }}
          unit={unitSelected}
        />
      )}

      <ModalFormUnit
        onCloseModal={() => { setUnitSelected(undefined); setOpenModal(false); }}
        isOpen={isOpenModal}
        businessUnit={unitSelected}
        unidadeEmpresaPaiId={getUnidadeEmpresaPaiId}
        onSetBusinessUnit={(businessUnit, update) => {
          if (update) {
            const index = units.findIndex((u) => u.id === businessUnit.id);
            units[index] = businessUnit;
            setUnits([...units]);
          } else {
            setUnits([...units, businessUnit]);
          }
          setOpenModal(false);
          setUnitSelected(undefined);
        }}
      />

      {/* {unitSelected && (
        <ModalFormVendas
          unitId={unitSelected?.id}
          onCloseModal={() => { setUnitSelected(undefined); setOpenModalVendas(false); }}
          isOpen={isOpenModalVendas}
        />
      )} */}

      <ModalUnitImport onCloseModal={handleCloseImportModal} showModal={showImportModal} />

      <HeaderTitle
        onNewItem={() => setOpenModal(true)}
        title={builderHistoryTitle()}
        text="Nesta aba você deve cadastrar unidades de sua empresa. Estas informações serão úteis para vincular seus usuários a uma unidade. Vamos lá?"
        secondButtonLabel="Importar"
        exportBtn
        onExportButtonClick={handleExportButtonClick}
        onSecondButtonClick={() => setShowImportModal(true)}
        exportUser={false}
      />

      <Row>
        {units.map((item) => (
          <Col key={item.id} md={4} className="mt-3">
            <ItemList
              {...item}
              icon={ImTree}
              category="Unidades"
              onFormEdit={handleFormEdit}
              onClick={() => history.push(`/unidades/${path}${item.id}`)}
              onDelete={item.matrix ? undefined : handleDeletarUnidade}
              additionalOptions={additionalOptions}
              titleWithId
              handleQRCode={handleQRCode}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
