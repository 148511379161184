import { Formik, Form } from 'formik';
import { useCallback, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BsLock } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/auth';
import { ConvertedTheme } from '../../../App';
import { LogoEntgames } from './LogoCopyright';

interface SubmitProps {
  login: string;
  password: string;
}

const data = {
  login: '',
  password: '',
};

const schema = yup.object({
  login: yup
    .string()
    .email('Digite um email válido')
    .required('Digite seu email'),
  password: yup.string().required('Digite sua senha'),
});

export const SignIn = () => {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [theme] = useStore<ConvertedTheme>('theme');

  const handleSubmit = useCallback(async ({ login, password }: SubmitProps) => {
    try {
      setLoading(true);
      await signIn({
        login,
        password,
      });
    } catch (err: any) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        return;
      }
      toast.error('Erro ao realizar login');
    } finally {
      setLoading(false);
    }
  }, [signIn]);

  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form>
        <Col md={{ span: 4, offset: 4 }} xs={{ span: 6, offset: 3 }}>
          <img src={theme.imgLogoEscuro} alt={theme.slogan} width="100%" />
        </Col>
        <div className="mb-3 mt-2">
          <p className="text-center">{theme.slogan}</p>
        </div>
        <Input type="email" name="login" label="Email" />

        <Input name="password" label="Senha" type="password" />

        <Row>
          <Col md={{ span: 8, offset: 2 }} className="mt-4">
            <Button
              background={theme.colors.main}
              color="#fff"
              loading={loading}
              className="w-100 text-uppercase"
              type="submit"
            >
              Entrar
            </Button>
          </Col>

          {/* <Col md={{ span: 8, offset: 2 }} className="mt-3">
            <Button
              background={theme.colors.secondary}
              color="#fff"
              className="w-100 text-uppercase"
              type="button"
            >
              Contrate 7 dias grátis!
            </Button>
          </Col> */}
        </Row>

        <div className="mt-4 d-flex flex-column align-items-center">
          <Link
            to="/forgot-password"
            className="text-uppercase d-flex align-items-center"
          >
            <BsLock />
            Esqueci minha senha
          </Link>

          {/* <p className="mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.
          </p> */}
        </div>
        <LogoEntgames />
      </Form>
    </Formik>
  );
};
