import { InputHTMLAttributes, useState, FocusEvent } from 'react';
import { Field, ErrorMessage, FieldProps } from 'formik';
import InputMask from 'react-input-mask';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Container, Content, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  mask?: string;
  isRequerid?: boolean;
  name: string;
  readonly?:boolean;
}

interface MaskInputProps {
  mask: string;
}

const MaskInput = ({ field, mask }: FieldProps & MaskInputProps) => <InputMask {...field} mask={mask} type="text" />;

export function Input({
  mask,
  isRequerid,
  name, label, type, readonly, ...rest
}: InputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handlePasswordVisible = () => {
    setPasswordVisible((old) => !old);
  };

  const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFilled(!!e.target.value);
    setIsFocused(false);
  };

  return (
    <Container>
      {label && (
      <label htmlFor={name}>
        {isRequerid ? (
          <span>
            {label}
            {' '}
            <strong className="text-danger">*</strong>
          </span>
        ) : (
          <span>{label}</span>
        )}
      </label>
      )}

      <Content
        isFilled={isFilled}
        isFocused={isFocused}
        isTextarea={type === 'textarea'}
      >
        {!mask ? (
          <Field
            id={name}
            as={type === 'textarea' ? 'textarea' : 'input'}
            name={name}
            {...rest}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            disabled={readonly}
            type={type === 'password' && passwordVisible ? 'text' : type}
          />
        ) : (
          <Field
            id={name}
            name={name}
            {...rest}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            component={MaskInput}
            disabled={readonly}
            mask={mask}
          />
        )}

        {type === 'password' && (
          <button type="button" onClick={handlePasswordVisible}>
            {passwordVisible ? <FiEye /> : <FiEyeOff />}
          </button>
        )}
      </Content>

      <ErrorMessage name={name}>
        {(msg) => (
          <Error className="d-flex align-items-center mt-1">
            <RiErrorWarningFill color="red" size={16} />
            <span>{msg}</span>
          </Error>
        )}
      </ErrorMessage>

    </Container>
  );
}
