/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import api from '../../../../services/api';
import { ReactSelect } from '../../../../components/ReactSelect';
import { CheckAndUncheckBtn } from '../../../Users/styles';
import { ISelect } from '../../../../interfaces/ISelect';
import { LabelStyled } from '../../../Users/ModalImport/styles';

export const MultiSelectRelatorio: React.FC<any> = (props) => {
  const [options, setOptions] = useState<ISelect[]>([]);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    if (!isFetched) {
      api
        .get(props.endpointUrl)
        .then((response) => {
          setOptions(response?.data?.data?.map(props.endpointDataConvertFn));
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      setIsFetched(true);
    }
  }, [options, isFetched]);

  const handleOnChange = (newValues: any) => {
    props.setRelatorioState({
      ...props.relatorioState,
      [props.name]: newValues.map((option: ISelect) => option.value),
    });
  };
  return (
    <>
      <LabelStyled>{props.label}</LabelStyled>
      <Select
        name={props.name}
        label={props.label}
        isMulti
        options={options}
        component={ReactSelect}
        // isDisabled={!isFilterEnabled || viewOnly}
        // styles={selectStyle}
        // {...rest}
        placeholder="Selecione"
        className="react-select-container"
        classNamePrefix="react-select"
        onChange={handleOnChange}
        // value={isMulti ? field.value : (options
        //   ? options.find((option) => option.value === field.value)
        //   : '') as any}
      />

      {/* <CheckAndUncheckBtn
        href="#"
        onClick={() => props.setRelatorioState({
          ...props.relatorioState,
          [props.name]: options.map((option: ISelect) => option.value),
        })}
      >
        Marcar Todos

      </CheckAndUncheckBtn>
      <CheckAndUncheckBtn
        href="#"
        onClick={() => props.setRelatorioState({
          ...props.relatorioState,
          [props.name]: [],
        })}
      >
        Desmarcar Todos

      </CheckAndUncheckBtn> */}
    </>
  );
};
