import { Formik, Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import theme from '../../../../styles/theme';
import { IEmpresa } from '../../../../interfaces/IEmpresa';
import api from '../../../../services/api';
import { ISelectOption } from '../../../../utils/shared-functions';
import { LabelStyled } from '../../../Users/ModalImport/styles';

const schema = yup.object({
  qtdPontos: yup.number().required('qtdPontos é obrigatório'),
});

export type ComponentProps = {
  company?: IEmpresa;
  handleFetchCompany?: () => void;
}

const initialValues = {
  qtdPontos: 0,
};
export const AdicionarPontuacaoConfigForm = ({ company, handleFetchCompany }: ComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [loadingUnidades, setLoadingUnidades] = useState(false);
  const [unidadesOptions, setUnidadesOptions] = useState<ISelectOption[]>([]);
  const [selectedUnidadeOption, setSelectedUnidadeOption] = useState<ISelectOption>();
  const [loadingUsuarios, setLoadingUsuarios] = useState(false);
  const [usuariosOptions, setUsuariosOptions] = useState<ISelectOption[]>([]);
  const [selectedUsuarioOption, setSelectedUsuarioOption] = useState<any>(null);

  const fetchUnidades = useCallback(async () => {
    setLoadingUnidades(true);
    api
      .get('unidade-empresa')
      .then(({ data }) => {
        setUnidadesOptions(data?.data?.map(
          (unidade: any) => ({ value: unidade.id, label: unidade.nome }
          ),
        ));
      })
      .catch(() => toast.error('Erro ao buscar unidades'))
      .finally(() => setLoadingUnidades(false));
  }, []);

  const fetchUsuarios = useCallback(async (unidadeId: number) => {
    setLoadingUsuarios(true);
    api
      .get(`usuario/unidade/${unidadeId}`)
      .then(({ data }) => {
        setUsuariosOptions(data?.data?.map(
          (unidade: any) => ({ value: unidade.id, label: unidade.nome }
          ),
        ));
      })
      .catch(() => toast.error('Erro ao buscar usuários'))
      .finally(() => setLoadingUsuarios(false));
  }, []);

  useEffect(() => {
    fetchUnidades();
    if (selectedUnidadeOption) {
      fetchUsuarios(parseInt(selectedUnidadeOption.value, 10));
    }
  }, []);

  const handleSubmit = useCallback(
    async (dataForm: any) => {
      setLoading(true);

      api
        .post(`usuario/${selectedUsuarioOption?.value}/pontosextras/${dataForm?.qtdPontos}`)
        .then(() => {
          toast.success(`Sucesso ao ${dataForm.qtdPontos > 0 ? 'adicionar' : 'remover'} os pontos`);
        })
        .catch(() => toast.error('Erro ao alterar as Energias'))
        .finally(() => setLoading(false));
    },
    [selectedUsuarioOption],
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, setValues }) => (
          <Form>
            <Row className="mt-4">
              <Col xs={6} style={{ paddingTop: '6px' }}>
                <LabelStyled>Unidade:</LabelStyled>

                <Select
                  isDisabled={loadingUnidades}
                  options={unidadesOptions}
                  value={selectedUnidadeOption}
                  onChange={(option: any) => {
                    setUsuariosOptions([]);
                    setSelectedUsuarioOption(null);
                    // setSelectedUsuarioOption({ label: '', value: '' });
                    setSelectedUnidadeOption(option);
                    fetchUsuarios(option.value);
                  }}
                />
              </Col>
              <Col xs={6} style={{ paddingTop: '6px' }}>
                <LabelStyled>
                  Usuário:
                </LabelStyled>

                <Select
                  isDisabled={loadingUsuarios || !selectedUnidadeOption}
                  options={usuariosOptions}
                  value={selectedUsuarioOption}
                  onChange={(option: any) => {
                    setSelectedUsuarioOption(option);
                  }}
                />
              </Col>
              <Col xs={6}>
                <Field
                  name="qtdPontos"
                  type="number"
                  as={Input}
                  label="Pontuação Extra"
                />
              </Col>

              <Col md={{ span: 4 }} className="d-flex align-items-end" style={{ paddingTop: '5px' }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  loading={loading}
                  type="submit"
                  disabled={loadingUsuarios
                    || !selectedUnidadeOption
                    || !selectedUsuarioOption
                    || values.qtdPontos === 0}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
