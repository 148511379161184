/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AiOutlineStar } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { number, string } from 'yup/lib/locale';
import { ICargo } from '../../../interfaces/ICargo';
import api from '../../../services/api';
import { ModalComponent } from '../../../components/Modal';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import { Input } from '../../../components/Input';
import { IDesligamentoUsuario } from '../../../interfaces/IDesligamentoUsuario';
import handleErrors from '../../../utils/handle.errors';
import { ReactSelect } from '../../../components/ReactSelect';
import { IUsuario } from '../../../interfaces/IUsuario';
import { ISelect } from '../../../interfaces/ISelect';

const dadosIniciais: IDesligamentoUsuario = {
  motivoDesligamento: '',
  tipoDesligamentoUsuarioId: {
    label: '',
    value: 0,
  },
  dataDesligamento: '',
};
interface ModalFormProps {
  onCloseModal: () => void;
  usuario?: IUsuario;
  showModal: boolean;
}

const tipoDesligamentoSelect: ISelect[] = [
  { label: '1 - Justa Causa', value: '1' },
  { label: '2 - Sem Justa Causa', value: '2' },
  { label: '3 - Pedido de Demissao', value: '3' },
  { label: '4 - Acordo Entre Partes', value: '4' },
  { label: '5 - Afastamento', value: '5' },
  { label: '6 - Encerramento de Contrato', value: '6' },
];

const schema = yup.object({
  motivoDesligamento: yup.string().required('O motivo do desligamento é obrigatório'),
});

export const ModalDesligamento = ({
  usuario,
  onCloseModal,
  showModal,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteUsuario = useCallback((dataForm: IDesligamentoUsuario) => {
    setLoading(true);
    Swal.fire({
      title: 'Deseja realmente desligar este usuario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (usuario) {
            const requestData = {
              ...dataForm,
              tipoDesligamentoUsuarioId: dataForm.tipoDesligamentoUsuarioId.value,
            };
            await api.put(`usuario/desligar/${usuario.id}`, requestData);
            toast.success('Usuário desligado com sucesso');
            onCloseModal();
          }
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao desligar Usuário');
        } finally {
          setLoading(false);
        }
      }
    });
  }, [usuario]);

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title="Desligamento de Usuário"
      icon={AiOutlineStar}
    >
      <Formik
        initialValues={dadosIniciais}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleDeleteUsuario}
      >
        <Form>
          <Input type="text" name="motivoDesligamento" label="Escreva o Motivo do Desligamento:" isRequerid />
          <Col md={6}>
            <Input type="date" name="dataDesligamento" label="Data de Desligamento:" isRequerid />
          </Col>
          <Col md={6}>
            <Field
              name="tipoDesligamentoUsuarioId"
              isRequerid
              label="Tipo de Desligamento"
              component={ReactSelect}
              options={tipoDesligamentoSelect}
            />
          </Col>

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Excluir
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
