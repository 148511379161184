import { ButtonHTMLAttributes } from 'react';
import { Spinner } from 'react-bootstrap';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  background?: string;
  color: string;
  loading?: boolean;
}

export function Button({ children, loading, disabled, ...props }: ButtonProps) {
  return (
    <Container {...props} disabled={disabled || loading}>
      {children}
      {loading && (
        <Spinner animation="border" variant="light" size="sm" style={{ marginLeft: '0.3rem' }} />
      )}
    </Container>
  );
}
