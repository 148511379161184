import { Formik, Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AiOutlineStar } from 'react-icons/ai';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { IBonus } from '../../../../interfaces/IBonus';
import { ISelect } from '../../../../interfaces/ISelect';
import { ReactSelect } from '../../../../components/ReactSelect';

const dataInitial: IBonus = {
  titulo: '',
  descricao: '',
  tipoBonusId: '',
};

interface ModalFormProps {
  onSetBonus: (data: IBonus, update?: boolean) => void;
  onCloseModal: () => void;
  bonus?: IBonus;
  showModal: boolean;
}

const schema = yup.object({
  titulo: yup.string().required('O título é obrigatório'),
  descricao: yup.string().required('Campo obrigatório'),
  tipoBonusId: yup.lazy((value) => (typeof value === 'object'
    ? yup.object().nullable().required('Campo obrigatório')
    : yup.string().required('Campo obrigatório'))),
});

export const ModalFormBonus = ({
  onSetBonus,
  bonus,
  onCloseModal,
  showModal,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);
  const [tipos, setTipos] = useState<ISelect>();

  useEffect(() => {
    api.get('bonus/tipos').then(({ data }) => setTipos(
      data.data.map((item: { id: number, descricao: string }) => ({
        label: item.descricao,
        value: item.id,
      })),
    ));
  }, []);

  const handleSubmit = useCallback(
    async (dataForm: IBonus) => {
      try {
        setLoading(true);
        if (bonus) {
          const { data } = await api.put(`/bonus/${bonus.id}`, {
            id: bonus.id,
            tipoBonusId: dataForm.tipoBonusId.value || dataForm.tipoBonusId,
            ...dataForm,
          });
          onSetBonus(data.data, true);
        } else {
          const { data } = await api.post('/bonus', {
            ...dataForm,
            tipoBonusId: dataForm.tipoBonusId.value,
          });
          onSetBonus(data.data);
        }

        onCloseModal();
        toast.success('Bônus adicionado com sucesso');
      } catch (err) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar Bônus');
      } finally {
        setLoading(false);
      }
    },
    [onSetBonus, onCloseModal, bonus],
  );

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title="Incluir bônus"
      icon={AiOutlineStar}
    >
      <h6>Preencha todos os dados solicitados.</h6>
      <Formik
        initialValues={bonus || dataInitial}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="text" name="titulo" label="Título" isRequerid />
          <Input type="textarea" name="descricao" label="Descrição" isRequerid />

          <Field
            name="tipoBonusId"
            label="Tipo"
            isRequerid
            component={ReactSelect}
            options={tipos}
          />

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
