import styled from 'styled-components';
import { Modal as ModalBootstrap } from 'react-bootstrap';

export const Modal = styled(ModalBootstrap)`
  h5 {
    margin: 0;
    font-weight: bold;
    margin-left: 0.5rem;
  }

  p {
    color: ${({ theme }) => theme.colors.text};
  }
  .modal-header {
    border-bottom-style: dashed;
  }

  .modal-body {
    padding: 1rem 3rem;
  }

  @media print {
    .modal-header {
      display: none;
    }

    .modal-content {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border: none;

      .modal-body {
        margin: auto 0;
      }
    }
  }
`;
