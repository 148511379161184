import styled from 'styled-components';

export const Header = styled.div`
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 0 6px 0 #bdbdbd;


  svg, h4,  p {
    color: ${({ theme }) => theme.colors.main};
  }

  p {
    margin: 0;
  }
`;
