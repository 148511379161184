/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */

import { TIPO_FILTRO } from '../../constants';
import { MultiSelectRelatorio } from '../multi-select';
import { SelectRelatorio } from '../select';

export const FiltroRelatorio: React.FC<any> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.tipo === TIPO_FILTRO.SELECT) {
    return (
      <SelectRelatorio {...props} />
    );
  }
  if (props.tipo === TIPO_FILTRO.MULTI_SELECT) {
    return (
      <MultiSelectRelatorio {...props} />
    );
  }
  return (<SelectRelatorio {...props} />);
};
