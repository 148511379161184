import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useStore } from 'react-context-hook';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { IEmpresa } from '../../../../interfaces/IEmpresa';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { CloseCircleIcon, ImagePreview } from '../../../Trilhas/components/ModalFormQuizV2/styles';
import { LabelStyled } from '../../../Users/ModalImport/styles';

const initialValues: any = {
  imgBackgroundLogin: '',
};

const PUT_BACKGROUND_TRILHA_URL = '/empresa/background-login';

export const BackgroundLoginCadastroForm = () => {
  const [loading, setLoading] = useState(false);
  const [base64Image, setBase64Image] = useState<string | undefined>('');
  const [empresa, setEmpresa] = useStore<IEmpresa>('company');

  const fetchCompany = async () => {
    api
      .get(`/empresa/${empresa?.id}`)
      .then(({ data }) => {
        setEmpresa(data.data);
        setBase64Image(undefined);
      }).catch(() => {
        toast.error('Erro ao buscar empresa');
      });
  };

  const handleSubmit = useCallback(
    async () => {
      const requestData = {
        imgBackgroundLogin: base64Image,
      };

      try {
        await api.put(PUT_BACKGROUND_TRILHA_URL, requestData);
        toast.success('Imagem salva com sucesso!');
        fetchCompany();
      } catch (error: any) {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
          return;
        }
        toast.error('Erro ao salvar imagem');
      } finally {
        setLoading(false);
      }
    },
    [base64Image],
  );

  const handleInputChange = (event: any) => {
    const { files } = event.target;

    if (!files || files.length < 1) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e: any) => {
      setBase64Image(e.target.result);
    };
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="d-flex flex-column mb-3">
            <Col md={12} style={{ marginBottom: '5px' }}>
              <Input
                type="file"
                name="imgFile"
                label="Escolha sua imagem aqui"
                onChange={handleInputChange}
                style={{ color: 'white' }}
              />
            </Col>
            {base64Image && (
            <Col>
              <LabelStyled>Confira aqui</LabelStyled>
              <div className="d-flex justify-content-center">
                <ImagePreview src={base64Image} alt="Pre-visualizacao da imagem" />
                <CloseCircleIcon onClick={() => setBase64Image(undefined)}>
                  X
                </CloseCircleIcon>
              </div>
            </Col>
            )}
            {empresa?.imgBackgroundLogin && !base64Image && (
            <Col>
              <LabelStyled>Imagem utilizada atualmente na Trilha</LabelStyled>
              <div className="d-flex justify-content-center">
                <ImagePreview src={empresa?.imgBackgroundLogin} alt="Pre-visualizacao da imagem" />
              </div>
            </Col>
            )}
          </div>

          <Row className="mt-4">
            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                type="submit"
                loading={loading}
                disabled={!base64Image}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </>
  );
};
