import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/auth';

export const ExternalLogin = () => {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const { externalSignIn } = useAuth();

  const token: string = query.get('token') ?? '';

  useEffect(() => {
    externalSignIn(token).then(() => {
      history.push('/');
    });
  }, []);

  return (
    <>
    </>
  );
};
