import styled from 'styled-components';

export const Profile = styled.div`
  background: ${({ theme }) => theme.colors.main};
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  div {
    margin-left: 0.75rem;
    padding-left: 0.75rem;
    border-left: 2px dotted ${({ theme }) => theme.colors.lightSecondary};

    h5 {
      margin: 0;
    }

    h5, h6 {
      color: #fff;
    }

    span {
      color: ${({ theme }) => theme.colors.lightSecondary};
    }
  }
`;

export const Acessos = styled.div`
  padding-left: 2.5rem;
  margin-left: 2.5rem;
  border-left: 1px dotted #ccc;

  span {
    display: block;
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;

export const Media = styled.div`
  text-align: center;
  padding-left: 2.5rem;
  margin-left: 2.5rem;
  border-left: 1px dotted #ccc;

  h6 {
    color: ${({ theme }) => theme.colors.main};
  }

  h6, h3 {
    margin: 0;
  }

  span {
    font-size: 0.9rem;
  }
`;

export const Tutorial = styled.div`
  div {
    h6 {
      font-size: 0.8rem;
    }

    h5 {
      font-size: 0.9rem;
      font-weight: 600;
    }

    h6, svg, h5 {
      color: ${({ theme }) => theme.colors.main};
    }

    svg {
      font-size: 1.5rem;
    }
  }

  & + div {
    margin-top: 1.25rem;
  }
`;
