/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { SiCircle } from 'react-icons/si';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { ICurso } from '../../../../interfaces/ICurso';
import { ReactSelect } from '../../../../components/ReactSelect';
import { ISelectOption } from '../../../../utils/shared-functions';

const dataInitial = {
  cursoId: {
    value: '',
    label: '',
  },
};

interface SubmitProps {
  cursoId: {
    label: string;
    value: string;
  };
}

interface ModalFormCursoProps {
  onCloseModal: () => void;
  selectedCursos: Array<ICurso>;
  idAssociado?: string;
  postEndpointUrl: string;
  reqAttrName: string;
  showModalFormCurso: boolean;
  includeInstalados?: boolean
}

const schema = yup.object({});

export const ModalFormCurso = ({
  idAssociado,
  showModalFormCurso,
  selectedCursos,
  onCloseModal,
  postEndpointUrl,
  reqAttrName,
  includeInstalados = false,
}: ModalFormCursoProps) => {
  const [loading, setLoading] = useState(false);
  const [cursos, setCursos] = useState<ICurso[]>([]);
  const [cursosInstalados, setCursosInstalados] = useState<ICurso[]>([]);

  useEffect(() => {
    api.get('/curso/ativos').then(({ data }) => {
      const cursosOptions = data.data.map((item: { id: number, nome: string }) => ({
        label: item.nome,
        value: item.id,
      }));

      const notSelectedCursos = cursosOptions
        .filter((item: { label: string, value: number }) => !selectedCursos
          .find((curso) => curso.id === item.value));

      setCursos(notSelectedCursos);
    });

    return () => {
      setCursos([]);
    };
  }, [selectedCursos, setCursos]);

  type IItem = { label: string, value: number };

  useEffect(() => {
    if (includeInstalados) {
      api.get('/curso/empresa/instalados').then(({ data }) => {
        const cursosInstaladosOptions = data.data.map((curso: { id: number, nome: string }) => ({
          label: curso.nome,
          value: curso.id,
        }));

        const notSelectedCursosInstalados = cursosInstaladosOptions
          .filter((item: IItem) => !selectedCursos
            .find((curso) => curso.id === item.value));

        setCursosInstalados(notSelectedCursosInstalados
          .map((curso: ISelectOption) => ({
            label: `${curso.label} (Curso Instalado)`,
            value: curso.value,
          })));
      });
    }

    return () => {
      setCursosInstalados([]);
    };
  }, [selectedCursos]);

  const handleSubmit = useCallback(
    async ({ cursoId }: SubmitProps) => {
      setLoading(true);

      try {
        await api.post(postEndpointUrl, {
          [reqAttrName]: idAssociado,
          CursosId: [{
            CursoId: cursoId.value,
          }],
        });

        toast.success('Curso salvo com sucesso');
        onCloseModal();
      } catch (err) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar curso');
      } finally {
        setLoading(false);
      }
    },
    [idAssociado, onCloseModal],
  );

  return (
    <>
      <ModalComponent
        onClose={onCloseModal}
        modalVisible={showModalFormCurso}
        title="Adicionar Cursos"
        icon={SiCircle}
        size="lg"
      >
        <h6>Preencha todos os dados solicitados</h6>
        <Formik
          initialValues={dataInitial}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          <Form>
            <Row>
              <Col md={12}>
                <Field
                  name="cursoId"
                  label="Adicione os cursos que deseja que estejam na trilha de aprendizado (Cursos instalados serão listados por útimo)
                  Todo curso neste subgrupo será disponibilizado também aos grupos maiores."
                  isRequerid
                  component={ReactSelect}
                  options={cursos.concat(cursosInstalados)}
                />

              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  loading={loading}
                  className="text-uppercase"
                  type="submit"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      </ModalComponent>
    </>
  );
};
