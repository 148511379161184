/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { Col, Row } from 'react-bootstrap';
import CPF from 'cpf-check';
import * as yup from 'yup';
import { FaRegIdBadge } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Input } from '../../../components/Input';
import { ModalComponent } from '../../../components/Modal';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import { IEmpresa } from '../../../interfaces/IEmpresa';
import { LabelStyled } from '../../Users/ModalImport/styles';
import { ReactSelect } from '../../../components/ReactSelect';
import { IRamoEmpresa } from '../../../interfaces/IRamoEmpresa';
import { ISelect } from '../../../interfaces/ISelect';

const dataInitial: IEmpresa = {
  id: 0,
  nome: '',
  fantasia: '',
  slogan: '',
  cnpj: '',
  imgLogo: '',
  imgLogoEscuro: '',
  corPrimaria: '',
  nomeIdentificador: '',
  email: '',
  linkBi: '',
  ramoEmpresaId: undefined,
  hasImgLogoChanged: false,
  hasImgLogoEscuroChanged: false,
  quantidadeInicialEnergiasUsuario: 6,
};
const schema = yup.object({
  nome: yup.string().required('Nome da empresa é obrigatório'),
  fantasia: yup.string().required('Nome fantasia é obrigatório'),
  cnpj: yup.string().required('CNPJ é obrigatório'),
});

interface ModalFormProps {
  onCloseModal: () => void;
  empresaM?: IEmpresa;
  onSetEmpresas: (empresa: IEmpresa, update?: boolean) => void;
  showModal: boolean;
}

function getStringOrDefault(v: any) {
  return (typeof v === 'string') ? v : '';
}

function getIntOrDefault(v: any) {
  return (typeof v === 'number') ? v : 0;
}

function getBoolOrDefault(v: any) {
  return (v) ? 'true' : 'false';
}

export const ModalForm = ({ onCloseModal, showModal,
  onSetEmpresas, empresaM: empresa }: ModalFormProps) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(() => empresa?.corPrimaria);
  const [selectedFileLogo, setSelectedFileLogo] = useState<File | any>();
  const [selectedFileLogoEscuro, setSelectedFileLogoEscuro] = useState<File | any>();
  const [hasImgLogoChanged, setHasImgLogoChanged] = useState<boolean>(false);
  const [hasImgLogoEscuroChanged, setHasImgLogoEscuroChanged] = useState<boolean>(false);
  const [ramoEmpresas, setRamoEmpresas] = useState<ISelect[]>([]);
  // const [previewLogo, setPreviewLogo] = useState(() => empresa?.imgLogo);

  useEffect(() => {
    api.get('ramo-empresa').then(({ data }) => {
      const ramoEmpresasData: ISelect[] = data.data.map((ramo: IRamoEmpresa) => ({
        value: ramo.id,
        label: ramo.nome,
      }));

      setRamoEmpresas(ramoEmpresasData);
    });
  }, []);

  useEffect(() => {
    if (empresa?.corPrimaria != null) {
      setColor(empresa?.corPrimaria);
    }
  }, [empresa?.corPrimaria, setColor]);

  const handleUploadLogo = (file: any) => {
    setSelectedFileLogo(file.target.files[0]);
    setHasImgLogoChanged(true);
  };

  const handleUploadLogoEscuro = (file: any) => {
    setSelectedFileLogoEscuro(file.target.files[0]);
    setHasImgLogoEscuroChanged(true);
  };

  const handleSetColor = (corPrimaria: any) => {
    setColor(corPrimaria);
  };

  const getFormattedFormData = useCallback(async (dataForm: IEmpresa) => {
    const formData = new FormData();
    formData.append('nome', getStringOrDefault(dataForm.nome));
    formData.append('fantasia', getStringOrDefault(dataForm.fantasia));
    formData.append('ramoEmpresaId', dataForm.ramoEmpresaId.value || dataForm.ramoEmpresaId);
    formData.append('nomeidentificador', getStringOrDefault(dataForm.nomeIdentificador));
    formData.append('cnpj', getStringOrDefault(dataForm.cnpj).replace(/[-.]/g, '').replace('/', ''));
    formData.append('slogan', getStringOrDefault(dataForm.slogan));
    formData.append('email', getStringOrDefault(dataForm.email));
    formData.append('imgLogo', selectedFileLogo);
    formData.append('imgLogoEscuro', selectedFileLogoEscuro);
    formData.append('corPrimaria', getStringOrDefault(color));
    formData.append('hasImgLogoChanged', getBoolOrDefault(hasImgLogoChanged));
    formData.append('hasImgLogoEscuroChanged', getBoolOrDefault(hasImgLogoEscuroChanged));
    formData.append('linkBi', getStringOrDefault(dataForm.linkBi));
    formData.append('quantidadeInicialEnergiasUsuario', getIntOrDefault(dataForm.quantidadeInicialEnergiasUsuario).toString());

    return formData;
  }, [color, hasImgLogoChanged, hasImgLogoEscuroChanged,
    selectedFileLogo, selectedFileLogoEscuro]);

  const handleSubmit = useCallback(async (dataForm: IEmpresa) => {
    try {
      setLoading(true);

      const formData = await getFormattedFormData(dataForm);

      let returnData;
      if (empresa) {
        formData.append('id', getIntOrDefault(empresa?.id).toString());
        returnData = await api.put(`empresa/${empresa?.id}`, formData);
      } else {
        returnData = await api.post('empresa', formData);
      }

      onSetEmpresas({ ...returnData?.data?.data }, true);
      onCloseModal();
      toast.success('Empresa salva com sucesso');
    } catch (err: any) {
      if (err.response.data.errors) {
        const errors = err.response.data.errors.join(', ');
        toast.error(errors);
        return;
      }
      toast.error('Erro ao salvar empresa');
    } finally {
      setLoading(false);
    }
  }, [getFormattedFormData, empresa, onSetEmpresas, onCloseModal]);

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title="Incluir empresa"
      icon={FaRegIdBadge}
      size="lg"
    >
      <p>
        Cadastre aqui as empresas.
      </p>
      <h6>Preencha todos os dados solicitados.</h6>

      <Formik
        initialValues={empresa || dataInitial}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        <Form>
          <Row>
            <Col md={12}>
              <Input type="text" name="nome" label="Nome Empresa" isRequerid />
            </Col>
            <Col md={8}>
              <Input type="text" name="fantasia" label="Nome Fantasia" isRequerid />
            </Col>
            <Col md={4}>
              <Input type="text" name="nomeIdentificador" label="Identificador Empresa" />
            </Col>
            <Col md={6}>
              <Input type="text" mask="99.999.999/9999-99" name="cnpj" label="CNPJ" isRequerid />
            </Col>
            <Col md={6}>
              <Input type="text" name="email" label="email" isRequerid />
            </Col>
            <Col md={12}>
              <Input type="text" name="slogan" label="Slogan" />
            </Col>
            <Col md={12}>
              <Input type="text" name="linkBi" label="Link BI" />
            </Col>
            <Col md={4}>
              <Input type="number" min={1} name="quantidadeInicialEnergiasUsuario" label="Quantidade de Energias Diárias" />
            </Col>
            <Col md={8}>
              <Field
                name="ramoEmpresaId"
                isRequerid
                label="Ramo da Empresa"
                component={ReactSelect}
                options={ramoEmpresas}
              />
            </Col>
            <Col md={6}>
              <LabelStyled>Logo</LabelStyled>
              <input onChange={handleUploadLogo} type="file" name="imgLogo" />
            </Col>
            <Col md={6}>
              <LabelStyled>Logo Escuro</LabelStyled>
              <input onChange={handleUploadLogoEscuro} type="file" name="imgLogoEscuro" />
            </Col>
            <Col md={6}>
              <LabelStyled>Cor Primária</LabelStyled>
              <HexColorInput color={color} onChange={handleSetColor} placeholder="Informe uma cor" />
              <HexColorPicker color={color} onChange={handleSetColor} />
            </Col>

          </Row>
          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                loading={loading}
                className="text-uppercase"
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
