/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { BiTask } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ModalComponent } from '../../../components/Modal';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import api from '../../../services/api';

interface ModalFormChecklistCategoryProps {
  onClose: () => void;
  isOpen: boolean;
  checklistCategory?: IChecklistCategory;
  categoriaPaiId?: string;
  isSuperAdmin?: boolean;
}

interface IChecklistCategory {
  checklistUnidadeCategoriaIdPai: number;
  checklistUnidadeCategoriaId: number;
  nome: string;
  descricao: string,
}

const defaultValues: IChecklistCategory = {
  checklistUnidadeCategoriaIdPai: 0,
  checklistUnidadeCategoriaId: 0,
  nome: '',
  descricao: '',
};

const CHECKLIST_CATEGORY_BASE_URL = 'checklist-unidade/categorias';

export const ModalFormChecklistCategory = ({
  isOpen,
  onClose,
  checklistCategory,
  categoriaPaiId,
  isSuperAdmin = false,
}: ModalFormChecklistCategoryProps) => {
  const [loading, setLoading] = useState(false);

  const handleOnClose = () => {
    onClose();
  };

  const handleSubmit = useCallback(
    async (values: IChecklistCategory) => {
      let method;
      let url = '';
      let operation = '';
      let requestData = {};
      if (checklistCategory) {
        method = api.put;
        url = `${CHECKLIST_CATEGORY_BASE_URL}/${checklistCategory.checklistUnidadeCategoriaId}`;
        operation = 'editado';
        requestData = {
          nome: values.nome,
          descricao: values.descricao,
        };
      } else {
        method = api.post;
        url = CHECKLIST_CATEGORY_BASE_URL;
        operation = 'criado';
        requestData = {
          nome: values.nome,
          descricao: values.descricao,
          checklistUnidadeCategoriaPaiId: categoriaPaiId,
        };
      }

      setLoading(true);
      method(url, requestData)
        .then(() => {
          toast.success(`Categoria de Checklist ${operation} com sucesso`);
          console.log('🚀 ~ file: index.tsx:110 ~ .then ~ then');
          onClose();
        })
        .catch((error) => {
          console.log('🚀 ~ file: index.tsx:113 ~ catch', error);
          toast.error(error?.response?.data?.errors[0] || 'Ocorreu um erro ao salvar a categoria');
        })
        .finally(() => {
          console.log('🚀 ~ file: index.tsx:122 ~ .finally ~ finally');
          setLoading(false);
        });
    },
    [checklistCategory, categoriaPaiId, onClose],
  );

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.nome) {
      errors.nome = 'O campo Nome é obrigatório';
      errors.descricao = 'O campo Descrição é obrigatório';
    }

    return errors;
  };

  return (
    <ModalComponent
      onClose={handleOnClose}
      modalVisible={isOpen}
      title={`${checklistCategory ? 'Editar' : 'Adicionar'} Categoria de Checklist`}
      icon={BiTask}
      size="lg"
    >
      <Formik
        initialValues={checklistCategory || defaultValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {() => (
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Input type="text" name="nome" label="Nome" isRequerid />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input type="text" name="descricao" label="Descrição" isRequerid />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  type="submit"
                  loading={loading}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ModalComponent>
  );
};
