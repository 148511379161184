/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { HeaderTitle } from '../../../../components/HeaderTitle';
import { Button } from '../../../../components/Button';
import api from '../../../../services/api';
import { SimpleSelect } from '../../../../components/SimpleSelect';
import { Container } from '../../Trilha/styles';

export const SelectRelatorio: React.FC = (props: any) => {
  const [options, setOptions] = useState<any[]>([]);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    if (!isFetched) {
      api.get(props.endpointUrl).then((response) => {
        setOptions(response?.data?.data?.map(props.endpointDataConvertFn));
      }).catch((error) => {
        toast.error(error.response.data.message);
      });
      setIsFetched(true);
    }
  }, [options, isFetched]);

  const handleOnChange = (e: any) => {
    props.setRelatorioState({
      ...props.relatorioState,
      [props.name]: e.target.value,
    });
  };
  return (
    <div style={{ marginTop: '0', paddingTop: '0.2rem' }}>
      <SimpleSelect
        options={options}
        name={props.name}
        label={props.label}
        onChange={handleOnChange}
        defaultValue={props.defaultValue}
        value={props.relatorioState[props.name]}
      />
    </div>
  );
};
