import React, { SelectHTMLAttributes, useState } from 'react';
import { ISelect } from '../../interfaces/ISelect';
import { Container, Content } from '../Input/styles';

// interface Props {
//   name: string;
//   label?: string;
//   options: Array<ISelect>;
//   defaultValue?: ISelect;
// }

export function SimpleSelect({
  options, name, label, defaultValue, onChange, value, disabled = false,
}: any) {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleOnChangeInternal = (option: any) => {
    onChange(option);
  };

  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <Content isFocused={isFocused}>
        <select
          name={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleOnChangeInternal}
          value={value}
          disabled={disabled}
        >
          {!value && (
            // <option value="" selected disabled>
            <option value="">
              Selecione
            </option>
          )}

          {options?.map((option: any) => (
            <option
              key={option.value}
              value={option.value}
            // selected={defaultValue?.value === option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </Content>
    </Container>
  );
}
