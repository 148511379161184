import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ItemList } from '../../components/ItemList';
import api from '../../services/api';
import { IChecklistCategory } from '../../interfaces/IChecklistCategory';

const CHECKLIST_CATEGORY_URL = '/categoria-checklist';

export const ChecklistSystemCategory = () => {
  const history = useHistory();
  const [
    checklistCategories, setChecklistCategories,
  ] = useState<any[]>([]);

  const builderHistoryTitle: () => JSX.Element[] = () => {
    const link = CHECKLIST_CATEGORY_URL;
    const result: JSX.Element[] = [
      <Link to={link} key={link}>
        Categorias do Checklist
      </Link>,
    ];

    return result;
  };

  const getCategoriasSistemaChecklist = () => {
    api.get('/checklist-unidade/categorias/principais')
      .then(({ data }) => {
        setChecklistCategories(data.data);
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de categorias');
      });
  };

  useEffect(() => {
    try {
      getCategoriasSistemaChecklist();
    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);

  const handleOpenChecklistCategory = (id: number) => {
    history.push(`${CHECKLIST_CATEGORY_URL}/${id}`);
  };

  return (
    <>
      <HeaderTitle
        title={builderHistoryTitle()}
        text="Nesta aba você pode ver as categorias dos checklists. Clique para ver as subcategorias."
      />

      <Row>
        {checklistCategories.map((checklist, index) => (
          <Col key={index} md={6} className="mt-3">
            <ItemList
              {...checklist}
              id={checklist.checklistUnidadeCategoriaId}
              icon={BsCheckAll}
              category={checklist.descricao}
              title={checklist.nome}
              onClick={() => handleOpenChecklistCategory(checklist.checklistUnidadeCategoriaId)}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
