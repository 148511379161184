import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  @media print {
    width: 500px;
  }

  &.only-qr-code {
    svg.qr-code {
      display: none;
    }

    @media print {
      *:not(.qr-code) {
        display: none;
      }

      svg.qr-code, svg.qr-code > * {
        display: block !important;
      }
    }
  }
`;

export const Logo = styled.div`
  width: 100%;
  text-align: center;
`;

export const Description = styled.div`
  word-spacing: 500px;
  font-size: 32px;
  padding-right: 15px;
  font-weight: 700;
`;

export const QRCodeContainer = styled.div`
  width: 192px;
  height: 192px;
  text-align: center;
`;

export const QRBody = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
`;

export const LinkBottom = styled.div`
  margin-top: 15px;
  width: 100%;
  text-align: center;
`;

export const ButtonPrint = styled.button`
  @media print {
    display: none;
  }

  background: none;
  border: none;
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const ButtonOnlyQrCode = styled(ButtonPrint)`
  right: 52px;
`;
