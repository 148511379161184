/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImTree } from 'react-icons/im';
import { Col, Row, Spinner } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { ModalComponent } from '../../../../../components/Modal';
import { Button } from '../../../../../components/Button';
import theme from '../../../../../styles/theme';
import api from '../../../../../services/api';
import { EMPTY_OPTION, ISelectOption, UFS_SELECT_OPTIONS } from '../../../../../utils/shared-functions';
import { ICurso } from '../../../../../interfaces/ICurso';
import { IEmpresa } from '../../../../../interfaces/IEmpresa';
import { CompanyItem } from './styles';

interface ModalEmpresasInstaladasProps {
  onCloseModal: () => void;
  isOpen: boolean;
  course: ICurso | undefined;
}

export const ModalEmpresasInstaladas = ({
  onCloseModal, isOpen, course,
}: ModalEmpresasInstaladasProps) => {
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingInstall, setLoadingInstall] = useState(false);
  const [loadingInstalledCompanies, setLoadingInstalledCompanies] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<ISelectOption[]>([]);
  const [selectedCompanyOption, setSelectedCompanyOption] = useState<ISelectOption>(EMPTY_OPTION);
  const [installedCompanies, setInstalledCompanies] = useState<IEmpresa[]>([]);

  const fetchCompanies = useCallback(async () => {
    setLoadingCompanies(true);
    api
      .get('empresa')
      .then(({ data }) => {
        setCompanyOptions(data?.data?.map(
          (company: IEmpresa) => ({ value: company.id, label: company.nome }
          ),
        ));
      })
      .catch(() => toast.error('Erro ao buscar empresas'))
      .finally(() => setLoadingCompanies(false));
  }, []);

  const fetchInstalledCompanies = useCallback(async () => {
    setLoadingInstalledCompanies(true);
    api
      .get(`empresa/curso/${course?.id}/instalacoes`)
      .then(({ data }) => setInstalledCompanies(data.data))
      .catch(() => toast.error('Erro ao buscar compartilhamentos'))
      .finally(() => setLoadingInstalledCompanies(false));
  }, [course]);

  useEffect(() => {
    if (isOpen && course) {
      fetchCompanies();
      fetchInstalledCompanies();
    }
  }, [isOpen, fetchCompanies, course, fetchInstalledCompanies]);

  const SelectEmpresas: React.FC = () => (
    <div style={{ marginTop: '0', paddingTop: '0.2rem' }}>
      <Select
        isDisabled={loadingCompanies}
        options={companyOptions}
        value={selectedCompanyOption}
        onChange={(option: any) => {
          setSelectedCompanyOption(option);
        }}
      />
    </div>
  );

  const handleOnInstalarClick = () => {
    setLoadingInstall(true);

    api
      .post(`curso/${course?.id}/instalar/empresa/${selectedCompanyOption?.value}`)
      .then(({ data }) => {
        toast.success('Curso compartilhado com sucesso!');
        setSelectedCompanyOption(EMPTY_OPTION);
        fetchInstalledCompanies();
      })
      .catch(() => toast.error('Erro ao compartilhar curso'))
      .finally(() => setLoadingInstall(false));
  };

  const handleOnDesinstalarClick = (companyId: number) => {
    Swal.fire({
      title: 'Deseja realmente descompartilhar?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        api
          .delete(`curso/${course?.id}/desinstalar/empresa/${companyId}`)
          .then(({ data }) => {
            toast.success('Curso compartilhado com sucesso!');
            setSelectedCompanyOption(EMPTY_OPTION);
            fetchInstalledCompanies();
          })
          .catch(() => toast.error('Erro ao compartilhar curso'))
          .finally(() => setLoadingInstalledCompanies(false));
      }
    });
  };

  const internalOnClose = () => {
    setSelectedCompanyOption(EMPTY_OPTION);
    setInstalledCompanies([]);
    onCloseModal();
  };

  const BtnInstall: React.FC = () => (
    <Button
      type="button"
      disabled={selectedCompanyOption.value === EMPTY_OPTION.value}
      loading={loadingInstall}
      background={theme.colors.main}
      color="#fff"
      onClick={handleOnInstalarClick}
      className="ml-2"
      style={{ padding: '0 1.5rem' }}
    >
      Compartilhar
    </Button>

  );

  const InstallCourse: React.FC = () => (
    <Row>
      <Col xs={12} className="mb-3">
        <h5>Compartilhar com a empresa:</h5>
      </Col>
      <Col xs={8}>
        <SelectEmpresas />
      </Col>
      <Col xs={4} className="d-flex justify-content-center align-items-end">
        <BtnInstall />
      </Col>
    </Row>
  );

  const CompaniesList: React.FC = () => (
    <Row>
      <Col xs={12} className="mb-3">
        <h5>Empresas que possuem este curso compartilhado:</h5>
      </Col>
      <Col xs={12}>
        {!loadingInstalledCompanies && !installedCompanies.length && (<span>Sem registros</span>)}
        {loadingInstalledCompanies && (<Spinner animation="border" size="sm" />)}

        {installedCompanies.map((company: IEmpresa, idx: number) => (
          <CompanyItem key={idx}>
            <Col xs={11}>
              {company?.nome}
            </Col>
            {/* <Col xs={4}>
              desde...
            </Col> */}
            <Col xs={1}>
              <button type="button" title="Excluir" onClick={() => handleOnDesinstalarClick(company?.id || 0)} className="reset-btn-layout">
                <BiTrash />
              </button>
            </Col>
          </CompanyItem>
        ))}
      </Col>
    </Row>
  );

  return (
    <ModalComponent
      onClose={internalOnClose}
      modalVisible={isOpen}
      title={`Compartilhamentos do Curso: ${course?.nome}`}
      icon={ImTree}
      size="lg"
    >
      {/* <Row>
        <Col xs={12}>
          <h5>
            Curso:
            {course?.nome}
          </h5>
        </Col>
      </Row>
      <hr /> */}
      <Row>

        <Col xs={12} className="mb-3">
          <InstallCourse />
        </Col>

        <hr />

        <Col xs={12}>
          <CompaniesList />
        </Col>
      </Row>
    </ModalComponent>
  );
};
