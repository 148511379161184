/* eslint-disable @typescript-eslint/no-explicit-any */
import { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle<{ theme: any }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
   scroll-behavior: smooth;

   @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: #fafafa;
  }

  html, body, #root, main {
    min-height: 100%;
    height: 100%;
  }

  body, input, button, textarea {
    font-family: 'Montserrat', sans-serif;
  }

  button, input, select{
    outline: none;
    &:focus {
      outline: transparent !important;
    }
  }

  ul {
    list-style: none;
  }

  a{
    text-decoration: none !important;
  }

  label.label{
    font-size: 0.8rem;
    font-weight: 600;
    pointer-events: none;
    width: auto;
    color: #333333;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .border-dotted-left {
    border-left: 2px dotted #fff;
  }

  .border-dotted-right {
    border-right: 2px dotted #fff;
  }

  .border-dotted-top {
    border-top: 2px dotted #fff;
  }

  .border-dotted-top {
    border-top: 1px dotted #ccc;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .theme-color {
    color: ${({ theme }) => theme.colors.main} !important;
  }
  
  .theme-color-bg {
    background: ${({ theme }) => theme.colors.main} !important;
  }

  .red-color {
    color: red !important;
  }

  // .field-error{
  //   width: 100%;
  //   margin-top: 0.25rem;
  //   font-size: .875em;
  //   color: #dc3545;
  // }

  .rounded-gray-bg{
    background-color: #cccccc85;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label.styled-label{
    font-size: 0.8rem;
    font-weight: 600;
    background: #fff;
    pointer-events: none;
    overflow: hidden;
    width: auto;
    display: inline-block;
    position: relative;
    top: 0px;
    left: 4px;
    transition: all 0.3s ease-out;
    color: #333333;
  }

  .reset-btn-layout {
    border: none !important;
    background: transparent !important;
  }


  @media (max-width: 768px) {
    .border-dotted-left {
      border: 0;
    }

    .border-dotted-right {
      border: 0;
    }
  }
`;
