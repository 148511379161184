import { ImTree } from 'react-icons/im';
import QRCode from 'react-qr-code';
import { useStore } from 'react-context-hook';
import { FaDownload, FaPrint } from 'react-icons/all';
import { useEffect, useState } from 'react';
import { ModalComponent } from '../../../../components/Modal';
import { ConvertedTheme } from '../../../../App';
import {
  ButtonOnlyQrCode,
  ButtonPrint,
  Container,
  Description,
  LinkBottom,
  Logo,
  QRBody,
  QRCodeContainer,
} from './styles';
import { IBusinessUnit } from '../../../../interfaces/IBusinessUnit';

interface ModalQRCodeProps {
  isOpen: boolean;
  onClose: () => void;
  unit: IBusinessUnit;
}

export const ModalQRCode = (props: ModalQRCodeProps) => {
  const { isOpen, onClose, unit } = props;
  const [onlyQRCode, setOnlyQRCode] = useState(false);
  const [theme] = useStore<ConvertedTheme>('theme');

  const formatURL = (unit_ : IBusinessUnit) => {
    const url = `${window.location.origin}/${window.location.pathname.split('/')[1]}/nps/${unit_.id}`;
    return url.replaceAll('admin', 'jogador');
  };

  const printContainer = () => {
    window.print();
  };

  useEffect(() => {
    if (onlyQRCode) {
      printContainer();
      setOnlyQRCode(false);
    }
  }, [onlyQRCode]);

  return (
    <ModalComponent title="QR Code" icon={ImTree} onClose={onClose} modalVisible={isOpen}>
      <Container id="container-printable" className={onlyQRCode ? 'only-qr-code' : ''}>
        <ButtonPrint type="button" onClick={printContainer}>
          <FaPrint size={24} />
        </ButtonPrint>
        <ButtonOnlyQrCode
          type="button"
          onClick={() => {
            setOnlyQRCode(true);
          }}
        >
          <FaDownload size={24} />
        </ButtonOnlyQrCode>
        <Logo>
          <img src={theme.imgLogo} alt={process.env.REACT_APP_COMPANY_NAME} width="130" />
        </Logo>
        <QRBody className="qr-code">
          <Description>
            <span>Avalie sua Experiência</span>
          </Description>
          <QRCodeContainer className="qr-code">
            <QRCode size={192} value={formatURL(unit)} />
            {onlyQRCode && (
              <QRCode className="qr-code" size={512} value={formatURL(unit)} />
            )}
          </QRCodeContainer>
        </QRBody>
        <p>{unit.nome}</p>
        <LinkBottom>
          <span>
            <a href={formatURL(unit)}>{formatURL(unit)}</a>
          </span>
        </LinkBottom>
      </Container>
    </ModalComponent>
  );
};
