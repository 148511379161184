import { lighten } from 'polished';
import styled, { css, keyframes } from 'styled-components';

interface IMenuVisible {
  visible: boolean;
}

const animateFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-200px);
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
`;

const animateOpacity = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const animateFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const blurAnimate = keyframes`
  from {
    opacity: 0;
    width: 100%;
  }
  to {
    opacity: 1;
    width: 25%;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Menu = styled.div<IMenuVisible>`
  background: ${({ theme }) => theme.colors.main};
  width: 19%;
  height: 100%;
  position: fixed;
  z-index: 4;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* animation: ${animateFromLeft} 0.6s ease forwards; */
  /* visibility: hidden; */
  /* animation-delay: 0.6s; */

  @media (max-width: 768px) {
    animation-name: none;
    visibility: visible;
    top: 0;
    width: 100%;
    height: 70px;
    background: transparent;

    ${({ visible }) => visible
      && css`
        &::after {
          content: '';
          position: absolute;
          height: 100%;
          width: 25%;
          background: rgba(1, 1, 1, 0.3);
          animation: ${blurAnimate} 400ms ease forwards;
          right: 0;
          top: 0;
        }
      `}
  }
`;

export const Mobile = styled.div`
  display: none;
  padding: 0 0.5rem;
  background: ${({ theme }) => theme.colors.main};
  height: 70px;

  h4 {
    margin: 0;
  }

  @media (max-width: 768px) {
    display: flex;
    height: 100%;
    align-items: center;

    button {
      background: transparent;
      border: 0;
      color: #fff;
    }
  }
`;

export const HeaderMobile = styled.div`
  display: none;
  padding: 20px 15px;

  button {
    background: transparent;
    border: 0;
    color: #fff;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Logo = styled.div`
  display: flex;
  padding: 0.85rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Navbar = styled.div<IMenuVisible>`
  height: 100%;

  p {
    font-size: 0.9rem;
    padding: 0 0.5rem;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      background: ${({ theme }) => lighten(0.7, theme.colors.main)};

      & + li {
        margin-top: 1px;
      }



      a, button {
        padding: 0.7rem 0.9rem 0.7rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: start;
        font-size: 1rem;
        background: transparent;
        border: 0;
        color: #141618;
        font-weight: 500;

        svg {
          font-size: 1.1rem;
          margin-right: 0.5rem;
        }
      }

      &.disabled{
        a,button{
          color:#777777;
          cursor: not-allowed;
        }
      }
    }

    .selected {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 8px;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.main};
      }
    }
  }

  @media (max-width: 768px) {
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    animation: ${animateFromLeft} 400ms ease forwards;
    height: 100vh;
    position: absolute;
    width: 75%;
    top: 0;
    background: ${({ theme }) => theme.colors.main};

    > ul {
      margin-top: 15px;
    }
  }
`;

export const ApplyOverflow = styled.div`
  overflow-y: hidden;

  &:hover {
    overflow-y: auto;
  }
`;

export const HeaderNavbar = styled.div`
  background: ${({ theme }) => lighten(0.1, theme.colors.main)};
  width: 82%;
  position: fixed;
  right: 0px;
  padding: 0.8rem 1rem 0.8rem 1.75rem;
  z-index: 3;
  /* animation: ${animateFromTop} 0.6s ease forwards; */

  @media (max-width: 768px) {
    animation-name: none;
    top: 70px;
    width: 100%;
    padding: 0.8rem;

    p {
      display: none;
    }
  }
`;

export const ItemHeaderNavbar = styled.div`
  p {
    margin: 0;
  }

  img {
    margin-right: 2rem;
  }
`;

export const Wrapper = styled.div<IMenuVisible>`
  width: 81%;
  position: absolute;
  top: 65px;
  right: 0px;
  z-index: 2;
  /* animation: ${animateOpacity} 0.8s ease forwards; */
  /* visibility: hidden; */
  animation-delay: 1.2s;

  @media (max-width: 768px) {
    animation-name: none;
    visibility: visible;
    width: 100%;
    top: 130px;
  }
`;

export const Content = styled.div`
  padding: 0 1rem;
  padding-bottom: 2rem;
`;
