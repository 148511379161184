import { GoGraph } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { Container, Icon } from './styles';

interface ReportProps {
  title: string;
  link: string;
  description: string;
}

export const Report = ({ title, link, description }: ReportProps) => (
  <Container className="d-flex flex-column justify-content-center">
    <Link to={link}>
      <div className="d-flex align-items-center">
        <Icon className="d-flex align-items-center justify-content-center">
          <GoGraph />
        </Icon>

        <h6>{title}</h6>
      </div>
      <p>{description}</p>
    </Link>

  </Container>
);
