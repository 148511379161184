/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { toast } from 'react-toastify';

export default (err: any) => {
  console.log(err);
  if (err.response) {
    if (err.response.data.message) {
      toast.error(err.response.data.message);
    } else if (Array.isArray(err.response.data.errors)) {
      err.response.data.errors.forEach((e: any) => toast.error(e));
    }
  } else {
    toast.error('Erro interno!');
  }
};
