import { HeaderTitle } from '../../../components/HeaderTitle';
import { FormUseTerms } from '../../Use-Terms/components/form-use-terms';
import { Container } from '../../Use-Terms/styles';

export const UseTermsSuperAdminPage = () => (
  <Container>

    <HeaderTitle
      title="Termos de Uso"
      text="Ao alterar os Termos de Uso e salvar, automaticamente nova versão é apresentada para todos os jogadores de todas as empresas no próximo login"
    />

    <FormUseTerms />
  </Container>
);
