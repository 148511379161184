import { useEffect } from 'react';
import { useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoMdImages } from 'react-icons/io';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import api from '../../services/api';
import theme from '../../styles/theme';
import { Button } from '../Button';
import DropzoneUpload from '../Dropzone';
import { ModalComponent } from '../Modal';
import { Thumbnail } from '../Thumbnail';
import { Footer, ButtonSelectImage } from './styles';

interface GaleriaProps {
  onCloseModal: () => void;
  onSelectImage: (path: string) => void;
  showModal: boolean;
  path: string;
}

interface IFile {
  urlPreview: string;
  file: File;
}

interface IImage {
  url: string;
  path: string;
}

export const ModalGaleria = ({
  onCloseModal,
  path,
  onSelectImage,
  showModal,
}: GaleriaProps) => {
  const [selectedImage, setSelectedImage] = useState('');
  const [showDropzone, setShowDropzone] = useState(false);
  const [selectedFile, setSelectedFile] = useState<IFile>();
  const [images, setImages] = useState<IImage[]>([]);
  const [loading, setLoading] = useState(false);

  const handleUploadImage = useCallback((file: any) => {
    setSelectedFile({
      file: file[0],
      urlPreview: URL.createObjectURL(file[0]),
    });
  }, []);

  useEffect(() => {
    if (showModal) {
      api
        .get(`galeria?pasta=${path}`)
        .then(({ data }) => setImages(data.data.map((item: string) => ({
          url: `https://api.hmp.entgames.com.br/imagens/${item}`,
          path: item,
        }))))
        .catch(() => toast.error('Erro ao buscar imagens'));
    }

    return () => {
      setImages([]);
      setSelectedImage('');
      setShowDropzone(false);
    };
  }, [path, showModal]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (selectedFile) {
        formData.append('imagem', selectedFile?.file);
      }
      formData.append('pasta', path);
      const { data } = await api.post('galeria', formData);
      setImages((old) => [...old, {
        url: `https://api.hmp.entgames.com.br/imagens/${data.data}`,
        path: data.data,
      }]);
      setShowDropzone(false);
      setSelectedFile(undefined);
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        return;
      }
      toast.error('Erro ao salvar imagem');
    } finally {
      setLoading(false);
    }
  }, [selectedFile, path]);

  const handleSelectImage = useCallback(() => {
    onSelectImage(selectedImage);
    onCloseModal();
  }, [onSelectImage, onCloseModal, selectedImage]);

  const handleDeleteImage = useCallback(async (pathImage: string) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete('galeria', {
            params: {
              pasta: path,
              arquivo: pathImage.split('/')[1],
            },
          });
          setImages((old) => [...old].filter((item) => item.path !== pathImage));
          toast.success('Imagem removida com sucesso');
        } catch (err) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir imagem');
        }
      }
    });
  }, [path]);

  return (
    <>
      <ModalComponent
        onClose={onCloseModal}
        modalVisible={showModal}
        title="Galeria"
        icon={IoMdImages}
        size="xl"
      >
        <Row style={{ minHeight: '50vh' }}>
          {showDropzone ? (
            <>
              <Col md={12}>
                <DropzoneUpload
                  onUpload={handleUploadImage}
                  fileMime="image/*"
                  large
                />
                {selectedFile && (
                <Col md={{ span: 2, offset: 5 }} xs={{ span: 6, offset: 3 }} className="d-flex align-items-center flex-column">
                  <Thumbnail
                    url={selectedFile.urlPreview}
                    onDeleteImage={() => setSelectedFile(undefined)}
                  />

                  <Button
                    type="button"
                    background="#424242"
                    className="mt-3"
                    loading={loading}
                    onClick={handleSubmit}
                    color="#eee"
                  >
                    Salvar imagem
                  </Button>
                </Col>
                )}
              </Col>
            </>
          ) : (

            <Col md={12}>
              <h5>Suas imagens</h5>

              <Row>
                {images.map((image) => (
                  <Col md={2} xs={6} key={image.path} className="mt-2">
                    <ButtonSelectImage
                      type="button"
                      onClick={() => setSelectedImage(image.path)}
                      selected={selectedImage === image.path}
                    >
                      <Thumbnail
                        url={image.url}
                        onDeleteImage={() => handleDeleteImage(image.path)}
                      />
                    </ButtonSelectImage>
                  </Col>
                ))}

              </Row>
            </Col>
          )}
        </Row>

        <Footer>
          <Row className="d-flex justify-content-center">
            <Col md={3} xs={6}>
              <Button
                type="button"
                background="#424242"
                color="#eee"
                onClick={() => setShowDropzone((old) => !old)}
              >
                {showDropzone ? 'Voltar' : 'Nova imagem'}
              </Button>
            </Col>

            {!showDropzone && (
            <Col md={3} xs={6}>
              <Button
                type="button"
                background={theme.colors.main}
                color="#fff"
                disabled={!selectedImage}
                onClick={handleSelectImage}
              >
                Selecionar imagem
              </Button>
            </Col>
            )}
          </Row>
        </Footer>
      </ModalComponent>
    </>
  );
};
