import {
  Container,
  IAraContainer,
  IconIAra,
  ReactMarkdownFormated, Spinner, SpinnerContainer,
  UserContainer,
  UserMessage,
} from './message-chat-item.styled';
import iara from '../../../../../../assets/icons/iara.rounded.svg';

export interface ChatMessage {
  roleStr: string,
  content: string,
}

export const MessageChatItem = (props: ChatMessage) => {
  const USER_TYPE = 'user';
  const ASSISTANT_TYPE = 'assistant';
  const loadMsg = '#!isloading';
  const { roleStr, content } = props;

  return (
    <Container isRight={roleStr === USER_TYPE}>
      {roleStr === USER_TYPE
        && (
        <UserContainer>
          <UserMessage>{content}</UserMessage>
        </UserContainer>
        )
      }

      {roleStr === ASSISTANT_TYPE
        && (
          <IAraContainer>
            <IconIAra src={iara} />
            {loadMsg === content
              ? <SpinnerContainer><Spinner /></SpinnerContainer>
              : <ReactMarkdownFormated>{content}</ReactMarkdownFormated>}
          </IAraContainer>
        )
      }
    </Container>
  );
};
