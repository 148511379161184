import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { Container } from '../../../../components/Input/styles';
import { ModalComponent } from '../../../../components/Modal';
import { IBehaviorQuestion } from '../../../../interfaces/IBehaviourQuestion';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';

interface ModalFormProps {
  onCloseModal: () => void;
  showModal: boolean;
  questionToEdit?: IBehaviorQuestion;
}

export const ModalFormBehaviorQuestion = (
  { onCloseModal, showModal, questionToEdit }: ModalFormProps,
) => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    pergunta: '',
    qtdeAlternativas: '2',
    alternativas: [
      {
        descricao: '',
      },
      {
        descricao: '',
      },
    ],
  };

  const validationSchema = yup.object().shape({
    pergunta: yup.string()
      .required('pergunta is required'),
    qtdeAlternativas: yup.string()
      .required('qtdeAlternativas is required'),
    alternativas: yup.array().of(
      yup.object().shape({
        descricao: yup.string()
          .required('descricao is required'),
      }),
    ),
  });

  function onChangeQtdeAlternativas(e: any, field: any, values: any, setValues: any) {
    // update dynamic form
    const alternativas = [...values.alternativas];
    const qtdeAlternativas = e.target.value || 0;
    const previousNumber = parseInt(field.value || '0', 10);

    if (previousNumber < qtdeAlternativas) {
      for (let i = previousNumber; i < qtdeAlternativas; i += 1) {
        alternativas.push({ descricao: '' });
      }
    } else {
      for (let i = previousNumber; i >= qtdeAlternativas; i -= 1) {
        alternativas.splice(i, 1);
      }
    }
    setValues({ ...values, alternativas });

    // call formik onChange method
    field.onChange(e);
  }

  const handleSubmit = useCallback(
    async (dataForm: any) => {
      setLoading(true);

      try {
        if (questionToEdit) {
          await api.put(`/perguntacomportamental/${questionToEdit.id}`, {
            id: questionToEdit.id,
            ...dataForm,
          });
        } else {
          await api.post('/perguntacomportamental', {
            ...dataForm,
          });
        }
        onCloseModal();
        toast.success('Pergunta cadastrada com sucesso!');
      } catch (err) {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao cadastrar pergunta');
      } finally {
        setLoading(false);
      }

      // try {
      //   setLoading(true);
      //   if (faseId) {
      //     const { data } = await api.put(`${cursoId}/fase/${faseId}`, {
      //       ...dataForm,
      //       id: faseId,
      //       icone,
      //       iconeBloqueado,
      //       cursoId,
      //       video: {
      //         ...dataForm.video,
      //         tipoMidiaId: dataForm.video?.tipoMidiaId?.value || dataForm.video?.tipoMidiaId,
      //       },
      //     });
      //     onSetFase(data.data, true);
      //   } else {
      //     const { data } = await api.post(`${cursoId}/fase`, {
      //       ...dataForm,
      //       cursoId,
      //       iconeBloqueado,
      //       icone,
      //       video: {
      //         ...dataForm.video,
      //         tipoMidiaId: dataForm.video?.tipoMidiaId?.value,
      //       },
      //     });
      //     onSetFase(data.data);
      //   }

      //   toast.success('Fase salva com sucesso');
      //   onCloseModal();
      // } catch (err) {
      //   if (err.response.data.message) {
      //     toast.error(err.response.data.message);
      //     return;
      //   }
      //   toast.error('Erro ao adicionar fase');
      // } finally {
      //   setLoading(false);
      // }
    },
    [questionToEdit],
  );

  return (
    <>

      <ModalComponent
        onClose={() => onCloseModal()}
        modalVisible={showModal}
        title="Incluir Pergunta Comportamental"
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={questionToEdit || initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, values, touched, setValues }) => (
            <Form>
              <Row>
                <Col xs={12} md={9}>
                  <Input type="text" name="pergunta" label="Pergunta" />
                </Col>
                <Col xs={6} md={3}>
                  <Container>
                    <label htmlFor="qtdeAlternativas">
                      Quantas Alternativas?
                    </label>
                    <Field name="qtdeAlternativas">
                      {({ field }: {field: any}) => (
                        <select {...field} className={`form-control${errors.qtdeAlternativas && touched.qtdeAlternativas ? ' is-invalid' : ''}`} onChange={(e) => onChangeQtdeAlternativas(e, field, values, setValues)} style={{ height: '2.6rem' }}>
                          {[2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                            (i) => <option key={i} value={i}>{i}</option>,
                          )}
                        </select>
                      )}
                    </Field>
                    <ErrorMessage name="qtdeAlternativas" component="div" className="invalid-feedback" />
                  </Container>
                </Col>
              </Row>

              <Row>
                <Col xs={12} className="mt-2">
                  <label htmlFor="qtdeAlternativas" className="styled-label">
                    Alternativas
                  </label>
                  <FieldArray name="alternativas">
                    {() => (values.alternativas.map((ticket, i) => {
                      const alternativeErrors: any = (
                        errors.alternativas?.length && errors.alternativas[i]
                      ) || {};
                      const alternativeTouched: any = (
                        touched.alternativas?.length && touched.alternativas[i]
                      ) || {};

                      return (
                        <Row style={{ marginLeft: 0, marginBottom: '0.5rem' }} key={i}>
                          <Col xs={1} className="rounded-gray-bg">
                            <span>{i + 1}</span>
                          </Col>
                          <Col xs={11}>
                            <Field name={`alternativas.${i}.descricao`} type="text" className={`form-control${alternativeErrors.descricao && alternativeTouched.descricao ? ' is-invalid' : ''}`} />
                            <ErrorMessage name={`alternativas.${i}.descricao`} component="div" className="invalid-feedback" />
                          </Col>
                        </Row>
                      );
                    }))}
                  </FieldArray>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                  <Button
                    background={theme.colors.main}
                    color="#fff"
                    className="text-uppercase"
                    type="submit"
                    loading={loading}
                  >
                    Salvar pergunta
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};
