import { lighten } from 'polished';
import styled from 'styled-components';

export const Header = styled.div`
  box-shadow: 0 0 6px 0 #bdbdbd;
  border-radius: 10px;
  padding: 1rem;

  h4 {
    color: ${({ theme }) => theme.colors.main};
    margin: 0;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.text};
    font-size: 0.9rem;
  }
`;

export const ItemUser = styled.div`
  box-shadow: 0 0 6px 0 #bdbdbd;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
  min-height: 8rem;
  padding-bottom: 2rem;

  button {
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.main};
  }

  p {
    font-size: 0.9rem;
  }
`;

export const Actions = styled.div`
  position: absolute;
  bottom: 2px;
  right: 5px;

  svg {
    font-size: 1.2rem;
  }

  button {
    border: 0;
    color: ${({ theme }) => lighten(0.2, theme.colors.main)};
    font-size: 1.2rem;
    padding: 0.15rem 0.25rem;
    border-radius: 10px;

    &:hover {
      background: ${({ theme }) => lighten(0.7, theme.colors.main)};
    }
  }
`;
