import styled from 'styled-components';
import { GrAttachment } from 'react-icons/gr';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #373737;
  border-radius: 8px;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
  height: 48px;
  background: #3C3C3C;
  padding: 0 24px;
`;

export const HeaderLeftGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 72px;
`;

export const IconFullScreen = styled.img`
  width: 24px;
  height: 24px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
`;

export const HistoryMessagesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 5px;
  overflow: hidden;
`;

export const HistoryChats = styled.div`
  display: flex;
  border-radius:  0 0 0 8px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: #3C3C3C;
  gap: 10px;
  padding: 20px 12px 8px 24px;
  overflow: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: #606060;
  }

  ::-webkit-scrollbar-track {
    margin-block: 10px;
  }
`;

export const HistoryChatsTitle = styled.span`
  color: ${({ theme }) => theme.colors.lightPrimary};
  font-weight: bold;
`;

export const HistoryChatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HistoryChatsItem = styled.div`
  color: ${({ theme }) => theme.colors.lightPrimary};
  background: #606060;
  border-radius: 8px;
  width: 160px;
  height: 50px;
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`;

export const BodyChatsContainer = styled.div`
  display: flex;
  width: 210px; //largura do container dos chats
  flex-direction: column;
  gap: 10px;
  border-top: solid 1px #373737;
  transition: "right 1s ease-in-out",
`;

export const BodyMessagesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

export const HistoryMessages = styled.div`
  flex: 1;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #373737;
  gap: 10px;
  overflow: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: #606060;
  }

  ::-webkit-scrollbar-track {
    margin-block: 10px;
  }
`;

export const HistoryMessageDefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 8px;
`;

export const HistoryMessageDefaultHeader = styled.span`
  color: ${({ theme }) => theme.colors.lightPrimary};
`;

export const HistoryMessageDefaultLineOne = styled.span`
  color: ${({ theme }) => theme.colors.lightPrimary};
`;

export const HistoryMessageDefaultLineTwo = styled.span`
  color: ${({ theme }) => theme.colors.lightPrimary};
`;

export const SendMessage = styled.div<{isChatOpened: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius:  ${({ isChatOpened }) => (isChatOpened && '0 0 8px 0px') || '0 0 8px 8px'};
  height: 70px;
  background: #606060;
  border-left: 1px solid #212529;
  padding: 0 24px 0 48px;
`;

export const GrAttachmentStyled = styled(GrAttachment)`
  margin-right: 6px;
  filter: invert(92%) sepia(92%) saturate(3%) hue-rotate(71deg) brightness(106%) contrast(100%);
`;

export const InputMessage = styled.input`
  width: 100%;
  margin: 0 6px;
  border-radius: 10px 10px 10px 10px;
  height: 34px;
  padding: 0 24px;
  line-height: 11px;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
`;

export const IconIara = styled.img`
  margin: 0 6px;
  width: 60px;
  padding-bottom: 8px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
`;

export const IconBase = styled.img`
  margin: 0 6px;
  width: 34px;
  height: 34px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  :hover {
    fill: #91d9ff;
    color: red;


    width: 40px;
    height: 40px;
    margin: 0 3px;
  }
`;

export const SendMessageIcon = styled.img`
  margin: 0 6px;
  width: 34px;
  height: 34px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  :hover {
    color: red;
    width: 40px;
    height: 40px;
    margin: 0 3px;
  }
`;
