import { BsLayoutSidebar, BsPencilSquare } from 'react-icons/bs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  BodyChatsContainer, BodyContainer,
  BodyMessagesContainer,
  Container,
  GrAttachmentStyled,
  Header, HeaderLeftGroup, HistoryChats, HistoryChatsContainer, HistoryChatsTitle,
  HistoryMessageDefaultContainer,
  HistoryMessageDefaultLineOne,
  HistoryMessageDefaultLineTwo,
  HistoryMessages, HistoryMessagesContainer,
  IconBase,
  IconFullScreen, IconIara,
  InputMessage,
  SendMessage, SendMessageIcon,
} from './iara.styled';
import theme from '../../../../styles/theme';
import arrowsFullscreen from '../../../../assets/icons/arrows-fullscreen.svg';
import sendMessageIcon from '../../../../assets/icons/send-message.svg';
import sendMessageColoredIcon from '../../../../assets/icons/send-message-colored.svg';
import recordMic from '../../../../assets/icons/record-mic.svg';
import iara from '../../../../assets/icons/iara.svg';
import { ChatData, ChatMessage, ToggleFullscreen } from './iara.interfaces';
import api from '../../../../services/api';
import { HistoryChatItem } from './components/HIstoryChat/history-chat-item';
import { MessageChatItem } from './components/Messages/message-chat-item';

export const IAra = (props: ToggleFullscreen) => {
  const ROLE_ASSISTANT = 'assistant';
  const ROLE_USER = 'user';
  const loadMsg = '#!isloading';
  const [sendIcon, setSendIcon] = useState(sendMessageIcon);
  const [isChatsOpened, setChatStatus] = useState(false);
  const [chats, setChats] = useState<ChatData[]>([]);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [chatIdSelect, setChatIdSelected] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>('');
  const [scrollDownClicked, setScrollDownClicked] = useState<boolean>(false);
  const [isSendingMessage, setSendingMessage] = useState<boolean>(false);
  const historyMessagesRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    ScrollDown();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getChats();
  }, [chatMessages]);

  useEffect(() => {
    if (chatIdSelect === 0) return;

    api.get(`chatbot/${chatIdSelect}`).then((r) => {
      const { data } = r.data;
      setChatMessages(data.map((item: { internalId : number, role: string, content: string }) => ({
        internalId: item.internalId,
        role: item.role,
        content: item.content,
      })));
      if (scrollDownClicked) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        ScrollDown(true);
        setScrollDownClicked(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateSelectedChat(chatIdSelect);
    }).catch((error) => {
      toast.error(error?.response?.data?.message);
    });
  }, [chatIdSelect]);

  const getChats = () => {
    api.get('chatbot/recuperar-chats').then((r) => {
      const { data } = r.data;
      setChats(data.map((item: { chatId: number, title: string, dataCriacao: string }) => ({
        title: item.title,
        chatId: item.chatId,
        dataCriacao: moment(item.dataCriacao).format('DD-MM-YYYY'),
        selected: item.chatId === chatIdSelect,
      })));
    }).catch((error) => {
      toast.error(error?.response?.data?.message);
    });
  };

  const ScrollDown = (comingFromTheTop = false) => {
    if (comingFromTheTop) {
      historyMessagesRef.current?.scrollTo(0, 0);
    }

    historyMessagesRef.current?.scrollBy({
      top: historyMessagesRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  };

  const switchChatsList = () => {
    setChatStatus(!isChatsOpened);
  };

  const switchFullScreen = () => {
    props.onToggleFullScreen();
  };

  const updateSelectedChat = (chatId: number) => {
    const updatedChats = [...chats];

    for (let i = 0; i < updatedChats.length; i += 1) {
      updatedChats[i].selected = updatedChats[i].chatId === chatId;
    }
    setChats(updatedChats);
  };

  const getHistoryMessageDefaultContainer = () => (
    <HistoryMessageDefaultContainer>
      {/* <HistoryMessageDefaultHeader>IAra</HistoryMessageDefaultHeader> */}
      <IconIara src={iara} alt="Enviar menssagem para IAra" />
      <HistoryMessageDefaultLineOne>
        A IAra está aqui para te auxiliar nos relatórios
      </HistoryMessageDefaultLineOne>
      <HistoryMessageDefaultLineTwo>
        Sobre o que você quer aprofundar os seus conhecimentos?
      </HistoryMessageDefaultLineTwo>
    </HistoryMessageDefaultContainer>
  );

  const getHistoryMessages = () => (
    <>
      {chatMessages.map((message, i) => (
        <MessageChatItem key={`MessageChatItem-#${chatIdSelect}-${i}`} roleStr={message.role} content={message.content} />
      ))}
    </>
  );

  const atualizarMenssagens = (content: string, chatId: number) => {
    if (chatIdSelect === chatId) {
      const copyMessages = [...chatMessages];
      copyMessages.push({ internalId: 0, role: ROLE_USER, content: inputValue });
      copyMessages.push({ internalId: 0, role: ROLE_ASSISTANT, content });
      setChatMessages(copyMessages);
    } else {
      setChatIdSelected(chatId);
    }
  };

  const sendMessage = () => {
    if (isSendingMessage) { return; }
    setSendingMessage(true);
    const copyMessages = [...chatMessages];
    copyMessages.push({ internalId: 0, role: ROLE_USER, content: inputValue });
    copyMessages.push({ internalId: 0, role: ROLE_ASSISTANT, content: loadMsg });
    setChatMessages(copyMessages);
    setInputValue('');
    ScrollDown();

    api.post('/chatbot/enviar-mensagem', { chatId: chatIdSelect, mensagem: inputValue })
      .then((r) => {
        const { data } = r.data;
        atualizarMenssagens(data.mensagem, data.chatId);
        getChats();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      }).finally(() => {
        setSendingMessage(false);
      });
  };

  const newChat = () => {
    setChatIdSelected(0);
    setChatMessages([]);
  };

  const getSendMessage = () => (
    <SendMessage isChatOpened={isChatsOpened}>
      <GrAttachmentStyled size={20} color={theme.colors.lightPrimary} display="none" />
      <InputMessage
        placeholder="Escreva a sua pergunta a IAra"
        value={inputValue}
        onChange={(value) => setInputValue(value.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            sendMessage();
          }
        }}
      />
      <SendMessageIcon
        src={sendIcon}
        onMouseOver={() => { setSendIcon(sendMessageColoredIcon); }}
        onMouseOut={() => { setSendIcon(sendMessageIcon); }}
        onClick={sendMessage}
      />
      <IconBase src={recordMic} alt="Gravar áudio para IAra" style={{ display: 'none' }} />
    </SendMessage>
  );

  const getBodyChatsContainer = () => (
    <BodyChatsContainer>
      <HistoryChats>
        <HistoryChatsTitle>Histórico</HistoryChatsTitle>
        <HistoryChatsContainer>
          {chats.map((chat) => (
            <HistoryChatItem
              key={`chat-key-${chat.chatId}`}
              chatId={chat.chatId}
              title={chat.title}
              data={chat.dataCriacao}
              isSelected={chat.selected}
              onClick={(chatId) => { setScrollDownClicked(true); setChatIdSelected(chatId); }}
            />
          ))}
        </HistoryChatsContainer>
      </HistoryChats>
    </BodyChatsContainer>
  );

  return (
    <Container>
      <Header>
        <HeaderLeftGroup>
          <BsLayoutSidebar size={24} color={theme.colors.lightPrimary} onClick={switchChatsList} />
          <BsPencilSquare size={24} color={theme.colors.lightPrimary} onClick={newChat} />
        </HeaderLeftGroup>
        <IconFullScreen
          src={arrowsFullscreen}
          alt="Acionar o fullscrren com o componente da IAra"
          onClick={switchFullScreen}
        />
      </Header>
      <BodyContainer>
        {isChatsOpened && getBodyChatsContainer() }
        <BodyMessagesContainer>
          <HistoryMessagesContainer>
            <HistoryMessages ref={historyMessagesRef}>
              {chatMessages.length === 0
                ? getHistoryMessageDefaultContainer() : getHistoryMessages()}
            </HistoryMessages>
          </HistoryMessagesContainer>
          {getSendMessage()}
        </BodyMessagesContainer>
      </BodyContainer>
    </Container>
  );
};
