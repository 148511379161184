/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import { ImTree } from 'react-icons/im';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import { IGrupo } from '../../../../interfaces/IGrupo';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';

interface SubmitProps {
  nome: string;
  descricao: string;
}

interface ModalFormProps {
  onSetFilial: (data: IGrupo, update?: boolean) => void;
  onCloseModal: () => void;
  grupoPai?: string;
  grupo?: IGrupo;
  showModal: boolean;
}

const schema = yup.object({
  nome: yup.string().required('O nome é obrigatório'),
  descricao: yup.string().required('Campo obrigatório'),
});

export const ModalForm = ({
  onSetFilial,
  grupoPai,
  grupo,
  onCloseModal,
  showModal,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async ({ nome, descricao }: SubmitProps) => {
      try {
        setLoading(true);
        if (grupo) {
          const { data } = await api.put(`/filial/${grupo.id}`, {
            id: grupo.id,
            nome,
            descricao,
          });
          onSetFilial(data.data, true);
        } else {
          const { data } = await api.post('/filial', {
            grupoPaiId: grupoPai,
            nome,
            descricao,
          });
          onSetFilial(data.data);
        }

        onCloseModal();
        toast.success('Grupo adicionado com sucesso');
      } catch (err) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar grupo');
      } finally {
        setLoading(false);
      }
    },
    [onSetFilial, onCloseModal, grupo],
  );

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title="Incluir Grupo"
      icon={ImTree}
      size="lg"
    >
      <h6>Preencha todos os dados solicitados.</h6>
      <Formik
        initialValues={grupo || { nome: '', descricao: '' }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="text" name="nome" label="Nome" isRequerid />
          <Input type="textarea" name="descricao" label="Descrição" isRequerid />
          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
