import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ImQuestion } from 'react-icons/im';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ItemList } from '../../components/ItemList';
import api from '../../services/api';
import { ModalFormCategoria } from './components/ModalFormCategoria';

export interface ICategChamado {
  id: number;
  descricao: string;
  usuarioResponsavelId?: number;
  ativo?: boolean;
}

const GET_CATEGORIAS_DE_CHAMADOS_URL = '/chamado/categoria';

export const CategoriasDeChamados = () => {
  const [categories, setCategories] = useState<ICategChamado[]>([]);
  const [isFormModalVisible, setIsFormModalVisible] = useState<boolean>(false);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState<ICategChamado>();

  const fetchCategoriasDeChamado = useCallback(async () => {
    api
      .get(GET_CATEGORIAS_DE_CHAMADOS_URL)
      .then(({ data }) => setCategories(data.data))
      .catch(() => toast.error('Erro ao buscar categorias'));
  }, []);

  useEffect(() => {
    fetchCategoriasDeChamado();
  }, [fetchCategoriasDeChamado]);

  const handleOnCloseModal = () => {
    fetchCategoriasDeChamado();
    setIsFormModalVisible(false);
    setCategoriaSelecionada(undefined);
  };

  const handleDeleteCategory = useCallback((categoryId: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`chamado/categoria/${categoryId}`);

          fetchCategoriasDeChamado();
          toast.success('A categoria foi removida');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao remover categoria');
        }
      }
    });
  }, []);

  return (
    <div>
      <ModalFormCategoria
        showModal={isFormModalVisible}
        onCloseModal={handleOnCloseModal}
        categoria={categoriaSelecionada}
      />

      <HeaderTitle
        onNewItem={() => setIsFormModalVisible(true)}
        title="Categorias de Chamados"
        text="Aqui você gerencia as categorias dos chamados de comunicação interna"
      />

      <Row>
        {categories.map((item) => (
          <Col key={item.id} md={4} className="mt-3">
            <ItemList
              {...item}
              icon={ImQuestion}
              category="Categoria"
              onFormEdit={() => {
                console.log('🚀 ~ file: index.tsx ~ line 60 ~ CategoriasDeChamados ~ item', item);
                setCategoriaSelecionada(item);
                setIsFormModalVisible(true);
              }}
              onDelete={() => handleDeleteCategory(item.id)}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
