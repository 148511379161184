import styled, { css } from 'styled-components';

interface MessageProps {
  type?: string;
  large?: boolean;
}

interface ContainerProps {
  isDragActive: boolean;
  isDragReject: boolean;
}

export const Container = styled.div`
  h6 {
    font-size: 0.8rem;
    font-weight: 600;
    overflow: hidden;
    width: auto;
    display: inline-block;
    position: relative;
    top: 0px;
    left: 4px;
    margin-bottom: 0.2rem;
    transition: all 0.3s ease-out;
    color: ${({ theme }) => theme.colors.main};
  }

  & + div {
    margin-top: 1rem;
  }
`;

export const DropContainer = styled.div.attrs<ContainerProps>({
  className: 'dropzone',
})`
  border: 2px dashed #969cb3;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: height 0.2s ease;
`;

export const UploadMessage = styled.div<MessageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1.1rem;
  padding: 1rem 0;
  color: #5654;

  ${({ large }) => large && css`
    padding: 3rem 0;
  `};


  ${({ type }) => type === 'error'
    && css`
      color: #e83f5b;
    `}

  ${({ type }) => type === 'success'
    && css`
      color: #12a454;
    `}

  svg {
    font-size: 3.5rem;
  }
`;
