/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { ImArrowDown } from 'react-icons/im';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { HeaderTitle } from '../../components/HeaderTitle';
import { IQuestionnaireCategory, IQuestionnaireCategoryWithChildrens } from '../../interfaces/IQuestionario';
import { ItemList } from '../../components/ItemList';
import { ModalFormQuestionnaireCategory } from './ModalFormQuestionnaireCategory';

const CATEGORY_URL = 'questionario-empresa';

export const QuestionnaireCategoryPage = () => {
  const { ids } = useParams<{ ids: string }>();
  const history = useHistory();

  const [
    categories, setCategories,
  ] = useState<IQuestionnaireCategoryWithChildrens[] | IQuestionnaireCategory[]>([]);
  const [
    categQuestHierarchyUnit, setCategQuestionarioHierarchyUnit,
  ] = useState<IQuestionnaireCategoryWithChildrens>();
  const [showModalForm, setShowModalForm] = useState(false);

  const [selectedCategory, setSelectedCategory]
  = useState<IQuestionnaireCategoryWithChildrens | IQuestionnaireCategory | undefined>(undefined);

  const isSubCategoria = (ids !== undefined);
  const path = isSubCategoria ? (`${ids}/`) : '';
  const categoriasIds = isSubCategoria ? ids.split('/') : [];
  const lastCategoriaId = isSubCategoria ? categoriasIds[categoriasIds.length - 1] : undefined;

  const listHierarchy: () => IQuestionnaireCategoryWithChildrens[] = () => {
    const list: IQuestionnaireCategoryWithChildrens[] = [];

    let nextHierarchy = categQuestHierarchyUnit;
    let lastHierarchy = categQuestHierarchyUnit;

    categoriasIds.forEach((id) => {
      if (nextHierarchy && `${nextHierarchy?.id}` === id) {
        lastHierarchy = nextHierarchy;
        list.push(lastHierarchy);

        if (lastHierarchy.categoriasFilhas?.length > 0) {
          [nextHierarchy] = lastHierarchy.categoriasFilhas;
        }
      }
    });

    return list;
  };

  const builderHistoryTitle: () => JSX.Element[] = () => {
    const list = listHierarchy();

    let link = '/super-admin/questionario-categoria';
    const result : JSX.Element[] = [
      <Link to={link} key={link}>
        Categorias de Questionários
      </Link>,
    ];

    for (let i = 0; i < list.length; i += 1) {
      link += `/${list[i].id}`;

      result.push(<span>{' > '}</span>);
      result.push(
        <Link to={link}>
          {list[i].nome}
        </Link>,
      );
    }

    return result;
  };

  useEffect(() => {
    try {
      if (categQuestHierarchyUnit === undefined) return;

      const listHierarchies = listHierarchy();
      const lastHierarchy = listHierarchies[listHierarchies.length - 1];

      setCategories(lastHierarchy?.categoriasFilhas || []);
    } catch (error: any) {
      toast.error(error.message);
    }
  }, [categQuestHierarchyUnit]);

  const fetchCategorias = () => {
    if (isSubCategoria) {
      api
        .get(`questionario-empresa/categoria/super-admin/hierarchy/${ids}`)
        .then(({ data }) => setCategQuestionarioHierarchyUnit(data.data))
        .catch(() => {
          toast.error('Erro ao buscar lista de categorias');
        });
    } else {
      setCategQuestionarioHierarchyUnit(undefined);
      api
        .get('questionario-empresa/categoria/hierarchy/father')
        .then(({ data }) => setCategories(data.data))
        .catch(() => {
          toast.error('Erro ao buscar lista de categorias');
        });
    }
  };

  useEffect(fetchCategorias, [isSubCategoria]);

  useEffect(() => {
    fetchCategorias();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  const handleFormEdit = (id: number) => {
    const selectedCateg = categories.find((c) => c.id === id);
    setSelectedCategory(selectedCateg);
    setShowModalForm(true);
  };

  const handleFormClose = () => {
    setSelectedCategory(undefined);
    setShowModalForm(false);
    fetchCategorias();
  };

  const handleNewItem = () => {
    setSelectedCategory(undefined);
    setShowModalForm(true);
  };

  const onDelete = (id: number) => {
    api.delete(`${CATEGORY_URL}/categoria/${id}`)
      .then(() => {
        toast.success('Categoria excluída com sucesso');
        fetchCategorias();
      })
      .catch((error) => {
        if (String(error).includes('403')) return toast.error('Você não tem permissão para essa operação. Consulte seu RH.');
        return toast.error('Não foi possível excluir a categoria');
      });
  };

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir essa categoria de checklist?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          onDelete(id);
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao remover cetegoria de checklist');
        }
      }
    });
  };

  const handleOnClick = (id: number) => {
    setCategories([]);
    history.push(`/super-admin/questionario-categoria/${path}${id}`);
  };

  return (
    <>
      <ModalFormQuestionnaireCategory
        onClose={handleFormClose}
        isOpen={showModalForm}
        questionnaireCategory={selectedCategory}
        categoriaPaiId={lastCategoriaId}
        isSuperAdmin
      />

      <HeaderTitle
        onNewItem={handleNewItem}
        title={builderHistoryTitle()}
        text="Nesta aba você deve cadastrar as Categorias de Questionários que as empresas terão disponíveis por padrão. Além disso, as empresas poderão criar suas próprias Categorias de Questionários. Clique para ver as subcategorias"
      />

      <Row>
        {categories.map((item) => (
          <Col key={item.id} md={6} className="mt-3">
            <ItemList
              {...item}
              id={item?.id || 0}
              icon={ImArrowDown}
              category="Categoria da Plataforma"
              title={item.nome}
              onClick={() => handleOnClick(item.id)}
              onFormEdit={handleFormEdit}
              onDelete={handleDelete}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
