import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0 0 6px 0 #bdbdbd;
  border-radius: 10px;
  padding: 1rem;

  h4 {
    color: ${({ theme }) => theme.colors.main};
    margin: 0;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.text};
    font-size: 0.9rem;
  }
`;

export const TitleHeader = styled.h4`
  a:link {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
  }

  a:visited {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: underline !important;
  }

  color: ${({ theme }) => theme.colors.main} !important;
  margin: 0;
`;
