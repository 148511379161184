import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { BsCheckBox, BsCloudUpload } from 'react-icons/bs';
import { MdErrorOutline } from 'react-icons/md';
import { Container, UploadMessage, DropContainer } from './styles';

interface DropzoneProps {
  onUpload: (files: File[]) => void;
  fileMime: string;
  large?: boolean;
  label?: string;
}

const DropzoneUpload: React.FC<DropzoneProps> = ({ onUpload, fileMime, large, label }) => {
  const renderDragMessage = useCallback((isDragActive, isDragRejest) => {
    if (!isDragActive) {
      return (
        <UploadMessage large={large}>
          <BsCloudUpload />
          <p>Selecione ou arraste o arquivo aqui.</p>
        </UploadMessage>
      );
    }

    if (isDragRejest) {
      return (
        <UploadMessage large={large} type="error">
          <MdErrorOutline />
          <p>Arquivo não suportado</p>
        </UploadMessage>
      );
    }

    return (
      <UploadMessage large={large} type="success">
        <BsCheckBox />
        <p>Solte o arquivo aqui</p>
      </UploadMessage>
    );
  }, [large]);

  return (
    <Container>
      {label && <h6>{label}</h6>}
      <Dropzone accept={fileMime} onDropAccepted={(files) => onUpload(files)}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer {...getRootProps()}>
            <input {...getInputProps()} data-testid="upload" />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    </Container>
  );
};

export default DropzoneUpload;
