import { IconType } from 'react-icons/lib';
import React, { useEffect } from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import styled from 'styled-components';
import * as Icons from 'react-icons/all';
import { ModalComponent } from '../../../../../components/Modal';
import { Button } from '../../../../../components/Button';
import theme from '../../../../../styles/theme';

type IconsComponentProps = {
  onSelectIcon: (icon: string) => void;
  onCloseModal: () => void;
  showModal: boolean;
}

const ContainerIcons = styled.div`
  display: flex;
  width: 100%;
  max-height: 400px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const IconComp = styled.div`
  cursor: pointer;
  width: 20%;
  height: auto;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  font-size: 0.9rem;

  @media (max-width: 768px) {
    width: 30%;
  }

  &.selected {
    background-color: #ccc;
  }

  &:not(.selected):hover {
    background-color: #f0f0f0;
  }

  span {
    margin-top: 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const Filter = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SIZE_PAGE = 36; // multiples of 3 and 4
const ICONS_AVAILABLE = Object.entries(Icons).map(([key, Icon]) => ({ key, Icon }));

export const ModalIconsComponent = (
  {
    onSelectIcon,
    onCloseModal,
    showModal,
  }: IconsComponentProps,
) => {
  const [selectedIcon, setSelectedIcon] = React.useState<string>('');
  const [filter, setFilter] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(1);
  const [totalPages, setTotalPages]
    = React.useState<number>(Math.ceil(ICONS_AVAILABLE.length / SIZE_PAGE));
  const [icons, setIcons]
    = React.useState<{ key: string; Icon: IconType }[]>(ICONS_AVAILABLE);

  useEffect(() => {
    const filteredIcons = ICONS_AVAILABLE
      .filter(({ key }) => key.toLowerCase().includes(filter.toLowerCase()));

    setIcons(filteredIcons);
    setPage(1);
  }, [filter]);

  useEffect(() => {
    setTotalPages(Math.ceil(icons.length / SIZE_PAGE));
  }, [icons]);

  const handleSelectIcon = (iconName: string) => {
    console.log(iconName);
    setSelectedIcon(iconName);
  };

  const handleConfirmIcon = () => {
    if (selectedIcon) {
      onSelectIcon(selectedIcon);
    }
  };

  const Pagination = () => {
    const maxButtons = 10;
    const hasMorePages = totalPages > maxButtons;

    const buttons = Array.from(
      { length: Math.min(totalPages, hasMorePages ? maxButtons - 1 : maxButtons) },
      (_, i) => i + 1,
    );
    return (
      <div>
        {buttons.map((button) => (
          <button
            type="button"
            key={button}
            onClick={() => setPage(button)}
            style={{
              background: button === page ? 'blue' : 'transparent',
              color: button === page ? 'white' : 'black',
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '5px',
              margin: '5px',
              cursor: 'pointer',
            }}
          >
            {button}
          </button>
        ))}
        {hasMorePages && (
          <>
            <span>...</span>
            <button
              type="button"
              onClick={() => setPage(totalPages)}
              style={{
                background: totalPages === page ? 'blue' : 'transparent',
                color: totalPages === page ? 'white' : 'black',
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '5px',
                margin: '5px',
                cursor: 'pointer',
              }}
            >
              {totalPages}
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <ModalComponent
      title="Selecione um icone"
      modalVisible={showModal}
      icon={AiOutlineStar}
      onClose={onCloseModal}
      size="xl"
    >
      <Filter type="text" placeholder="Filtrar" value={filter} onChange={(e) => setFilter(e.target.value)} />
      <ContainerIcons>
        {icons
          .slice((page - 1) * SIZE_PAGE, page * SIZE_PAGE)
          .map(({ key, Icon }) => {
            const isSelected = selectedIcon === key;
            return (
              <IconComp key={key} onClick={() => handleSelectIcon(key)} className={isSelected ? 'selected' : ''}>
                <Icon size={42} />
                <span>{key}</span>
              </IconComp>
            );
          })}
      </ContainerIcons>
      <Page>
        <Pagination />
      </Page>

      <Button onClick={handleConfirmIcon} background={theme.colors.main} color="#fff">Confirmar</Button>
    </ModalComponent>
  );
};
