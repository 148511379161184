/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from '../../../../../components/Button';
import { Input } from '../../../../../components/Input';
import { ModalComponent } from '../../../../../components/Modal';
import api from '../../../../../services/api';
import theme from '../../../../../styles/theme';
import { IRamoEmpresa } from '../../../../../interfaces/IRamoEmpresa';

interface ModalFormProps {
  onCloseModal: () => void;
  showModal: boolean;
  ramoEmpresa?: IRamoEmpresa;
}

export const ModalFormRamoEmpresa = (
  { onCloseModal, showModal, ramoEmpresa }: ModalFormProps,
) => {
  const [loading, setLoading] = useState(false);
  const initialRamoEmpresa: IRamoEmpresa = {
    id: 0,
    nome: '',
    descricao: '',
  };

  const validationSchema = yup.object().shape({
    nome: yup.string()
      .required('nome é obrigatório'),
    descricao: yup.string()
      .required('descrição é obrigatório'),
  });

  const handleSubmit = useCallback(
    async (dataForm: IRamoEmpresa) => {
      setLoading(true);

      try {
        if (ramoEmpresa) {
          await api.put(`/ramo-empresa/${ramoEmpresa.id}`, dataForm);
        } else {
          await api.post('/ramo-empresa', dataForm);
        }
        onCloseModal();
        toast.success('Ramo cadastrado com sucesso!');
      } catch (err: any) {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao cadastrar ramo de empresa');
      } finally {
        setLoading(false);
      }
    },
    [ramoEmpresa],
  );

  return (
    <>

      <ModalComponent
        onClose={() => onCloseModal()}
        modalVisible={showModal}
        title="Incluir Ramo de Empresa"
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={ramoEmpresa || initialRamoEmpresa}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, values, touched, setValues }) => (
            <Form>
              <Row>
                <Col xs={12} md={12}>
                  <Input type="text" name="nome" label="Nome" />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <Input type="textarea" name="descricao" label="Descrição" />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                  <Button
                    background={theme.colors.main}
                    color="#fff"
                    className="text-uppercase"
                    type="submit"
                    loading={loading}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};
