import styled from 'styled-components';

export const ContainerLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  text-align: center;
  margin: 10px auto 0;

  img {
    height: 100px;
  }

  span {
    font-size: 12px;
  }
`;
