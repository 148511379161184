import styled from 'styled-components';

export const HtmlVerticalAlignCenter = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  width: 100%;

  p {
    margin-bottom: unset;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
    width: 100%;
  }
`;
