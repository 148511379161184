/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */
import { Card, Col, Row } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import api from '../../services/api';
import { IUsuario, UFs } from '../../interfaces/IUsuario';
import { ModalForm } from './ModalForm';
import { ItemUser, Actions, Title } from './styles';
import { HeaderTitle } from '../../components/HeaderTitle';
import handleErrors from '../../utils/handle.errors';
import { ModalImport } from './ModalImport';
import avatarIcon from '../../assets/icons/user.svg';
import { ModalDesligamento } from './ModalDelete';
import { IEmpresa } from '../../interfaces/IEmpresa';
import { ModalExport } from './ModalExport';
import { ISelect, ISelectWNumber } from '../../interfaces/ISelect';
import { SimpleSelect } from '../../components/SimpleSelect';

enum GENDER_ENUM {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

const mapGender = (genderResponse: number) => {
  if (genderResponse === GENDER_ENUM.MALE) {
    return 'Masculino';
  }
  if (genderResponse === GENDER_ENUM.FEMALE) {
    return 'Feminino';
  }
  return 'Outro';
};

type Filter = {
  perfis: number;
  grupos: number;
  cargos: number;
  unidades: number;
  filialPrincipalId: number;
}

export const Users = () => {
  const [perfis, setPerfis] = useState<ISelectWNumber[]>([]);
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [usuario, setUsuario] = useState<IUsuario>();
  const [showModalForm, setShowModalForm] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [mostrarModalDeDesligamento, setMostrarModalDeDesligamento] = useState(false);
  const [empresa] = useStore<IEmpresa>('company');
  const [currentFilter, setCurrentFilter] = useState<Filter>({ perfis: 0, grupos: 0, cargos: 0, unidades: 0, filialPrincipalId: 0 });
  const [users, setUsers] = useState<IUsuario[]>([]);
  const [cargos, setCargos] = useState<ISelect[]>([]);
  const [grupos, setGrupos] = useState<ISelect[]>([]);
  const [unidades, setUnidades] = useState<ISelect[]>([]);

  const fetchUsers = () => {
    api
      .get('usuario')
      .then(({ data }) => {
        const dataFormatted = data.data.map((item: any) => ({
          ...item,
          filialIds: [
            ...item.filiais.map((p: { id: number; nome: string }) => ({
              value: p.id,
              label: p.nome,
            }))],
          unidadesIds: item.unidades.map((p: { id: number; nome: string }) => ({
            value: p.id,
            label: p.nome,
          })),
          allIds: [
            ...item.filiais.map((p: { id: number; nome: string }) => ({
              value: p.id,
              label: p.nome,
            })),
            {
              value: item.filialPrincipalId,
              label: 'Principal Filial',
            },
          ],
          dataAdmissao: new Date(item.dataAdmissao).toISOString().slice(0, 10),
          dataNascimento: new Date(item.dataNascimento)
            .toISOString()
            .slice(0, 10),
          sexo: {
            label: mapGender(item.sexo),
            value: item?.sexo && +item.sexo,
          },
          uf: {
            label: UFs.get(item.uf),
            value: item?.uf,
          },
        }));
        setUsuarios(dataFormatted);
        setUsers(dataFormatted);
      })
      .catch((err) => handleErrors(err));
  };

  const fetchPerfis = () => {
    api
      .get('usuario/perfis')
      .then(({ data }) => {
        const response = data.data.map((item: { id: number; nome: string }) => ({
          label: item.nome,
          value: item.id,
        }));
        setPerfis(response.filter((item: { label: string; }) => item.label !== 'Validação'));
      })
      .catch(() => toast.error('Erro ao buscar tipos de perfil'));
  };

  const fetchUnidades = () => {
    api
      .get('unidade-empresa')
      .then(({ data }) => {
        const response = data.data.map((item: { id: number; nome: string }) => ({
          label: item.nome,
          value: item.id,
        }));
        setUnidades(response);
      })
      .catch(() => toast.error('Erro ao recuperar as unidades'));
  };

  useEffect(() => {
    fetchUsers();
    fetchPerfis();
    fetchUnidades();
  }, []);

  useEffect(() => {
    const conditions = {
      cargos: (user: any) => user.cargoUsuarioId === currentFilter.cargos,
      grupos: (user: any) => user?.allIds?.find((e: any) => Number(e.value) === currentFilter.grupos),
      perfis: (user: any) => user.perfilId === currentFilter.perfis,
      unidades: (user: any) => user?.unidadesIds?.find((e: any) => Number(e.value) === currentFilter.unidades) || (user?.unidadePrincipalId === currentFilter.unidades),
    };

    //@ts-ignore
    const filtered = usuarios.filter((user) => Object.keys(conditions).every((key: any) => (currentFilter[key] ? conditions[key](user) : true)));
    setUsers(filtered);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter.cargos, currentFilter.perfis, currentFilter.grupos, currentFilter.filialPrincipalId, currentFilter.unidades, usuarios]);

  const handleSelect = (e: any) => {
    const { name, value } = e.target;

    setCurrentFilter({ ...currentFilter, [name]: Number(value) });
  };

  const handleExportButtonClick = () => {
    setShowExportModal(true);
  };

  const handleCloseModalForm = () => {
    setShowModalForm(false);
    setUsuario(undefined);
    fetchUsers();
  };

  const handleOnCloseDeleteModal = () => {
    setMostrarModalDeDesligamento(false);
    setUsuario(undefined);
    fetchUsers();
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
    fetchUsers();
  };

  const handleCloseExportModal = () => {
    setShowExportModal(false);
    fetchUsers();
  };

  const handleSetUsuarios = useCallback(
    (data: IUsuario, update?: boolean) => {
      if (update) {
        const oldUsuariosArr = [...usuarios];

        const findedIndexUser = oldUsuariosArr.findIndex((u) => u.id === data.id);
        if (findedIndexUser > -1) {
          oldUsuariosArr[findedIndexUser] = {
            ...data,
          };

          setUsuarios([...oldUsuariosArr]);
        }
      } else {
        setUsuarios((old) => [...old, data]);
      }
    },
    [usuarios],
  );

  const handleShowModalForm = (user: IUsuario) => {
    api
      .get(`usuario/full/${user.id}`)
      .then((response: any) => {
        const dataFormatted = user;
        dataFormatted.cpf = response?.data?.data?.cpf;
        setUsuario(dataFormatted);
        setShowModalForm(true);
      })
      .catch((err) => handleErrors(err));
  };

  const handleMostrarDeleteModal = (user: IUsuario) => {
    setUsuario(user);
    setMostrarModalDeDesligamento(true);
  };

  const childToParent = (cargos: any, grupos: any) => {
    setCargos(cargos);
    setGrupos(grupos);
  };

  return (
    <>
      <ModalForm
        showModal={showModalForm}
        onCloseModal={handleCloseModalForm}
        usuario={usuario}
        onSetUsuarios={handleSetUsuarios}
        childToParent={childToParent}
      />

      <ModalDesligamento
        showModal={mostrarModalDeDesligamento}
        usuario={usuario}
        onCloseModal={handleOnCloseDeleteModal}
      />

      <ModalImport
        showModal={showImportModal}
        onCloseModal={handleCloseImportModal}
      />

      <ModalExport
        showModal={showExportModal}
        onCloseModal={handleCloseExportModal}
        empresaId={Number(empresa?.id)}
      />

      <HeaderTitle
        onNewItem={() => setShowModalForm(true)}
        title="Usuários"
        text="Ambiente destinado ao cadastro dos administradores, gestores e usuários."
        secondButtonLabel="Importar"
        exportBtn
        onExportButtonClick={handleExportButtonClick}
        onSecondButtonClick={() => setShowImportModal(true)}
        exportUser
      />
      <Title><h4>Filtros</h4></Title>

      <section className="row row-cols-md-4">
        <div className="mt-2">

          <Card className="p-2">
            <SimpleSelect
              label="Perfis"
              name="perfis"
              options={perfis}
              onChange={handleSelect}
            />
          </Card>
        </div>
        <div className="mt-2">

          <Card className="p-2">
            <SimpleSelect
              label="Grupos"
              name="grupos"
              options={grupos}
              onBlur
              onChange={handleSelect}
            />
          </Card>
        </div>
        <div className="mt-2">

          <Card className="p-2">
            <SimpleSelect
              label="Cargos"
              name="cargos"
              options={cargos}
              onChange={handleSelect}
            />
          </Card>
        </div>

        <div className="mt-2">

          <Card className="p-2">
            <SimpleSelect
              label="Unidades"
              name="unidades"
              options={unidades}
              onChange={handleSelect}
            />
          </Card>
        </div>
      </section>

      <Row className="pb-5">
        {users.map((user) => (
          <Col key={user.id} md={4} className="mt-3">
            <ItemUser>
              <div className="d-flex justify-content-between">
                <div className="d-flex ">
                  <img
                    src={user.imagem || avatarIcon}
                    alt="Usuário"
                    width="50"
                    height="50"
                    className="rounded-circle"
                  />

                  <div className="ms-2 ">
                    <button
                      type="button"
                      className="text-start"
                      onClick={() => handleShowModalForm(user)}
                    >
                      {user.nome}
                    </button>
                    <p className="m-0 text-secondary">
                      {user?.email}
                      <br />
                      (
                      {user.perfil ? user.perfil.nome : ''}
                      )
                      <br />
                      <small>{user.perfil ? user.perfil.descricao : ''}</small>
                    </p>
                  </div>
                </div>
              </div>
              <Actions>
                <button type="button" onClick={() => handleShowModalForm(user)}>
                  <AiOutlineEdit />
                </button>

                <button
                  type="button"
                  onClick={() => handleMostrarDeleteModal(user)}
                >
                  <BiTrash />
                </button>
              </Actions>
            </ItemUser>
          </Col>
        ))}
      </Row>
    </>
  );
};
