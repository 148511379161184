import { Formik, Form } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AiOutlineStar } from 'react-icons/ai';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { ICargo } from '../../../../interfaces/ICargo';

const dadosIniciais: ICargo = {
  nome: '',
  empresaId: 0,
};

interface ModalFormProps {
  onSetCargo: (data: ICargo, update?: boolean) => void;
  onCloseModal: () => void;
  cargo?: ICargo;
  showModal: boolean;
}

const schema = yup.object({
  nome: yup.string().required('O nome do cargo é obrigatório'),
});

export const ModalFormCargo = ({
  onSetCargo,
  cargo,
  onCloseModal,
  showModal,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(
    async (dataForm: ICargo) => {
      try {
        setLoading(true);
        if (cargo) {
          const { data } = await api.put(`/cargo/${cargo.id}`, {
            id: cargo.id,
            ...dataForm,
          });
          onSetCargo(data.data, true);
        } else {
          const { data } = await api.post('/cargo', {
            ...dataForm,
          });
          onSetCargo(data.data);
        }

        onCloseModal();
        toast.success('Cargo foi adicionado com sucesso');
      } catch (err: any) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar o novo Cargo');
      } finally {
        setLoading(false);
      }
    },
    [onSetCargo, onCloseModal, cargo],
  );

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title="Adicionar Cargo"
      icon={AiOutlineStar}
    >
      {cargo
        ? <h6>Digite o nome do cargo que deseja alterar</h6>
        : <h6>Digite o nome do cargo que deseja adicionar.</h6> }
      <Formik
        initialValues={cargo || dadosIniciais}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="text" name="nome" label="Nome do Cargo:" isRequerid />

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
