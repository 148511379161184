/* eslint-disable max-len */
import { Field, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import { IQuestionario, IQuestionnaireCategory } from '../../../../interfaces/IQuestionario';
import { ISelect } from '../../../../interfaces/ISelect';
import { ReactSelect } from '../../../../components/ReactSelect';
import { CheckAndUncheckBtn } from '../../../Users/styles';

interface ModalFormProps {
  category: IQuestionnaireCategory;
  onCloseModal: () => void;
  showModal: boolean;
  questionnaire?: IQuestionario;
  viewOnly?: boolean;
}

export const ModalFormQuestionnaires = (
  { onCloseModal, showModal, questionnaire, category, viewOnly }: ModalFormProps,
) => {
  const allowFilters = category?.tipoFiltro?.unidade || category?.tipoFiltro?.grupo;

  const [unidades, setUnidades] = useState<ISelect[]>([]);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [isPesquisaExternaEnabled, setIsPesquisaExternaEnabled] = useState(false);
  const [grupos, setGrupos] = useState<ISelect[]>([]);
  const [loading, setLoading] = useState(false);
  const [isPermanent, setIsPermanent] = useState<boolean>(false);
  const [isValePontos, setIsValePontos] = useState(true);

  const initialValues: IQuestionario = {
    id: 0,
    titulo: '',
    ativo: true,
    liberado: false,
    prazoDias: 0,
    perguntas: [],
    filtro: {
      desativarFiltro: false,
      grupos: [],
      unidades: [],
    },
    permanente: false,
    valePontos: false,
    externo: false,
  };

  const fetchFiliais = () => api
    .get('filial')
    .then(({ data }) => setGrupos(
      data.data.map((item: { id: number; nome: string; }) => ({
        label: item.nome,
        value: item.id,
      })),
    ))
    .catch(() => {
      toast.error('Erro ao buscar lista de Grupos');
    });

  const fetchUnidades = () => api
    .get('unidade-empresa')
    .then(({ data }) => setUnidades(
      data.data.map((item: { id: number; nome: string; }) => ({
        label: item.nome,
        value: item.id,
      })),
    ))
    .catch(() => {
      toast.error('Erro ao buscar lista de Unidades');
    });

  useEffect(() => {
    setIsValePontos(Boolean(questionnaire?.valePontos));
    fetchFiliais();
    fetchUnidades();
    setIsValePontos(() => (questionnaire?.valePontos ? questionnaire?.valePontos
      : initialValues.valePontos));
    setIsPermanent(() => (questionnaire?.permanente ? questionnaire?.permanente
      : initialValues.permanente));
    setIsPesquisaExternaEnabled(() => (questionnaire?.externo ? questionnaire?.externo
      : initialValues.externo));

    if (questionnaire?.filtro) {
      setIsFilterEnabled(
        questionnaire?.filtro?.grupos?.length > 0
        || questionnaire?.filtro?.unidades?.length > 0,
      );
    }
  }, [questionnaire]);

  const internalOnCloseModal = () => {
    setIsFilterEnabled(false);
    onCloseModal();
  };

  const handleSubmit = useCallback(
    async (dataForm: any) => {
      setLoading(true);
      const filtros = {
        desativarFiltro: !isFilterEnabled,
        unidadesIds: dataForm.unidadesIds?.map((item: { value: number }) => item.value) ?? [],
        gruposIds: dataForm.gruposIds?.map((item: { value: number }) => item.value) ?? [],
      };

      try {
        console.log('isPesquisaExternaEnabled', dataForm.isPesquisaExternaEnabled);
        if (questionnaire) {
          await api.put('/questionario-empresa', {
            titulo: dataForm.titulo,
            id: questionnaire.id,
            prazoDias: dataForm.prazoDias,
            filtro: filtros,
            permanente: isPermanent,
            valePontos: isValePontos,
            externo: isPesquisaExternaEnabled,
          });
        } else {
          await api.post('/questionario-empresa', {
            categoriaId: category.id,
            titulo: dataForm.titulo,
            prazoDias: dataForm.prazoDias,
            filtro: filtros,
            permanente: isPermanent,
            valePontos: isValePontos,
            externo: isPesquisaExternaEnabled,
          });
        }

        internalOnCloseModal();
      } catch (error: any) {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
          return;
        }
        toast.error('Erro ao salvar Questionário');
      } finally {
        setLoading(false);
      }
    },
    [
      isPermanent,
      isValePontos,
      isFilterEnabled,
      questionnaire,
      category.id,
      isPesquisaExternaEnabled,
    ],
  );

  const mappingValues = (values: IQuestionario | undefined) => {
    if (values === undefined) {
      return initialValues;
    }

    return {
      ...values,
      unidadesIds: values.filtro.unidades.map((value) => ({
        value: value.unidade.id,
        label: value.unidade.nome,
      })),
      gruposIds: values.filtro.grupos.map((value) => ({
        value: value.grupo.id,
        label: value.grupo.nome,
      })),
    };
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.titulo) {
      errors.titulo = 'O campo Título é obrigatório';
    }

    if (values.titulo.length > 60) errors.titulo = 'O campo Título deve conter até 60 caracteres';

    if (
      isFilterEnabled
      && (!values.gruposIds || values.gruposIds?.length <= 0)
      && (!values.unidadesIds || values.unidadesIds?.length <= 0)
    ) {
      errors.filtroVazio = 'Prencha pelo menos 1 dos filtros abaixo ou desative os filtros';
    }

    return errors;
  };

  const getTitle = () => {
    if (viewOnly) { return 'Visualizar Questionario (desative o questionário caso queira editar)'; }
    if (questionnaire) { return 'Editar Questionário'; }

    return 'Incluir Questionario';
  };

  return (
    <>
      <ModalComponent
        onClose={() => internalOnCloseModal()}
        modalVisible={showModal}
        title={getTitle()}
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={mappingValues(questionnaire)}
          validate={validate}
          onSubmit={handleSubmit}
        >

          {({ errors, setFieldValue }: any) => (
            <Form>
              <Row className="mb-2">
                <Col xs={12} md={12}>
                  <Input type="text" name="titulo" label="Titulo (até 60 caracteres)" />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} md={12}>
                  <Input type="number" name="prazoDias" label="Prazo em dias. (0) para sem prazo." defaultValue={0} />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex">
                  <h6 className="text-secondary ms-3 me-2">Pesquisa externa</h6>
                  <Switch
                    onChange={() => setIsPesquisaExternaEnabled((old) => !old)}
                    checked={isPesquisaExternaEnabled || false}
                    height={22}
                    width={53}
                  />
                </Col>
              </Row>
              {!isPesquisaExternaEnabled && (
                <Row>
                  <Col className="d-flex">
                    <h6 className="text-secondary ms-3 me-2">Questionario vai ser permanente?</h6>
                    <Switch
                      onChange={() => setIsPermanent((old) => !old)}
                      checked={isPermanent}
                      height={22}
                      width={53}
                    />
                  </Col>
                </Row>
              )}
              {!isPesquisaExternaEnabled && (
                <Row>
                  <Col className="d-flex">
                    <h6 className="text-secondary ms-3 me-2">Vai valer pontos?</h6>
                    <Switch
                      onChange={() => setIsValePontos((old) => !old)}
                      checked={isValePontos}
                      height={22}
                      width={53}
                    />
                  </Col>
                </Row>
              )}

              {/* <Row>
                <Col className="d-flex" style={{ gap: '8px' }}>
                  <h6 className="text-secondary ms-3">Incluir Vídeo</h6>
                  <Switch
                    onChange={() => setIncludeVideo((old) => !old)}
                    checked={includeVideo}
                    height={22}
                    width={53}
                    disabled={viewOnly}
                  />
                </Col>

                {includeVideo
                  && (
                    <>
                      <Col md={12}>
                        <Input type="text" name="video.titulo" label="Titulo do vídeo" />
                      </Col>

                      <Col md={6}>
                        <Field
                          name="video.tipoMidiaId"
                          label="Tipo de mídia"
                          component={ReactSelect}
                          options={typesMedia}
                        />
                      </Col>

                      <Col md={6}>
                        <Input type="text" name="video.url" label="URL do vídeo" />
                      </Col>

                      <Col md={12}>
                        <Input type="textarea" name="video.descricao" label="Coloque aqui o conteúdo por escrito" />
                      </Col>
                    </>
                  )
                }
              </Row> */}

              {allowFilters && !isPesquisaExternaEnabled && (
                <>
                  {!viewOnly && (
                    <Row style={{ marginTop: '0.6rem' }}>
                      <Col className="d-flex flex-column mb-3">
                        <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                          Ativar Filtros
                        </strong>
                        <Switch
                          onChange={() => setIsFilterEnabled((old) => !old)}
                          checked={isFilterEnabled || false}
                          height={22}
                          width={53}
                        />
                      </Col>
                    </Row>
                  )}

                  {isFilterEnabled && (
                    <>
                      <Row>
                        <Col xs={12}>
                          Filtros:
                          <p>
                            <span> Esta pesquisa será disponibilizada para este grupo/unidade</span>
                            <span> e todos os seus subgrupos/subunidades.</span>
                          </p>
                          <small style={{ color: 'red', marginLeft: '1rem' }}>{errors.filtroVazio}</small>
                        </Col>
                      </Row>
                      <Row>
                        {category.tipoFiltro?.grupo && (
                          <Col md={6}>
                            <Field
                              className="form-control"
                              name="gruposIds"
                              isRequerid={category.tipoFiltro?.grupo}
                              label="Grupo (pode ser mais de 1)"
                              isMulti
                              component={ReactSelect}
                              isDisabled={!isFilterEnabled || viewOnly}
                              options={grupos}
                            />
                            <CheckAndUncheckBtn
                              href="#"
                              onClick={() => setFieldValue('gruposIds', grupos, false)}
                            >
                              Marcar Todos

                            </CheckAndUncheckBtn>
                            <CheckAndUncheckBtn
                              href="#"
                              onClick={() => setFieldValue('gruposIds', [], false)}
                            >
                              Desmarcar Todos

                            </CheckAndUncheckBtn>
                          </Col>
                        )}

                        {category.tipoFiltro?.unidade && (
                          <Col md={6}>
                            <Field
                              className="form-control"
                              name="unidadesIds"
                              isRequerid={isFilterEnabled}
                              label="Unidades (pode ser mais de 1)"
                              isMulti
                              component={ReactSelect}
                              options={unidades}
                              isDisabled={!isFilterEnabled || viewOnly}
                            />
                            <CheckAndUncheckBtn
                              href="#"
                              onClick={() => setFieldValue('unidadesIds', unidades, false)}
                            >
                              Marcar Todos

                            </CheckAndUncheckBtn>
                            <CheckAndUncheckBtn
                              href="#"
                              onClick={() => setFieldValue('unidadesIds', [], false)}
                            >
                              Desmarcar Todos

                            </CheckAndUncheckBtn>
                          </Col>
                        )}
                      </Row>
                    </>
                  )}

                </>
              )}

              {!viewOnly && (
                <Row className="mt-4">
                  <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                    <Button
                      background={theme.colors.main}
                      color="#fff"
                      className="text-uppercase"
                      type="submit"
                      loading={loading}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};
