import React, { createContext, useCallback, useState, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { useStore } from 'react-context-hook';
import api from '../services/api';

export const Roles = {
  all: 'ALL',
  administrador: 'ADMIN',
  user: 'USER',
};

interface UserData {
  unique_name: string;
  role: 'USER' | 'Administrador' | 'Gestor';
  PerfilId: string;
  EmpresaId: string;
  UsuarioId: string;
  email: Array<string>;
  roles: Array<string>;
}

interface AuthState {
  token: string;
  user: UserData;
}

interface SignInCredentials {
  login: string;
  password: string;
}
interface AuthContextInterface {
  user: UserData;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  externalSignIn(token: string): Promise<void>;
  updateUser(usuario: UserData): void;
}

export const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface,
);

export const AuthProvider: React.FC = ({ children }) => {
  const [domain] = useStore('domain');

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Ent-games:token');
    const user = localStorage.getItem('@Ent-games:user');

    if (token && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }
    delete api.defaults.headers.common.Authorization;

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ login, password }) => {
    const res = await api.post('/auth/login', { login, password, empresaIdentificador: domain });
    const decode: UserData = jwt_decode(res.data.data.accessToken);
    localStorage.setItem('@Ent-games:token', res.data.data.accessToken);
    localStorage.setItem('@Ent-games:user', JSON.stringify(decode));
    api.defaults.headers.Authorization = `Bearer ${res.data.data.accessToken}`;

    setData({ user: decode, token: res.data.data.accessToken });
  }, [domain]);

  const externalSignIn = useCallback(async (token: string) => {
    const decode: UserData = jwt_decode(token);
    localStorage.setItem('@Ent-games:token', token);
    localStorage.setItem('@Ent-games:user', JSON.stringify(decode));
    api.defaults.headers.Authorization = `Bearer ${token}`;

    setData({ user: decode, token });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Ent-games:token');
    localStorage.removeItem('@Ent-games:user');
    delete api.defaults.headers.Authorization;

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (usuario: UserData) => {
      localStorage.setItem('@Ent-games:user', JSON.stringify(usuario));

      setData((old) => ({
        ...old,
        user: usuario,
      }));
    },
    [setData],
  );

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, externalSignIn, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextInterface {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used withn an AuthProvider');
  }

  return context;
}
