import styled, { css } from 'styled-components';

export const Footer = styled.div`
  border-top: 1px dashed #ccc;
  margin-top: 2rem;
  padding-top: 1rem;
`;

interface ButtonSelectImageProps {
  selected: boolean;
}

export const ButtonSelectImage = styled.button<ButtonSelectImageProps>`
  border: 2.5px solid #eee;
  background: transparent;
  border-radius: 8px;

  ${({ theme, selected }) => selected && css`
    border-color: ${theme.colors.main};
  `}
`;
