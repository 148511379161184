import { Formik, Form } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AiOutlineStar } from 'react-icons/ai';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { INivel } from '../../../../interfaces/INivel';

const dataInitial: INivel = {
  nome: '',
  pontos: '',
};

interface ModalFormProps {
  onSetNivel: (data: INivel, update?: boolean) => void;
  onCloseModal: () => void;
  nivel?: INivel;
  showModal: boolean;
}

const schema = yup.object({
  nome: yup.string().required('O nome é obrigatório'),
  pontos: yup.number().required('Campo obrigatório').min(1, 'No mínimo 1'),
});

export const ModalFormNivel = ({
  onSetNivel,
  nivel,
  onCloseModal,
  showModal,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (dataForm: INivel) => {
      try {
        setLoading(true);
        if (nivel) {
          // eslint-disable-next-line no-console
          console.log('🚀 ~ file: index.tsx ~ line 45 ~ nivel', nivel);
          const { data } = await api.put(`/nivel/${nivel.id}`, {
            id: nivel.id,
            ...dataForm,
          });
          onSetNivel(data.data, true);
        } else {
          const { data } = await api.post('/nivel', dataForm);
          onSetNivel(data.data);
        }

        onCloseModal();
        toast.success('Nível adicionado com sucesso');
      } catch (err) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar nível');
      } finally {
        setLoading(false);
      }
    },
    [onSetNivel, onCloseModal, nivel],
  );

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title="Incluir nível"
      icon={AiOutlineStar}
    >
      <h6>Preencha todos os dados solicitados.</h6>
      <Formik
        initialValues={nivel || dataInitial}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="text" name="nome" label="Nome do nível" isRequerid />
          <Input type="text" name="pontos" label="Quantidade mínima de pontos para atingir" isRequerid />

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
