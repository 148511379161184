import { BsCircle } from 'react-icons/bs';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ModalComponent } from '../../../../../components/Modal';
import { Button } from '../../../../../components/Button';
import { LabelConteudo } from './styles';
import HtmlEditor from '../../../../Use-Terms/components/Html-Editor/editor';

interface ModalFormProps {
  originalContent: string;
  isShowModal: boolean;
  onCloseModal: (newContent: string) => void;
}

export const ModalFormTarefaHTMLEditor = ({
  onCloseModal,
  isShowModal,
  originalContent }: ModalFormProps) => {
  const [editorContent, setEditorContent] = useState<string>(originalContent);

  useEffect(() => {
    setEditorContent(originalContent);
  }, [originalContent]);

  const handleEditorChange = (content: string) => {
    setEditorContent(content);
  };

  const handleButtonSave = () => {
    onCloseModal(editorContent);
  };

  return (
    <ModalComponent
      onClose={() => { onCloseModal(originalContent); }}
      modalVisible={isShowModal}
      icon={BsCircle}
      title="Incluir texto de aprendizado"
      size="lg"
    >
      <div>
        <Row className="mt-2">
          <Col xs={12}>
            <LabelConteudo>
              <h4>Conteúdo de aprendizado </h4>
            </LabelConteudo>

            <HtmlEditor
              placeholder="Escreva algo..."
              value={editorContent}
              onChange={handleEditorChange}
            />

          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <Button color="#fff" onClick={handleButtonSave}>Salvar</Button>
          </Col>
        </Row>
      </div>

    </ModalComponent>
  );
};
