import { SweetAlertOptions } from 'sweetalert2';

export const swalOptions: SweetAlertOptions = {
  title: 'Deseja realmente excluir?',
  text: 'Você não será capaz de reverter isso.',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#ef5350',
  cancelButtonColor: '#757575',
  confirmButtonText: 'Sim, excluir',
  cancelButtonText: 'Cancelar',
  denyButtonColor: '#757575',
};
