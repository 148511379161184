import { Formik, Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AiOutlineStar } from 'react-icons/ai';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { ReactSelect } from '../../../../components/ReactSelect';
import { ICategChamado } from '../..';
import { ISelect } from '../../../../interfaces/ISelect';

const dataInitial: IDataForm = {
  id: 0,
  descricao: '',
  usuarioResponsavelId: {
    value: 0,
    label: '',
  },
};

type IDataForm = {
  descricao: string,
  id: number,
  usuarioResponsavelId: { label: string, value: number },
};

interface ModalFormProps {
  // onSetBonus: (data: IBonus, update?: boolean) => void;
  onCloseModal: () => void;
  categoria?: ICategChamado;
  showModal: boolean;
}

const schema = yup.object({
  descricao: yup.string().required('O título é obrigatório'),
  usuarioResponsavelId: yup.lazy((value) => (typeof value === 'object'
    ? yup.object().nullable().required('Campo obrigatório')
    : yup.string().required('Campo obrigatório'))),
});

export const ModalFormCategoria = ({
  categoria,
  onCloseModal,
  showModal,
}: ModalFormProps) => {
  console.log('🚀 ~ file: index.tsx ~ line 52 ~ categoria', categoria);
  const [loading, setLoading] = useState(false);

  const [usuarios, setUsuarios] = useState<ISelect>();

  useEffect(() => {
    api.get('usuario').then(({ data }) => {
      setUsuarios(
        data.data.map((item: { id: number, nome: string }) => ({
          label: item.nome,
          value: item.id,
        })),
      );

      if (categoria) {
        console.log('🚀 ~ file: index.tsx ~ line 66 ~ api.get ~ categoria', categoria);
        //
      }
    });
  }, []);

  const handleSubmit = useCallback(
    async (dataForm: any) => {
      console.log('🚀 ~ file: index.tsx ~ line 74 ~ dataForm', dataForm);
      try {
        setLoading(true);
        if (categoria) {
          console.log('🚀 ~ file: index.tsx ~ line 81 ~ dataForm.usuarioResponsavelId.value', dataForm.usuarioResponsavelId.value);
          console.log('🚀 ~ file: index.tsx ~ line 84 ~ categoria.usuarioResponsavelId', categoria.usuarioResponsavelId);
          const { data } = await api.put(`/chamado/categoria/${categoria.id}`, {
            ...dataForm,
            usuarioResponsavelId: dataForm.usuarioResponsavelId.value
            || categoria.usuarioResponsavelId,
            id: categoria.id,
          });
          // onSetBonus(data.data, true);
        } else {
          const { data } = await api.post('/chamado/categoria', {
            ...dataForm,
            usuarioResponsavelId: dataForm.usuarioResponsavelId.value,
          });
          // onSetBonus(data.data);
        }

        onCloseModal();
        toast.success('Categoria de Chamado adicionada com sucesso');
      } catch (err: any) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar Categoria de Chamado');
      } finally {
        setLoading(false);
      }
    },
    [categoria, onCloseModal],
  );

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title={categoria ? 'Editar Categoria de Chamado' : 'Cadastrar Categoria de Chamado'}
      icon={AiOutlineStar}
    >
      <h6>Preencha todos os dados solicitados.</h6>
      <Formik
        initialValues={categoria || dataInitial}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <Col>
              <Input type="text" name="descricao" label="Categoria" isRequerid />

              <Field
                name="usuarioResponsavelId"
                label="Usuário"
                isRequerid
                component={ReactSelect}
                options={usuarios}
              />

            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
