import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 4rem;

  h6, p {
    color: ${({ theme }) => theme.colors.main};
    margin: 0;
  }
`;

export const Category = styled.div`
  color: ${({ theme }) => theme.colors.main};

  h6 {
    font-size: 0.8rem;
  }
`;

export const Description = styled.div`
  padding-left: 0.5rem;
  margin-left: 0.5rem;
  border-left: 2px dotted ${({ theme }) => theme.colors.main};
  position: relative;
  padding-bottom: 1.5rem;

  h6 {
    font-size: 0.9rem;
  }

  a {
    color: ${({ theme }) => theme.colors.main};
    font-size: 0.9rem;
    display: block;
    line-height: 15px;
  }

  p {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 0.8rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
  }
`;

export const Actions = styled.div`
  position: absolute;
  bottom: -10px;
  right: -10px;
  z-index: 111;

  button {
    background: #fff;
    border: 0;
    font-size: 1.2rem;
    padding: 0.15rem 0.25rem;
    border-radius: 10px;

    &:hover {
      background: ${({ theme }) => lighten(0.7, theme.colors.main)};
    }
  }
`;
