import { Formik, Form, Field } from 'formik';
import {
  useState,
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { ImTree } from 'react-icons/im';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import { ReactSelect } from '../../../../components/ReactSelect';
import { ModalHandles } from '../../../../interfaces/ModalHandles';
import theme from '../../../../styles/theme';

const data = {
  email: '',
  password: '',
};

const testes = [
  {
    label: 'Teste 1',
    value: 'teste1',
  },
  {
    label: 'Teste 2',
    value: 'teste2',
  },
];

const ModalForm: ForwardRefRenderFunction<ModalHandles> = (props, ref) => {
  const [visible, setVisible] = useState(false);

  const handleSubmit = () => {
    console.log('enviando dados...');
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const onShowModal = () => {
    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    onShowModal,
  }));

  return (
    <ModalComponent
      onClose={handleCloseModal}
      modalVisible={visible}
      title="Incluir curso"
      icon={ImTree}
      size="lg"
    >
      <p>Lorem ipsum dolor sit amet consectetur adipiscing elit</p>
      <h6>Preencha todos os dados solicitados</h6>
      <Formik
        initialValues={data}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <Col md={6}>
              <Input type="text" name="name" label="Nome" />

              <Input type="textarea" name="descricao" label="Descrição" />
            </Col>
            <Col md={6}>
              <Input type="text" name="name" label="Lorem ipsum" />

              <Field
                name="teacher_id"
                label="Professor"
                component={ReactSelect}
                options={testes}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                type="submit"
              >
                Incluir
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};

export default forwardRef(ModalForm);
