/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { HeaderTitle } from '../../components/HeaderTitle';
import api from '../../services/api';
import { LabelStyled } from '../Users/ModalImport/styles';
import { Container } from './styles';
import theme from '../../styles/theme';

export const PlanilhasImportacao = () => {
  const UploadPlanilhaTemplateImportacao = ({ configKey }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [planilhaBase64, setPlanilhaBase64] = useState<string>();

    const handleInputChange = (event: any) => {
      const { files } = event.target;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = (e: any) => {
        setPlanilhaBase64(e.target.result);
      };
    };

    const handleOnPlanilhaSubmit = () => {
      const formattedData = {
        Key: configKey,
        Value: planilhaBase64,
      };
      api.put(`configuracao/key/${configKey}`, formattedData)
        .then(() => {
          toast.success('Salvo com sucesso');
          setPlanilhaBase64('');
        })
        .catch((error: any) => {
          toast.error('Erro ao salvar planilha');
        })
        .finally(() => setIsLoading(false));
    };

    return (
      <div className={`config-planilha-import-${configKey}`} style={{ marginBottom: '2rem' }}>
        <Row>
          <Col md={12}>
            <LabelStyled>
              Planilha Modelo para Download -
              {' '}
              {configKey}
            </LabelStyled>

            {planilhaBase64 && (
            <div style={{ textAlign: 'center' }}>
              <span style={{ marginRight: '1rem' }}>Planilha Carregada</span>
              <button type="button" onClick={() => setPlanilhaBase64('')}>Escolher outra</button>
            </div>
            )}

            {!planilhaBase64 && <input type="file" name={`planilha-import-${configKey}`} onChange={handleInputChange} /> }
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              type="button"
              background={theme.colors.main}
              color="#fff"
              onClick={handleOnPlanilhaSubmit}
              style={{ padding: '0 1.5rem' }}
              disabled={!planilhaBase64}
              loading={isLoading}
            >
              Salvar
            </Button>

          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Container>
      <HeaderTitle
        title="Planilhas"
        text="Faça upload das planilhas de Usuários, Unidades e Cursos"
      />
      <hr />

      <UploadPlanilhaTemplateImportacao configKey="planilhaImportacaoUsuarios" />

      <hr />

      <UploadPlanilhaTemplateImportacao configKey="planilhaImportacaoUnidades" />

      <hr />

      <UploadPlanilhaTemplateImportacao configKey="planilhaImportacaoCursos" />
    </Container>
  );
};
