/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { darken, lighten } from 'polished';
import { useStore, withStore } from 'react-context-hook';
import { QueryClientProvider } from 'react-query';
import AppProvider from './hooks';
import { Routes } from './routes';
import GlobalStyle from './styles/global';
import { IEmpresa } from './interfaces/IEmpresa';
import { queryClient } from './services/queryClient';

export interface ICompanyApiResponse {
  succeeded: boolean;
  data: IEmpresa;
  errors: [];
}
// export interface ICompany {
//   id?: number;
//   nome?: string;
//   fantasia?: string;
//   cnpj?: string;
//   ativo?: false;
//   imgLogo?: string;
//   imgLogoEscuro?: string;
//   corPrimaria?: string;
//   corSecundaria?: string;
//   slogan?: string;
// }

export type ConvertedTheme = {
  colors: {
    main: string,
    success: string,
    secondary: string,
    text: string,
    lightPrimary: string,
    lightSecondary: string,
    textPrimary: string,
    textSecondary: string,
  },
  backgroundUrl: string,
  imgLogo: string | undefined,
  imgLogoEscuro: string | undefined,
  slogan: string | undefined
};

const createTheme = (company: IEmpresa): ConvertedTheme => ({
  colors: {
    main: company.corPrimaria || '#767676',
    success: '#4e9c19',
    secondary: `#${process.env.REACT_APP_COLOR_SECONDARY_THEME}`,
    text: '#616161',
    lightPrimary: '#fff',
    lightSecondary: darken(0.25, '#fff'),
    textPrimary: lighten(0.2, '#000'),
    textSecondary: lighten(0.4, '#000'),
  },
  backgroundUrl: 'https://img.freepik.com/vetores-gratis/tecnologia-de-internet-em-nuvem_53876-94170.jpg',
  imgLogo: company.imgLogo,
  imgLogoEscuro: company.imgLogoEscuro,
  slogan: company?.slogan,
});

const baseURL = process.env.REACT_APP_ENDPOINT;
const gameBaseURL = baseURL?.replace('/painel', '/jogo');

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [domain, setDomain] = useStore('domain');
  const [theme, setTheme] = useStore<ConvertedTheme>('theme');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [company, setCompany] = useStore<IEmpresa>('company');

  const loadCompanyThemeIfExists = () => {
    if (domain) {
      const findCompanyByDomainUrl = `${gameBaseURL}/empresa/by-name/${domain}`;
      setIsLoading(true);
      setIsNotFound(false);

      axios
        .create({
          baseURL,
        })
        .get(findCompanyByDomainUrl)
        .then(({ data: companyApiResponse }: { data: ICompanyApiResponse }) => {
          setIsLoading(false);
          setIsNotFound(false);
          setTheme(createTheme(companyApiResponse.data));
          setCompany(companyApiResponse.data);
        })
        .catch(() => {
          setIsLoading(false);
          setIsNotFound(true);
        });
    }
  };

  useEffect(() => {
    const pathDomain = window.location.pathname.split('/')[1];
    setDomain(pathDomain);

    if (!!pathDomain && !theme && !isNotFound) {
      loadCompanyThemeIfExists();
    }
  }, [theme, domain]);

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          {isLoading && <h5>Carregando tema...</h5>}
          {(!domain || (!isLoading && isNotFound)) && (
            <>
              <h1>Empresa Não encontrada</h1>
              <h2>Verifique a URL ou consulte o seu RH.</h2>
            </>
          )}

          {theme && (
            <ThemeProvider theme={theme}>
              <BrowserRouter basename={`${domain}/`}>
                <Routes />
              </BrowserRouter>
              <ToastContainer autoClose={3000} hideProgressBar />
              <GlobalStyle />
            </ThemeProvider>
          )}
        </AppProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default withStore(App);
