import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { Col, Row } from 'react-bootstrap';
import { ImQuestion } from 'react-icons/im';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { Container } from './styles';
import { HeaderTitle } from '../../../components/HeaderTitle';
import { ItemList } from '../../../components/ItemList';
import { IRamoEmpresa } from '../../../interfaces/IRamoEmpresa';
import { ModalFormRamoEmpresa } from './components/ModalFormRamoEmpresa';

export const RamoEmpresaPage = () => {
  const [ramoEmpresas, setRamoEmpresas] = useState<IRamoEmpresa[]>([]);
  const [ramoEmpresaSelected, setRamoEmpresaSelected] = useState<IRamoEmpresa>();
  const [showModalForm, setShowModalForm] = useState(false);

  const fetchRamoEmpresas = () => {
    api
      .get('/ramo-empresa')
      .then(({ data }) => setRamoEmpresas(data.data))
      .catch(() => {
        toast.error('Erro ao buscar lista com os ramos de empresa');
      });
  };

  useEffect(() => {
    fetchRamoEmpresas();
  }, []);

  const handleOnCloseModal = () => {
    setShowModalForm(false);
    fetchRamoEmpresas();
    setRamoEmpresaSelected(undefined);
  };

  const handleEditRamoEmpresa = (id: number) => {
    const ramoEmpresa = ramoEmpresas.find(
      (_ramoEmpresa) => _ramoEmpresa.id === id,
    );

    if (ramoEmpresa) {
      setRamoEmpresaSelected(ramoEmpresa);
      setShowModalForm(true);
    }
  };

  const handleDeleteRamoEmpresa = useCallback(async (ramoEmpresaId: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/ramo-empresa/${ramoEmpresaId}`);
          fetchRamoEmpresas();
          toast.success('Ramo de empresa removida com sucesso');
        } catch (err) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir Ramo de empresa');
        }
      }
    });
  }, []);

  return (
    <Container>
      <ModalFormRamoEmpresa
        showModal={showModalForm}
        onCloseModal={handleOnCloseModal}
        ramoEmpresa={ramoEmpresaSelected}
      />

      <HeaderTitle
        onNewItem={() => setShowModalForm(true)}
        title="Ramos de atuação"
        text="Nesta aba você deve cadastrar os ramos de empresa para serem associados as empresas"
      />

      <Row>
        {ramoEmpresas.map((item) => (
          <Col key={item.id} md={6} className="mt-3">
            <ItemList
              {...item}
              id={item?.id || 0}
              icon={ImQuestion}
              category="Pergunta"
              title={item.nome}
              descricao={item.descricao}
              onFormEdit={(id: number) => handleEditRamoEmpresa(id)}
              onDelete={(id: number) => handleDeleteRamoEmpresa(id)}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
