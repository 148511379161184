import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useStore } from 'react-context-hook';
import { BiTask } from 'react-icons/bi';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { ModalComponent } from '../../../components/Modal';
import { ICategoriaComunicado } from '../../../interfaces/IComunicado';
import { IEmpresa } from '../../../interfaces/IEmpresa';
import api from '../../../services/api';
import theme from '../../../styles/theme';

interface ModalFormCategoriaComunicadoProps {
  onClose: () => void;
  isOpen: boolean;
  categoriaComunicado?: any;
  categoriaPaiId?: string;
}

const defaultValues: ICategoriaComunicado = {
  id: 0,
  nome: '',
  tipoFiltro: {
    id: 0,
    todos: true,
    unidade: false,
    grupo: false,
    ativo: true,
  },
  empresaId: 0,
  categoriasFilhas: [],
};

export const ModalFormCategoriaComunicado = ({
  isOpen,
  onClose,
  categoriaComunicado,
  categoriaPaiId,
}: ModalFormCategoriaComunicadoProps) => {
  const CATEGORY_ENDPOINT_URL = 'comunicado/categoria';

  const [company] = useStore<IEmpresa>('company');
  const [loading, setLoading] = useState(false);

  const handleOnClose = () => {
    onClose();
  };

  const handleSubmit = useCallback(
    async (values: ICategoriaComunicado) => {
      const method = categoriaComunicado ? api.put : api.post;

      setLoading(true);

      const requestData = {
        ...values,
        ComunicadoCategoriaPaiId: categoriaPaiId ? parseInt(categoriaPaiId, 10) : null,
        empresaId: company?.id,
      };

      method(
        CATEGORY_ENDPOINT_URL,
        requestData,
      )
        .then(() => {
          onClose();
        })
        .catch((error) => {
          if (String(error).includes('403')) return toast.error('Você não tem permissão para essa operação. Consulte seu RH.');
          return toast.error('Ocorreu um erro ao salvar a categoria');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [categoriaComunicado, categoriaPaiId, onClose],
  );

  return (
    <ModalComponent
      onClose={handleOnClose}
      modalVisible={isOpen}
      title="Incluir Categoria Comunicado"
      icon={BiTask}
      size="lg"
    >
      <Formik initialValues={categoriaComunicado || defaultValues} onSubmit={handleSubmit}>
        {({ errors, values, touched, setValues }) => (
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Input type="text" name="nome" label="Nome" />
              </Col>
            </Row>
            {/* <Row>
              <Col xs={12} md={6}>
                <Input type="textarea" name="descricao" label="Descrição" />
              </Col>
            </Row> */}
            <Row style={{ marginTop: '10px' }}>
              <Col xs={12} md={6}>
                <label htmlFor="">
                  <b style={{ fontSize: 12, marginLeft: 3 }}>Filtros:</b>
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="d-flex">
                <Switch
                  onChange={(value) => {
                    setValues({
                      ...values,
                      tipoFiltro: {
                        ...(values.tipoFiltro ?? defaultValues.tipoFiltro),
                        todos: true,
                        unidade: false,
                        grupo: false,
                      },
                    });
                  }}
                  checked={values.tipoFiltro?.todos ?? true}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Sem Filtro</h6>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="d-flex">
                <Switch
                  onChange={(value) => setValues({
                    ...values,
                    tipoFiltro: {
                      ...(values.tipoFiltro ?? defaultValues.tipoFiltro),
                      grupo: value,
                      todos: !values.tipoFiltro?.unidade && !value,
                    },
                  })
                  }
                  checked={values.tipoFiltro?.grupo ?? false}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Filtrar por Grupo</h6>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="d-flex">
                <Switch
                  onChange={(value) => setValues({
                    ...values,
                    tipoFiltro: {
                      ...(values.tipoFiltro ?? defaultValues.tipoFiltro),
                      unidade: value,
                      todos: !value && !values.tipoFiltro?.grupo,
                    },
                  })
                  }
                  checked={values.tipoFiltro?.unidade ?? false}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Filtrar por Unidade</h6>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  type="submit"
                  loading={loading}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ModalComponent>
  );
};
