import Select, { Props, StylesConfig } from 'react-select';
import theme from '../../styles/theme';
import { Container } from '../Input/styles';

interface ReactSelectProps {
  label: string;
  isRequerid?: boolean;
  onChange?: (value: any) => void;
  value: any;
}

type MyOptionType = {
  label: string;
  value: string;
};

export function ControlledReactSelect({
  options,
  isRequerid,
  isMulti,
  label,
  onChange,
  value,
  ...rest
}: Props<MyOptionType, boolean> & ReactSelectProps) {
  const selectStyle: StylesConfig<MyOptionType, boolean> = {
    control: (provided, state) => {
      let borderColor = 'rgba(1, 1, 1, 0.2)';

      if (state.isFocused) {
        borderColor = theme.colors.main;
      }

      return {
        ...provided,
        borderStyle: 'solid',
        borderWidth: '1.5px',
        borderColor,
        boxShadow: '0 transparent',
        outline: 'none',
        background: '#fff',
        minHeight: '2.6rem',
        borderRadius: '5px',
        '&:hover': {
          boxShadow: '0 transparent',
        },
      };
    },
  };
  return (
    <Container>
      {label && (
        <label>
          {isRequerid ? (
            <span>
              {label}
              {' '}
              <strong className="text-danger">*</strong>
            </span>
          ) : (
            <span>{label}</span>
          )}
        </label>
      )}
      <Select
        options={options}
        styles={selectStyle}
        isMulti={isMulti}
        {...rest}
        placeholder="Selecione"
        className="react-select-container"
        classNamePrefix="react-select"
        onChange={onChange}
        value={value}
      />
    </Container>
  );
}
