import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FiHome, FiMenu } from 'react-icons/fi';
import { SiCircle } from 'react-icons/si';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { AiOutlineClose } from 'react-icons/ai';
import { useStore } from 'react-context-hook';
import { ImShare2 } from 'react-icons/im';
import {
  ApplyOverflow,
  Container,
  Content,
  HeaderMobile,
  HeaderNavbar,
  ItemHeaderNavbar,
  Logo,
  Menu,
  Mobile,
  Navbar,
  Wrapper,
} from '../default/styles';
import { ConvertedTheme } from '../../App';

export const SuperAdminLayout: React.FC = ({ children }) => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [theme] = useStore<ConvertedTheme>('theme');

  const handleOpenMenu = () => {
    setVisible(true);
  };

  const handleCloseMenu = () => {
    setVisible(false);
  };

  useEffect(() => {
    function handleClickOverlay(event: MouseEvent): void {
      if (
        navbarRef.current
        && event.target instanceof Node
        && !navbarRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    }

    if (visible) {
      document.addEventListener('mouseup', handleClickOverlay);
    }
  }, [visible]);

  return (
    <Container>
      <Menu visible={visible}>
        <Mobile>
          <button type="button" onClick={handleOpenMenu}>
            <FiMenu size={28} />
          </button>

          <div className="d-flex align-items-center justify-content-center">
            <h4 className="text-white ml-3">

              {theme.slogan}

            </h4>
          </div>
        </Mobile>

        <Navbar ref={navbarRef} visible={visible}>
          <ApplyOverflow className="d-flex justify-content-between flex-column h-100">
            <div>
              <HeaderMobile className="justify-content-between align-items-center">
                <h5 className="text-white">

                  {theme.slogan}

                </h5>
                <button type="button" onClick={handleCloseMenu}>
                  <AiOutlineClose size={25} />
                </button>
              </HeaderMobile>

              <Logo className="align-items-center justify-content-center" style={{ background: 'white' }}>
                <img src={theme.imgLogo} alt={process.env.REACT_APP_COMPANY_NAME} width="130" />
              </Logo>
              <ul>
                <li>
                  <NavLink to="/super-admin/empresas" onClick={handleCloseMenu} activeClassName="selected">
                    <FiHome />
                    <span>Empresas</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/super-admin/ramos" onClick={handleCloseMenu} activeClassName="selected">
                    <FiHome />
                    <span>Ramos</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/super-admin/cursos" onClick={handleCloseMenu} activeClassName="selected">
                    <ImShare2 />
                    <span>Compartilhamento de Cursos</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/super-admin/perguntas-comportamentais" onClick={handleCloseMenu} activeClassName="selected">
                    <SiCircle />
                    <span>Perguntas da Plataforma</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/super-admin/questionario-categoria" onClick={handleCloseMenu} activeClassName="selected">
                    <SiCircle />
                    <span>Categ. de Questionários</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/super-admin/checklist-categoria" onClick={handleCloseMenu} activeClassName="selected">
                    <SiCircle />
                    <span>Categ. de Checklist</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/super-admin/configuracoes" onClick={handleCloseMenu} activeClassName="selected">
                    <SiCircle />
                    <span>Configurações</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/super-admin/planilhas" onClick={handleCloseMenu} activeClassName="selected">
                    <SiCircle />
                    <span>Planilhas de Importação</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/super-admin/termos-de-uso" onClick={handleCloseMenu} activeClassName="selected">
                    <SiCircle />
                    <span>Termos de Uso</span>
                  </NavLink>
                </li>
              </ul>
            </div>

            <ul>
              <li>
                <NavLink to="/" onClick={handleCloseMenu} activeClassName="selected">
                  <RiLogoutCircleRLine size={20} />
                  <span>Voltar para Admin</span>
                </NavLink>
              </li>
            </ul>

          </ApplyOverflow>
        </Navbar>
      </Menu>

      <HeaderNavbar className=" d-flex align-items-center justify-content-between">
        <ItemHeaderNavbar>
          <div className="d-flex align-items-center w-100 ">
            {/* <img src={logo} alt={process.env.REACT123_APP_COMPANY_NAME} width="100" /> */}
            <p className="text-light">{theme.slogan}</p>
          </div>
        </ItemHeaderNavbar>
      </HeaderNavbar>

      <Wrapper visible={visible}>
        <Content>
          {children}
        </Content>
      </Wrapper>
    </Container>
  );
};
