import { Field, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import { ReactSelect } from '../../../../components/ReactSelect';
import { ICategoriaComunicado, IComunicado } from '../../../../interfaces/IComunicado';
import { ISelect } from '../../../../interfaces/ISelect';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { CheckAndUncheckBtn } from '../../../Users/styles';
import { ModalFormTarefaHTMLEditor } from '../../../Trilhas/components/ModalFormTarefa/ModalFormTarefaHTMLEditor';

interface ModalFormProps {
  category: ICategoriaComunicado;
  onCloseModal: () => void;
  showModal: boolean;
  comunicado?: IComunicado;
  viewOnly?: boolean;
}

export const ModalFormComunicado = (
  { onCloseModal, showModal, comunicado, category, viewOnly }: ModalFormProps,
) => {
  const allowFilters = category?.tipoFiltro?.unidade || category?.tipoFiltro?.grupo;

  const [unidades, setUnidades] = useState<ISelect[]>([]);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [grupos, setGrupos] = useState<ISelect[]>([]);
  const [loading, setLoading] = useState(false);
  const [includeVideo, setIncludeVideo] = useState(false);
  const [includeFile, setIncludeFile] = useState(false);
  const [includeHTMLContent, setIncludeHTMLContent] = useState(false);
  const [typesMedia, setTypesMedia] = useState<ISelect[]>([]);
  const [isShowModalHtmlEditor, setIsShowModalHtmlEditor] = useState<boolean>(false);
  const [conteudoHtml, setConteudoHtml] = useState<string>();

  useEffect(() => {
    setIncludeVideo(comunicado?.video != null);
    setIncludeFile(comunicado?.arquivo != null);
    setIncludeHTMLContent(comunicado?.conteudoHtml != null);
    setConteudoHtml(comunicado?.conteudoHtml);
    return () => {
      setConteudoHtml('');
      setIncludeVideo(false);
      setIncludeFile(false);
      setIncludeHTMLContent(false);
    };
  }, [comunicado]);

  useEffect(() => {
    api
      .get('tipomidia')
      .then(({ data }) => setTypesMedia(
        data.data.map((item: { id: number, opcao: string }) => ({
          label: item.opcao,
          value: item.id,
        })),
      ))
      .catch(() => {
        toast.error('Erro ao buscar lista de tipos de mídia');
      });
  }, []);

  const initialValues: IComunicado = {
    id: 0,
    titulo: '',
    ativo: true,
    liberado: false,
    perguntas: [],
    filtro: {
      desativarFiltro: false,
      grupos: [],
      unidades: [],
    },
  };

  const fetchFiliais = () => api
    .get('filial')
    .then(({ data }) => setGrupos(
      data.data.map((item: { id: number; nome: string; }) => ({
        label: item.nome,
        value: item.id,
      })),
    ))
    .catch(() => {
      toast.error('Erro ao buscar lista de Grupos');
    });

  const fetchUnidades = () => api
    .get('unidade-empresa')
    .then(({ data }) => setUnidades(
      data.data.map((item: { id: number; nome: string; }) => ({
        label: item.nome,
        value: item.id,
      })),
    ))
    .catch(() => {
      toast.error('Erro ao buscar lista de Unidades');
    });

  useEffect(() => {
    fetchFiliais();
    fetchUnidades();

    if (comunicado?.filtro) {
      setIsFilterEnabled(
        comunicado?.filtro?.grupos?.length > 0
        || comunicado?.filtro?.unidades?.length > 0,
      );
    }
  }, [comunicado]);

  const internalOnCloseModal = () => {
    setIsFilterEnabled(false);
    onCloseModal();
  };

  const handleSubmit = useCallback(
    async (dataForm: any) => {
      setLoading(true);
      const filtros = {
        desativarFiltro: !isFilterEnabled,
        unidadesIds: dataForm.unidadesIds?.map((item: { value: number }) => item.value) ?? [],
        gruposIds: dataForm.gruposIds?.map((item: { value: number }) => item.value) ?? [],
      };

      try {
        if (comunicado) {
          await api.put('/comunicado-empresa', {
            titulo: dataForm.titulo,
            id: comunicado.id,
            filtro: filtros,
            video: includeVideo && dataForm.video ? {
              ...dataForm.video,
              tipoMidiaId: dataForm?.video?.tipoMidiaId?.value || dataForm?.video?.tipoMidiaId || 1,
            } : null,
            arquivo: includeFile ? { ...dataForm.arquivo } : null,
            conteudoHtml: includeHTMLContent ? conteudoHtml : null,
          });
        } else {
          await api.post('/comunicado-empresa', {
            categoriaId: category.id,
            titulo: dataForm.titulo,
            filtro: filtros,
            video: includeVideo && dataForm.video ? {
              ...dataForm.video,
              tipoMidiaId: dataForm?.video?.tipoMidiaId?.value || dataForm?.video?.tipoMidiaId || 1,
            } : null,
            arquivo: includeFile ? { ...dataForm.arquivo } : null,
            conteudoHtml: includeHTMLContent ? conteudoHtml : null,
          });
        }

        internalOnCloseModal();
      } catch (error: any) {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
          return;
        }
        toast.error('Erro ao salvar Questionário');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFilterEnabled, comunicado, includeVideo,
      includeFile, includeHTMLContent, conteudoHtml, category.id],
  );

  const mappingValues = (values: IComunicado | undefined) => {
    if (values === undefined) {
      return initialValues;
    }

    return {
      ...values,
      unidadesIds: values.filtro.unidades.map((value) => ({
        value: value.unidade.id,
        label: value.unidade.nome,
      })),
      gruposIds: values.filtro.grupos.map((value) => ({
        value: value.grupo.id,
        label: value.grupo.nome,
      })),
    };
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.titulo) {
      errors.titulo = 'O campo Título é obrigatório';
    }

    if (
      isFilterEnabled
      && (!values.gruposIds || values.gruposIds?.length <= 0)
      && (!values.unidadesIds || values.unidadesIds?.length <= 0)
    ) {
      errors.filtroVazio = 'Prencha pelo menos 1 dos filtros abaixo ou desative os filtros';
    }

    return errors;
  };

  const getTitle = () => {
    if (viewOnly) { return 'Visualizar Comunicado (desative o questionário caso queira editar)'; }
    if (comunicado) { return 'Editar Comunicado'; }

    return 'Incluir Comunicado';
  };

  return (
    <>
      <ModalFormTarefaHTMLEditor
        originalContent={conteudoHtml || ''}
        isShowModal={isShowModalHtmlEditor}
        onCloseModal={(content) => {
          setConteudoHtml(content);
          setIsShowModalHtmlEditor(false);
        }}
      />

      <ModalComponent
        onClose={() => internalOnCloseModal()}
        modalVisible={showModal}
        title={getTitle()}
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={mappingValues(comunicado)}
          validate={validate}
          onSubmit={handleSubmit}
        >

          {({ errors, setFieldValue }: any) => (
            <Form>
              <Row className="mb-2">
                <Col xs={12} md={12}>
                  <Input type="text" name="titulo" label="Titulo" readOnly={viewOnly} />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="d-flex">
                  <Switch
                    onChange={() => setIncludeVideo((old) => !old)}
                    checked={includeVideo}
                    height={22}
                    width={53}
                    disabled={viewOnly}
                  />
                  <h6 className="text-secondary ms-3">Incluir Vídeo</h6>
                </Col>

                {includeVideo
                  && (
                    <>
                      <Col md={12}>
                        <Input type="text" name="video.titulo" label="Titulo do vídeo" />
                      </Col>

                      <Col md={6}>
                        <Field
                          name="video.tipoMidiaId"
                          label="Tipo de mídia"
                          component={ReactSelect}
                          options={typesMedia}
                        />
                      </Col>

                      <Col md={6}>
                        <Input type="text" name="video.url" label="URL do vídeo" />
                      </Col>

                      <Col md={12}>
                        <Input type="textarea" name="video.descricao" label="Coloque aqui o conteúdo por escrito" />
                      </Col>
                    </>
                  )
                }
              </Row>

              <Row className="mt-3">
                <Col className="d-flex" md={12}>
                  <Switch
                    onChange={() => setIncludeFile((old) => !old)}
                    checked={includeFile}
                    height={22}
                    width={53}
                    disabled={viewOnly}
                  />
                  <h6 className="text-secondary ms-3">Incluir Arquivo PDF</h6>
                </Col>
                {includeFile
                  && (
                    <>
                      <Col md={6}>
                        <Input type="text" name="arquivo.titulo" label="Titulo do arquivo" />
                      </Col>

                      <Col md={6}>
                        <Input type="text" name="arquivo.url" label="URL do arquivo" />
                      </Col>

                      <Col md={12}>
                        <Input type="textarea" name="arquivo.descricao" label="Descrição" />
                      </Col>
                    </>
                  )
                }
              </Row>

              <Row className="mt-3">
                <Col className="d-flex" md={12}>
                  <Switch
                    onChange={() => setIncludeHTMLContent((old) => !old)}
                    checked={includeHTMLContent}
                    height={22}
                    width={53}
                    disabled={viewOnly}
                  />
                  <h6 className="text-secondary ms-3">Incluir Conteúdo HTML</h6>
                </Col>
                {includeHTMLContent && (
                  <Col className="d-flex" md={3} style={{ marginTop: '10px' }}>
                    <Button
                      color="#fff"
                      onClick={() => setIsShowModalHtmlEditor(true)}
                      type="button"
                    >
                      Editar Conteúdo
                    </Button>
                  </Col>
                )}
              </Row>

              {allowFilters && (
                <>
                  {!viewOnly && (
                    <Row style={{ marginTop: '0.6rem' }}>
                      <Col className="d-flex flex-column mb-3">
                        <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                          Ativar Filtros
                        </strong>
                        <Switch
                          onChange={() => setIsFilterEnabled((old) => !old)}
                          checked={isFilterEnabled || false}
                          height={22}
                          width={53}
                        />
                      </Col>
                    </Row>
                  )}

                  {isFilterEnabled && (
                    <>
                      <Row>
                        <Col xs={12}>
                          Filtros:
                          <p>
                            <span> Esta pesquisa será disponibilizada para este grupo/unidade</span>
                            <span> e todos os seus subgrupos/subunidades.</span>
                          </p>
                          <small style={{ color: 'red', marginLeft: '1rem' }}>{errors.filtroVazio}</small>
                        </Col>
                      </Row>
                      <Row>
                        {category.tipoFiltro?.grupo && (
                          <Col md={6}>
                            <Field
                              className="form-control"
                              name="gruposIds"
                              isRequerid={category.tipoFiltro?.grupo}
                              label="Grupo (pode ser mais de 1)"
                              isMulti
                              component={ReactSelect}
                              isDisabled={!isFilterEnabled || viewOnly}
                              options={grupos}
                            />
                            <CheckAndUncheckBtn
                              href="#"
                              onClick={() => setFieldValue('gruposIds', grupos, false)}
                            >
                              Marcar Todos

                            </CheckAndUncheckBtn>
                            <CheckAndUncheckBtn
                              href="#"
                              onClick={() => setFieldValue('gruposIds', [], false)}
                            >
                              Desmarcar Todos

                            </CheckAndUncheckBtn>
                          </Col>
                        )}

                        {category.tipoFiltro?.unidade && (
                          <Col md={6}>
                            <Field
                              className="form-control"
                              name="unidadesIds"
                              isRequerid={isFilterEnabled}
                              label="Unidades (pode ser mais de 1)"
                              isMulti
                              component={ReactSelect}
                              options={unidades}
                              isDisabled={!isFilterEnabled || viewOnly}
                            />
                            <CheckAndUncheckBtn
                              href="#"
                              onClick={() => setFieldValue('unidadesIds', unidades, false)}
                            >
                              Marcar Todos

                            </CheckAndUncheckBtn>
                            <CheckAndUncheckBtn
                              href="#"
                              onClick={() => setFieldValue('unidadesIds', [], false)}
                            >
                              Desmarcar Todos

                            </CheckAndUncheckBtn>
                          </Col>
                        )}
                      </Row>
                    </>
                  )}

                </>
              )}

              {!viewOnly && (
                <Row className="mt-4">
                  <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                    <Button
                      background={theme.colors.main}
                      color="#fff"
                      className="text-uppercase"
                      type="submit"
                      loading={loading}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};
