/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { HeaderTitle } from '../../components/HeaderTitle';
import ChecklistCard from './components/ChecklistCard';
import { Container } from './styles';

const GET_VALIDATIONS_URL = 'checklistunidade/checklist-auditar';
const GET_VALIDATED_URL = 'checklistunidade/auditoria-finalizada';

enum tabsTypes {
  VERIFICADOS = 'verificados',
  A_VERIFICAR = 'a verificar',
}

export const ChecklistValidation = () => {
  const [activTab, setActivTab] = React.useState(tabsTypes.A_VERIFICAR);

  const builderHistoryTitle: () => string = () => 'Validações de Checklist';

  return (
    <>
      <HeaderTitle
        title={builderHistoryTitle()}
        exportUser={false}
        text="Nesta aba você pode gerenciar os Checklists que estão pendentes de dupla verificação. Vamos lá?"
      />
      <Container>
        <h3
          onClick={() => setActivTab(tabsTypes.A_VERIFICAR)}
          className={`tw-cursor-pointer ${activTab === tabsTypes.A_VERIFICAR ? 'theme-color' : 'tw-text-gray-500 '} `}
        >
          A VERIFICAR
        </h3>
        <h3
          onClick={() => setActivTab(tabsTypes.VERIFICADOS)}
          className={`tw-cursor-pointer ${activTab === tabsTypes.VERIFICADOS ? 'theme-color' : 'tw-text-gray-500 '} `}
        >
          VERIFICADOS
        </h3>
      </Container>
      <ChecklistCard
        url={activTab === tabsTypes.VERIFICADOS ? GET_VALIDATED_URL : GET_VALIDATIONS_URL}
        modePreview={activTab === tabsTypes.VERIFICADOS}
      />
    </>
  );
};
