import styled from 'styled-components';
import { Table as TableBootstrap } from 'react-bootstrap';
import { lighten } from 'polished';

export const Content = styled.div`
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 0 6px 0 #bdbdbd;
  background: #fff;
  height: 100%;
  width: 100%;
`;

export const Table = styled(TableBootstrap)`
  tr {
    line-height: 0;
  }

  td:first-child {
    font-weight: 600;
  }

  tr td:last-child,
  tr th:last-child {
    display: flex;
    justify-content: flex-end;
  }

  tr {
    th {
      /* text-align: center; */
      padding: 1.5rem 0.5rem;
      color: ${({ theme }) => lighten(0.1, theme.colors.main)};
    }
  }

  td {
    vertical-align: middle;
    color: ${({ theme }) => lighten(0.1, theme.colors.main)};

    h6 {
      font-size: 1rem;
      color: rgba(1, 1, 1, 0.8);
      margin: 0;
    }

    p {
      font-size: 0.9rem;
      color: rgba(1, 1, 1, 0.6);
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 1;
      margin: 0;
    }

    span {
      font-size: 0.8rem;
    }
  }

  td button {
    background: transparent;
    color: ${({ theme }) => lighten(0.1, theme.colors.main)};
    font-size: 1.2rem;
    border-radius: 10px;
    padding: 0.5rem;
    border: none;
    position: relative;

    &:hover {
      background: ${({ theme }) => lighten(0.7, theme.colors.main)};
    }
  }

  tbody {
    tr:nth-child(even) {
      background: ${({ theme }) => lighten(0.8, theme.colors.main)};

    }

    tr:nth-child(odd){
      background-color: ${({ theme }) => lighten(0.75, theme.colors.main)};
    }
  }

`;
