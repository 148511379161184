/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { IoAdd, IoAlertCircleOutline, IoRemove } from 'react-icons/io5';
import Switch from 'react-switch';
import { useQuery } from 'react-query';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import {
  IQuestionario,
  IQuestionarioPergunta,
  IQuestionnaireCategory,
} from '../../../../interfaces/IQuestionario';
import { ControlledReactSelect } from '../../../../components/ReactSelect/controlled-select';
import { ISelect } from '../../../../interfaces/ISelect';
import { OptionsTipoPergunta } from '../../../QuestionnaireCategory/ModalFormQuestionnaireCategory';
import { ReactSelect } from '../../../../components/ReactSelect';
import { ErrorContainer, Label } from './styles';
import HtmlEditor from '../../../Use-Terms/components/Html-Editor/editor';
import { validateHtmlContent } from '../../../Use-Terms/components/Html-Editor/validator';

interface ModalFormProps {
  onCloseModal: (climate?: IQuestionario) => void;
  showModal: boolean;
  climateId: number;
  question?: IQuestionarioPergunta;
  category?: IQuestionnaireCategory;
}

const FindOptionsTipoPergunta = (value: string | number): ISelect | undefined => OptionsTipoPergunta.find((option) => option.value === `${value}`);

export const ModalFormOrganizationalClimateQuestions = ({
  onCloseModal,
  showModal,
  climateId,
  question,
  category,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);
  const [optionsTipoPerguntas, setOptionsTipoPerguntas] = useState<ISelect[]>(OptionsTipoPergunta);

  const [tipoPergunta, setTipoPergunta] = useState<ISelect>(OptionsTipoPergunta[0]);
  const [includeVideo, setIncludeVideo] = useState(false);
  const [typesMedia, setTypesMedia] = useState<ISelect[]>([]);
  const [htmlContent, setHtmlContent] = useState<string>(question?.titulo || '');

  const initialValues: any = {
    id: 0,
    titulo: '',
    referencia: '',
    alternativas: [
      {
        id: 0,
        descricao: '',
        referencia: '',
      },
      {
        id: 0,
        descricao: '',
        referencia: '',
      },
      {
        id: 0,
        descricao: '',
        referencia: '',
      },
    ],
  };

  const url = `/questionario-empresa/${climateId}/questionario`;

  const validationSchema = yup.object().shape({
    titulo: yup.string().required('Campo obrigatório'),
  });

  const handleSubmit = async (dataForm: any) => {
    const dataFornN = { ...dataForm };
    dataFornN.titulo = validateHtmlContent(htmlContent) ? '' : htmlContent;
    const requestData = {
      ...dataFornN,
      tipoPergunta: tipoPergunta.value,
      video: includeVideo && dataFornN.video ? {
        ...dataFornN.video,
        tipoMidiaId: dataFornN?.video?.tipoMidiaId?.value || dataFornN?.video?.tipoMidiaId || 1,
      } : null,
    };
    setLoading(true);

    try {
      const method = question ? api.put : api.post;
      const response = await method(url, requestData);
      onCloseModal(response.data.data);
      setHtmlContent('');
    } catch (error: any) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error('Erro ao salvar Pergunta');
    } finally {
      setLoading(false);
    }
  };

  const handleAddMoreAlternatives = useCallback((setValues: (values: any) => void) => {
    setValues((values: any) => {
      values.alternativas.push({
        id: 0,
        descricao: '',
        referencia: '',
      });

      return values;
    });
  }, []);

  useEffect(() => {
    if (category) {
      // const optionsQuestions: ISelect[] = category.tipoPerguntas
      //   .map((typeQuestion) => FindOptionsTipoPergunta(typeQuestion.questionarioTipoPerguntaId))
      //   .filter((value) => value !== undefined) as ISelect[];

      // setOptionsTipoPerguntas(optionsQuestions);
      setTipoPergunta(optionsTipoPerguntas[0]);
    }

    if (question && question.tipoPergunta) {
      setTipoPergunta(FindOptionsTipoPergunta(question.tipoPergunta) || OptionsTipoPergunta[0]);
    }
  }, [question, category]);

  const handleRemoveAlternatives = useCallback(
    (setValues: (values: any) => void, index: number) => {
      setValues((values: any) => {
        values.alternativas.splice(index, 1);
        return values;
      });
    },
    [],
  );

  const handleOnHtmlEditorChange = (content: string) => {
    setHtmlContent(content);
  };

  useEffect(() => {
    api
      .get('tipomidia')
      .then(({ data }) => setTypesMedia(
        data.data.map((item: { id: number, opcao: string }) => ({
          label: item.opcao,
          value: item.id,
        })),
      ))
      .catch(() => {
        toast.error('Erro ao buscar lista de tipos de mídia');
      });
  }, []);

  useEffect(() => {
    setIncludeVideo(question?.video);
  }, [question]);

  return (
    <>
      <ModalComponent
        onClose={() => {
          onCloseModal();
          setHtmlContent('');
        }}
        modalVisible={showModal}
        title="Incluir Pergunta"
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={question || initialValues}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validate={(values) => {
            try {
              const valuesN = { ...values };
              if (htmlContent.length > 0) {
                valuesN.titulo = htmlContent;
              }
              validationSchema.validateSync(valuesN, { abortEarly: false });
            } catch (err) {
              return { titulo: 'Campo título é obrigatório' };
            }
            return {};
          }}
        >
          {({ errors, values, touched, setValues }) => (
            <Form>
              <Row>
                <Col xs={12} md={12}>
                  <Input type="text" name="referencia" label="Referência" />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <Label htmlFor="pergunta.titulo"><span>Titulo</span></Label>
                  {/* <Field */}
                  {/*  id="pergunta.titulo" */}
                  {/*  name="titulo" */}
                  {/*  label="titulo" */}
                  {/*  component={HtmlEditor} */}
                  {/*  handleOnChange={handleOnHtmlEditorChange} */}
                  {/*  content={values?.titulo} */}
                  {/*  hideImage */}
                  {/* /> */}

                  <HtmlEditor
                    placeholder="Escreva algo..."
                    value={values?.titulo}
                    onChange={handleOnHtmlEditorChange}
                  />

                  {validateHtmlContent(htmlContent) && errors.titulo && (
                  <ErrorContainer>
                    <IoAlertCircleOutline />
                    <span>{errors.titulo}</span>
                  </ErrorContainer>
                  )}
                </Col>
              </Row>

              <Row>
                <Col className="d-flex" style={{ gap: '8px', marginTop: '16px' }}>
                  <h6 className="text-secondary ms-3">Incluir Vídeo</h6>
                  <Switch
                    onChange={() => setIncludeVideo((old) => !old)}
                    checked={includeVideo}
                    height={22}
                    width={53}
                  />
                </Col>

                {includeVideo
                  && (
                    <>
                      <Col md={12}>
                        <Input type="text" name="video.titulo" label="Titulo do vídeo" />
                      </Col>

                      <Col md={6}>
                        <Field
                          name="video.tipoMidiaId"
                          label="Tipo de mídia"
                          component={ReactSelect}
                          options={typesMedia}
                        />
                      </Col>

                      <Col md={6}>
                        <Input type="text" name="video.url" label="URL do vídeo" />
                      </Col>

                      <Col md={12}>
                        <Input type="textarea" name="video.descricao" label="Coloque aqui o conteúdo por escrito" />
                      </Col>
                    </>
                  )
                }
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <ControlledReactSelect
                    label="Tipo de Pergunta"
                    options={optionsTipoPerguntas}
                    value={tipoPergunta}
                    onChange={(value) => setTipoPergunta(value)}
                  />
                </Col>
              </Row>

              {(tipoPergunta?.value === '1' || tipoPergunta?.value === '5') && (
                <Row>
                  <Col xs={12} className="mt-2">
                    <label htmlFor="qtdeAlternativas" className="styled-label">
                      Alternativas
                    </label>
                    <FieldArray name="alternativas">
                      {() => values?.alternativas?.map((item: any, i: any) => (
                        <Row style={{ marginLeft: 0, marginBottom: '0.5rem' }} key={i}>
                          <Col xs={1} className="rounded-gray-bg">
                            <span>{i + 1}</span>
                          </Col>
                          <Col xs={10}>
                            <div style={{ display: 'flex' }}>
                              <Field
                                name={`alternativas.${i}.referencia`}
                                type="text"
                                className="form-control"
                                style={{ width: '10%' }}
                              />
                              <Field
                                name={`alternativas.${i}.descricao`}
                                type="text"
                                className="form-control"
                                style={{ width: '90%' }}
                              />
                            </div>
                            <ErrorMessage
                              name={`alternativas.${i}.descricao`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                          <Col xs={1}>
                            <Button
                              background={theme.colors.main}
                              color="#fff"
                              onClick={() => handleRemoveAlternatives(setValues, i)}
                              type="button"
                            >
                              <IoRemove size={24} />
                            </Button>
                          </Col>
                        </Row>
                      ))
                      }
                    </FieldArray>

                    {tipoPergunta.value === '5' && (
                      <small>
                        <b>
                          Quando o jogador for responder irá aparecer Outros como última opção. Caso
                          o usuário escolha a opção Outros um campo aberto será mostrado )
                        </b>
                      </small>
                    )}

                    <Button
                      background={theme.colors.main}
                      color="#fff"
                      onClick={() => handleAddMoreAlternatives(setValues)}
                      type="button"
                    >
                      <IoAdd size={24} />
                    </Button>
                  </Col>
                </Row>
              )}

              <Row className="mt-4">
                <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                  <Button
                    background={theme.colors.main}
                    color="#fff"
                    className="text-uppercase"
                    type="submit"
                    loading={loading}
                  >
                    Salvar pergunta
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};
