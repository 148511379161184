/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { SiCircle } from 'react-icons/si';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { ICurso } from '../../../../interfaces/ICurso';
import { ISelect } from '../../../../interfaces/ISelect';
import { ReactSelect } from '../../../../components/ReactSelect';
import { IVideo } from '../../../../interfaces/IVideo';
import { IArquivo } from '../../../../interfaces/IArquivo';

const fileInitialData = {
  id: undefined,
  url: '',
  titulo: '',
  descricao: '',
};

const videoInitialData = {
  id: undefined,
  tipoMidiaId: 1,
  url: '',
  titulo: '',
  descricao: '',
};

const dataInitial = {
  nome: '',
  descricao: '',
  prazoFinalizacao: 0,
  bonusIds: [],
  valor: 1000,
  video: videoInitialData,
  arquivo: fileInitialData,
};

interface SubmitProps {
  nome: string;
  descricao: string;
  prazoFinalizacao: number;
  valor: number;
  bonusIds: Array<{
    label: string;
    value: string;
  }>;
  arquivo: IArquivo;
  video: IVideo;
}

interface ModalFormCursoProps {
  onSetCurso?: () => void;
  onCloseModal: () => void;
  idCurso?: number;
  categoryId?: number;
  showModalFormCurso: boolean;
}

export const ModalFormCurso = ({
  onSetCurso,
  showModalFormCurso,
  idCurso,
  onCloseModal,
  categoryId,
}: ModalFormCursoProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [bonus, setBonus] = useState<ISelect[]>([]);
  const [curso, setCurso] = useState<ICurso>();
  const [cursoAtivo, setCursoAtivo] = useState(false);
  const [vendivel, setVendivel] = useState(false);
  const [podeGastarEnergiaJogador, setPodeGastarEnergiaJogador] = useState(false);
  const [comAproveitamento, setComAproveitamento] = useState(false);
  // const [showGaleriaDesktop, setShowGaleriaDesktop] = useState(false);
  // const [showGaleriaApp, setShowGaleriaApp] = useState(false);
  const [imgFundoDesktop, setImgFundoDesktop] = useState('');
  const [imgFundoApp, setImgFundoApp] = useState('');
  const [typesMedia, setTypesMedia] = useState<ISelect[]>([]);
  const [includeVideo, setIncludeVideo] = useState(!!curso?.video);
  const [includeFile, setIncludeFile] = useState(!!curso?.arquivo);

  const videoSchema = yup.object({
    titulo: yup.string().nullable(),
    tipoMidiaId: yup.lazy((value) => (typeof value === 'object'
      ? yup.object().nullable()
      : yup.string())),
    url: yup.string().required('A URL é obrigatória'),
    descricao: yup.string().nullable(),
  }).nullable();

  const fileSchema = yup.object({
    url: yup.string().required('A URL é obrigatória'),
    titulo: yup.string().nullable(),
    descricao: yup.string().nullable(),
  }).nullable();

  const schema = yup.object({
    nome: yup.string().required('O nome é obrigatório'),
    descricao: yup.string().required('A descrição é obrigatória'),
    video: includeVideo ? videoSchema : yup.object().nullable(),
    arquivo: includeFile ? fileSchema : yup.object().nullable(),
  });

  // useEffect(() => {
  //   if (curso) {
  //     setIncludeVideo(!!curso.video);
  //     setIncludeFile(!!curso.arquivo);
  //   }
  // }, [curso]);

  useEffect(() => {
    api.get('bonus').then(({ data }) => setBonus(
      data.data.map((item: { id: number, titulo: string }) => ({
        label: item.titulo,
        value: item.id,
      })),
    )).catch(() => toast.error('Erro ao buscar bônus'));
  }, []);

  useEffect(() => {
    if (idCurso) {
      api.get(`/curso/${idCurso}`).then(({ data }) => {
        setCurso({
          ...data.data,
          bonusIds: data.data.bonus.map((p: { id: number, titulo: string }) => ({
            value: p.id,
            label: p.titulo,
          })),
        });
        setCursoAtivo(data.data.ativo);
        setImgFundoDesktop(data.data.imgFundoDesktop);
        setImgFundoApp(data.data.imgFundoApp);
        setVendivel(data.data.vendivel);
        setPodeGastarEnergiaJogador(data.data.podeGastarEnergiaJogador);
        setComAproveitamento(data.data.comAproveitamento);
        setIncludeVideo(!!data.data.video);
        setIncludeFile(!!data.data.arquivo);
      }).catch(() => toast.error('Erro ao buscar curso'));
    }
    return () => {
      setImgFundoDesktop('');
      setImgFundoApp('');
      setCurso(undefined);
      setPodeGastarEnergiaJogador(true);
    };
  }, [idCurso]);

  useEffect(() => {
    api
      .get('tipomidia')
      .then(({ data }) => setTypesMedia(
        data.data.map((item: { id: number, opcao: string }) => ({
          label: item.opcao,
          value: item.id,
        })),
      ))
      .catch(() => {
        toast.error('Erro ao buscar lista de tipos de mídia');
      });

    return () => {
      setTypesMedia([]);
    };
  }, []);

  const handleSubmit = useCallback(
    async ({ nome, descricao, prazoFinalizacao, bonusIds, video, arquivo, valor }
      : SubmitProps) => {
      try {
        setLoading(true);
        const dataFormatted = {
          nome,
          descricao,
          ativo: cursoAtivo,
          bonusIds: bonusIds.map((p) => +p.value).concat([]),
          imgFundoDesktop,
          prazoFinalizacao,
          imgFundoApp,
          vendivel,
          valor,
          comAproveitamento,
          podeGastarEnergiaJogador,
          cursoCategoriaId: categoryId,
          video: includeVideo ? {
            ...video,
            tipoMidiaId: video?.tipoMidiaId?.value || video?.tipoMidiaId || 1,
          } : null,
          arquivo: includeFile ? arquivo : null,
        };

        if (curso) {
          const { data } = await api.put(`/curso/${curso.id}`, {
            ...dataFormatted,
            id: curso.id,
          });
          const dataReturnFormatted = {
            ...data.data,
            bonusIds: data.data.bonus.map((p: { id: number, titulo: string }) => ({
              value: p.id,
              label: p.titulo,
            })),
          };
          setCurso(dataReturnFormatted);
          if (onSetCurso) {
            onSetCurso();
          }
        } else {
          const { data } = await api.post('/curso', dataFormatted);
          history.push(`/categories/${categoryId}/cursos/${data.data.id}`);
        }
        toast.success('Curso salvo com sucesso');
        onCloseModal();
      } catch (err: any) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar curso');
      } finally {
        setLoading(false);
      }
    },
    [onSetCurso, curso, onCloseModal, imgFundoDesktop,
      imgFundoApp, history, cursoAtivo, podeGastarEnergiaJogador,
      vendivel, includeFile, includeVideo, categoryId, comAproveitamento],
  );

  // const handleRemoveImage = (type: string) => {
  //   Swal.fire({
  //     title: 'Deseja realmente excluir?',
  //     text: 'Você não será capaz de reverter isso.',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#ef5350',
  //     cancelButtonColor: '#757575',
  //     confirmButtonText: 'Sim, excluir',
  //     cancelButtonText: 'Cancelar',
  //     denyButtonColor: '#757575',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       if (type === 'imgApp') {
  //         setImgFundoApp('');
  //       } else {
  //         setImgFundoDesktop('');
  //       }
  //     }
  //   });
  // };

  useEffect(() => {
    console.log(comAproveitamento);
  }, [comAproveitamento]);

  return (
    <>
      {/* <ModalGaleria
        showModal={showGaleriaDesktop}
        onCloseModal={() => setShowGaleriaDesktop(false)}
        path="curso"
        onSelectImage={(path) => setImgFundoDesktop(path)}
      />
      <ModalGaleria
        showModal={showGaleriaApp}
        onCloseModal={() => setShowGaleriaApp(false)}
        path="curso"
        onSelectImage={(path) => setImgFundoApp(path)}
      /> */}
      <ModalComponent
        onClose={() => {
          onCloseModal();
        }}
        modalVisible={showModalFormCurso}
        title="Adicionar Curso"
        icon={SiCircle}
        size="lg"
      >
        <h6>Preencha todos os dados solicitados.</h6>
        <Formik
          initialValues={curso || dataInitial}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          <Form>
            <Row>
              <Col md={12}>
                {curso && (
                  <div className="d-flex flex-column mb-3">
                    <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                      Curso ativo
                    </strong>
                    <Switch
                      onChange={() => setCursoAtivo((old) => !old)}
                      checked={cursoAtivo}
                      height={22}
                      width={53}
                    />
                  </div>
                )}
                <div className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                    O Curso pode ser vendível?
                  </strong>
                  <Switch
                    onChange={() => setVendivel((old) => !old)}
                    checked={vendivel}
                    height={22}
                    width={53}
                  />
                  {
                    vendivel && <Input type="text" name="valor" label="Qual será o valor do Curso?" isRequerid />
                  }
                </div>
                <div className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                    Pode gastar energia?
                  </strong>
                  <Switch
                    onChange={() => setPodeGastarEnergiaJogador((old) => !old)}
                    checked={podeGastarEnergiaJogador}
                    height={22}
                    width={53}
                  />
                </div>
                <div className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                    Com Aproveitamento?
                  </strong>
                  <Switch
                    onChange={() => setComAproveitamento((old) => !old)}
                    checked={comAproveitamento}
                    height={22}
                    width={53}
                  />
                </div>
                <Input type="text" name="nome" label="Qual será o nome do Curso?" isRequerid />

                <Input type="number" name="prazoFinalizacao" label="Qual será o prazo do Curso? (0 = sem prazo)" isRequerid />

                <Input type="textarea" name="descricao" label="Qual será a descrição do curso?" isRequerid />

                <Field
                  name="bonusIds"
                  label="Qual será o prêmio ao terminar o curso? (Bônus)"
                  isMulti
                  component={ReactSelect}
                  options={bonus}
                />

                <div className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                    Incluir Arquivo?
                  </strong>
                  <Switch
                    onChange={() => setIncludeFile((old) => !old)}
                    checked={includeFile}
                    height={22}
                    width={53}
                  />
                </div>

                {includeFile
                  && (
                    <div className="d-flex flex-column mb-3">
                      <Col md={12}>
                        <Input type="text" name="arquivo.titulo" label="Titulo do arquivo" />
                      </Col>

                      <Col md={12}>
                        <Input type="text" name="arquivo.url" label="URL do arquivo" isRequerid />
                      </Col>

                      <Col md={12}>
                        <Input type="textarea" name="arquivo.descricao" label="Descrição" />
                      </Col>
                    </div>
                  )
                }

                <div className="d-flex flex-column mb-3">
                  <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
                    Incluir Vídeo?
                  </strong>
                  <Switch
                    onChange={() => setIncludeVideo((old) => !old)}
                    checked={includeVideo}
                    height={22}
                    width={53}
                  />

                </div>
                {includeVideo
                  && (
                    <div className="d-flex flex-column mb-3">
                      <Col md={12}>
                        <Input type="text" name="video.titulo" label="Titulo do vídeo" />
                      </Col>

                      <Col md={12} className="mt-2">
                        <Field
                          name="video.tipoMidiaId"
                          label="Tipo de mídia"
                          component={ReactSelect}
                          options={typesMedia}
                        />
                      </Col>
                      <Col md={12} className="mt-2">
                        <Input type="text" name="video.url" label="URL do vídeo" isRequerid />
                      </Col>

                      <Col md={12} className="mt-2">
                        <Input
                          type="textarea"
                          name="video.descricao"
                          label="Coloque aqui o conteúdo por escrito"
                        />
                      </Col>
                    </div>
                  )
                }
              </Col>
              {/*
                <div className="d-flex justify-content-center">
                <div>
                <Thumbnail
                title="Fundo desktop (em breve)"
                // url={imgFundoDesktop ? `https://api.hmp.entgames.com.br/imagens/${imgFundoDesktop}` : undefined}
                // onShowModalUpload={() => setShowGaleriaDesktop(true)}
                // onDeleteImage={() => handleRemoveImage('imgDesktop')}
                />
                </div>
                  <div style={{ marginLeft: '2rem' }}>
                    <Thumbnail
                      title="Fundo app (em breve)"
                      // url={imgFundoApp ? `https://api.hmp.entgames.com.br/imagens/${imgFundoApp}` : undefined}
                      // onShowModalUpload={() => setShowGaleriaApp(true)}
                      // onDeleteImage={() => handleRemoveImage('imgApp')}
                    />
                  </div>
                </div>
                */}
            </Row>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  loading={loading}
                  className="text-uppercase"
                  type="submit"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      </ModalComponent>
    </>
  );
};
