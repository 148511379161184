import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 3rem;

  h6, p {
    color: ${({ theme }) => theme.colors.main};
    margin: 0;
  }
`;

export const Category = styled.div`
  color: ${({ theme }) => theme.colors.main};

  h6 {
    font-size: 0.8rem;
  }
`;

export const Description = styled.div`
  padding-left: 0.5rem;
  margin-left: 0.5rem;
  border-left: 2px dotted ${({ theme }) => theme.colors.main};
  position: relative;
  padding-bottom: 1.5rem;

  h6 {
    justify-self: center;
    font-size: 16px;
  }
`;
