import styled from 'styled-components';

interface Props {
  check: boolean;
}

export const SpanAlternative = styled.button<Props>`
  width: 50px;
  font-weight: 600;
  background: ${({ check, theme }) => (check ? theme.colors.main : '#eee')};
  color: white;
`;
