/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { SiCircle } from 'react-icons/si';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { ModalComponent } from '../../../../components/Modal';
import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import api from '../../../../services/api';

interface ModalFormAgendamentoProps {
  idCurso: number;
  onClose: (isUpdate: boolean) => void;
  isOpen: boolean;
}

interface FormikValues {
  dataLancamento: string;
  horaLancamento: string;
}

export const ModalFormAgendamento = ({ idCurso, onClose, isOpen }: ModalFormAgendamentoProps) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());

  useEffect(() => {
    // Set now every user open modal
    setDate(moment());
  }, []);
  const dataAtualFormatada: string = date.local().format('YYYY-MM-DD');
  const horaAtualFormatada: string = date.local().format('HH:mm');

  const handleSubmit = useCallback(
    async ({ dataLancamento, horaLancamento }: FormikValues) => {
      const dateData = moment(`${dataLancamento} ${horaLancamento}`, 'YYYY-MM-DD HH:mm').local();

      if (moment(dataLancamento).format().split('-')[0].replace(/^0+/, '').length !== 4) {
        toast.error('Por favor, verifique a data inserida.');
      } else {
        try {
          await api.post(`curso/${idCurso}/agendar-cadastro`, {
            data: dateData,
          });
          toast.success('Agendamento feito com sucesso.');
          onClose(true);
        } catch (error: unknown) {
          toast.error('Ocorreu um erro ao agendar seu curso.');
        }
      }
    },
    [date],
  );

  return (
    <ModalComponent
      onClose={() => onClose(false)}
      title="Agendar Lançamento de Curso"
      icon={SiCircle}
      size="lg"
      modalVisible={isOpen}
    >
      <h6>Agende seu o lançamento do seu curso</h6>
      <Formik
        initialValues={{
          dataLancamento: dataAtualFormatada,
          horaLancamento: horaAtualFormatada,
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <Col>
              <Input
                type="date"
                name="dataLancamento"
                label="Data do lançamento"
                min={dataAtualFormatada}
                defaultValue={dataAtualFormatada}
              />
            </Col>
            <Col>
              <Input
                type="time"
                name="horaLancamento"
                label="Hora do lançamento"
                defaultValue={horaAtualFormatada}
                min={horaAtualFormatada}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button color="#fff">Agendar</Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
