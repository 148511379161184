/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { BiTask } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import { ModalComponent } from '../../../components/Modal';
import { IQuestionnaireCategory } from '../../../interfaces/IQuestionario';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import api from '../../../services/api';
import { ControlledReactSelect } from '../../../components/ReactSelect/controlled-select';
import { ISelect } from '../../../interfaces/ISelect';
import { IEmpresa } from '../../../interfaces/IEmpresa';

interface ModalFormQuestionnaireCategoryProps {
  onClose: () => void;
  isOpen: boolean;
  questionnaireCategory?: IQuestionnaireCategory;
  categoriaPaiId?: string;
  isSuperAdmin?: boolean;
}

const defaultValues: IQuestionnaireCategory = {
  id: 0,
  nome: '',
  descricao: '',
  bloquearCursos: false,
  maximoLancamentos: 1,
  prazoDias: 30,
  tipoFiltro: {
    id: 0,
    todos: true,
    unidade: false,
    grupo: false,
  },
  tipoPerguntas: [
    {
      questionarioTipoPerguntaId: 1,
    },
    {
      questionarioTipoPerguntaId: 2,
    },
    {
      questionarioTipoPerguntaId: 3,
    },
  ],
};

export const OptionsTipoPergunta: ISelect[] = [
  { value: '1', label: 'Múltipla Escolha' },
  { value: '2', label: 'Campo aberto' },
  { value: '3', label: 'Pontuação' },
  { value: '4', label: 'Imagem' },
  { value: '5', label: 'Múltipla Escolha c/ opção Outros' },
  { value: '6', label: 'Data' },
  { value: '7', label: 'Escala em número' },
  { value: '8', label: 'Associar Usuário' },
];

export const ModalFormQuestionnaireCategory = ({
  isOpen,
  onClose,
  questionnaireCategory,
  categoriaPaiId,
  isSuperAdmin = false,
}: ModalFormQuestionnaireCategoryProps) => {
  const CATEGORY_ENDPOINT_BASE_URL = 'questionario-empresa/categoria';
  const CATEGORY_ENDPOINT_SUPER_ADMIN_URL = `${CATEGORY_ENDPOINT_BASE_URL}/super-admin`;

  const [company] = useStore<IEmpresa>('company');
  const [tipoPerguntas, setTipoPerguntas] = useState<ISelect[]>(OptionsTipoPergunta);
  const [loading, setLoading] = useState(false);

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (questionnaireCategory) {
      const tipoPerguntasMapped = questionnaireCategory.tipoPerguntas.map(
        (tipoPergunta) => OptionsTipoPergunta[tipoPergunta.questionarioTipoPerguntaId - 1],
      );
      setTipoPerguntas(tipoPerguntasMapped);
    }
  }, [questionnaireCategory]);

  const handleSubmit = useCallback(
    async (values: IQuestionnaireCategory) => {
      const method = questionnaireCategory ? api.put : api.post;

      setLoading(true);

      const tipoPerguntasData = tipoPerguntas.map((value) => ({
        questionarioTipoPerguntaId: value.value,
      }));

      const requestData = {
        ...values,
        tipoPerguntas: tipoPerguntasData,
        questionarioEmpresaCategoriaPaiId: categoriaPaiId ? parseInt(categoriaPaiId, 10) : null,
        empresaId: isSuperAdmin ? null : company.id,
      };

      method(
        isSuperAdmin ? CATEGORY_ENDPOINT_SUPER_ADMIN_URL : CATEGORY_ENDPOINT_BASE_URL,
        requestData,
      )
        .then(() => {
          console.log('🚀 ~ file: index.tsx:110 ~ .then ~ then');
          onClose();
        })
        .catch((error) => {
          console.log('🚀 ~ file: index.tsx:113 ~ catch', error);
          toast.error(error?.response?.data?.errors[0] || 'Ocorreu um erro ao salvar a categoria');
        })
        .finally(() => {
          console.log('🚀 ~ file: index.tsx:122 ~ .finally ~ finally');
          setLoading(false);
        });
    },
    [questionnaireCategory, tipoPerguntas, categoriaPaiId, onClose],
  );

  return (
    <ModalComponent
      onClose={handleOnClose}
      modalVisible={isOpen}
      title="Incluir Categoria Questionario"
      icon={BiTask}
      size="lg"
    >
      <Formik initialValues={questionnaireCategory || defaultValues} onSubmit={handleSubmit}>
        {({ errors, values, touched, setValues }) => (
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Input type="text" name="nome" label="Nome" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input type="textarea" name="descricao" label="Descrição" />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="d-flex">
                <Switch
                  onChange={() => setValues({ ...values, bloquearCursos: !values.bloquearCursos })}
                  checked={values.bloquearCursos}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Bloquear Cursos</h6>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input type="number" name="prazoDias" label="Prazo em dias" />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  type="number"
                  name="maximoLancamentos"
                  label="Máximo de questionários ativos."
                />
              </Col>
            </Row>
            <Row>
              <ControlledReactSelect
                label="Tipo de Pergunta"
                options={OptionsTipoPergunta}
                value={tipoPerguntas}
                isMulti
                onChange={(value) => setTipoPerguntas(value)}
              />
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col xs={12} md={6}>
                <label htmlFor="">
                  <b>Filtros:</b>
                </label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="d-flex">
                <Switch
                  onChange={(value) => {
                    setValues({
                      ...values,
                      tipoFiltro: {
                        ...(values.tipoFiltro ?? defaultValues.tipoFiltro),
                        todos: value,
                        unidade: false,
                        grupo: false,
                      },
                    });
                  }}
                  checked={values.tipoFiltro?.todos ?? true}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Sem Filtro</h6>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="d-flex">
                <Switch
                  onChange={(value) => setValues({
                    ...values,
                    tipoFiltro: {
                      ...(values.tipoFiltro ?? defaultValues.tipoFiltro),
                      grupo: value,
                      todos: !values.tipoFiltro?.unidade && !value,
                    },
                  })
                  }
                  checked={values.tipoFiltro?.grupo ?? false}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Filtrar por Grupo</h6>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="d-flex">
                <Switch
                  onChange={(value) => setValues({
                    ...values,
                    tipoFiltro: {
                      ...(values.tipoFiltro ?? defaultValues.tipoFiltro),
                      unidade: value,
                      todos: !value && !values.tipoFiltro?.grupo,
                    },
                  })
                  }
                  checked={values.tipoFiltro?.unidade ?? false}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Filtrar por unidade</h6>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  type="submit"
                  loading={loading}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ModalComponent>
  );
};
