/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { ModalForm } from './ModalForm';
import { ItemUser, Actions } from './styles';
import { HeaderTitle } from '../../components/HeaderTitle';
import handleErrors from '../../utils/handle.errors';
import { IEmpresa } from '../../interfaces/IEmpresa';

export const SuperAdmin = () => {
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [empresa, setEmpresa] = useState<IEmpresa>();
  const [showModalForm, setShowModalForm] = useState(false);

  const fetchEmpresas = () => {
    api
      .get('empresa')
      .then(({ data }) => {
        const dataFormatted = data.data.map((item: any) => ({
          ...item,
        }));
        setEmpresas(dataFormatted);
      })
      .catch((err) => handleErrors(err));
  };
  useEffect(() => {
    fetchEmpresas();
  }, []);

  const handleCloseModalForm = () => {
    setShowModalForm(false);
    setEmpresa(undefined);
  };

  const handleSetEmpresas = useCallback((data: IEmpresa, update?: boolean) => {
    if (update) {
      const oldEmpresasArr = [...empresas];
      const findedIndexEmpresa = oldEmpresasArr.findIndex((u) => u.id === data.id);
      if (findedIndexEmpresa > -1) {
        oldEmpresasArr[findedIndexEmpresa] = {
          ...data,
        };

        setEmpresas([...oldEmpresasArr]);
      }
    } else {
      setEmpresas((old) => [...old, data]);
    }
  }, [empresas]);

  const handleShowModalForm = (empresaM: IEmpresa) => {
    setEmpresa(empresaM);
    setShowModalForm(true);
  };

  const handleDeleteEmpresa = useCallback((empresaId?: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`empresa/${empresaId}`);
          setEmpresas((old) => [...old.filter((item) => item.id !== empresaId)]);
          toast.success('Empresa removida com sucesso');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao remover empresa');
        }
      }
    });
  }, []);

  return (
    <>
      <ModalForm
        showModal={showModalForm}
        onCloseModal={handleCloseModalForm}
        empresaM={empresa}
        onSetEmpresas={handleSetEmpresas}
      />

      <HeaderTitle
        onNewItem={() => setShowModalForm(true)}
        title="Cadastro de Empresas"
        text=""
      />

      <Row className="pb-5">
        {empresas.map((empresaM) => (
          <Col key={empresaM.id} md={4} className="mt-3">
            <ItemUser>
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <img
                    src={empresaM.imgLogo != null ? empresaM.imgLogo : 'https://randomuser.me/api/portraits/men/43.jpg'}
                    alt="Empresa"
                    width="50"
                    height="50"
                    className="rounded-circle"
                  />

                  <div className="ms-2">
                    <button
                      type="button"
                      className="text-start"
                      onClick={() => handleShowModalForm(empresaM)}
                    >
                      {empresaM.nome}

                    </button>
                    <p className="m-0 text-secondary">
                      (
                      {empresaM.nome ? empresaM.nome : ''}
                      )
                    </p>
                  </div>
                </div>
              </div>
              <Actions>
                <button type="button" onClick={() => handleShowModalForm(empresaM)}>
                  <AiOutlineEdit />
                </button>
                <button type="button" onClick={() => handleDeleteEmpresa(empresaM.id)}>
                  <BiTrash />
                </button>
              </Actions>
            </ItemUser>
          </Col>
        ))}
      </Row>
    </>
  );
};
