import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 550px;

  p {
    color: #616161;
    font-size: 0.9rem;
  }

  h5 {
    font-weight: bold;
  }

  form {
    width: 100%;
    padding: 0 3rem;

    a {
      text-align: center;
      color: ${({ theme }) => theme.colors.text};

      svg {
        margin-right: 0.3rem;
      }

      &:hover {
        color: ${({ theme }) => darken(0.2, theme.colors.text)};
      }
    }
  }
`;

export const Background = styled.div<{ url: string }>`
  flex: 1;
  background-image: url(${({ theme }) => theme.backgroundUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
