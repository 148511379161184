import { ErrorMessage, FieldProps } from 'formik';
import { RiErrorWarningFill } from 'react-icons/ri';
import Select, { Props, StylesConfig } from 'react-select';
import theme from '../../styles/theme';
import { Container, Error } from '../Input/styles';

interface ReactSelectProps {
  label: string;
  isRequerid?: boolean;
}

type MyOptionType = {
  label: string;
  value: string;
};

type IsMulti = false;

export function ReactSelect({
  options,
  field,
  form,
  isRequerid,
  isMulti,
  label,
  ...rest
}: Props & FieldProps & ReactSelectProps) {
  const selectStyle: StylesConfig<MyOptionType, IsMulti> = {
    control: (provided, state) => {
      let borderColor = 'rgba(1, 1, 1, 0.2)';

      if (state.isFocused) {
        borderColor = theme.colors.main;
      }

      return {
        ...provided,
        borderStyle: 'solid',
        borderWidth: '1.5px',
        borderColor,
        boxShadow: '0 transparent',
        outline: 'none',
        background: '#fff',
        minHeight: '2.6rem',
        borderRadius: '5px',
        '&:hover': {
          boxShadow: '0 transparent',
        },
      };
    },
  };
  return (
    <Container>
      {label && (
      <label htmlFor={field.name}>
        {isRequerid ? (
          <span>
            {label}
            {' '}
            <strong className="text-danger">*</strong>
          </span>
        ) : (
          <span>{label}</span>
        )}
      </label>
      )}
      <Select
        options={options}
        name={field.name}
        styles={selectStyle}
        isMulti={isMulti}
        {...rest}
        placeholder="Selecione"
        className="react-select-container"
        classNamePrefix="react-select"
        onChange={
          (option) => form.setFieldValue(field.name, option)
        }
        onBlur={field.onBlur}
        value={isMulti ? field.value : (options
          ? options.find((option) => option.value === field.value)
          : '') as any}
      />

      <ErrorMessage name={field.name}>
        {(msg) => (
          <Error className="d-flex align-items-center mt-1">
            <RiErrorWarningFill color="red" size={16} />
            <span>{msg}</span>
          </Error>
        )}
      </ErrorMessage>
    </Container>
  );
}
