import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiOutlineStar } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { useStore } from 'react-context-hook';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import theme from '../../../../styles/theme';
import { IIcone } from '../../../../interfaces/IIcone';
import api from '../../../../services/api';
import { IEmpresa } from '../../../../interfaces/IEmpresa';

export interface IModalFormIconesProps {
  children?: React.ReactNode;
  onSetIcone: (data: IIcone, update?: boolean) => void;
  onCloseModal: () => void;
  icone?: IIcone;
  showModal: boolean;
}

const dadosIniciais: IIcone = {
  id: 0,
  nome: '',
  link: '',
  empresaId: 0,
  isTarefa: false,
  isFase: false,
  ArquivoImagem: undefined,
};

function getIntOrDefault(v: any) {
  return (typeof v === 'number') ? v : 0;
}
function getStringOrDefault(v: any) {
  return (typeof v === 'string') ? v : '';
}

function getBoolOrDefault(v: any) {
  return (v) ? 'true' : 'false';
}

const schema = yup.object({
  nome: yup.string().required('O nome do icone deve ser informado!'),
});

export const ModalFormIcones = ({
  icone,
  onSetIcone,
  onCloseModal,
  showModal,
}: IModalFormIconesProps) => {
  const [loading, setLoading] = useState(false);
  const [isTarefaIcone, setIsTarefaIcone] = useState(true);
  const [isFaseIcone, setIsFaseIcone] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | any>();
  const [company] = useStore<IEmpresa>('company');

  useEffect(() => {
    if (icone) {
      if (icone.id === 0) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
      api.get(`/icone/porid/${icone.id}`).then(({ data }) => {
        setIsFaseIcone(data.data.isFase);
        setIsTarefaIcone(data.data.isTarefa);
      }).catch(() => toast.error('Erro ao buscar Icone'));
    }
  }, [icone, company?.id]);

  const getFormattedFormData = useCallback(async (dataForm: IIcone) => {
    const formData = new FormData();

    formData.append('nome', getStringOrDefault(dataForm.nome));
    formData.append('isTarefa', getBoolOrDefault(isTarefaIcone));
    formData.append('isFase', getBoolOrDefault(isFaseIcone));
    formData.append('ArquivoImagem', selectedFile);
    if (company?.id) {
      const companyId: string = (company.id.toString());
      formData.append('empresaId', companyId);
    }
    return formData;
  }, [selectedFile, isFaseIcone, isTarefaIcone, company?.id]);

  const handleSubmit = useCallback(
    async (dataForm: IIcone) => {
      const formData = await getFormattedFormData(dataForm);
      try {
        setLoading(true);
        if (icone) {
          formData.append('id', getIntOrDefault(icone.id).toString());
          const { data } = await api.put(`/icone/${icone.id}`, formData);
          onSetIcone({ ...data.data }, true);
        } else {
          const { data } = await api.post('/icone', formData).then((response) => response);
          onSetIcone({ ...data.data });
        }

        onCloseModal();
        toast.success('O icone foi adicionado com sucesso');
      } catch (err: any) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao adicionar o novo icone. Verifique o Tamanho e as Informacoes da Imagem');
      } finally {
        setLoading(false);
      }
    },
    [getFormattedFormData, onSetIcone, onCloseModal, icone],
  );

  const handleUploadImage = (file: any) => {
    setSelectedFile(file.target.files[0]);
  };

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title="Adicionar Icone"
      icon={AiOutlineStar}
    >
      {icone
        ? <h6>Digite o nome do Icone que deseja alterar</h6>
        : <h6>Digite o nome do Icone que deseja adicionar.</h6>}
      <Formik
        initialValues={icone || dadosIniciais}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="text" name="nome" label="Nome do Icone:" isRequerid />

          <div className="d-flex flex-column mb-3">
            <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
              O Icone será de Tarefa?
            </strong>
            <Switch
              onChange={() => {
                if (isFaseIcone) {
                  setIsFaseIcone((old) => !old);
                  setIsTarefaIcone((old) => !old);
                }
              }}
              checked={isTarefaIcone}
              height={22}
              width={53}
              name="isTarefa"
            />
          </div>
          <div className="d-flex flex-column mb-3">
            <strong style={{ fontSize: '0.8rem', color: '#333' }} className="mb-2">
              O Icone será de Fase?
            </strong>

            <Switch
              onChange={() => {
                if (isTarefaIcone) {
                  setIsFaseIcone((old) => !old);
                  setIsTarefaIcone((old) => !old);
                }
              }}
              checked={isFaseIcone}
              height={22}
              width={53}
              name="isFase"
            />
          </div>
          <p>
            Para subir um icone na nossa plataforma, clique em &quot;Choose
            File&quot; e selecione o arquivo que deseja enviar. O arquivo deve ter um tamanho
            máximo de 1MB!
          </p>
          <p>No momento só é permitido um icone para tarefa e um icone para fase.</p>
          <input
            onChange={handleUploadImage}
            type="file"
            name="ArquivoImagem"
          />

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
