/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { BsCheckAll } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ItemList } from '../../components/ItemList';
import { ModalFormChecklistCategory } from './ModalFormChecklistCategory';
import { IChecklistCategory } from '../../interfaces/IChecklistCategory';

const CATEGORY_URL = 'checklist-unidade/categorias/sistema';

export const SystemChecklistCategoryPage = () => {
  const { ids } = useParams<{ ids: string }>();
  const [showModalForm, setShowModalForm] = useState(false);
  const [
    checklistCategories, setChecklistCategories,
  ] = useState<IChecklistCategory[]>([]);
  const [selectedCategory, setSelectedCategory]
  = useState<any>(undefined);

  const isSubCategoria = (ids !== undefined);
  const categoriasIds = isSubCategoria ? ids.split('/') : [];
  const lastCategoriaId = isSubCategoria ? categoriasIds[categoriasIds.length - 1] : undefined;

  const builderHistoryTitle: () => JSX.Element[] = () => {
    const link = '/super-admin/checklist-categoria';
    const result : JSX.Element[] = [
      <Link to={link} key={link}>
        Categorias de Checklist
      </Link>,
    ];

    return result;
  };

  const getCategoriasChecklist = () => {
    api.get('/checklist-unidade/categorias/sistema')
      .then(({ data }) => {
        setChecklistCategories(data.data);
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de categorias');
      });
  };

  useEffect(() => {
    try {
      getCategoriasChecklist();
    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);

  useEffect(() => {
    getCategoriasChecklist();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids, isSubCategoria]);

  const handleFormEdit = (id: number) => {
    const selectedChecklistCategory = checklistCategories
      .find((c) => c.checklistUnidadeCategoriaId === id);

    setSelectedCategory({
      checklistUnidadeCategoriaId: selectedChecklistCategory?.checklistUnidadeCategoriaId,
      nome: selectedChecklistCategory?.nome,
      descricao: selectedChecklistCategory?.descricao,
      unidadesEmpresa: selectedChecklistCategory?.empresas.map((item) => ({
        label: item.nome,
        value: item.id,
      })),
    });
    setShowModalForm(true);
  };

  const handleFormClose = () => {
    setSelectedCategory(undefined);
    setShowModalForm(false);
    getCategoriasChecklist();
  };

  const handleNewItem = () => {
    setSelectedCategory(undefined);
    setShowModalForm(true);
  };

  const handleDelete = (id: number) => {
    api.delete(`${CATEGORY_URL}/${id}`)
      .then(() => {
        toast.success('Categoria de Checklist excluída com sucesso');
        getCategoriasChecklist();
      })
      .catch((error) => {
        if (String(error).includes('403')) return toast.error('Você não tem permissão para essa operação. Consulte seu RH.');
        return toast.error('Não foi possível excluir a categoria');
      });
  };

  const handleDeleteCategory = (id: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir essa categoria de checklist?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          handleDelete(id);
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao remover cetegoria de checklist');
        }
      }
    });
  };

  return (
    <>
      <ModalFormChecklistCategory
        onClose={handleFormClose}
        isOpen={showModalForm}
        checklistCategory={selectedCategory}
        categoriaPaiId={lastCategoriaId}
      />

      <HeaderTitle
        onNewItem={handleNewItem}
        title={builderHistoryTitle()}
        text="Nesta aba você deve cadastrar as Categorias de Checklist que as empresas terão disponíveis por padrão. Além disso, as empresas poderão criar suas próprias Categorias de Checklist. Clique para ver as subcategorias"
      />

      <Row>
        {checklistCategories.map((item) => (
          <Col key={item.checklistUnidadeCategoriaId} md={6} className="mt-3">
            <ItemList
              {...item}
              id={item?.checklistUnidadeCategoriaId || 0}
              icon={BsCheckAll}
              category="Categoria da Plataforma"
              title={item.nome}
              onClick={() => { /* no op */ }}
              onFormEdit={() => handleFormEdit(item.checklistUnidadeCategoriaId)}
              onDelete={() => handleDeleteCategory(item.checklistUnidadeCategoriaId)}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
