import { AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { HeaderTitle } from '../../components/HeaderTitle';
import { Input } from '../../components/Input';
import api from '../../services/api';
import { Container } from './styles';

const initialData = {
  UrlVideoTutorial: '',
};

const validationSchema = yup.object().shape({
  UrlVideoTutorial: yup.string()
    .url()
    .required('Campo obrigatório'),
});

export interface IConfig {
  key: string;
  value: string;
}

export interface IConfigResponse {
  succeeded: boolean;
  data: IConfig[];
  errors: [];
}

export const Configurations = () => {
  const [currentConfigs, setCurrentConfigs] = useState<IConfig[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchConfigs = () => {
    api.get('configuracao')
      .then((response: AxiosResponse) => {
        setCurrentConfigs(response.data.data);
      })
      .catch((error: any) => {
        toast.error('Erro ao buscar configurações');
        console.log('🚀 ~ file: index.tsx ~ line 37 ~ fetchConfigs ~ error', error);
      });
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  const handleSubmit = (formValues: {UrlVideoTutorial?: string}) => {
    setIsLoading(true);

    const formattedData = {
      Key: 'UrlVideoTutorial',
      Value: formValues.UrlVideoTutorial,
    };

    api.put('configuracao/key/UrlVideoTutorial', formattedData)
      .then(() => {
        toast.success('Salvo com sucesso');
        fetchConfigs();
      })
      .catch((error: any) => {
        console.log('🚀 ~ file: index.tsx ~ line 60 ~ handleSubmit ~ error', error);
        toast.error('Erro ao recuperar valor atual da configuração');
      })
      .finally(() => setIsLoading(false));
  };

  const getCurrentConfigValue = (key: string) => currentConfigs?.find(
    (e: IConfig) => e.key === key,
  )?.value;

  return (
    <Container>

      <HeaderTitle
        title="Configurações"
        text="Estas configurações são aplicadas a todas as empresas"
      />
      <Formik
        initialValues={
          { UrlVideoTutorial: getCurrentConfigValue('UrlVideoTutorial') }
          || initialData
        }
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        { ({ values }) => (
          <Form>
            <Row className="mt-3">
              <Col xs={12}>
                <Input type="text" name="UrlVideoTutorial" label="URL do Tutorial em Vídeo" />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 4 }}>
                <Button
                  background="transparent"
                  color="#fff"
                  loading={isLoading}
                  className="text-uppercase"
                  type="submit"
                  disabled={getCurrentConfigValue('UrlVideoTutorial') === values.UrlVideoTutorial}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}

      </Formik>
    </Container>
  );
};
