/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { HeaderTitle } from '../../../components/HeaderTitle';
import { Button } from '../../../components/Button';
import api from '../../../services/api';
import { Container } from './styles';
import { FiltroRelatorio } from '../components/filtro';
import { TIPO_FILTRO } from '../constants';
import { ISelect } from '../../../interfaces/ISelect';
import { SimpleSelect } from '../../../components/SimpleSelect';
import {
  downloadExcelFromResponseBinary,
  getDataCorrentePtBr,
} from '../../../utils/shared-functions';

export const convertToOptionsArray = (item: any) => ({
  label: item.nome,
  value: item.id,
});

export const convertToOptionsArrayQuizzes = (item: any) => ({
  label: item.pergunta,
  value: item.id,
});

export const convertToOptionsArrayQuestionario = (item: any) => ({
  value: item.id,
  label: item.titulo,
});
export const RelatorioQuestionarioPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relatorioState, setRelatorioState] = useState<any>({});

  const [optionsCategoriaQuestionario, setOptionsCategoriaQuestionario] = useState<ISelect[]>([]);
  const [optionsQuestionarios, setoptionsQuestionarios] = useState<ISelect[]>([]);
  const [optionsPergunta, setOptionsPergunta] = useState<ISelect[]>([]);

  const [filteredCategoriaQuestionario, setfilteredCategoriaQuestionario] = useState<ISelect>();
  const [filteredQuestionario, setfilteredQuestionario] = useState<any>();
  const [filteredPergunta, setfilteredPergunta] = useState<any>();

  const cleanAfterQuestionarioChange = () => {
    setOptionsPergunta([]);
    setfilteredPergunta(undefined);
  };

  const cleanAfterCategoriaQuestionarioChange = () => {
    setoptionsQuestionarios([]);
    setfilteredQuestionario(undefined);

    cleanAfterQuestionarioChange();
  };

  const fetchCategoriaQuestionario = () => {
    api
      .get('/questionario-empresa/categoria/hierarchy/father')
      .then((response: any) => {
        setOptionsCategoriaQuestionario(response?.data?.data?.map(convertToOptionsArray));
      })
      .catch(() => {
        toast.error('Erro ao recuperar Questionários da sua empresa');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchCategoriaQuestionario();
  }, []);

  const fetchQuestionario = (questionarioId: string) => {
    setoptionsQuestionarios([]);

    api
      .get(`questionario-empresa/${questionarioId}`)
      .then((response: any) => {
        setoptionsQuestionarios(response?.data?.data?.map(convertToOptionsArrayQuestionario));
      })
      .catch(() => {
        toast.error('Erro ao recuperar  de Cursos');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchPerguntas = (perguntaId: string) => {
    setOptionsPergunta([]);

    api
      .get(`questionario-empresa/questionario/${perguntaId}`)
      .then((response: any) => {
        setOptionsPergunta(response?.data?.data?.perguntas.map(convertToOptionsArrayQuestionario));
      })
      .catch(() => {
        toast.error('Erro ao recuperar Fases');
      })
      .finally(() => setIsLoading(false));
  };

  const handleCategoriaQuestionarioChange = (e: any) => {
    cleanAfterCategoriaQuestionarioChange();

    const questionarioId = parseInt(e?.target?.value, 10);
    const option = optionsCategoriaQuestionario?.find((op: any) => op.value === questionarioId);

    if (option) {
      setfilteredCategoriaQuestionario(option);
      fetchQuestionario(option.value);
    }
  };

  const handleQuestionarioChange = (e: any) => {
    cleanAfterQuestionarioChange();

    const cursoId = parseInt(e?.target?.value, 10);
    const option = optionsQuestionarios?.find((op: any) => op.value === cursoId);

    if (option) {
      setfilteredQuestionario(option);
      fetchPerguntas(option.value);
    }
  };

  const handleOnPerguntaChange = (e: any) => {
    const perguntaId = parseInt(e?.target?.value, 10);
    const option = optionsPergunta?.find((op: any) => op.value === perguntaId);
    if (option) {
      setfilteredPergunta(option);
    }
  };

  const loadFiltrosFromRelatorioState = (filtersObj: any) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in relatorioState) {
      if (Object.prototype.hasOwnProperty.call(relatorioState, prop)) {
        // && relatorioState[prop])
        if (relatorioState[prop]?.length > 0) {
          filtersObj[prop] = relatorioState[prop];
        }
      }
    }
  };

  const loadFiltrosEspecificos = (filtersObj: any) => {
    if (filteredCategoriaQuestionario) {
      filtersObj.categoriaQuestionario = filteredCategoriaQuestionario?.value;
    }
    if (filteredQuestionario) {
      filtersObj.questionario = filteredQuestionario?.value;
    }

    if (filteredPergunta) {
      filtersObj.pergunta = filteredPergunta?.value;
    }
  };

  const getFiltros = () => {
    const filtersObj = {};

    loadFiltrosFromRelatorioState(filtersObj);
    loadFiltrosEspecificos(filtersObj);

    return new URLSearchParams(filtersObj);
  };

  const handleOnGerarRelatorioClick = () => {
    api({
      url: `${process.env.REACT_APP_ENDPOINT}/relatorios/relatoriotrilhausuariolms?${getFiltros()}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      downloadExcelFromResponseBinary(
        response.data,
        `Relatorio_De_Questionarios-${getDataCorrentePtBr()}.xlsx`,
      );
    });
  };

  return (
    <Container>
      <HeaderTitle
        title="Relatório de Questionários"
        text="Filtre de acordo com seu objetivo e baixe uma planilha com os resultados instantaneamente."
      />

      <Row>
        <Col xs={12} md={6}>
          <SimpleSelect
            label="Categorias de Questionários"
            name="categoriaQuestionarios"
            options={optionsCategoriaQuestionario}
            defaultValue={filteredCategoriaQuestionario}
            value={filteredCategoriaQuestionario?.value}
            onChange={handleCategoriaQuestionarioChange}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Questionário"
            name="questionario"
            options={optionsQuestionarios}
            value={filteredQuestionario?.value}
            onChange={handleQuestionarioChange}
            disabled={!filteredCategoriaQuestionario}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Pergunta"
            name="fase"
            options={optionsPergunta}
            value={filteredPergunta?.value}
            onChange={handleOnPerguntaChange}
            disabled={!filteredQuestionario}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="unidades"
            label="Unidades"
            endpointUrl="unidade-empresa"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="cargo"
            label="Cargos"
            endpointUrl="cargo"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="usuario"
            label="Usuários"
            endpointUrl="usuario"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 4, offset: 4 }}>
          <Button
            background="transparent"
            color="#fff"
            loading={isLoading}
            className="text-uppercase"
            type="submit"
            onClick={handleOnGerarRelatorioClick}
          >
            Gerar Relatório
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
