import { darken, lighten } from 'polished';
import styled from 'styled-components';

export const Header = styled.div`
  background: ${({ theme }) => lighten(0.2, theme.colors.main)};
  padding: 1rem;
  border-radius: 8px;
  color: #fff;

  img {
    height: 40px;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
    color: ${darken(0.2, '#fff')};
  }

  svg {
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    .col-spacing {
      margin-top: 1rem;
    }
  }
`;
