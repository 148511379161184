import { darken, lighten } from 'polished';

const defaultTheme = {
  colors: {
    main: '#003B82',
    success: '#4e9c19',
    secondary: `#${process.env.REACT_APP_COLOR_SECONDARY_THEME}`,
    text: '#616161',
    lightPrimary: '#fff',
    lightSecondary: darken(0.25, '#fff'),
    textPrimary: lighten(0.2, '#000'),
    textSecondary: lighten(0.4, '#000'),
  },
};

export default defaultTheme;
