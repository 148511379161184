import styled from 'styled-components';

export const Container = styled.div<{isSelected: boolean}>`
  color: ${({ theme }) => theme.colors.lightPrimary};
  display: flex;
  flex-direction: column;
  background: ${({ isSelected }) => (isSelected && '#858585') || '#606060'};
  border-radius: 8px;
  width: 180px;
  height: 50px;
  padding: 4px 9px 8px 9px;
  gap: 4px;
`;

export const Title = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  font-weight: 500;
  font-size: 14px;
`;

export const Date = styled.div`
  flex: 1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  font-weight: 300;
  font-size: 12px;
`;
