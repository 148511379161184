/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Container, StyledCard, StyledButton, StyledP } from './styles';
import api from '../../../../services/api';

interface ChecklistCardProps {
  url: string;
  modePreview?: boolean;
}

function ChecklistCard({ url, modePreview = false }: ChecklistCardProps) {
  const history = useHistory();

  const [checklist, setChecklist] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(url)
      .then(({ data }) => {
        setChecklist(data.data);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de Auditores');
        setLoading(false);
      });
  }, [url]);

  const handleOnClick = (id: number) => {
    history.push({
      pathname: `/validacao/${id}`,
      state: { modePreview },
    });
  };

  const handleFormatDate = (date: any) => {
    const data = moment(date).format('DD/MM/YYYY');
    return data;
  };

  return (
    <>
      {loading
        ? <p>Carregando...</p>
        : (
          <>
            {checklist?.map((item: any) => (
              <StyledCard>
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>
                    <Container>
                      <div>
                        <StyledP>Unidade: {item?.checklistUnidade?.titulo}</StyledP>
                        <StyledP>Gestor Respondente: {item.gestorResponsavel?.nome}</StyledP>
                        <StyledP>Data de realização: {handleFormatDate(item.dataEnvioChecklist)}</StyledP>
                      </div>
                      <StyledButton onClick={() => handleOnClick(item.id)}>{modePreview ? 'Visualizar' : 'Verificar'}</StyledButton>
                    </Container>
                  </Card.Text>
                </Card.Body>
              </StyledCard>
            ))}
          </>
        )
      }
    </>
  );
}

export default ChecklistCard;
