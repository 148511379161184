/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Redirect, Route as ReactDOMRoute, RouteProps as ReactDOMRouteProps } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType | any;
  layout?: React.ComponentType;
  roles?: Array<'Sistema' | 'Administrador' | 'USER' | 'Gestor'>;
}
const BlankLayout: React.FC = ({ children }) => <>{children}</>;

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  roles,
  layout: Layout = BlankLayout,
  ...rest
}) => {
  const { user, signOut } = useAuth();
  React.useEffect(() => {
    const pathDomain = window.location.pathname.split('/')[1];

    const previousDomain = localStorage.getItem('adminDomain');

    if (!!previousDomain && previousDomain !== pathDomain) {
      signOut();
      localStorage.removeItem('adminDomain');
    }
    localStorage.setItem('adminDomain', pathDomain);
  }, [signOut]);

  return (
    <ReactDOMRoute
      {...rest}
      render={(props) => {
        if (!user && isPrivate) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }

        if (
          (user && !isPrivate)
        ) {
          return (
            <Redirect
              to={{
                pathname: '/inicio',
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default Route;
