import styled from 'styled-components';
import Table from 'react-bootstrap/Table';

interface TableRowProps {
  isEven: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const TableRow = styled(Table)<TableRowProps>`
  background-color: ${({ isEven }) => (isEven ? '#f2f2f2' : '#ffffff')};
`;

export const CustomDiv = styled.div`
  width: 200px;
  height: 100px;
  word-wrap: break-word;
  overflow: overlay;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
