import { useEffect, useState, useRef } from 'react';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import iconUser from '../../../assets/icons/user.svg';
import { useAuth } from '../../../hooks/auth';
import { Container, Content, ProfileData } from './styles';

export const Profile = () => {
  const notificationRef = useRef<HTMLDivElement>(null);
  const { signOut, user } = useAuth();
  const [visible, setVisible] = useState(false);

  const handleSetVisible = () => {
    setVisible((old) => !old);
  };

  useEffect(() => {
    function handleClickOverlay(event: MouseEvent): void {
      if (
        notificationRef.current
        && event.target instanceof Node
        && !notificationRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    }

    if (visible) {
      document.addEventListener('mouseup', handleClickOverlay);
    }
  }, [visible]);

  return (
    <Container ref={notificationRef} className="d-flex align-items-center border-dotted-left">
      <button type="button" className="bg-transparent border-0" onClick={handleSetVisible}>
        <img
          src={iconUser}
          alt={user.unique_name}
          width="40"
          height="40"
          className="border rounded-circle"
        />
      </button>

      {visible && (
        <Content>
          <div className="p-3">
            <ProfileData>
              <h5 className="m-0">{user.unique_name}</h5>
              <p className="m-0">{user.email[0]}</p>
            </ProfileData>
          </div>
          <button type="button" onClick={signOut} className="d-flex align-items-center">
            <RiLogoutCircleRLine size={20} />
            Sair
          </button>
        </Content>
      )}

    </Container>
  );
};
