import styled from 'styled-components';

export const ImagePreview = styled.img`
    max-height: 90px;
    margin-left: 5%;
`;
export const CloseCircleIcon = styled.span`
    cursor: pointer;
    width: 23px;
    height: 23px;
    background: #C20000;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: white;
    position: relative;
    left: -10px;
    top: -10px;
    border: 1px solid white;
    font-size: 13px;
`;
