import { ChatItemData } from '../../iara.interfaces';
import { Container, Date, Title } from './history-chat-item.styled';

export const HistoryChatItem = (props: ChatItemData) => {
  const { chatId, title, data, isSelected, onClick } = props;

  return (
    <Container
      isSelected={isSelected}
      onClick={() => {
        if (onClick) {
          onClick(chatId);
        }
      }}
    >
      <Title>{title}</Title>
      <Date>{data}</Date>
    </Container>
  );
};
