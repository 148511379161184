import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const StyledEditorContainer = styled.div`
  .ql-container {
    min-height: 100px; /* Set minimum height for the entire editor */
    max-height: 250px;
  }

  .ql-editor {
    min-height: 100px; /* Set minimum height for the editable area */
    max-height: 250px;
  }
`;

interface HtmlEditorProps {
  placeholder?: string;
  value?: string;
  onChange: (content: string) => void;
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({
  placeholder, value, onChange,
}) => {
  const [editorValue, setEditorValue] = useState<string>(value || '');

  const handleEditorChange = (content: string) => {
    setEditorValue(content);
    onChange(content);
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['link'],
      ['clean'],
    ],
  };

  return (
    <StyledEditorContainer>
      <ReactQuill
        value={editorValue}
        onChange={handleEditorChange}
        placeholder={placeholder}
        theme="snow"
        modules={modules}
      />
    </StyledEditorContainer>
  );
};

export default HtmlEditor;
