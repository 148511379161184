import { format } from 'date-fns';

export const convertToPtBrDate = (utcDate: string): string => {
  try {
    return format(new Date(utcDate), 'dd/MM/yyyy');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return '';
  }
};

export const convertToPtBrDateTime = (utcDate: string): string => {
  try {
    return format(new Date(utcDate), 'dd/MM/yyyy kk:mm:ss');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return '';
  }
};

export type ISelectOption = {
  value: string,
  label: string,
}

export const EMPTY_OPTION = { label: '', value: '' };

export const STATUS_CURSO = [
  {
    value: 'todos',
    label: 'Todos',
  },
  {
    value: 'ativos',
    label: 'Ativos',
  },
  {
    value: 'editando',
    label: 'Em edição (ativos + inativos)',
  },
  {
    value: 'inativos',
    label: 'Inativos',
  },
];

export const UFS_SELECT_OPTIONS = [
  {
    value: 'AC',
    label: 'AC',
  },
  {
    value: 'AL',
    label: 'AL',
  },
  {
    value: 'AP',
    label: 'AP',
  },
  {
    value: 'AM',
    label: 'AM',
  },
  {
    value: 'BA',
    label: 'BA',
  },
  {
    value: 'CE',
    label: 'CE',
  },
  {
    value: 'DF',
    label: 'DF',
  },
  {
    value: 'ES',
    label: 'ES',
  },
  {
    value: 'GO',
    label: 'GO',
  },
  {
    value: 'MA',
    label: 'MA',
  },
  {
    value: 'MT',
    label: 'MT',
  },
  {
    value: 'MS',
    label: 'MS',
  },
  {
    value: 'MG',
    label: 'MG',
  },
  {
    value: 'PA',
    label: 'PA',
  },
  {
    value: 'PB',
    label: 'PB',
  },
  {
    value: 'PR',
    label: 'PR',
  },
  {
    value: 'PE',
    label: 'PE',
  },
  {
    value: 'PI',
    label: 'PI',
  },
  {
    value: 'RJ',
    label: 'RJ',
  },
  {
    value: 'RN',
    label: 'RN',
  },
  {
    value: 'RS',
    label: 'RS',
  },
  {
    value: 'RO',
    label: 'RO',
  },
  {
    value: 'RR',
    label: 'RR',
  },
  {
    value: 'SC',
    label: 'SC',
  },
  {
    value: 'SP',
    label: 'SP',
  },
  {
    value: 'SE',
    label: 'SE',
  },
  {
    value: 'TO',
    label: 'TO',
  },
];

export const OPTIONS_TIPOS_RECORRENCIA = [
  {
    label: 'Escolher opção',
    value: 0,
  },
  {
    label: 'Diária',
    value: 1,
  },
  {
    label: 'Semanal',
    value: 2,
  },
  {
    label: 'Quinzenal',
    value: 3,
  },
  {
    label: 'Mensal',
    value: 4,
  },
  {
    label: 'Semestral',
    value: 5,
  },
  {
    label: 'Anual',
    value: 6,
  },
];

export const DAYS_OF_WEEK = [
  {
    label: 'Domingo',
    value: 0,
  },
  {
    label: 'Segunda-feira',
    value: 1,
  },
  {
    label: 'Terça-feira',
    value: 2,
  },
  {
    label: 'Quarta-feira',
    value: 3,
  },
  {
    label: 'Quinta-feira',
    value: 4,
  },
  {
    label: 'Sexta-feira',
    value: 5,
  },
  {
    label: 'Sábado',
    value: 6,
  },
];

const DOMINIO_DUMMY_USUARIO_SEM_EMAIL = '@usuario.sem.email.entgames';
export const getCpfDummyEmail = (cpf: string) => `${cpf}${DOMINIO_DUMMY_USUARIO_SEM_EMAIL}`;

export const unformatCpf = (cpf: string) => cpf?.replace(/[-.]/g, '');
export const unformatPhone = (celphone: string) => celphone?.replace(/\D/g, '');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const downloadExcelFromResponseBinary = (responseBin: any, fileName = 'Relatorio.xlsx') => {
  const url = window.URL.createObjectURL(new Blob([responseBin]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // or any other extension
  document.body.appendChild(link);
  link.click();
};

export const getDataCorrentePtBr = () => format(new Date(), 'dd/MM/yyyy kk:mm:ss');
