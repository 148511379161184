import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 5px;
  h3 {
    font-size: 20px;
    cursor: pointer;
  }
`;
