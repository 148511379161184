/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { SiCircle } from 'react-icons/si';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ItemList } from '../../components/ItemList';
import { ICurso } from '../../interfaces/ICurso';
import api from '../../services/api';
import { ModalFormCurso } from './components/ModalFormCurso';
import { ICourseCategory } from '../../interfaces/ICourseCategory';
import { BarraDeFiltros } from '../../components/BarraDeFiltros';
import { STATUS_CURSO } from '../../utils/shared-functions';
import { FlexRow } from '../Checklist-Validation/Kanbam/FormValidation/styles';

export const Trilhas = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const history = useHistory();
  const [showModalForm, setShowModalForm] = useState(false);
  // const [cursos, setCursos] = useState<ICurso[]>([]);
  const [cursoSelecionado, setCursoSelecionado] = useState<ICurso>();
  // const [categoria, setCategoria] = useState<ICourseCategory>();
  const [filtroStatus, setFiltroStatus] = useState<string>(STATUS_CURSO[0].value);

  const { data: cursos, refetch: refetchCursos, isLoading } = useQuery(['cursos-por-categoria', categoryId], async () => {
    try {
      const response = await api.get(`curso/por-categoria/${categoryId}`);
      return response?.data?.data;
    } catch (err: any) {
      toast.error('Erro ao buscar cursos');
      return null;
    }
  });

  const { data: categoria } = useQuery(['categorias', categoryId], async () => {
    try {
      const response = await api.get(`curso/categoria/${categoryId}`);
      return response?.data?.data;
    } catch (err: any) {
      toast.error('Erro ao buscar infos da categoria');
      return null;
    }
  });

  const handleDeleteCurso = useCallback((cursoId: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`curso/${cursoId}`);
          refetchCursos();
          toast.success('O curso foi removido');
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            return;
          }
          toast.error('Erro ao excluir curso');
        }
      }
    });
  }, []);

  // const handleSetCourse = useCallback((data: ICurso, update?: boolean) => {
  //   if (update) {
  //     setCursos((old) => [...old].map((object) => {
  //       if (object.id === data.id) {
  //         return data;
  //       }
  //       return object;
  //     }));
  //   } else {
  //     setCursos((old) => [...old, data]);
  //   }
  // }, []);

  const handleCloseModalFormCurso = () => {
    setShowModalForm(false);
    setCursoSelecionado(undefined);
  };

  const handleSetFormEdit = useCallback((cursoId) => {
    const findCurso = cursos.find((item: any) => item.id === cursoId);
    setCursoSelecionado(findCurso);
    setShowModalForm(true);
  }, [cursos]);

  const headerTitle = categoria?.id === 1 ? 'Cursos Sem Categoria' : `Categorias > ${categoria?.nome} > Cursos`;

  const handleStatusCursoChange = (value: any) => {
    setFiltroStatus(value);
  };

  return (
    <>
      <ModalFormCurso
        onSetCurso={refetchCursos}
        idCurso={cursoSelecionado?.id}
        onCloseModal={handleCloseModalFormCurso}
        showModalFormCurso={showModalForm}
        categoryId={parseInt(categoryId, 10)}
      />

      <HeaderTitle
        onNewItem={() => history.push(`/categories/${categoryId}/cursos/novo`)}
        title={categoria ? headerTitle : 'Cursos'}
        text=" Este é seu ambiente de criação dos cursos que aparecerão sob a forma de jogos para seus usuários.Clique em adicionar para transformar seu curso em um jogo ou gerencie abaixo os que já foram criados."
      />

      <Row className="mt-2">
        <Col xs={12}>
          <BarraDeFiltros handleStatusCursoChange={handleStatusCursoChange} />
        </Col>
      </Row>

      <Row>
        {cursos?.length > 0 && !isLoading ? (
          <>
            {cursos?.map((item: any) => {
              if (filtroStatus === 'todos'
                || (filtroStatus === 'ativos' && item.ativo)
                || (filtroStatus === 'inativos' && item.ativo === false)
                || (filtroStatus === 'editando' && !item.cadastroFinalizado)) {
                return (
                  <Col key={item.id} lg={4} md={4} sm={4} className="mt-3">
                    <ItemList
                      {...item}
                      icon={SiCircle}
                      category="Curso"
                      link={`/categories/${categoryId}/cursos/${item.id}`}
                      onFormEdit={() => history.push(`/categories/${categoryId}/cursos/${item.id}`)}
                      onDelete={() => handleDeleteCurso(item.id)}
                      seeStatus
                      status={`${item.ativo ? 'Ativo' : 'Inativo'} ${item.cadastroFinalizado ? '' : '(Em edição)'}`}
                    />
                  </Col>
                );
              }

              return null;
            })}
          </>
        ) : (
          <>
            {isLoading ? (
              <FlexRow style={{ marginLeft: '46%', alignItems: 'center', width: '70vw', height: '50vh' }}>
                <Spinner animation="grow" />
              </FlexRow>
            ) : (
              <p style={{ marginLeft: '37%', marginTop: '2rem' }}>Nenhum curso encontrado.</p>
            )}
          </>
        )}
      </Row>
    </>
  );
};
