/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik, Form, Field } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { FaRegIdBadge } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ModalComponent } from '../../../components/Modal';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { ReactSelect } from '../../../components/ReactSelect';
import { Usuarios } from '../../../interfaces/IUsuario';
import { downloadExcelFromResponseBinary, getDataCorrentePtBr } from '../../../utils/shared-functions';
import theme from '../../../styles/theme';

interface ModalExportProps {
  empresaId: number;
  onCloseModal: () => void;
  showModal: boolean;
}

const dataInitial = {
  usuario: '',
};

export const ModalExport = ({ onCloseModal, showModal, empresaId }: ModalExportProps) => {
  const schema = yup.object({
    usuario: yup.lazy((value) => (typeof value === 'object'
      ? yup.object().nullable().required('Campo obrigatório')
      : yup.string().required('Campo obrigatório'))),
  });

  const usuarios = Array.from(Usuarios.entries(), ([key, value]) => ({
    label: value,
    value: key,
  }));

  const handleOnGerarPlanilhaClick = (value?: string) => {
    api({
      url: `${
        process.env.REACT_APP_ENDPOINT
      }/usuario/exportall/${empresaId}${value}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      toast.success('Planilha de usuários exportada com êxito');
      downloadExcelFromResponseBinary(
        response.data,
        `planilha-de-usuarios-${getDataCorrentePtBr()}.xlsx`,
      );
    }).catch((err) => toast.error('Erro ao exportar planilha de usuários'));
  };

  const handleSubmit = (formValues: any) => {
    if (formValues.usuario.value === null) return handleOnGerarPlanilhaClick('');
    return handleOnGerarPlanilhaClick(`?ativos=${formValues.usuario.value}`);
  };

  const internalOnClose = () => {
    onCloseModal();
  };

  return (
    <ModalComponent
      onClose={internalOnClose}
      modalVisible={showModal}
      title="Exportar usuários"
      icon={FaRegIdBadge}
      size="lg"
      closeModal
    >
      <div className="orientation-area">
        <p>
          Escolha a planilha de usuários que deseja exportar:
        </p>
        <Formik
          initialValues={dataInitial}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          <Form>
            <Col md={6}>
              <Field
                name="usuario"
                isRequerid
                label="Usuarios"
                component={ReactSelect}
                options={usuarios}
              />
            </Col>
            <Row className="mt-4">
              <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  type="submit"
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Form>
        </Formik>
      </div>
    </ModalComponent>
  );
};
