/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Form, Formik, Field } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { BiTask } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ModalComponent } from '../../../components/Modal';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import api from '../../../services/api';
import { IChecklistCategory } from '../../../interfaces/IChecklistCategory';
import { ReactSelect } from '../../../components/ReactSelect';
import { CheckAndUncheckBtn } from '../../Users/styles';

interface ModalFormChecklistCategoryProps {
  onClose: () => void;
  isOpen: boolean;
  checklistCategory?: IChecklistCategory;
  categoriaPaiId?: string;
}

const defaultValues: IChecklistCategory = {
  checklistUnidadeCategoriaId: 0,
  nome: '',
  descricao: '',
  empresas: [],
};

interface IEmpresaAtiva {
  id: string | number;
  value: number;
  label: string;
}

export const ModalFormChecklistCategory = ({
  isOpen,
  onClose,
  checklistCategory,
  categoriaPaiId,
}: ModalFormChecklistCategoryProps) => {
  const CHECKLIST_CATEGORY_ENDPOINT_BASE_URL = 'checklist-unidade/categorias/sistema';
  const EMPRESAS_ATIVAS_URL = 'empresa/ativas';
  const [empresasAtivas, setEmpresasAtivas] = useState<IEmpresaAtiva[]>([]);
  const [loading, setLoading] = useState(false);
  const schema = yup.object({
    nome: yup.string().required('O nome é obrigatório'),
    descricao: yup.string().required('A descrição é obrigatória'),
    empresas: yup.array(),
  });

  const getEmpresaIds = (item: any) => item.value;

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    api.get(EMPRESAS_ATIVAS_URL).then((data) => {
      setEmpresasAtivas(
        data.data.data.map((item: { id: number; nome: string; }) => ({
          label: item.nome,
          value: item.id,
        })),
      );
    })
      .catch((error) => {
        console.log('🚀 ~ file: index.tsx:87 ~ catch', error);
        toast.error(error?.response?.data?.errors[0] || 'Ocorreu um erro ao resgatar lista de empresas ativas');
      });
  }, []);

  const handleSubmit = async (values: any) => {
    let method;
    let url = '';
    let operation = '';

    setLoading(true);

    if (checklistCategory) {
      method = api.put;
      url = `${CHECKLIST_CATEGORY_ENDPOINT_BASE_URL}/${checklistCategory.checklistUnidadeCategoriaId}`;
      operation = 'editado';
    } else {
      method = api.post;
      url = CHECKLIST_CATEGORY_ENDPOINT_BASE_URL;
      operation = 'criado';
    }
    const empresaIds = values.unidadesEmpresa.map(getEmpresaIds);
    const requestData = {
      ...values,
      empresaIds,
    };
    method(
      url,
      requestData,
    )
      .then(() => {
        toast.success(`Categoria de Checklist ${operation} com sucesso`);
        console.log('🚀 ~ file: index.tsx:110 ~ .then ~ then');
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        console.log('🚀 ~ file: index.tsx:113 ~ catch', error);
        toast.error(error?.response?.data?.errors[0] || 'Ocorreu um erro ao salvar a categoria');
        setLoading(false);
      })
      .finally(() => {
        console.log('🚀 ~ file: index.tsx:122 ~ .finally ~ finally');
        setLoading(false);
      });
  };

  return (
    <ModalComponent
      onClose={handleOnClose}
      modalVisible={isOpen}
      title={checklistCategory ? 'Editar Categoria' : 'Incluir Categoria'}
      icon={BiTask}
      size="lg"
    >
      <Formik
        initialValues={checklistCategory || defaultValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ setFieldValue }) => (
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Input type="text" name="nome" label="Nome" isRequerid />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Input type="textarea" name="descricao" label="Descrição" isRequerid />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12} md={12}>
                <Col md={6}>
                  <Field
                    name="unidadesEmpresa"
                    label="Empresa (pode ser mais de 1)"
                    isMulti
                    component={ReactSelect}
                    options={empresasAtivas}
                  />
                  <CheckAndUncheckBtn
                    href="#"
                    onClick={() => setFieldValue('unidadesEmpresa', empresasAtivas, false)}
                  >
                    Marcar Todos
                  </CheckAndUncheckBtn>
                  <CheckAndUncheckBtn
                    href="#"
                    onClick={() => setFieldValue('unidadesEmpresa', [], false)}
                  >
                    Desmarcar Todos
                  </CheckAndUncheckBtn>
                </Col>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  type="submit"
                  loading={loading}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ModalComponent>
  );
};
