import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px; 
  align-items: center; 
  width: 100%; 
  text-align: center;

  input {
    width: 24px;
    height: 24px;
    background-color: green;
    color: yellow;
  }
`;
