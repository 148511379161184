import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiCircle } from 'react-icons/si';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ICurso } from '../../interfaces/ICurso';
import api from '../../services/api';
import { ViewOnlyListItem } from '../../components/ViewOnlyListItem';

const getTitle: () => JSX.Element[] = () => {
  const result : JSX.Element[] = [
    <Link to="../">
      Conteúdos
    </Link>,
  ];

  result.push(<span>{' > Instalados'}</span>);

  return result;
};

export const ListaSimples = () => {
  const [cursos, setCursos] = useState<ICurso[]>([]);

  useEffect(() => {
    api
      .get('curso/empresa/instalados')
      .then(({ data }) => setCursos(data.data))
      .catch(() => toast.error('Erro ao buscar cursos instalados'));
  }, []);

  return (
    <>

      <HeaderTitle
        title={getTitle()}
        text=" Este é seu ambiente de criação dos cursos que aparecerão sob a forma de jogos para seus usuários.Clique em adicionar para transformar seu curso em um jogo ou gerencie abaixo os que já foram criados."
      />

      <Row>
        {cursos.map((item) => (
          <Col key={item.id} lg={4} md={4} sm={4} className="mt-3">
            <ViewOnlyListItem
              title={item.nome}
              descricao={`criadora: ${item.nomeEmpresaPai}`}
              icon={SiCircle}
              status={!item.cadastroFinalizado ? 'Em edição' : item.ativo ? 'Ativo' : 'Inativo'}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
