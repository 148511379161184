/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { HeaderTitle } from '../../../components/HeaderTitle';
import { Button } from '../../../components/Button';
import api from '../../../services/api';
import { Container } from './styles';
import { FiltroRelatorio } from '../components/filtro';
import { TIPO_FILTRO } from '../constants';
import { ISelect } from '../../../interfaces/ISelect';
import { SimpleSelect } from '../../../components/SimpleSelect';
import {
  downloadExcelFromResponseBinary,
  getDataCorrentePtBr,
} from '../../../utils/shared-functions';

export const convertToOptionsArray = (item: any) => ({
  label: item.nome,
  value: item.id,
});

export const convertToOptionsArrayQuizzes = (item: any) => ({
  label: item.pergunta,
  value: item.id,
});

const Anos: ISelect[] = [
  {
    label: 'Menos de 1 ano',
    value: '0',
  },
  {
    label: '1 ano',
    value: '1',
  },
  {
    label: '2 anos',
    value: '2',
  },
  {
    label: '3 anos',
    value: '3',
  },
  {
    label: '4 anos',
    value: '4',
  },
  {
    label: '5 anos',
    value: '5',
  },
  {
    label: 'Mais de 5 anos',
    value: '6',
  },
];

export const RelatorioCursoPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relatorioState, setRelatorioState] = useState<any>({});

  const [optionsCategCurso, setOptionsCategCurso] = useState<ISelect[]>([]);
  const [optionsCurso, setOptionsCurso] = useState<ISelect[]>([]);
  const [optionsFase, setOptionsFase] = useState<ISelect[]>([]);
  const [optionsTarefa, setOptionsTarefa] = useState<ISelect[]>();
  const [optionsQuizzes, setOptionsQuizzes] = useState<ISelect[]>();

  const [filteredCategoriaCurso, setFilteredCategoriaCurso] = useState<ISelect>();
  const [filteredCurso, setFilteredCurso] = useState<any>();
  const [filteredFase, setFilteredFase] = useState<any>();
  const [filteredTarefa, setFilteredTarefa] = useState<any>();
  const [filteredQuizz, setFilteredQuizz] = useState<any>();
  const [anoSelecionado, setanoSelecionado] = useState<any>();

  const cleanAfterTarefaChange = () => {
    setOptionsQuizzes([]);
    setFilteredQuizz(undefined);
  };

  const cleanAfterFaseChange = () => {
    setOptionsTarefa([]);
    setFilteredTarefa(undefined);

    cleanAfterTarefaChange();
  };

  const cleanAfterCursoChange = () => {
    setOptionsFase([]);
    setFilteredFase(undefined);

    cleanAfterFaseChange();
  };

  const cleanAfterCategCursoChange = () => {
    setOptionsCurso([]);
    setFilteredCurso(undefined);

    cleanAfterCursoChange();
  };

  const fetchCategCursos = () => {
    api
      .get('curso/categoria')
      .then((response: any) => {
        setOptionsCategCurso(response?.data?.data?.map(convertToOptionsArray));
      })
      .catch(() => {
        toast.error('Erro ao recuperar Categ. de Cursos');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchCategCursos();
  }, []);

  const fetchCursos = (categCursoId: string) => {
    setOptionsCurso([]);

    api
      .get(`curso/por-categoria/${categCursoId}`)
      .then((response: any) => {
        setOptionsCurso(response?.data?.data?.map(convertToOptionsArray));
      })
      .catch(() => {
        toast.error('Erro ao recuperar  de Cursos');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchFases = (cursoId: string) => {
    setOptionsFase([]);

    api
      .get(`${cursoId}/fase`)
      .then((response: any) => {
        setOptionsFase(
          response?.data?.data?.map((fase: any) => ({
            label: fase.titulo,
            value: fase.id,
          })),
        );
      })
      .catch(() => {
        toast.error('Erro ao recuperar Fases');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchTarefas = (faseId: string) => {
    setOptionsTarefa([]);

    api
      .get(`${faseId}/tarefa`)
      .then((response: any) => {
        setOptionsTarefa(response?.data?.data?.map(convertToOptionsArray));
      })
      .catch(() => {
        toast.error('Erro ao recuperar Fases');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchQuizzes = (tarefaId: string, faseId: string) => {
    if (tarefaId && faseId) {
      api
        .get(`${tarefaId}/quiz`)
        .then((response: any) => {
          setOptionsQuizzes(response?.data?.data?.map(convertToOptionsArrayQuizzes));
        })
        .catch(() => {
          toast.error('Erro ao recuperar Quizzes');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleOnCategCursoChange = (e: any) => {
    cleanAfterCategCursoChange();

    const categCursoId = parseInt(e?.target?.value, 10);
    const option = optionsCategCurso?.find((op: any) => op.value === categCursoId);

    if (option) {
      setFilteredCategoriaCurso(option);

      fetchCursos(option.value);
    }
  };

  const handleOnCursoChange = (e: any) => {
    cleanAfterCursoChange();

    const cursoId = parseInt(e?.target?.value, 10);
    const option = optionsCurso?.find((op: any) => op.value === cursoId);

    if (option) {
      setFilteredCurso(option);
      fetchFases(option.value);
    }
  };

  const handleOnFaseChange = (e: any) => {
    cleanAfterFaseChange();

    const faseId = parseInt(e?.target?.value, 10);
    const option = optionsFase?.find((op: any) => op.value === faseId);
    if (option) {
      setFilteredFase(option);
      fetchTarefas(option.value);
    }
  };

  const handleOnTarefaChange = (e: any) => {
    cleanAfterTarefaChange();

    const tarefaIdString = e?.target?.value;
    const tarefaId = parseInt(tarefaIdString, 10);
    const option = optionsTarefa?.find((op: any) => op.value === tarefaId);
    if (option) {
      setFilteredTarefa(option);
    }
    fetchQuizzes(tarefaIdString, filteredFase.value);
  };

  const handleOnQuizzChange = (e: any) => {
    const quizzId = parseInt(e?.target?.value, 10);
    const option = optionsQuizzes?.find((op: any) => op.value === quizzId);
    if (option) {
      setFilteredQuizz(option);
    }
  };

  const handleOnMesChange = (event: any) => {
    const mesUsuario = parseInt(event?.target?.value, 10);
    setanoSelecionado(mesUsuario);
  };

  const loadFiltrosFromRelatorioState = (filtersObj: any) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in relatorioState) {
      if (Object.prototype.hasOwnProperty.call(relatorioState, prop)) {
        // && relatorioState[prop])
        if (relatorioState[prop]?.length > 0) {
          filtersObj[prop] = relatorioState[prop];
        }
      }
    }
  };

  const loadFiltrosEspecificos = (filtersObj: any) => {
    if (filteredCategoriaCurso) {
      filtersObj.categoriaCurso = filteredCategoriaCurso?.value;
    }
    if (filteredCurso) {
      filtersObj.curso = filteredCurso?.value;
    }
    if (filteredFase) {
      filtersObj.fase = filteredFase?.value;
    }
    if (filteredTarefa) {
      filtersObj.tarefa = filteredTarefa?.value;
    }
    if (filteredQuizz) {
      filtersObj.quizzId = filteredQuizz?.value;
    }
    if (anoSelecionado) {
      filtersObj.tempoUsuarioNaEmpresa = anoSelecionado;
    }
  };

  const getFiltros = () => {
    const filtersObj = {};

    loadFiltrosFromRelatorioState(filtersObj);
    loadFiltrosEspecificos(filtersObj);
    return new URLSearchParams(filtersObj).toString();
  };

  const handleOnGerarRelatorioClick = () => {
    api({
      url: `${
        process.env.REACT_APP_ENDPOINT
      }/relatorios/relatoriodetalhamentorealizacaocurso?${getFiltros()}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      downloadExcelFromResponseBinary(
        response.data,
        `Relatorio_De_Cursos-${getDataCorrentePtBr()}.xlsx`,
      );
    });
  };

  return (
    <Container>
      <HeaderTitle
        title="Relatório de Cursos"
        text="Filtre de acordo com seu objetivo e baixe uma planilha com os resultados instantaneamente."
      />

      <Row>
        <Col xs={12} md={6}>
          <SimpleSelect
            label="Categorias de Cursos"
            name="categCurso"
            options={optionsCategCurso}
            defaultValue={filteredCategoriaCurso}
            value={filteredCategoriaCurso?.value}
            onChange={handleOnCategCursoChange}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Cursos"
            name="curso"
            options={optionsCurso}
            value={filteredCurso?.value}
            onChange={handleOnCursoChange}
            disabled={!filteredCategoriaCurso}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Fases"
            name="fase"
            options={optionsFase}
            value={filteredFase?.value}
            onChange={handleOnFaseChange}
            disabled={!filteredCurso}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Tarefas"
            name="tarefa"
            options={optionsTarefa}
            value={filteredTarefa?.value}
            onChange={handleOnTarefaChange}
            disabled={!filteredFase}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="grupos"
            label="Grupos"
            endpointUrl="filial"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="unidades"
            label="Unidades"
            endpointUrl="unidade-empresa"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="cargos"
            label="Cargos"
            endpointUrl="cargo"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="usuarios"
            label="Usuários"
            endpointUrl="usuario"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Quizz"
            name="quizz"
            options={optionsQuizzes}
            value={filteredQuizz?.nome}
            onChange={handleOnQuizzChange}
            disabled={false}
          />
        </Col>
        <Col xs={12} md={6}>
          <SimpleSelect
            label="Tempo de Empresa"
            name="tempoDeEmpresa"
            options={Anos}
            value={anoSelecionado}
            onChange={handleOnMesChange}
            disabled={false}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 4, offset: 4 }}>
          <Button
            background="transparent"
            color="#fff"
            loading={isLoading}
            className="text-uppercase"
            type="submit"
            onClick={handleOnGerarRelatorioClick}
          >
            Gerar Relatório
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
