import styled from 'styled-components';

export const LabelConteudo = styled.div`
  text-align: center;
  width: 100%;

  span {
    font-size: 1.9rem;
  }
`;
