import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { ISelect } from '../../interfaces/ISelect';
import { STATUS_CURSO } from '../../utils/shared-functions';
import { SimpleSelect } from '../SimpleSelect';

export const BarraDeFiltros = ({ handleStatusCursoChange }: any) => {
  const [status, setStatus] = useState<ISelect>(STATUS_CURSO[0]);

  return (
    <>
      <Card className="p-2">
        <SimpleSelect
          label="Status"
          name="status-curso"
          options={STATUS_CURSO}
          defaultValue={status}
          value={status?.value}
          onChange={(event: any) => {
            const s = STATUS_CURSO?.find((option: any) => option.value === event.target.value);

            if (s) {
              setStatus(status);
            }

            if (handleStatusCursoChange) {
              handleStatusCursoChange(event.target.value);
            }
          }}
        />
      </Card>
    </>
  );
};
