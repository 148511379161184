/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Form, Formik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { BsCircle } from 'react-icons/bs';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Switch from 'react-switch';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import { ReactSelect } from '../../../../components/ReactSelect';
import { IQuiz } from '../../../../interfaces/IQuiz';
import { ISelect } from '../../../../interfaces/ISelect';
import { ITarefa } from '../../../../interfaces/ITarefa';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { ModalFormQuiz } from '../ModalFormQuizV2';
import { Question, QuestionRow } from './styles';
// import { ModalGaleria } from '../../../../components/ModalGaleria';
import handleErrors from '../../../../utils/handle.errors';
import { ModalFormTarefaHTMLEditor } from './ModalFormTarefaHTMLEditor';
import HTMLDisplayToText from '../../../../components/HtmlToText';

const dataInitial: ITarefa = {
  id: 0,
  nome: '',
  recompensaMoedas: 1,
  icone: '',
  iconeBloqueado: '',
  video: {
    id: undefined,
    tipoMidiaId: 1,
    url: '',
    titulo: '',
    descricao: '',
  },
  arquivo: {
    id: undefined,
    url: '',
    titulo: '',
    descricao: '',
  },
};

interface ModalFormProps {
  onCloseModal: () => void;
  onSetTarefa: (data: ITarefa, update?: boolean) => void;
  showModal: boolean;
  faseId: number;
  tarefa?: ITarefa;
}

const schema = yup.object().shape({
  nome: yup.string().required('O nome é obrigatório'),
  video: yup.object().nullable().shape({
    url: yup.string(),
    titulo: yup.string().nullable(),
    descricao: yup.string().nullable(),
    tipoMidiaId: yup.lazy((value) => (typeof value === 'object'
      ? yup.object().nullable()
      : yup.string())),
  }),
  arquivo: yup.object().nullable().shape({
    url: yup.string(),
    titulo: yup.string().nullable(),
    descricao: yup.string().nullable(),
  }),
});

export const ModalFormTarefa = ({
  showModal,
  onCloseModal,
  onSetTarefa,
  tarefa,
  faseId,
}: ModalFormProps) => {
  const [typesMedia, setTypesMedia] = useState<ISelect[]>([]);
  const [loading, setLoading] = useState(false);
  const [includeVideo, setIncludeVideo] = useState(false);
  const [includeFile, setIncludeFile] = useState(false);
  const [includeHTMLContent, setIncludeHTMLContent] = useState(false);
  const [showModalQuiz, setShowModalQuiz] = useState(false);
  const [quizzes, setQuizzes] = useState<IQuiz[]>([]);
  const [quizEdit, setQuizEdit] = useState<IQuiz>();
  // const [showGaleriaIcone, setShowGaleriaIcone] = useState(false);
  // const [showGaleriaIconeBloqueado, setShowGaleriaIconeBloqueado] = useState(false);
  const [icone, setIcone] = useState('');
  const [iconeBloqueado, setIconeBloqueado] = useState('');
  const [isShowModalHtmlEditor, setIsShowModalHtmlEditor] = useState<boolean>(false);
  const [conteudoHtml, setConteudoHtml] = useState<string>();

  useEffect(() => {
    if (tarefa) {
      setQuizzes(tarefa.quizzes || []);
      setIconeBloqueado(tarefa.iconeBloqueado);
      setIcone(tarefa.icone);
      setIncludeVideo(tarefa.video != null);
      setIncludeFile(tarefa.arquivo != null);
      setIncludeHTMLContent(tarefa.conteudoHtml != null);
      setConteudoHtml(tarefa.conteudoHtml);
    }
    return () => {
      setQuizzes([]);
      setIconeBloqueado('');
      setIcone('');
      setConteudoHtml('');
      setIncludeVideo(false);
      setIncludeFile(false);
      setIncludeHTMLContent(false);
    };
  }, [tarefa]);

  const handleSubmit = useCallback(async (dataForm: ITarefa) => {
    try {
      setLoading(true);

      if (quizzes.length < 1) {
        toast.error('Adicione pelo menos uma pergunta');
        return;
      }

      const dataFormatted = {
        ...dataForm,
        faseId,
        icone,
        iconeBloqueado,
        video: includeVideo && dataForm.video ? {
          ...dataForm.video,
          tipoMidiaId: dataForm?.video?.tipoMidiaId?.value || dataForm?.video?.tipoMidiaId || 1,
        } : null,
        arquivo: includeFile ? { ...dataForm.arquivo } : null,
        conteudoHtml: includeHTMLContent ? conteudoHtml : null,
        quizzes,
      };

      if (tarefa) {
        dataFormatted.id = tarefa.id;
        const { data } = await api.put(`${faseId}/tarefa/${tarefa.id}`, dataFormatted);
        onSetTarefa(data.data, true);
      } else {
        const { data } = await api.post(`${faseId}/tarefa`, {
          ...dataFormatted,
          id: undefined,
        });
        onSetTarefa(data.data);
      }

      toast.success('Tarefa salva salva com sucesso');
      onCloseModal();
      setQuizzes([]);
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
      setIncludeVideo(false);
      setIncludeFile(false);
    }
  }, [faseId, tarefa, onSetTarefa, onCloseModal, quizzes, icone,
    iconeBloqueado, includeVideo, includeFile, includeHTMLContent, conteudoHtml]);

  useEffect(() => {
    api
      .get('tipomidia')
      .then(({ data }) => setTypesMedia(
        data.data.map((item: { id: number, opcao: string }) => ({
          label: item.opcao,
          value: item.id,
        })),
      ))
      .catch(() => {
        toast.error('Erro ao buscar lista de tipos de mídia');
      });
  }, []);

  const handleSetQuizzes = useCallback((data: IQuiz, update?: boolean) => {
    if (update) {
      setQuizzes((old) => [...old].map((object) => {
        if ((!!object.id && object.id === data.id) || (object.key === data.key)) {
          return data;
        }

        return object;
      }));
    } else {
      setQuizzes((old) => [...old, data]);
    }

    setShowModalQuiz(false);
    setQuizEdit(undefined);
  }, [quizzes]);

  const handleDeleteQuiz = useCallback(async (item: IQuiz) => {
    try {
      if (item?.id) {
        await api.delete(`${tarefa?.id}/quiz/${item.id}`);
        setQuizzes((old) => [...old].filter((q) => q.id !== item.id));
      } else {
        setQuizzes((old) => [...old].filter((q) => q.key !== item.key));
      }
      toast.success('Pergunta removida com sucesso');
    } catch {
      toast.error('Erro ao excluir pergunta');
    }
  }, [tarefa]);

  // const handleRemoveImage = (type: string) => {
  //   Swal.fire({
  //     title: 'Deseja realmente excluir?',
  //     text: 'Você não será capaz de reverter isso.',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#ef5350',
  //     cancelButtonColor: '#757575',
  //     confirmButtonText: 'Sim, excluir',
  //     cancelButtonText: 'Cancelar',
  //     denyButtonColor: '#757575',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       if (type === 'icone') {
  //         setIcone('');
  //       } else {
  //         setIconeBloqueado('');
  //       }
  //     }
  //   });
  // };

  const dragQuizz = useRef<number>(0);
  const draggedOverQuizz = useRef<number>(0);

  /* eslint-disable no-param-reassign */
  const handleSort = () => {
    const quizzesClone = [...quizzes];
    const temp = quizzesClone[dragQuizz.current];
    quizzesClone[dragQuizz.current] = quizzesClone[draggedOverQuizz.current];
    quizzesClone[draggedOverQuizz.current] = temp;
    quizzesClone.forEach((quizz, index) => {
      quizz.ordem = index;
    });
    setQuizzes(quizzesClone);
  };

  return (
    <>
      <ModalFormTarefaHTMLEditor
        originalContent={conteudoHtml || ''}
        isShowModal={isShowModalHtmlEditor}
        onCloseModal={(content) => {
          setConteudoHtml(content);
          setIsShowModalHtmlEditor(false);
        }}
      />

      <ModalFormQuiz
        showModal={showModalQuiz}
        onCloseModal={() => {
          setShowModalQuiz(false);
          setQuizEdit(undefined);
        }}
        onSetQuizzes={handleSetQuizzes}
        quiz={quizEdit}
      />
      {/* <ModalGaleria
        showModal={showGaleriaIcone}
        onCloseModal={() => setShowGaleriaIcone(false)}
        path="curso"
        onSelectImage={(path) => setIcone(path)}
      />
      <ModalGaleria
        showModal={showGaleriaIconeBloqueado}
        onCloseModal={() => setShowGaleriaIconeBloqueado(false)}
        path="curso"
        onSelectImage={(path) => setIconeBloqueado(path)}
      /> */}
      <ModalComponent
        onClose={() => {
          onCloseModal();
          setQuizzes([]);
        }}
        modalVisible={showModal}
        title="Incluir Tarefa"
        icon={BsCircle}
        size="lg"
      >
        <p>
          Aqui você deve incluir as tarefas da sua fase/módulo/etapa.
          Selecione quantos pontos dará por esta realização no campo &apos;&apos;moedas&apos;&apos;,
          afinal esta será a recompensa pela realização da tarefa. Adicionar um
          vídeo explicativo ou arquivo PDF é opcional. No campo Questionário,
          crie as perguntas do seu quiz.
        </p>
        <h6>Preencha todos os dados solicitados.</h6>

        <Formik
          initialValues={tarefa || dataInitial}
          enableReinitialize
          validationSchema={schema}
          onSubmit={handleSubmit}
        >

          <Form>
            <Row>
              <Col md={12}>
                <Input type="text" name="nome" label="Qual será o título da Tarefa?" isRequerid />
              </Col>

            </Row>

            <Row className="mt-3">
              <Col className="d-flex">
                <Switch
                  onChange={() => setIncludeVideo((old) => !old)}
                  checked={includeVideo}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Incluir Vídeo</h6>
              </Col>
              {includeVideo
                && (
                  <>
                    <Col md={12}>
                      <Input type="text" name="video.titulo" label="Titulo do vídeo" />
                    </Col>

                    <Col md={6}>
                      <Field
                        name="video.tipoMidiaId"
                        label="Tipo de mídia"
                        component={ReactSelect}
                        options={typesMedia}
                      />
                    </Col>

                    <Col md={6}>
                      <Input type="text" name="video.url" label="URL do vídeo" />
                    </Col>

                    <Col md={12}>
                      <Input type="textarea" name="video.descricao" label="Coloque aqui o conteúdo por escrito" />
                    </Col>
                  </>
                )
              }
            </Row>

            <Row className="mt-3">
              <Col className="d-flex" md={12}>
                <Switch
                  onChange={() => setIncludeFile((old) => !old)}
                  checked={includeFile}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Incluir Arquivo PDF</h6>
              </Col>
              {includeFile
                && (
                  <>
                    <Col md={6}>
                      <Input type="text" name="arquivo.titulo" label="Titulo do arquivo" />
                    </Col>

                    <Col md={6}>
                      <Input type="text" name="arquivo.url" label="URL do arquivo" />
                    </Col>

                    <Col md={12}>
                      <Input type="textarea" name="arquivo.descricao" label="Descrição" />
                    </Col>
                  </>
                )
              }
            </Row>

            <Row className="mt-3">
              <Col className="d-flex" md={12}>
                <Switch
                  onChange={() => setIncludeHTMLContent((old) => !old)}
                  checked={includeHTMLContent}
                  height={22}
                  width={53}
                />
                <h6 className="text-secondary ms-3">Incluir Conteúdo HTML</h6>
              </Col>
              {includeHTMLContent && (
                <Col className="d-flex" md={3} style={{ marginTop: '10px' }}>
                  <Button
                    color="#fff"
                    onClick={() => setIsShowModalHtmlEditor(true)}
                    type="button"
                  >
                    Editar Conteúdo
                  </Button>
                </Col>
              )}
            </Row>

            <Row className="mt-3">
              {/* <div className="d-flex justify-content-center mt-3">
                <div>
                  <Thumbnail
                    title="Ícone (em breve)"
                    // url={icone ? `https://api.hmp.entgames.com.br/imagens/${icone}` : undefined}
                    // onShowModalUpload={() => setShowGaleriaIcone(true)}
                    // onDeleteImage={() => handleRemoveImage('icone')}
                  />
                </div>
                <div className="ms-4">
                  <Thumbnail
                    title="Ícone bloqueado (em breve)"
                    // url={iconeBloqueado ? `https://api.hmp.entgames.com.br/imagens/${iconeBloqueado}` : undefined}
                    // onShowModalUpload={() => setShowGaleriaIconeBloqueado(true)}
                    // onDeleteImage={() => handleRemoveImage('iconeBloqueado')}
                  />
                </div>
              </div> */}

              <Col md={12} className="mt-4">
                <h6 className="text-secondary m-0">Questionário</h6>

                {quizzes.length > 0 ? (
                  quizzes.map((item, index) => (
                    <QuestionRow
                      key={item.id || item.key}
                      className="mt-1"
                      draggable
                      onDragStart={() => { dragQuizz.current = index; }}
                      onDragEnter={() => { draggedOverQuizz.current = index; }}
                      onDragEnd={handleSort}
                      onDragOver={(e: any) => e.preventDefault()}
                    >
                      <Col xs={1} style={{ background: '#bb061b', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                        <strong style={{ color: '#fff' }}>{`${index + 1}`}</strong>
                      </Col>
                      <Col xs={9} className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <Question className="justify-content-end">
                          <HTMLDisplayToText htmlContent={item.pergunta} />
                        </Question>
                      </Col>
                      <Col xs={2} className="d-flex justify-content-end" style={{ alignItems: 'center', paddingTop: '2px', paddingBottom: '2px' }}>
                        <Button
                          type="button"
                          className="secondary"
                          style={{ width: '2rem' }}
                          background="#757575"
                          color="#fff"
                          onClick={() => handleDeleteQuiz(item)}
                        >
                          <FiTrash2 size={19} />
                        </Button>

                        <Button
                          type="button"
                          className="secondary ms-1"
                          style={{ width: '2rem' }}
                          onClick={() => {
                            setQuizEdit(item);
                            setShowModalQuiz(true);
                          }}
                          background="#757575"
                          color="#fff"
                        >
                          <FiEdit3 size={19} />
                        </Button>
                      </Col>
                    </QuestionRow>
                  ))
                ) : (
                  <>
                    <h5 className="text-center">Nenhuma pergunta ainda :(</h5>
                    <p className="text-secondary text-center">
                      Toque em Adicionar para iniciar!
                    </p>
                  </>
                )}

                <Row className="mt-4">
                  <Col md={{ span: 4, offset: 4 }}>
                    <Button
                      background="#757575"
                      color="#fff"
                      className="text-uppercase d-flex align-items-center"
                      type="button"
                      onClick={() => {
                        setShowModalQuiz(true);
                      }}
                    >
                      <AiOutlineFileAdd size={20} className="me-1" />
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  loading={loading}
                  className="text-uppercase"
                  type="submit"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>

        </Formik>
      </ModalComponent>
    </>
  );
};
