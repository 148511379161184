import { FaTrash } from 'react-icons/fa';
import { IoEye } from 'react-icons/io5';
import { BsImageFill } from 'react-icons/bs';
import { Container, Content, Actions } from './styles';

interface ThumbanailCircleProps {
  url?: string;
  title?: string;
  onShowModalUpload?: () => void;
  onDeleteImage?: () => void;
}

export const Thumbnail = ({
  url,
  title,
  onShowModalUpload,
  onDeleteImage,
}: ThumbanailCircleProps) => (
  <Container>
    {title && <h6 className="mb-3">{title}</h6>}
    <Content image={url}>
      {url && <img src={url} alt="images" />}
      <Actions>
        {url && (
          <button type="button" onClick={() => window.open(url)}>
            <IoEye />
          </button>
        )}
        {onShowModalUpload && (
          <button type="button" onClick={onShowModalUpload}>
            <BsImageFill />
          </button>
        )}
        {onDeleteImage && url && (
          <button type="button" onClick={onDeleteImage}>
            <FaTrash />
          </button>
        )}
      </Actions>
    </Content>
  </Container>
);
