import styled from 'styled-components';

interface ContainerProps {
  image?: string;
}

export const Container = styled.div`
  width: 100%;

  h6 {
    font-size: 0.8rem;
    font-weight: 600;
  }

  & + div {
    margin-left: 3rem;
  }
`;

export const Actions = styled.div`
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.5);
  height: 100%;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  display: none;

  button {
    background: transparent;
    border: 0;
    background-color: #c4c4c4;
    border-radius: 50%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    padding: 0.5rem;

    svg {
      font-size: 2rem;
      color: #fff;
    }

    &:hover {
      transform: scale(1.06);
    }

    & + button {
      margin-left: 0.3rem;
    }
  }
`;

export const Content = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  align-self: center;
  background: #d7d7d7;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #00000029;
  width: 8.75rem;
  height: 7.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover div {
    display: flex;
  }
`;
