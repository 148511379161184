import { Col, Row } from 'react-bootstrap';
import { SiCircle } from 'react-icons/si';
import { useCallback, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { toast } from 'react-toastify';
import { FaCheckCircle } from 'react-icons/all';
import { useParams } from 'react-router-dom';
import { Header } from '../../Trilhas/Kandan/styles';
import { BoxItemGame } from '../../../components/BoxItemGame';
import api from '../../../services/api';
import { IUnitChecklist } from '../../../interfaces/business-units/checklists/IUnitChecklist';
import { IUnitChecklistItem } from '../../../interfaces/business-units/checklists/item/IUnitChecklistItem';
import { ModalFormChecklist } from './ModalFormChecklist';
import { ModalFormChecklistItens } from './Modal-Form-Checklist-Itens';
import { Button } from '../../../components/Button';
import { ModalFormAgendamento } from '../../ChecklistCategories/ModalFormAgendamento';
import { ModalFormSubtema } from './Modal-Form-Subtema';
import { swalOptions } from './constants';

export const ChecklistsKanban = () => {
  const CHECKLIST_UNIDADE_BASE_URL = '/checklistunidade/';
  const { id: unidadeId } = useParams<{ id: string }>();

  const [showModalForm, setShowModalForm] = useState(false);
  const [showModalFormChecklistItem, setShowModalFormChecklistItem] = useState(false);
  const [showModalFormSubtema, setShowModalFormSubtema] = useState(false);
  const [isOpenModalFormAgendamento, setIsOpenModalFormAgendamento] = useState<boolean>(false);
  const [showModalFormViewOnly, setShowModalFormViewOnly] = useState(false);

  const [selectedChecklist, setSelectedChecklist] = useState<any>();
  const [unitChecklists, setUnitChecklists] = useState<any[]>([]);

  const [selectedSubtema, setSelectedSubtema] = useState<any>();
  const [selectedSubtemas, setSelectedSubtemas] = useState<any[]>([]);

  const [selectedChecklistItem, setSelectedChecklistItem] = useState<IUnitChecklistItem>();
  const [unitChecklistItems, setUnitChecklistItems] = useState<IUnitChecklistItem[]>([]);

  const [isLoadingChecklist, setIsLoadingChecklist] = useState(true);
  const [isLoadingSubtema, setIsLoadingSubtema] = useState(false);
  const [isLoadingItens, setIsLoadingItens] = useState(false);

  const getExtraInfoLabel = (jaLancado?: boolean, agendado?: boolean): string => {
    let result = '';
    if (jaLancado) {
      result = 'Checklist lançado';
    } else if (agendado) {
      result = 'Checklist agendado';
    }
    return result;
  };

  const mapChecklistBox = (checklists: IUnitChecklist[]): IUnitChecklist[] => checklists
    .map((_checklist) => ({
      ..._checklist,
      extraInfo: getExtraInfoLabel(_checklist.jaLancado, _checklist.agendado),
      canEdit: !((_checklist.jaLancado || _checklist.agendado) ?? false),
      canDuplicate: !((_checklist.jaLancado || _checklist.agendado) ?? false),
      canPreview: (_checklist.jaLancado || _checklist.agendado) ?? false,
    }));

  const getChecklistsUnidade = () => {
    api.get(`checklistunidade/categoria/${unidadeId}`)
      .then(({ data }) => {
        setIsLoadingChecklist(false);
        setUnitChecklists(mapChecklistBox(data.data));
      })
      .catch(() => {
        setIsLoadingChecklist(false);
        toast.error('Erro ao buscar lista de categorias');
      });
  };

  useEffect(() => {
    try {
      getChecklistsUnidade();
    } catch (error: any) {
      toast.error(error.message);
    }
  }, []);

  const fetchUnitChecklistSubtema = (checklistId: number) => {
    api.get(`${CHECKLIST_UNIDADE_BASE_URL}${checklistId}/subtema`).then((response) => {
      const sortedResponse = response.data.data.sort((a: any, b: any) => a.ordem - b.ordem);
      setSelectedSubtemas(sortedResponse);
      setIsLoadingSubtema(false);
    });
  };

  const fetchUnitChecklistItems = (subtemaId: number) => {
    api.get(`${CHECKLIST_UNIDADE_BASE_URL}${selectedChecklist.id}/subtema/${subtemaId}/itens`).then((response) => {
      const sortedResponse = response.data.data.sort((a: any, b: any) => a.ordem - b.ordem);
      setUnitChecklistItems(sortedResponse);
      setIsLoadingItens(false);
    });
  };

  const handleOnChecklistSelect = (itemId: number) => {
    setSelectedSubtemas([]);
    setUnitChecklistItems([]);
    const selected = unitChecklists.find((checklist) => checklist.id === itemId);
    setSelectedChecklist(selected);

    if (selected) {
      setIsLoadingSubtema(true);
      fetchUnitChecklistSubtema(selected.id);
    }
  };

  const handleOnSubtemaSelected = (subtemaId: number) => {
    setUnitChecklistItems([]);
    const selected = selectedSubtemas.find((subtemas) => subtemas.id === subtemaId);
    setSelectedSubtema(selected);

    if (selected) {
      setIsLoadingItens(true);
      fetchUnitChecklistItems(selected.id);
    }
  };

  const handleOnSubtemaModalClose = () => {
    setShowModalFormSubtema(false);
    setSelectedSubtema(undefined);

    if (selectedChecklist) {
      fetchUnitChecklistSubtema(selectedChecklist.id);
    }
  };

  const handleOnCloseModal = () => {
    setShowModalForm(false);
    getChecklistsUnidade();
    setShowModalFormViewOnly(false);
  };

  const handleOnChecklistItensModalClose = () => {
    setShowModalFormChecklistItem(false);
    setSelectedChecklistItem(undefined);
    if (selectedSubtema) {
      fetchUnitChecklistItems(selectedSubtema.id);
    }
  };

  const handleDeleteChecklist = (id: number) => {
    Swal.fire({
      ...swalOptions,
    }).then(async (result) => {
      if (result.value) {
        try {
          await api.delete(`${CHECKLIST_UNIDADE_BASE_URL}${id}`);
          setSelectedChecklist(undefined);
          getChecklistsUnidade();
          setUnitChecklistItems([]);
          setSelectedSubtema(undefined);
          toast.success('Checklist excluído com sucesso!');
        } catch (error) {
          toast.error('Erro ao excluir Checklist!');
        }
      }
    });
  };

  const handleDeleteSubtema = (checklistId: number, subtemaId: number) => {
    Swal.fire({
      ...swalOptions,
    }).then(async (result) => {
      if (result.value) {
        try {
          await api.delete(`${CHECKLIST_UNIDADE_BASE_URL}${checklistId}/subtema/${subtemaId}`);
          setSelectedChecklist(undefined);
          getChecklistsUnidade();
          setUnitChecklistItems([]);
          setSelectedSubtema(undefined);
          toast.success('Subtema excluído com sucesso!');
        } catch (error) {
          toast.error('Erro ao excluir Subtema!');
        }
      }
    });
  };

  const handlerDuplicarChecklist = useCallback((questionnaireId: number) => {
    api
      .post(`${CHECKLIST_UNIDADE_BASE_URL}duplicar/${questionnaireId}`)
      .then((response) => {
        getChecklistsUnidade();
        setUnitChecklists([]);
        toast.success('Checklist duplicada com sucesso!');
      })
      .catch((error: any) => {
        toast.error('Error ao tentar duplicar Checklist!');
      });
  }, []);

  const handleDeleteChecklistItem = useCallback(
    (selectedChecklistItemId: number) => {
      Swal.fire({
        ...swalOptions,
      }).then(async (result) => {
        if (result.value) {
          try {
            await api.delete(`${CHECKLIST_UNIDADE_BASE_URL}item/${selectedChecklistItemId}`);
            fetchUnitChecklistItems(selectedSubtema?.id || 0);
            toast.success('Item excluído com sucesso!');
          } catch (error) {
            toast.error('Erro ao excluir Item!');
          }
        }
      });
    },
    [selectedChecklist],
  );

  const handleDisplayTitle = (item: any) => {
    if (item.liberado) {
      return (
        <span>
          <strong>{item.titulo}</strong>
          <FaCheckCircle
            size={15}
            color="#4dbd74"
            style={{ marginLeft: '5px', marginTop: '-5px' }}
          />
        </span>
      );
    }
    return <span>{item.titulo}</span>;
  };

  const handleOnDragEndChecklists = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setUnitChecklists((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordenacaoChecklists = unitChecklists
        .map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);
      try {
        await api.put(`${CHECKLIST_UNIDADE_BASE_URL}ordenar`, ordenacaoChecklists);
      } catch {
        toast.error('Erro ao ordernar checklists');
      }
    },
    [unitChecklists],
  );

  const handleSubtemaDragEnd = (resultIndex: any, resultDestination: any) => {
    setSelectedSubtemas((old) => {
      const [reorderedItem] = old.splice(resultIndex, 1);
      old.splice(resultDestination, 0, reorderedItem);
      return old;
    });

    const ordenacaoSubtemas = selectedSubtemas
      .map((p, index) => ({
        id: p.id,
        ordem: index,
      }))
      .concat([]);

    try {
      api
        .post(`${CHECKLIST_UNIDADE_BASE_URL}${selectedChecklist.id}/subtema/ordem`, ordenacaoSubtemas)
        .catch((error) => {
          toast.error('Erro ao ordernar checklists');
          fetchUnitChecklistSubtema(selectedChecklist.id);
        });
    } catch {
      toast.error('Erro ao ordernar checklists');
      fetchUnitChecklistSubtema(selectedChecklist.id);
    }
  };

  const handleQuestionarioDragEnd = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setUnitChecklistItems((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordenacaoChecklistItems = unitChecklistItems
        .map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);
      try {
        await api.put(`checklistunidade/ordenar/${unitChecklistItems[0].checklistUnidadeId}`, ordenacaoChecklistItems);
      } catch {
        toast.error('Erro ao ordernar itens do checklists');
      }
    },
    [unitChecklistItems],
  );

  const handleEditClick = (itemId: number) => {
    const selected = unitChecklists.find((checklist) => checklist.id === itemId);
    if (selected) {
      selected.unidadesIds = selected?.unidadesEmpresa.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
      selected.cargosIds = selected?.cargos.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
    }
    setSelectedChecklist(selected);
    setShowModalForm(true);
  };

  const handleSubtemaEditClick = (itemId: number) => {
    const selected = selectedSubtemas.find((subtema) => subtema.id === itemId);
    setSelectedSubtema(selected);
    setShowModalFormSubtema(true);
  };

  const handlePreviewClick = (itemId: number) => {
    const selected = unitChecklists.find((checklist) => checklist.id === itemId);
    if (selected) {
      selected.unidadesIds = selected?.unidadesEmpresa.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
      selected.cargosIds = selected?.cargos.map((item: any) => ({
        label: item.nome,
        value: item.id,
      }));
    }
    setSelectedChecklist(selected);
    setShowModalFormViewOnly(true);
  };

  const handleDesativarChecklist = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Você quer realmente desativar?',
        icon: 'warning',
        input: 'text',
        inputLabel: 'Gostaria de informar o motivo da desativação?',
      }).then((result) => {
        const data = {
          data: {
            motivoDesativacao: result.value,
          },
        };

        api.delete(`/checklistunidade/${selectedChecklist?.id}/desativar`, data).then(() => {
          getChecklistsUnidade();
          setSelectedChecklist(undefined);
          setUnitChecklistItems([]);
          toast.success('Questionário desativado!');
        });
      });
    } catch (error: any) {
      if (error.response.data) {
        toast.error(error.response.data.errors.join(','));
      } else {
        toast.error('Erro ao desativar questionário!');
      }
    }
  }, [selectedChecklist]);

  const handleDisableScheduler = () => {
    try {
      api.delete(`/checklistunidade/${selectedChecklist?.id}/cancelar-lancamento`).then(() => {
        getChecklistsUnidade();
        setSelectedChecklist(undefined);
        setUnitChecklistItems([]);
        toast.success('Agendamento cancelado!');
      });
    } catch (error: any) {
      toast.error('Erro ao cancelar checklist!');
      if (error.response.data) {
        toast.error(error.response.data.errors.join(','));
      } else {
        toast.error('Erro ao cancelar checklist!');
      }
    }
  };

  return (
    <>
      <ModalFormChecklist
        showModal={showModalForm || showModalFormViewOnly}
        onCloseModal={handleOnCloseModal}
        checklist={selectedChecklist}
        unidadeEmpresaId={parseInt(unidadeId, 10)}
        viewOnly={showModalFormViewOnly}
      />

      {showModalFormSubtema && (
        <ModalFormSubtema
          showModal={showModalFormSubtema}
          onCloseModal={handleOnSubtemaModalClose}
          subtema={selectedSubtema}
          selectedChecklistId={selectedChecklist.id}
        />
      )}

      {showModalFormChecklistItem && (
        <ModalFormChecklistItens
          showModal={showModalFormChecklistItem}
          onCloseModal={handleOnChecklistItensModalClose}
          checklist={selectedChecklist}
          subtemaId={selectedSubtema?.id}
          checklistItem={selectedChecklistItem}
        />
      )}

      <ModalFormAgendamento
        onClose={() => {
          getChecklistsUnidade();
          setSelectedChecklist(undefined);
          setUnitChecklistItems([]);
          setIsOpenModalFormAgendamento(false);
          setSelectedSubtema(undefined);
        }}
        isOpen={isOpenModalFormAgendamento}
        id={selectedChecklist?.id}
      />

      <Header className="mt-3">
        <Row>
          <Col md={6} className="col-spacing d-flex">
            <SiCircle size={35} />
            <div style={{ flex: 1 }}>
              <h5>Checklists da Unidade</h5>
              <p>Vamos colocar a mão na massa!</p>
            </div>
          </Col>

          {selectedChecklist?.id && selectedSubtemas.length > 0
            && (
              <>
                {!selectedChecklist?.liberado && (
                  <>
                    <Col md={6} xs={6} className="d-flex align-items-center">
                      {selectedChecklist?.agendado ? (
                        <Button
                          style={{ backgroundColor: '#e22c2c' }}
                          type="button"
                          color="#fff"
                          className="mt-2"
                          onClick={handleDisableScheduler}
                        >
                          Cancelar lançamento
                        </Button>
                      )
                        : (
                          <Button
                            style={{ backgroundColor: '#43af56' }}
                            type="button"
                            color="#fff"
                            className="mt-2"
                            onClick={() => setIsOpenModalFormAgendamento(true)}
                          >
                            Programar lançamento
                          </Button>
                        )
                      }
                    </Col>
                  </>
                )}
                {selectedChecklist?.liberado && (
                  <Col md={6} xs={6} className="d-flex align-items-center">
                    <Button
                      type="button"
                      color="#fff"
                      className="mt-2"
                      onClick={handleDesativarChecklist}
                    >
                      Desativar Checklist
                    </Button>
                  </Col>
                )}
              </>
            )}
        </Row>
      </Header>

      <Row>
        <Col md={4}>
          <BoxItemGame
            header="Checklists"
            icon={SiCircle}
            items={unitChecklists}
            onShowFormEdit={(itemId: number) => handleEditClick(itemId)}
            onShowFormModal={() => {
              setSelectedChecklist(undefined);
              setShowModalForm(true);
            }}
            onDeleteItem={(itemId: number) => {
              const selected = unitChecklists.find((checklist) => checklist.id === itemId);
              if (selected) {
                setSelectedChecklist(selected);
                handleDeleteChecklist(selected.id);
              }
            }}
            onPreview={(itemId: number) => handlePreviewClick(itemId)}
            onDuplicateWithId={handlerDuplicarChecklist}
            onSelecetItem={(itemId: number) => handleOnChecklistSelect(itemId)}
            selectedId={selectedChecklist?.id}
            displayTitle={handleDisplayTitle}
            onDragEnd={handleOnDragEndChecklists}
            superiorSelecionado
            isLoading={isLoadingChecklist}
          />
        </Col>

        <Col md={4}>
          <BoxItemGame
            header={`Subtema do ${selectedChecklist?.titulo}`}
            icon={SiCircle}
            items={selectedSubtemas || []}
            onShowFormEdit={(itemId: number) => handleSubtemaEditClick(itemId)}
            onShowFormModal={() => {
              setSelectedSubtema(undefined);
              setShowModalFormSubtema(true);
            }}
            onDeleteItem={() => handleDeleteSubtema(selectedChecklist.id, selectedSubtema?.id)}
            onSelecetItem={(itemId: number) => handleOnSubtemaSelected(itemId)}
            superiorSelecionado={!!selectedChecklist}
            onDragEnd={handleSubtemaDragEnd}
            displayTitle={handleDisplayTitle}
            hideBoxItem={!selectedChecklist}
            selectedId={selectedSubtema?.id}
            messageEmpty="Selecione um Checklist para incluir ou alterar subtemas."
            isLoading={isLoadingSubtema}
          />
        </Col>

        <Col md={4}>
          <BoxItemGame
            header={`Itens de Checagem do ${selectedSubtema?.titulo}`}
            icon={SiCircle}
            items={unitChecklistItems || []}
            onShowFormEdit={
              !(selectedChecklist?.liberado || selectedChecklist?.agendado)
                ? (itemId: number) => {
                  if (selectedChecklist?.itens) {
                    const selected = unitChecklistItems?.find(
                      (checklistItem: IUnitChecklistItem) => checklistItem.id === itemId,
                    );
                    setSelectedChecklistItem(selected);
                    setShowModalFormChecklistItem(true);
                  }
                }
                : undefined
            }
            onShowFormModal={() => {
              setShowModalFormChecklistItem(true);
            }}
            onDeleteItem={handleDeleteChecklistItem}
            superiorSelecionado={!!selectedSubtema}
            disabledButtonAdd={!selectedSubtema}
            onDragEnd={handleQuestionarioDragEnd}
            displayTitle={handleDisplayTitle}
            messageEmpty="Selecione um Subtema para incluir ou alterar itens."
            hideBoxItem={!selectedSubtema}
            isLoading={isLoadingItens}
          />
        </Col>
      </Row>
    </>
  );
};
