import styled, { css } from 'styled-components';

interface ContainerProps {
  isError?: boolean;
  isFocused?: boolean;
  isTextarea?: boolean;
  isFilled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  margin-top: 0.5rem;

  & + div {
    margin-top: 1rem;
  }

  &:focus {
    outline: none;
  }

  label {
    font-size: 0.8rem;
    font-weight: 600;
    background: #fff;
    pointer-events: none;
    overflow: hidden;
    width: auto;
    display: inline-block;
    position: relative;
    top: 0px;
    left: 4px;
    transition: all 0.3s ease-out;
    color: #333333;
  }
`;

export const Content = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  font-weight: 500;
  background: #fff;
  transition: 0.1s;
  padding: 0 0.6rem;
  border: 1px solid #ccc;
  border-radius: 5px;

  ${({ isTextarea }) => !isTextarea
      && css`
        height: 2.6rem;
      `};

    ${({ isTextarea }) => isTextarea
      && css`
        min-height: 4rem;
      `};

  ${(props) => (props.isFocused || false)
    && css`
      border-width: 1px;
      border-color: ${({ theme }) => theme.colors.main};
    `}

  input, textarea, select {
    height: 100%;
    width: 100%;
    border: 0;
    outline: none;
    background: transparent;
  }

  button {
    background: transparent;
    border: 0;
    position: absolute;
    right: 7px;
    top: 6px;
    color: rgba(1, 1, 1, 0.4);
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  span {
    color: #dc3545;
    font-size: 0.8rem;
    padding: 0 4px;
  }
`;
