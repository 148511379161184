import * as React from 'react';

import Logo from '../../../../assets/logo-11-2022.png';
import { ContainerLogo } from './styles';

export const LogoEntgames = () => (
  <ContainerLogo>
    <img src={Logo} alt="copyright logo entgames" />
    <span>© 2021 EntGames - Todos os direitos reservados</span>
  </ContainerLogo>
);
