import { Formik, Form, Field } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import theme from '../../../../styles/theme';
import { ISelect } from '../../../../interfaces/ISelect';
import { IEmpresa } from '../../../../interfaces/IEmpresa';
import api from '../../../../services/api';

const schema = yup.object({
  quantidadeInicialEnergiasUsuario: yup.number().required('quantidadeInicialEnergiasUsuario é obrigatório'),
});

export type EnergyConfigFormProps = {
  company: IEmpresa;
  handleFetchCompany: () => void;
}

export const EnergyConfigForm = ({ company, handleFetchCompany }: EnergyConfigFormProps) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (dataForm: IEmpresa) => {
      setLoading(true);

      const formattedData = {
        quantidadeInicialEnergiasUsuario: dataForm.quantidadeInicialEnergiasUsuario,
      };

      api
        .post('/configuracao/energias-diarias', formattedData)
        .then(() => {
          toast.success('Energias alteradas com sucesso');
          handleFetchCompany();
        })
        .catch(() => toast.error('Erro ao alterar as Energias'))
        .finally(() => setLoading(false));
    },
    [handleFetchCompany],
  );

  return (
    <>
      <Formik
        initialValues={company}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <Row className="mt-4">
              <Col md={{ span: 8 }}>
                <Field
                  name="quantidadeInicialEnergiasUsuario"
                  type="number"
                  min={1}
                  as={Input}
                  label={`Quantidade de Energias Diárias (Valor atual: ${company?.quantidadeInicialEnergiasUsuario})`}
                />
              </Col>

              <Col md={{ span: 4 }} className="d-flex align-items-end" style={{ paddingTop: '5px' }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  className="text-uppercase"
                  loading={loading}
                  type="submit"
                  disabled={
                    loading
                    || values?.quantidadeInicialEnergiasUsuario
                      === company?.quantidadeInicialEnergiasUsuario}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
