import React from 'react';
import { Switch } from 'react-router-dom';
import { AuthLayout } from '../layouts/auth';
import { DefaultLayout } from '../layouts/default';
import { SuperAdminLayout } from '../layouts/super-admin';
import { ForgortPassword } from '../pages/Auth/ForgotPassword';
import { SignIn } from '../pages/Auth/SignIn';
import { Courses } from '../pages/Courses';
import Route from './Route';

import { NotFound404 } from '../components/NotFound404';
import { RelatoriosLayout } from '../layouts/relatorios';
import { BehaviorQuestions } from '../pages/Behaviour-Questions';
import { Brand } from '../pages/Brand';
import { BusinessUnit } from '../pages/Checklist';
import { ChecklistsKanban } from '../pages/Checklist/ChecklistsKanban';
import { KanbanTrilhaUnidades } from '../pages/Checklist/components/KanbanTrilha';
import { CategoriasDeChamados } from '../pages/Categorias-De-Chamados';
import { Categories } from '../pages/Categories';
import { CompanyQuestionnaire } from '../pages/Company-Questionnaire';
import { CompanyQuiz } from '../pages/Company-Questionnaire/Kandan';
import { Configurations } from '../pages/Configurations';
import { Grupos } from '../pages/Grupos';
import { Kandan } from '../pages/Grupos/Kandan';
import { EmailSender } from '../pages/Email-Sender';
import { ExternalLogin } from '../pages/ExternalLogin';
import { Game } from '../pages/Game';
import { ListaSimples } from '../pages/ListaSimples';
import { Perfil } from '../pages/Perfil';
import { PlanilhasImportacao } from '../pages/PlanilhasImportacao';
import { QuestionnaireCategoryPage } from '../pages/QuestionnaireCategory';
import { RelatorioCursoPage } from '../pages/Relatorios/Cursos/Index';
import { RelatorioQuestionarioPage } from '../pages/Relatorios/Questionarios/Index';
import { RelatorioTrilhaPage } from '../pages/Relatorios/Trilha';
import { SuperAdmin } from '../pages/Super-Admin';
import { SuperAdminCourses } from '../pages/Super-Admin/Courses';
import { RamoEmpresaPage } from '../pages/Super-Admin/RamoEmpresa';
import { UseTermsSuperAdminPage } from '../pages/Super-Admin/Use-Terms';
import { Trilhas } from '../pages/Trilhas';
import { KanbanCursos } from '../pages/Trilhas/Kandan';
import { UseTermsPage } from '../pages/Use-Terms';
import { Users } from '../pages/Users';
import { ChecklistValidation } from '../pages/Checklist-Validation';
import { ComunicadosPage } from '../pages/Comunicados';
import { ComunicadosKanbanPage } from '../pages/Comunicados/Kanban';
import ValidationForm from '../pages/Checklist-Validation/Kanbam/FormValidation';
import { ChecklistSystemCategory } from '../pages/ChecklistCategories';
import { ChecklistCategoryList } from '../pages/ChecklistCategories/ChecklistCategoriesList';
import { SystemChecklistCategoryPage } from '../pages/SystemChecklistCategory';
import { Home } from '../pages/Home';
import { Dashboard } from '../pages/Dashboard';

export const Routes: React.FC = () => (
  <Switch>
    <Route path="/" layout={AuthLayout} exact component={SignIn} />
    <Route path="/external-login" layout={AuthLayout} exact component={ExternalLogin} />
    <Route path="/forgot-password" layout={AuthLayout} exact component={ForgortPassword} />
    <Route path="/dashboard" isPrivate layout={DefaultLayout} exact component={Dashboard} />
    <Route path="/cursos" isPrivate layout={DefaultLayout} exact component={Courses} />
    <Route path="/grupo/:id" isPrivate layout={DefaultLayout} exact component={Kandan} />
    <Route
      path="/grupos/:ids+"
      isPrivate
      layout={DefaultLayout}
      exact
      component={Grupos}
    />
    <Route path="/grupos" isPrivate layout={DefaultLayout} exact component={Grupos} />
    <Route
      path="/unidades/:id/checklists"
      isPrivate
      layout={DefaultLayout}
      exact
      component={ChecklistsKanban}
    />
    <Route path="/unidades/trilha/:id" isPrivate layout={DefaultLayout} exact component={KanbanTrilhaUnidades} />
    <Route path="/unidades/:ids+" isPrivate layout={DefaultLayout} exact component={BusinessUnit} />
    <Route path="/unidades" isPrivate layout={DefaultLayout} exact component={BusinessUnit} />
    <Route path="/categories" isPrivate layout={DefaultLayout} exact component={Categories} />
    <Route path="/inicio" isPrivate layout={DefaultLayout} exact component={Home} />
    <Route
      path="/categories/:categoryId/cursos"
      isPrivate
      layout={DefaultLayout}
      exact
      component={Trilhas}
    />
    <Route
      path="/categories/:categoryId/cursos/novo"
      isPrivate
      layout={DefaultLayout}
      exact
      component={KanbanCursos}
    />
    <Route
      path="/categories/:categoryId/cursos/:courseId"
      isPrivate
      layout={DefaultLayout}
      exact
      component={KanbanCursos}
    />
    <Route
      path="/categories/cursos-instalados"
      isPrivate
      layout={DefaultLayout}
      exact
      component={ListaSimples}
    />
    <Route
      path="/pesquisas/trilha/:id"
      isPrivate
      layout={DefaultLayout}
      exact
      component={CompanyQuiz}
    />
    <Route
      path="/pesquisas/:ids+"
      isPrivate
      layout={DefaultLayout}
      exact
      component={CompanyQuestionnaire}
    />
    <Route
      path="/pesquisas"
      isPrivate
      layout={DefaultLayout}
      exact
      component={CompanyQuestionnaire}
    />
    <Route
      path="/categoria-checklist/checklist/:id"
      isPrivate
      layout={DefaultLayout}
      exact
      component={ChecklistsKanban}
    />
    <Route
      path="/categoria-checklist/:id+"
      isPrivate
      layout={DefaultLayout}
      exact
      component={ChecklistCategoryList}
    />
    <Route
      path="/categoria-checklist"
      isPrivate
      layout={DefaultLayout}
      exact
      component={ChecklistSystemCategory}
    />
    <Route
      path="/comunicados/trilha/:id"
      isPrivate
      layout={DefaultLayout}
      exact
      component={ComunicadosKanbanPage}
    />
    <Route
      path="/comunicados/:ids+"
      isPrivate
      layout={DefaultLayout}
      exact
      component={ComunicadosPage}
    />
    <Route
      path="/comunicados"
      isPrivate
      layout={DefaultLayout}
      exact
      component={ComunicadosPage}
    />
    <Route path="/validacoes" isPrivate layout={DefaultLayout} exact component={ChecklistValidation} />
    <Route path="/validacao/:id" isPrivate layout={DefaultLayout} exact component={ValidationForm} />
    <Route path="/usuarios" isPrivate layout={DefaultLayout} exact component={Users} />
    <Route path="/gamificacao" isPrivate layout={DefaultLayout} exact component={Game} />
    <Route path="/meuperfil" isPrivate layout={DefaultLayout} exact component={Perfil} />
    <Route path="/identidade-visual" isPrivate layout={DefaultLayout} exact component={Brand} />
    <Route path="/envio-de-emails" isPrivate layout={DefaultLayout} exact component={EmailSender} />
    <Route
      path="/categorias-de-chamados"
      isPrivate
      layout={DefaultLayout}
      exact
      component={CategoriasDeChamados}
    />
    <Route
      path="/termos-de-uso"
      roles={['Sistema']}
      isPrivate
      layout={DefaultLayout}
      exact
      component={UseTermsPage}
    />
    <Route
      path="/relatorios/trilha"
      isPrivate
      layout={RelatoriosLayout}
      exact
      component={RelatorioTrilhaPage}
    />
    <Route
      path="/relatorios/cursos"
      isPrivate
      layout={RelatoriosLayout}
      exact
      component={RelatorioCursoPage}
    />
    <Route
      path="/relatorios/questionarios"
      isPrivate
      layout={RelatoriosLayout}
      exact
      component={RelatorioQuestionarioPage}
    />
    <Route
      path="/relatorios"
      isPrivate
      layout={RelatoriosLayout}
      exact
      component={RelatorioTrilhaPage}
    />
    <Route
      path="/super-admin/empresas"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={SuperAdmin}
    />
    <Route
      path="/super-admin/cursos"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={SuperAdminCourses}
    />
    <Route
      path="/super-admin/perguntas-comportamentais"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={BehaviorQuestions}
    />
    <Route
      path="/super-admin/questionario-categoria/:ids+"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={QuestionnaireCategoryPage}
    />
    <Route
      path="/super-admin/questionario-categoria"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={QuestionnaireCategoryPage}
    />
    <Route
      path="/super-admin/checklist-categoria"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={SystemChecklistCategoryPage}
    />
    <Route
      path="/super-admin/ramos"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={RamoEmpresaPage}
    />
    <Route
      path="/super-admin/configuracoes"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={Configurations}
    />
    <Route
      path="/super-admin/planilhas"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={PlanilhasImportacao}
    />
    <Route
      path="/super-admin/termos-de-uso"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={UseTermsSuperAdminPage}
    />
    <Route
      path="/super-admin"
      roles={['Sistema']}
      isPrivate
      layout={SuperAdminLayout}
      exact
      component={SuperAdmin}
    />
    <Route path="/404" isPrivate layout={DefaultLayout} exact component={NotFound404} />
  </Switch>
);
