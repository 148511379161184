import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

export const Container = styled.div<{isRight: boolean}>`
  width: 100%;
  //border: 1px solid #FFF;
  min-height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: ${({ isRight }) => (isRight && 'flex-end') || 'flex-start'};;
`;

export const IAraContainer = styled.div`
  color: ${({ theme }) => theme.colors.lightPrimary};
  padding: 20px 10px;
  border-radius: 5px;
  float: left;
  display: flex;
  flex-direction: row;
}
`;

export const UserContainer = styled.div`
  color: ${({ theme }) => theme.colors.lightPrimary};
  background: #606060;
  border-radius: 8px;
  height: auto;
  max-width: 80%;
  padding: 10px;
  gap: 4px;
`;

export const UserMessage = styled.span`
  color: ${({ theme }) => theme.colors.lightPrimary};
  text-align: right;
  //width: 100%;
`;

export const IconIAra = styled.img`
  margin: 0 10px 0 0;
  width: 42px;
  height: 42px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
`;

export const ReactMarkdownFormated = styled(ReactMarkdown)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.lightPrimary};
  }

  p {
    color: ${({ theme }) => theme.colors.lightPrimary};
  }

  strong {
    color: ${({ theme }) => theme.colors.lightPrimary};
  }

  em {
    color: #F5A623;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  li {
    color: ${({ theme }) => theme.colors.lightPrimary};
  }

  blockquote {
    border-left: 5px solid #2381e8;
    padding-left: 10px;
    color: #777;
  }

  code {
    color: ${({ theme }) => theme.colors.lightPrimary};
    padding: 2px 4px;
    border-radius: 3px;
    font-family: 'Courier New', Courier, monospace;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
`;
export const Spinner = styled.div`
  border-radius: 50%;
  background: #ffffff;
  width: 100%;
  height: 100%;
  animation: grow 3s normal infinite;

  @keyframes grow {
    0% {
      transform: scale(0.5);
    }

    50% {
      transform: scale(0.7);
    }

    100% {
      transform: scale(0.5);
    }
  }
`;
