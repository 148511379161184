import { Formik, Form } from 'formik';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { Button } from '../../components/Button';
import { Content } from '../../styles/components';
import handleErrors from '../../utils/handle.errors';
import { Thumbnail } from '../../components/Thumbnail';
import { ModalGaleria } from '../../components/ModalGaleria';

const dataInitial = {
};

// interface SubmitProps {
// }

const schema = yup.object({
//
});

export const Brand = () => {
  const [loading, setLoading] = useState(false);
  const [showUploadLogoFundoClaroModal, setShowUploadLogoFundoClaroModal] = useState(false);
  const [showUploadLogoFundoEscuroModal, setShowUploadLogoFundoEscuroModal] = useState(false);
  const [imgLogoFundoClaro, setImgLogoFundoClaro] = useState('');
  const [imgLogoFundoEscuro, setImgLogoFundoEscuro] = useState('');

  const handleSubmit = async () => {
    try {
      setLoading(true);
      // await api.put(`usuario/${user.UsuarioId}`, {
      //   id: user.UsuarioId,
      //   perfilId: user.PerfilId,
      //   empresaId: process.env.REACT_APP_COMPANY_ID,
      //   cpf: cpf.replace(/[-.]/g, ''),
      //   filialIds: filialIds.map((p) => +p.value).concat([]),
      //   nome,
      //   apelido,
      //   email,
      // });

      toast.success('Informações da empresa atualizadas com sucesso');
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (type: string) => {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Você não será capaz de reverter isso.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (type === 'imgApp') {
          // setImgFundoApp('');
        } else {
          // setImgFundoDesktop('');
        }
      }
    });
  };

  return (
    <>
      <ModalGaleria
        showModal={showUploadLogoFundoClaroModal}
        onCloseModal={() => setShowUploadLogoFundoClaroModal(false)}
        path="curso"
        onSelectImage={(path) => setImgLogoFundoClaro(path)}
      />
      <ModalGaleria
        showModal={showUploadLogoFundoEscuroModal}
        onCloseModal={() => setShowUploadLogoFundoEscuroModal(false)}
        path="curso"
        onSelectImage={(path) => setImgLogoFundoEscuro(path)}
      />
      <Row>
        <Col md={8} className="mt-4">
          <Content>
            <h4>Identidade Visual</h4>
            <p className="text-secondary mb-0">
              Escolha seu slogan, cor principal e logos.
            </p>

            <Row className="mt-3">
              <Col>
                <Formik
                  initialValues={dataInitial}
                  enableReinitialize
                  validationSchema={schema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <Row>

                      <Col xs={12} className="border-dotted-top mb-3">
                        <h6 className="text-secondary">Logos</h6>
                      </Col>

                      <Col xs={12} md={6} className="">
                        <Thumbnail
                          title="Logo (para fundos claros)"
                          url={imgLogoFundoClaro ? `https://api.hmp.entgames.com.br/imagens/${imgLogoFundoClaro}` : undefined}
                          onShowModalUpload={() => setShowUploadLogoFundoClaroModal(true)}
                          onDeleteImage={() => handleRemoveImage('imgDesktop')}
                        />
                      </Col>

                      <Col xs={12} md={6} className="">
                        <Thumbnail
                          title="Logo (para fundos claros)"
                          url={imgLogoFundoEscuro ? `https://api.hmp.entgames.com.br/imagens/${imgLogoFundoEscuro}` : undefined}
                          onShowModalUpload={() => setShowUploadLogoFundoEscuroModal(true)}
                          onDeleteImage={() => handleRemoveImage('imgDesktop')}
                        />
                      </Col>

                      <Col xs={{ span: 12, offset: 12 }} md={{ span: 4, offset: 4 }} className="mt-3">
                        <Button
                          type="submit"
                          background="fff"
                          color="#fff"
                          loading={loading}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Formik>
              </Col>
            </Row>
          </Content>
        </Col>

      </Row>
    </>
  );
};
