import styled from 'styled-components';
import { lighten } from 'polished';

interface ContainerProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => lighten(0.7, theme.colors.main)};

  h6 {
    text-align: center;
    padding: 0.8rem 0;
    text-transform: uppercase;
    border-bottom: 1px dashed ${({ theme }) => theme.colors.main};
  }

  p {
    color: ${({ theme }) => lighten(0.2, theme.colors.main)};
    text-align: center;
  }
`;

export const List = styled.div`
  height: 65vh;
  max-height: 65vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.4em;
    border-radius: 10px;
    background: ${({ theme }) => lighten(0.6, theme.colors.main)};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => lighten(0.1, theme.colors.main)};
  }
`;

export const Item = styled.div`
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  &:hover{
    cursor: pointer;
  }

  & + div {
    margin-top: 0.5rem;
  }

  > div {
    padding: 0.2rem;
    span {
      color: ${({ theme }) => theme.colors.text};
    }
  }
`;

export const IconComponent = styled.div<ContainerProps>`
  background: ${({ theme, isSelected }) => (isSelected ? 'green' : lighten(0.2, theme.colors.main))};
  height: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  svg {
    color: ${({ theme }) => lighten(0.8, theme.colors.main)};
    font-size: 2.6rem;
  }
`;

export const Actions = styled.div`
  position: absolute;
  bottom: 0px;
  right: 3px;

  button {
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.main};
    font-size: 1.2rem;
    padding: 0.15rem 0.25rem;
    border-radius: 10px;

    &:hover {
      background: ${({ theme }) => lighten(0.7, theme.colors.main)};
    }
  }
`;
