/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { Card, Col, Row } from 'react-bootstrap';
import { FaRegIdBadge } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ModalComponent } from '../../../components/Modal';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { IGrupo } from '../../../interfaces/IGrupo';
import { LabelStyled } from './styles';

interface IResult {
  totalUsuarios: number;
  totalUsuariosImportados: number;
  totalUsuariosAtualizados: number;
  totalUsuariosDesativados: number;
  totalUsuariosErrors: number;
  errosImportandoUsuarios: IResultError[];
}

interface IResultError {
  cpf: string,
  errors: string[],
  nome: string,
  numeroLinhaPlanilha: number,
}

interface IImportApiResponse {
  data: {
    data: IResult;
  };
}

interface ModalImportProps {
  onCloseModal: () => void;
  showModal: boolean;
}

// interface IImportForm {
//   UsuariosArquivo: File | any;
//   FilialId: number;
// }

const dataInitial = {
  UsuariosArquivo: null,
  FilialId: 0,
};

const importationUrl = 'usuario/import';

// async function copyTextToClipboard(text: string) {
//   if ('clipboard' in navigator) {
//     // eslint-disable-next-line no-return-await
//     return await navigator.clipboard.writeText(text);
//   }
//   return document.execCommand('copy', true, text);
// }

export const ModalImport = ({ onCloseModal, showModal }: ModalImportProps) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<IResult>();
  const [selectedFile, setSelectedFile] = useState<File | any>();
  const [grupos, setGrupos] = useState<IGrupo[]>([]);
  const [planilhaBase64, setPlanilhaBase64] = useState<string>('');
  const [choosedFilialId, setChoosedFilialId] = useState(0);

  const getFilialDefaultId = (gruposToSearch: IGrupo[]) => {
    const geralOption = gruposToSearch.filter(
      (filial) => filial.nome === 'Geral',
    )[0];

    if (geralOption) return geralOption.id;
    return gruposToSearch[0].id;
  };

  useEffect(() => {
    api
      .get('filial')
      .then(({ data }) => {
        setGrupos(data.data);
        setChoosedFilialId(getFilialDefaultId(data.data));
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de grupos');
      });
    api
      .get('configuracao/key/planilhaImportacaoUsuarios')
      .then(({ data }) => {
        setPlanilhaBase64(data.data?.value);
      })
      .catch(() => {
        toast.error('Erro ao buscar planilha modelo');
      });
  }, []);

  const handleUploadImage = useCallback((file: any) => {
    setSelectedFile(file.target.files[0]);
  }, []);

  const handleSubmit = useCallback(
    async () => {
      try {
        setLoading(true);

        if (selectedFile) {
          const formData = new FormData();

          formData.append('UsuariosArquivo', selectedFile);
          formData.append('FilialId', choosedFilialId.toString());

          const importApiResponse: IImportApiResponse = await api.post(
            importationUrl,
            formData,
          );
          const resultData: IResult = importApiResponse.data.data;

          setLoading(false);
          setResult(resultData);

          // if (resultData.totalUsuariosErrors > 0) {
          //   const errors = resultData.errosImportandoUsuarios.join(', ');
          //   toast.error(errors);
          // }
        } else {
          toast.error('Selecione o arquivo antes');
        }
      } catch (err: any) {
        if (err.response.data.errors) {
          const errors = err.response.data.errors.join(', ');
          toast.error(errors);
          return;
        }
        toast.error('Erro ao importar usuários');
      } finally {
        setLoading(false);
      }
    },
    [selectedFile],
  );

  const internalOnClose = () => {
    setResult(undefined);
    onCloseModal();
  };

  // const handleOngruposSelectChange = (event: any) => {
  //   setChoosedFilialId(event.target.value);
  // };

  const ResultCard: React.FC<{ title: string; value: number }> = ({
    title,
    value,
  }) => (
    <Card style={{ padding: '0.8rem', marginTop: '0.5rem' }}>
      <p className="mb-0">
        <b className="theme-color">{title}</b>
      </p>
      <p className="mb-0 fs-2">{value}</p>
    </Card>
  );

  const CardDeErrosDoUsuario = ({ erro }: { erro: IResultError }) => (
    <Card style={{ padding: '0.8rem', marginTop: '0.5rem' }}>
      <p className="mb-0">
        <b className="theme-color">Cpf: </b>
        {erro.cpf}
      </p>
      <p>
        <b className="theme-color">Nome: </b>
        {erro.nome}
      </p>
      <p className="mb-0 fs-6">
        <ul>
          {erro.errors?.map((e: string) => (
            <li>{e}</li>
          ))}
        </ul>
      </p>
    </Card>
  );

  return (
    <ModalComponent
      onClose={internalOnClose}
      modalVisible={showModal}
      title={result ? 'Resultado da Importação' : 'Importar usuários'}
      icon={FaRegIdBadge}
      size="lg"
    >
      {!result && (
        <>
          <div className="orientation-area">
            <p>
              <a
                href={planilhaBase64}
              >
                Baixe aqui
              </a>
              {' '}
              o modelo de planilha que usará na importação
            </p>
            <p>
              Depois que terminar de trabalhar nela, clique em &quot;Choose
              File&quot; para importá-la novamente.
            </p>
          </div>
          <Formik
            initialValues={dataInitial}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            <Form>
              {/* <Row style={{ marginBottom: '1rem' }}>
                <Col md={6}>
                  <div className="d-flex flex-column">
                    <label className="label">Grupo</label>
                    <select
                      style={{ height: '42px', paddingLeft: '7px' }}
                      onChange={handleOngruposSelectChange}
                    >
                      {grupos.map((filial: IGrupo) => (
                        <option
                          key={filial.id}
                          selected={filial.id === getFilialDefaultId(grupos)}
                          value={filial.id}
                        >
                          {filial.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col md={6}>
                  <LabelStyled>Arquivo</LabelStyled>
                  <input
                    onChange={handleUploadImage}
                    type="file"
                    name="UsuariosArquivo"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                  <Button
                    background="#fff"
                    color="#fff"
                    loading={loading}
                    className="text-uppercase theme-color-bg"
                    type="submit"
                  >
                    Importar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </>
      )}

      {result && (
        <>
          <Row>
            <Col xs={12} md={12}>
              <ResultCard title="Total" value={result.totalUsuarios} />
            </Col>
            <Col xs={12} md={3}>
              <ResultCard
                title="Novos"
                value={result.totalUsuariosImportados}
              />
            </Col>
            <Col xs={12} md={3}>
              <ResultCard
                title="Alterados"
                value={result.totalUsuariosAtualizados}
              />
            </Col>
            <Col xs={12} md={3}>
              <ResultCard
                title="Desativados"
                value={result.totalUsuariosDesativados}
              />
            </Col>
            <Col xs={12} md={3}>
              <ResultCard title="Erros" value={result.totalUsuariosErrors} />
            </Col>
          </Row>

          {result.errosImportandoUsuarios?.length > 0 && (
            <Row className="mt-4">
              <Col>
                <h4>Detalhamento dos erros</h4>
              </Col>

              {result.errosImportandoUsuarios?.map((erro: IResultError) => (
                <Col xs={12}>
                  <CardDeErrosDoUsuario erro={erro} />
                </Col>
              ))}
            </Row>
          )}

          <Row className="mt-5">
            <Col xs={12} md={{ span: 4, offset: 4 }}>
              <Button
                background="#fff"
                color="#fff"
                loading={loading}
                className="text-uppercase theme-color-bg"
                onClick={internalOnClose}
              >
                Fechar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </ModalComponent>
  );
};
