/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { SiCircle } from 'react-icons/si';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { ModalComponent } from '../../../components/Modal';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import api from '../../../services/api';

interface ModalFormAgendamentoProps {
  id: number;
  onClose: () => void;
  isOpen: boolean;
}

interface FormikValues {
  dataLancamento: string;
  horaLancamento: string;
  dataDesativar: string;
  horaDesativar: string;
}

// extrair para método Utils
const gmtOffset = moment().utcOffset() / 60;
const gmt = gmtOffset >= 0 ? `+${gmtOffset}` : gmtOffset;

export const ModalFormAgendamento = ({
  id,
  onClose,
  isOpen,
}: ModalFormAgendamentoProps) => {
  const [date, setDate] = useState(moment().local());

  useEffect(() => {
    setDate(moment().local());
  }, []);

  const handleSubmit = useCallback(
    async ({ dataLancamento, horaLancamento, dataDesativar, horaDesativar }: FormikValues) => {
      const agora = moment().startOf('day');

      // Verifica se a data de lançamento é válida e não é anterior ao dia atual
      if (!moment(`${dataLancamento} ${horaLancamento}`, 'YYYY-MM-DD HH:mm').isValid()
        || moment(`${dataLancamento} ${horaLancamento}`, 'YYYY-MM-DD HH:mm').isBefore(agora)) {
        toast.error('Por favor, verifique a data de lançamento inserida.');
        return;
      }

      // Verifica se a data de desativação é válida e não é anterior ao dia atual
      if (dataDesativar && horaDesativar
        && (!moment(`${dataDesativar} ${horaDesativar}`, 'YYYY-MM-DD HH:mm').isValid()
          || moment(`${dataDesativar} ${horaDesativar}`, 'YYYY-MM-DD HH:mm').isBefore(agora))) {
        toast.error('Por favor, verifique a data de desativação inserida.');
        return;
      }

      // Se ambas as datas são válidas e não são anteriores ao dia atual, prossegue com a requisição
      const dateData = moment(`${dataLancamento} ${horaLancamento}`, 'YYYY-MM-DD HH:mm')
        .utcOffset(-180)
        .format();
      let dateDataDesativar = null;
      if (dataDesativar && horaDesativar) {
        dateDataDesativar = moment(`${dataDesativar} ${horaDesativar}`, 'YYYY-MM-DD HH:mm')
          .utcOffset(-180)
          .format();
      }

      try {
        await api.post(`checklistunidade/${id}/agendar-lancamento`, {
          data: dateData,
          dataDesativar: dateDataDesativar,
        });
        toast.success('Agendamento feito com sucesso.');
        onClose();
      } catch (error) {
        toast.error('Erro ao agendar seu comunicado.');
      }
    },
    [id, onClose],
  );

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dataAtualFormatada: string = date.local().format('YYYY-MM-DD');
  const horaAtualFormatada: string = date.local().format('HH:mm');

  return (
    <ModalComponent
      onClose={() => onClose()}
      title="Agendar Lançamento de Checklist"
      icon={SiCircle}
      size="lg"
      modalVisible={isOpen}
    >
      <h6>Agende o lançamento do seu checklist</h6>

      <Formik
        initialValues={{
          dataLancamento: dataAtualFormatada,
          horaLancamento: horaAtualFormatada,
          dataDesativar: '',
          horaDesativar: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <Col>
              <Input
                type="date"
                name="dataLancamento"
                min={dataAtualFormatada}
                label="Data do lançamento"
                defaultValue={dataAtualFormatada}
              />
            </Col>
            <Col>
              <Input
                type="time"
                name="horaLancamento"
                label="Hora do lançamento"
              />
              <span style={{ fontSize: '0.7rem' }}>{`(horário de ${timezone}, GMT ${gmt})`}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                type="date"
                name="dataDesativar"
                min={dataAtualFormatada}
                label="Data de desativação"
              />
            </Col>
            <Col>
              <Input
                type="time"
                name="horaDesativar"
                label="Hora de desativação"
                defaultValue={horaAtualFormatada}
              />
              <span style={{ fontSize: '0.7rem' }}>{`(horário de ${timezone}, GMT ${gmt})`}</span>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button color="#fff">Agendar</Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
