import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { Container } from './styles';
import { IAra } from './components/iara';
import { HeaderTitle } from '../../components/HeaderTitle';

export const Dashboard = () => {
  const [isExpanded, setExpanded] = useState(false);

  const getRelatorios = () => (
    <Row>
      <Col>
        <iframe
          title="PowerBI_PRD v30dez2021"
          width="100%"
          height="541.25"
          src="https://app.powerbi.com/reportEmbed?reportId=0df1128b-e17b-46ad-aaeb-3b1faff15da0&autoAuth=true&ctid=a9421fa8-1ed5-4b23-8725-85b8df47e6be&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWJyYXppbC1zb3V0aC1iLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
        />
      </Col>
    </Row>
  );

  const getIAra = () => (
    <Row style={{ marginLeft: -12, marginRight: -12 }}>
      <Col style={{ height: '70vh', paddingRight: 0, paddingLeft: 0 }}>
        <IAra
          onToggleFullScreen={() => {
            setExpanded(!isExpanded);
          }}
          isExpanded={isExpanded}
        />
      </Col>
    </Row>
  );

  return (
    <Container>
      <Row style={{ marginBottom: '10px' }}>
        <HeaderTitle
          title="Relatórios"
          text="Este é seu ambiente..."
        />
      </Row>
      {!isExpanded && getRelatorios()}
      {getIAra()}
    </Container>
  );
};
