/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiTask } from 'react-icons/bi';
import { IoAdd, IoAlertCircleOutline, IoRemove } from 'react-icons/io5';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import { ICategoriaComunicado, IComunicado, IComunicadoPergunta, IComunicadoAlternativa } from '../../../../interfaces/IComunicado';
import api from '../../../../services/api';
import theme from '../../../../styles/theme';
import { SpanAlternative } from '../../../Trilhas/components/ModalFormQuiz/styles';

import { ControlledReactSelect } from '../../../../components/ReactSelect/controlled-select';
import { ISelect } from '../../../../interfaces/ISelect';
import { OptionsTipoPergunta } from '../../../QuestionnaireCategory/ModalFormQuestionnaireCategory';
import { ErrorContainer } from '../../../Company-Questionnaire/Kandan/ModalFormOrganizationalClimateQuestions/styles';
import HtmlEditor from '../../../Use-Terms/components/Html-Editor/editor';
import { validateHtmlContent } from '../../../Use-Terms/components/Html-Editor/validator';

interface ModalFormProps {
  onCloseModal: (climate?: IComunicado) => void;
  showModal: boolean;
  comunicadoId: number;
  question?: IComunicadoPergunta;
  category?: ICategoriaComunicado;
}

export const ModalFormComunicadoQuestion = ({
  onCloseModal,
  showModal,
  comunicadoId,
  question,
  category,
}: ModalFormProps) => {
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState('');
  const [includeImage, setIncludeImage] = useState<boolean>(false);
  const [base64Image, setBase64Image] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [htmlContent, setHtmlContent] = useState<string>(question?.pergunta || '');

  const initialValues: any = {
    id: 0,
    titulo: '',
    alternativas: [
      {
        id: 0,
        descricao: '',
      },
      {
        id: 0,
        descricao: '',
      },
      {
        id: 0,
        descricao: '',
      },
    ],
  };

  const options = [
    { value: '1', label: 'Múltipla Escolha' },
    { value: '2', label: 'Campo aberto' },
  ];

  const [tipoPergunta, setTipoPergunta] = useState(options[0]);

  useEffect(() => {
    if (question) {
      const option = options.find((item) => item.value === question.tipoPergunta.toString());
      setTipoPergunta(option ?? options[0]);
    } else {
      setTipoPergunta(options[0]);
    }
  }, [question]);

  useEffect(() => {
    if (question) {
      const findIndex = question?.alternativas?.findIndex((item) => item?.valor);
      setCheck(String(findIndex));
    }
    return () => {
      setCheck('');
      setIncludeImage(false);
    };
  }, [question]);

  const validationSchema = yup.object().shape({
    titulo: yup.string().required('Campo obrigatório'),
  });

  const handleSubmit = async (dataForm: any) => {
    console.log('chamou handleSubmit');
    if (dataForm.alternativas.length < 2) {
      toast.error('Adicione ao menos 2 respostas para esta pergunta');
      return;
    }

    if (!check) {
      toast.error('Selecione a resposta certa para essa questão');
      return;
    }
    let isItemEmpty = false;
    dataForm.alternativas.forEach((item: any) => {
      if (!isItemEmpty && !item.descricao) {
        isItemEmpty = true;
      }
    });

    if (isItemEmpty) {
      toast.error('Adicione todas as respostas');
      return;
    }

    const dataFormatted = {
      ...dataForm,
      key: question ? question.id : Math.floor(1000 + Math.random() * 9000),
      alternativas: dataForm.alternativas.map((item: IComunicadoAlternativa, index: number) => ({
        ...item,
        key: item.id !== 0 ? item.id : Math.floor(1000 + Math.random() * 9000),
        valor: index === +check,
        // isRespostaCerta: index === +check,
      })),
      tipoPergunta: tipoPergunta?.value,
      base64Image,
    };

    setLoading(true);
    try {
      const POST_PERGUNTA_URL = `/comunicado-empresa/${comunicadoId}/comunicado`;
      const method = question ? api.put : api.post;
      const response = await method(POST_PERGUNTA_URL, dataFormatted);
      onCloseModal(response.data.data);

      setCheck('');
    } catch (error: any) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error('Erro ao salvar Pergunta');
    } finally {
      setIncludeImage(false);
      setBase64Image(undefined);
      setLoading(false);
    }
  };

  const handleSubmitCampoAberto = async (dataForm?: any) => {
    const dataFormatted = {
      ...dataForm,
      key: question ? question.id : Math.floor(1000 + Math.random() * 9000),
      tipoPergunta: tipoPergunta?.value,
    };
    dataFormatted.titulo = validateHtmlContent(htmlContent) ? '' : htmlContent;

    try {
      const POST_PERGUNTA_URL = `/comunicado-empresa/${comunicadoId}/comunicado`;
      const method = question ? api.put : api.post;
      const response = await method(POST_PERGUNTA_URL, dataFormatted);
      onCloseModal(response.data.data);

      setCheck('');
    } catch (error: any) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error('Erro ao salvar Pergunta');
    } finally {
      setIncludeImage(false);
      setBase64Image(undefined);
      setLoading(false);
    }
  };

  const handleAddMoreAlternatives = useCallback((setValues: (values: any) => void) => {
    setValues((values: any) => {
      values.alternativas.push({
        id: 0,
        descricao: '',
      });

      return values;
    });
  }, []);

  const [removeAlternativeTrigger, setRemoveAlternativeTrigger] = useState(false);

  const handleRemoveAlternatives = (setValues: (values: any) => void, index: number) => {
    if (index === Number(check)) {
      setCheck('');
    }
    setValues((values: any) => {
      values.alternativas.splice(index, 1);
      return values;
    });
    setRemoveAlternativeTrigger((prev) => !prev);
  };

  useEffect(() => {
    if (tipoPergunta?.value === '1' && check !== '' && check !== '-1') {
      setDisabled(false);
    } else if (tipoPergunta?.value === '2') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [tipoPergunta, check, removeAlternativeTrigger]);

  const handleOnHtmlEditorChange = (content: string) => {
    setHtmlContent(content);
  };

  return (
    <>
      <ModalComponent
        onClose={() => onCloseModal()}
        modalVisible={showModal}
        title="Incluir Pergunta"
        icon={BiTask}
        size="lg"
      >
        <Formik
          initialValues={question || initialValues}
          // validationSchema={validationSchema}
          // eslint-disable-next-line spaced-comment
          //@ts-ignore
          onSubmit={tipoPergunta?.value === 1 ? handleSubmit : handleSubmitCampoAberto}
          validate={(values) => {
            try {
              const valuesN = { ...values };
              if (!validateHtmlContent(htmlContent)) {
                valuesN.titulo = htmlContent;
              }
              validationSchema.validateSync(valuesN, { abortEarly: false });
            } catch (err) {
              return { titulo: 'Campo título é obrigatório' };
            }
            return {};
          }}
        >
          {({ errors, values, setFieldValue, setValues }) => (
            <Form>
              <Row>
                <Col xs={12} md={12}>
                  {/* <Input type="text" name="titulo" label="Titulo" /> */}

                  <HtmlEditor
                    placeholder="Escreva algo..."
                    value={values?.titulo}
                    onChange={handleOnHtmlEditorChange}
                  />
                  {validateHtmlContent(htmlContent) && errors.titulo && (
                    <ErrorContainer>
                      <IoAlertCircleOutline />
                      <span>{errors.titulo}</span>
                    </ErrorContainer>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <ControlledReactSelect
                    label="Tipo de Pergunta"
                    options={options}
                    value={tipoPergunta}
                    onChange={(value) => setTipoPergunta(value)}
                  />
                </Col>
              </Row>
              {(tipoPergunta?.value === '1' || tipoPergunta?.value === '5') && (
                <Row>
                  <Col xs={12} className="mt-2">
                    <label htmlFor="qtdeAlternativas" className="styled-label">
                      Alternativas
                    </label>
                    <FieldArray name="alternativas">
                      {() => values.alternativas.map((item: any, idx: any) => (
                        <Row style={{ marginLeft: 0, marginBottom: '0.5rem' }} key={idx}>
                          <Col
                            xs={1}
                            className="rounded-gray-bg"
                            style={{ paddingLeft: '0', paddingRight: '0' }}
                          >
                            <SpanAlternative
                              type="button"
                              check={check === String(idx)}
                              onClick={() => {
                                setCheck(String(idx));
                                setFieldValue(`alternativas.${idx}.valor`, true);
                              }}
                              className={`border-0 ${check !== String(idx) ? 'text-secondary' : ' '
                                // eslint-disable-next-line indent
                                } rounded d-flex align-items-center justify-content-center`}
                              style={{ height: '100%' }}
                            >
                              {idx + 1}
                            </SpanAlternative>
                          </Col>

                          <Col xs={10}>
                            <Field
                              name={`alternativas.${idx}.descricao`}
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name={`alternativas.${idx}.descricao`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </Col>
                          <Col xs={1}>
                            <Button
                              background={theme.colors.main}
                              color="#fff"
                              onClick={() => handleRemoveAlternatives(setValues, idx)}
                              type="button"
                            >
                              <IoRemove size={24} />
                            </Button>
                          </Col>
                        </Row>
                      ))
                      }
                    </FieldArray>

                    <Button
                      background={theme.colors.main}
                      color="#fff"
                      onClick={() => handleAddMoreAlternatives(setValues)}
                      type="button"
                    >
                      <IoAdd size={24} />
                    </Button>
                  </Col>
                </Row>
              )}

              <Row className="mt-4">
                <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
                  <Button
                    background={theme.colors.main}
                    color="#fff"
                    className="text-uppercase"
                    type="submit"
                    loading={loading}
                    disabled={disabled}
                  >
                    Salvar pergunta
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  );
};
