import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { ImArrowDown, ImQuestion } from 'react-icons/im';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FaMapMarkedAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { HeaderTitle } from '../../components/HeaderTitle';
import { ItemList } from '../../components/ItemList';
import { ModalFormCategoriaComunicado } from './ModalFormCategoriaComunicado';
import { ICategoriaComunicado } from '../../interfaces/IComunicado';

export const ComunicadosPage = () => {
  const { ids } = useParams<{ ids: string }>();
  const history = useHistory();

  const [showModalForm, setShowModalForm] = useState(false);
  const [
    categories, setCategories,
  ] = useState<ICategoriaComunicado[]>([]);
  const [
    comunicadoHierarchyUnit, setCategComunicadoHierarchyUnit,
  ] = useState<ICategoriaComunicado>();
  const [selectedCategory, setSelectedCategory]
    = useState<ICategoriaComunicado | undefined>(undefined);

  const isSubCategoria = (ids !== undefined);
  const path = isSubCategoria ? (`${ids}/`) : '';
  const categoriasIds = isSubCategoria ? ids.split('/') : [];
  const lastCategoriaId = isSubCategoria ? categoriasIds[categoriasIds.length - 1] : undefined;

  const listHierarchy: () => ICategoriaComunicado[] = () => {
    const list: ICategoriaComunicado[] = [];

    let nextHierarchy = comunicadoHierarchyUnit;
    let lastHierarchy = comunicadoHierarchyUnit;

    categoriasIds.forEach((id) => {
      if (nextHierarchy && `${nextHierarchy?.id}` === id) {
        lastHierarchy = nextHierarchy;
        list.push(lastHierarchy);

        if (lastHierarchy.categoriasFilhas?.length > 0) {
          [nextHierarchy] = lastHierarchy.categoriasFilhas;
        }
      }
    });

    return list;
  };

  const builderHistoryTitle: () => JSX.Element[] = () => {
    const list = listHierarchy();

    let link = '/comunicados';
    const result: JSX.Element[] = [
      <Link to={link} key={link}>
        Categorias de Comunicados
      </Link>,
    ];

    for (let i = 0; i < list.length; i += 1) {
      link += `/${list[i].id}`;

      result.push(<span>{' > '}</span>);
      result.push(
        <Link to={link}>
          {list[i].nome}
        </Link>,
      );
    }

    return result;
  };

  useEffect(() => {
    try {
      if (comunicadoHierarchyUnit === undefined) return;

      const listHierarchies = listHierarchy();
      const lastHierarchy = listHierarchies[listHierarchies.length - 1];

      setCategories(lastHierarchy?.categoriasFilhas || []);
    } catch (error: any) {
      toast.error(error.message);
    }
  }, [comunicadoHierarchyUnit]);

  const fetchCategorias = () => {
    if (isSubCategoria) {
      api
        .get(`comunicado/categoria/hierarchy/${ids}`)
        .then(({ data }) => setCategComunicadoHierarchyUnit(data.data))
        .catch(() => {
          toast.error('Erro ao buscar lista de categorias');
        });
    } else {
      setCategComunicadoHierarchyUnit(undefined);
      api
        .get('comunicado/categoria/hierarchy/father')
        .then(({ data }) => setCategories(data.data))
        .catch(() => {
          toast.error('Erro ao buscar lista de categorias');
        });
    }
  };

  useEffect(fetchCategorias, [isSubCategoria, ids]);

  const handleFormEdit = (id: number) => {
    const selectedCateg = categories.find((c) => c.id === id);
    setSelectedCategory(selectedCateg);
    setShowModalForm(true);
  };

  const handleFormClose = () => {
    setSelectedCategory(undefined);
    setShowModalForm(false);
    fetchCategorias();
  };

  const handleNewItem = () => {
    setSelectedCategory(undefined);
    setShowModalForm(true);
  };

  const handleOnClick = (id: number) => {
    setCategories([]);
    history.push(`/comunicados/${path}${id}`);
  };

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Deseja realmente excluir a categoria de comunicados?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Sim, excluir',
      cancelButtonText: 'Cancelar',
      denyButtonColor: '#757575',
    }).then(async (result) => {
      if (result.isConfirmed) {
        api.delete(`comunicado/categoria/${id}`)
          .then(() => {
            toast.success('Categoria excluída com sucesso');
            fetchCategorias();
          })
          .catch((error) => {
            if (String(error).includes('403')) return toast.error('Você não tem permissão para essa operação. Consulte seu RH.');
            return toast.error('Não foi possível excluir a categoria');
          });
      }
    });
  };

  return (
    <>
      <ModalFormCategoriaComunicado
        onClose={handleFormClose}
        isOpen={showModalForm}
        categoriaComunicado={selectedCategory}
        categoriaPaiId={lastCategoriaId}
      />

      <HeaderTitle
        onNewItem={() => handleNewItem()}
        title={builderHistoryTitle()}
        text="Nesta aba você pode ver as Categorias de Comunicados. Clique para ver as subcategorias"
      />

      <Row>
        {categories.map((category) => (
          <Col key={category.id} md={6} className="mt-3">
            <ItemList
              {...category}
              id={category?.id || 0}
              icon={ImQuestion}
              category="Categoria"
              title={category.nome}
              onClick={() => handleOnClick(category.id)}
              onFormEdit={handleFormEdit}
              onDelete={handleDelete}
              additionalOptions={category ? [{
                icon: FaMapMarkedAlt,
                label: 'Mapa',
                onClick: () => history.push(`/comunicados/trilha/${category.id}`),
              }] : []}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
