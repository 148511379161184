/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { HeaderTitle } from '../../../components/HeaderTitle';
import { Button } from '../../../components/Button';
import api from '../../../services/api';
import { Container } from './styles';
import { FiltroRelatorio } from '../components/filtro';
import { TIPO_FILTRO } from '../constants';
import { ISelect } from '../../../interfaces/ISelect';
import { SimpleSelect } from '../../../components/SimpleSelect';
import {
  downloadExcelFromResponseBinary,
  getDataCorrentePtBr,
} from '../../../utils/shared-functions';
import { convertToOptionsArrayQuizzes } from '../Cursos/Index';

export const convertToOptionsArray = (item: any) => ({
  label: item.nome,
  value: item.id,
});

export const RelatorioTrilhaPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relatorioState, setRelatorioState] = useState<any>({});

  const [optionsCategCurso, setOptionsCategCurso] = useState<ISelect[]>([]);
  const [optionsCurso, setOptionsCurso] = useState<ISelect[]>([]);
  const [optionsFase, setOptionsFase] = useState<ISelect[]>([]);
  const [optionsTarefa, setOptionsTarefa] = useState<ISelect[]>();
  const [optionsPergunta, setOptionsPergunta] = useState<ISelect[]>();

  const [filteredCategoriaCurso, setFilteredCategoriaCurso] = useState<ISelect>();
  const [filteredCurso, setFilteredCurso] = useState<any>();
  const [filteredFase, setFilteredFase] = useState<any>();
  const [filteredTarefa, setFilteredTarefa] = useState<any>();
  const [filteredPergunta, setFilteredPergunta] = useState<any>();

  const cleanAfterTarefaChange = () => {
    setOptionsPergunta([]);
    setFilteredPergunta(undefined);
  };

  const cleanAfterFaseChange = () => {
    setOptionsTarefa([]);
    setFilteredTarefa(undefined);

    cleanAfterTarefaChange();
  };

  const cleanAfterCursoChange = () => {
    setOptionsFase([]);
    setFilteredFase(undefined);

    cleanAfterFaseChange();
  };

  const cleanAfterCategCursoChange = () => {
    setOptionsCurso([]);
    setFilteredCurso(undefined);

    cleanAfterCursoChange();
  };

  const fetchCategCursos = () => {
    api
      .get('curso/categoria')
      .then((response: any) => {
        setOptionsCategCurso(response?.data?.data?.map(convertToOptionsArray));
      })
      .catch(() => {
        toast.error('Erro ao recuperar Categ. de Cursos');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchCategCursos();
  }, []);

  const fetchCursos = (categCursoId: string) => {
    setOptionsCurso([]);

    api
      .get(`curso/por-categoria/${categCursoId}`)
      .then((response: any) => {
        setOptionsCurso(response?.data?.data?.map(convertToOptionsArray));
      })
      .catch(() => {
        toast.error('Erro ao recuperar  de Cursos');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchFases = (cursoId: string) => {
    setOptionsFase([]);

    api
      .get(`${cursoId}/fase`)
      .then((response: any) => {
        setOptionsFase(
          response?.data?.data?.map((fase: any) => ({
            label: fase.titulo,
            value: fase.id,
          })),
        );
      })
      .catch(() => {
        toast.error('Erro ao recuperar Fases');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchTarefas = (faseId: string) => {
    setOptionsTarefa([]);

    api
      .get(`${faseId}/tarefa`)
      .then((response: any) => {
        setOptionsTarefa(response?.data?.data?.map(convertToOptionsArray));
      })
      .catch(() => {
        toast.error('Erro ao recuperar Fases');
      })
      .finally(() => setIsLoading(false));
  };

  const fetchPerguntas = (tarefaId: string) => {
    setOptionsPergunta([]);

    api
      .get(`${tarefaId}/quiz`)
      .then((response: any) => {
        setOptionsPergunta(response?.data?.data?.map(convertToOptionsArrayQuizzes));
      })
      .catch(() => {
        toast.error('Erro ao recuperar Quizzes');
      })
      .finally(() => setIsLoading(false));
  };

  const handleOnCategCursoChange = (e: any) => {
    cleanAfterCategCursoChange();

    const categCursoId = parseInt(e?.target?.value, 10);
    const option = optionsCategCurso?.find((op: any) => op.value === categCursoId);

    if (option) {
      setFilteredCategoriaCurso(option);

      fetchCursos(option.value);
    }
  };

  const handleOnCursoChange = (e: any) => {
    cleanAfterCursoChange();

    const cursoId = parseInt(e?.target?.value, 10);
    const option = optionsCurso?.find((op: any) => op.value === cursoId);

    if (option) {
      setFilteredCurso(option);
      fetchFases(option.value);
    }
  };

  const handleOnFaseChange = (e: any) => {
    cleanAfterFaseChange();

    const faseId = parseInt(e?.target?.value, 10);
    const option = optionsFase?.find((op: any) => op.value === faseId);
    if (option) {
      setFilteredFase(option);
      fetchTarefas(option.value);
    }
  };

  const handleOnTarefaChange = (e: any) => {
    cleanAfterTarefaChange();

    const tarefaId = parseInt(e?.target?.value, 10);
    const option = optionsTarefa?.find((op: any) => op.value === tarefaId);
    if (option) {
      setFilteredTarefa(option);
      fetchPerguntas(option.value);
    }
  };

  const handleOnPerguntaChange = (e: any) => {
    const quizzId = parseInt(e?.target?.value, 10);
    const option = optionsPergunta?.find((op: any) => op.value === quizzId);
    if (option) {
      setFilteredPergunta(option);
    }
  };

  const loadFiltrosFromRelatorioState = (filtersObj: any) => {
    console.log(
      '🚀 ~ file: index.tsx ~ line 161 ~ loadFiltrosFromRelatorioState ~ relatorioState',
      relatorioState,
    );
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in relatorioState) {
      if (Object.prototype.hasOwnProperty.call(relatorioState, prop)) {
        // && relatorioState[prop])
        console.log('🚀 ~ file: index.tsx ~ line 160 ~ loadFiltrosFromRelatorioState ~ prop', prop);
        if (relatorioState[prop]?.length > 0) {
          filtersObj[prop] = relatorioState[prop];
        }
      }
    }
  };

  const loadFiltrosEspecificos = (filtersObj: any) => {
    if (filteredCategoriaCurso) {
      filtersObj.categoriaCurso = filteredCategoriaCurso?.value;
    }
    if (filteredCurso) {
      filtersObj.curso = filteredCurso?.value;
    }
    if (filteredFase) {
      filtersObj.fase = filteredFase?.value;
    }
    if (filteredTarefa) {
      filtersObj.tarefa = filteredTarefa?.value;
    }
  };

  const getFiltros = () => {
    const filtersObj = {};

    loadFiltrosFromRelatorioState(filtersObj);
    loadFiltrosEspecificos(filtersObj);

    return new URLSearchParams(filtersObj);
  };

  const handleOnGerarRelatorioClick = () => {
    api({
      url: `${process.env.REACT_APP_ENDPOINT}/relatorios/relatoriotrilhausuariolms?${getFiltros()}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      downloadExcelFromResponseBinary(
        response.data,
        `Relatorio_De_Trilha-${getDataCorrentePtBr()}.xlsx`,
      );
    });
  };

  return (
    <Container>
      <HeaderTitle
        title="Relatório de Trilha"
        text="Filtre de acordo com seu objetivo e baixe uma planilha com os resultados instantaneamente."
      />

      <Row>
        <Col xs={12} md={6}>
          <SimpleSelect
            label="Categorias de Cursos"
            name="categCurso"
            options={optionsCategCurso}
            defaultValue={filteredCategoriaCurso}
            value={filteredCategoriaCurso?.value}
            onChange={handleOnCategCursoChange}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Cursos"
            name="curso"
            options={optionsCurso}
            value={filteredCurso?.value}
            onChange={handleOnCursoChange}
            disabled={!filteredCategoriaCurso}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Fases"
            name="fase"
            options={optionsFase}
            value={filteredFase?.value}
            onChange={handleOnFaseChange}
            disabled={!filteredCurso}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Tarefas"
            name="tarefa"
            options={optionsTarefa}
            value={filteredTarefa?.value}
            onChange={handleOnTarefaChange}
            disabled={!filteredFase}
          />
        </Col>

        <Col xs={12} md={6}>
          <SimpleSelect
            label="Perguntas"
            name="pergunta"
            options={optionsPergunta}
            value={filteredPergunta?.value}
            onChange={handleOnPerguntaChange}
            disabled={!filteredPergunta}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="grupos"
            label="Grupos"
            endpointUrl="filial"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="unidades"
            label="Unidades"
            endpointUrl="unidade-empresa"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="cargo"
            label="Cargos"
            endpointUrl="cargo"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>

        <Col xs={12} md={6}>
          <FiltroRelatorio
            tipo={TIPO_FILTRO.MULTI_SELECT}
            name="usuario"
            label="Usuários"
            endpointUrl="usuario"
            relatorioState={relatorioState}
            setRelatorioState={setRelatorioState}
            endpointDataConvertFn={convertToOptionsArray}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={{ span: 4, offset: 4 }}>
          <Button
            background="transparent"
            color="#fff"
            loading={isLoading}
            className="text-uppercase"
            type="submit"
            onClick={handleOnGerarRelatorioClick}
          >
            Gerar Relatório
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
