import { ComponentType } from 'react';
import { IconBaseProps } from 'react-icons';
import { Link } from 'react-router-dom';
import { Content } from '../../styles/components';
import { Category, Container, Description } from './styles';

interface Props {
  icon: ComponentType<IconBaseProps>;
  link?: string;
  title?: string;
  nome?: string;
  descricao?: string;
  seeStatus?: boolean;
  status?: string;
}

export const ViewOnlyListItem = ({
  icon: Icon,
  title,
  descricao,
  nome,
  seeStatus = false,
  status,
  link }: Props) => (
    <Content>
      <Container>
        <Link to={link ?? '#'} className="d-flex align-items-center h-100" style={{ cursor: link ? 'pointer' : 'inherit' }}>
          <Category className="d-flex flex-column align-items-center">
            <h6>{title}</h6>
            <Icon size={24} />
          </Category>
          <Description className="w-100">
            <h6>{nome || title}</h6>
            <p>{descricao}</p>
            {seeStatus && (
              <p>
                <strong>Status: </strong>
                {status}
              </p>
            )}
          </Description>
        </Link>

      </Container>
    </Content>
);
