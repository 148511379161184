import React, { useState, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Card, Col, Row } from 'react-bootstrap';
import { FaRegIdBadge } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import { ModalComponent } from '../../../../components/Modal';
import api from '../../../../services/api';
import { Button } from '../../../../components/Button';
import { LabelStyled } from './styles';
import { IEmpresa } from '../../../../interfaces/IEmpresa';

interface IResult {
  totalUnidades: number;
  totalUnidadesImportadas: number;
  totalUnidadesAtualizadas: number;
  totalUnidadesDesativadas: number;
  totalUnidadesErrors: number;
  errors: string[];
}

interface IImportApiResponse {
  data: {
    data: IResult;
  };
}

interface ModalImportProps {
  onCloseModal: () => void;
  showModal: boolean;
}

const dataInitial = {
  UsuariosArquivo: null,
  FilialId: 0,
};

const importationUrl = 'unidade-empresa/import';

export const ModalUnitImport = ({ onCloseModal, showModal }: ModalImportProps) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<IResult>();
  const [company] = useStore<IEmpresa>('company');
  const [selectedFile, setSelectedFile] = useState<File | any>();
  const [planilhaBase64, setPlanilhaBase64] = useState<string>('');

  useEffect(
    () => {
      api
        .get('configuracao/key/planilhaImportacaoUnidades')
        .then(({ data }) => {
          setPlanilhaBase64(data.data?.value);
        })
        .catch(() => {
          toast.error('Erro ao buscar planilha modelo');
        });
    }, [],
  );

  const handleUploadImage = useCallback((file: any) => {
    setSelectedFile(file.target.files[0]);
  }, []);

  const handleSubmit = useCallback(
    async () => {
      try {
        setLoading(true);

        if (selectedFile) {
          const formData = new FormData();

          formData.append('UnidadesArquivo', selectedFile);
          formData.append('EmpresaId', (company.id ?? 0).toString());

          const importApiResponse: IImportApiResponse = await api.post(
            importationUrl,
            formData,
          );

          const resultData: IResult = importApiResponse.data.data;

          setLoading(false);
          setResult(resultData);

          if (resultData.totalUnidadesErrors > 0) {
            const errors = resultData.errors.join(', ');
            toast.error(errors);
          }
        } else {
          toast.error('Selecione o arquivo antes');
        }
      } catch (err: any) {
        if (err.response.data.errors) {
          const errors = err.response.data.errors.join(', ');
          toast.error(errors);
          return;
        }
        toast.error('Erro ao importar usuários');
      } finally {
        setLoading(false);
      }
    },
    [selectedFile, company],
  );

  const internalOnClose = () => {
    setResult(undefined);
    onCloseModal();
  };

  const ResultCard: React.FC<{ title: string; value: number }> = ({
    title,
    value,
  }) => (
    <Card style={{ padding: '0.8rem', marginTop: '0.5rem' }}>
      <p className="mb-0">
        <b className="theme-color">{title}</b>
      </p>
      <p className="mb-0 fs-2">{value}</p>
    </Card>
  );

  return (
    <ModalComponent
      onClose={internalOnClose}
      modalVisible={showModal}
      title={result ? 'Resultado da Importação' : 'Importar unidades'}
      icon={FaRegIdBadge}
      size="lg"
    >
      {!result && (
        <>
          <div className="orientation-area">
            <p>
              <a href={planilhaBase64}>
                Baixe aqui
              </a>
              {' '}
              o modelo de planilha que usará na importação
            </p>
            <p>
              Depois que terminar de trabalhar nela, clique em &quot;Choose
              File&quot; para importá-la novamente.
            </p>
          </div>
          <Formik
            initialValues={dataInitial}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            <Form>
              <Row>
                <Col md={6}>
                  <LabelStyled>Arquivo</LabelStyled>
                  <input
                    onChange={handleUploadImage}
                    type="file"
                    name="UsuariosArquivo"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                  <Button
                    background="#fff"
                    color="#fff"
                    loading={loading}
                    className="text-uppercase theme-color-bg"
                    type="submit"
                  >
                    Importar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </>
      )}

      {result && (
        <>
          <Row>
            <Col xs={12} md={12}>
              <ResultCard title="Total" value={result.totalUnidades} />
            </Col>
            <Col xs={12} md={3}>
              <ResultCard
                title="Novos"
                value={result.totalUnidadesImportadas}
              />
            </Col>
            <Col xs={12} md={3}>
              <ResultCard
                title="Alterados"
                value={result.totalUnidadesAtualizadas}
              />
            </Col>
            <Col xs={12} md={3}>
              <ResultCard
                title="Desativados"
                value={result.totalUnidadesDesativadas}
              />
            </Col>
            <Col xs={12} md={3}>
              <ResultCard title="Erros" value={result.totalUnidadesErrors} />
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12} md={{ span: 4, offset: 4 }}>
              <Button
                background="#fff"
                color="#fff"
                loading={loading}
                className="text-uppercase theme-color-bg"
                onClick={internalOnClose}
              >
                Fechar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </ModalComponent>
  );
};
