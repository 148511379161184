/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/all';
import { SiCircle } from 'react-icons/si';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { BoxItemGame } from '../../../components/BoxItemGame';
import { Button } from '../../../components/Button';
import { ICategoriaComunicado, IComunicado, IComunicadoPergunta } from '../../../interfaces/IComunicado';
import api from '../../../services/api';
import theme from '../../../styles/theme';
import { Header } from '../../Trilhas/Kandan/styles';
import { ModalFormAgendamentoComunicado } from './ModalFormAgendamento';
import { ModalFormComunicado } from './ModalFormComunicado';
import { ModalFormComunicadoQuestion } from './ModalFormComunicadoQuestion';
import { useAuth } from '../../../hooks/auth';

export const ComunicadosKanbanPage = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  const [comunicados, setComunicados] = useState<IComunicado[]>([]);
  const [comunicado, setComunicado] = useState<IComunicado>();
  const [question, setQuestion] = useState<IComunicadoPergunta>();
  const [category, setCategory] = useState<ICategoriaComunicado>();
  const [showModalForm, setShowModalForm] = useState(false);
  const [showModalFormViewOnly, setShowModalFormViewOnly] = useState(false);
  const [showModalFormQuestion, setShowModalFormQuestion] = useState(false);
  const [isOpenModalFormAgendamento, setIsOpenModalFormAgendamento] = useState(false);

  const swalOptions: SweetAlertOptions = {
    title: 'Deseja realmente excluir?',
    text: 'Você não será capaz de reverter isso.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ef5350',
    cancelButtonColor: '#757575',
    confirmButtonText: 'Sim, excluir',
    cancelButtonText: 'Cancelar',
    denyButtonColor: '#757575',
  };

  const getExtraInfoLabel = (jaLancado?: boolean, agendado?: boolean): string => {
    let result = '';
    if (jaLancado) {
      result = 'Comunicado lançado';
    } else if (agendado) {
      result = 'Comunicado agendado';
    }
    return result;
  };

  const mapFetchComunicadoResponse = (
    _comunicados: IComunicado[],
  ): IComunicado[] => _comunicados
    .map((c) => {
      const momentDtLancamento = moment(`${c.dataLancamento}-03:00`).local();

      // extrair para método Utils
      const gmtOffset = momentDtLancamento.utcOffset() / 60;
      const gmt = gmtOffset >= 0 ? `+${gmtOffset}` : gmtOffset;
      const dataLancamento = `${momentDtLancamento.format('DD/MM/YYYY HH:mm')} (GMT ${gmt})`;

      return {
        ...c,
        extraInfo: getExtraInfoLabel(c.jaLancado, c.agendado),
        canEdit: !(c.jaLancado ?? false),
        canPreview: c.jaLancado ?? false,
      };
    });

  const fetchComunicado = () => {
    api.get(`/comunicado-empresa/${id}`).then((response) => {
      if (response.data?.data) {
        setComunicados(mapFetchComunicadoResponse(response.data.data));
      }
    });
  };

  const fetchCategory = () => {
    api.get(`/comunicado/categoria/${id}`).then((response) => {
      if (response.data?.data) {
        setCategory(response.data.data);
      }
    });
  };

  useEffect(() => {
    fetchComunicado();
    fetchCategory();
  }, []);

  /**
   * Atualiza os comunicados a cada 30 segundos...
   */
  useEffect(() => {
    const interval = setInterval(() => {
      fetchComunicado();
    }, 1000 * 30);
    return () => clearInterval(interval);
  });

  const handleOnComunicadoSelect = async (item: number) => {
    const comunicadoSelected = comunicados.find((c) => c.id === item);

    setComunicado(comunicadoSelected);

    await api.get(`/comunicado-empresa/comunicado/${item}`).then((response) => {
      setComunicado(response.data.data);
    });
  };

  const handleOnCloseModal = () => {
    fetchComunicado();
    setShowModalForm(false);
    setShowModalFormViewOnly(false);
  };

  const handleOnCloseFormQuestion = (comunic?: IComunicado) => {
    if (comunic) setComunicado(comunic);
    setShowModalFormQuestion(false);
  };

  const handleDeleteClimate = useCallback((comunicadoId: number) => {
    Swal.fire({
      ...swalOptions,
    }).then(async (result) => {
      if (result.value) {
        try {
          await api.delete(`/comunicado-empresa/${comunicadoId}`);
          fetchComunicado();
          setComunicado(undefined);
          toast.success('Clima organizacional excluído com sucesso!');
        } catch (error) {
          toast.error('Erro ao excluir clima organizacional!');
        }
      }
    });
  }, []);

  const handleDuplicateQuestion = useCallback(
    (questionId: number) => {
      api
        .post(`/comunicado-empresa/${comunicado?.id}/duplicar-questao/${questionId}`)
        .then((response) => {
          fetchComunicado();
          setComunicado(response.data.data);
          toast.success('Pergunta duplicada com sucesso!');
        })
        .catch((error) => {
          toast.error('Error ao tentar duplicar pergunta!');
        });
    },
    [comunicado],
  );

  const handleDeleteQuestion = useCallback(
    (questionId: number) => {
      Swal.fire({
        ...swalOptions,
      }).then(async (result) => {
        if (result.value) {
          try {
            await api.delete(
              `/comunicado-empresa/${comunicado?.id}/comunicado/${questionId}`,
            );
            setComunicado((old) => {
              if (old) {
                const newClimate = { ...old };
                newClimate.perguntas = newClimate.perguntas.filter((q) => q.id !== questionId);
                return newClimate;
              }

              return old;
            });
            toast.success('Pergunta excluída com sucesso!');
          } catch (error) {
            toast.error('Erro ao excluir pergunta!');
          }
        }
      });
    },
    [comunicado],
  );

  const handleDesativarComunicado = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Você quer realmente desativar?',
        icon: 'warning',
        confirmButtonColor: theme.colors.main,
        input: 'text',
        inputLabel: 'Gostaria de informar o motivo da desativação?',
      }).then((result) => {
        const data = {
          data: {
            motivoDesativacao: result.value,
          },
        };

        api.delete(`/comunicado-empresa/${comunicado?.id}/versao`, data).then(() => {
          setComunicado(undefined);
          fetchComunicado();
          toast.success('Questionário desativado!');
        });
      });
    } catch (error: any) {
      if (error.response.data) {
        toast.error(error.response.data.errors.join(','));
      } else {
        toast.error('Erro ao desativar questionário!');
      }
    }
  }, [comunicado]);

  const handleDisableScheduler = () => {
    api
      .post(`/comunicado-empresa/${comunicado?.id}/cancelar-lancamento`)
      .then(() => {
        setComunicado(undefined);
        fetchComunicado();
        toast.success('Agendamento cancelado!');
      })
      .catch((error) => {
        if (error.response.data) {
          toast.error(error.response.data.errors.join(','));
        } else {
          toast.error('Erro ao cancelar agendamento!');
        }
      });
  };

  const handleDisplayTitle = (item: any) => {
    if (item.liberado) {
      return (
        <span>
          <strong>{item.titulo}</strong>
          <FaCheckCircle
            size={15}
            color="#4dbd74"
            style={{ marginLeft: '5px', marginTop: '-5px' }}
          />
        </span>
      );
    }
    return <span>{item.titulo}</span>;
  };

  const handleOnQuestionDragEnd = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      const perguntas = comunicado?.perguntas || [];
      const [reorderedItem] = perguntas.splice(resultIndex, 1);
      perguntas?.splice(resultDestination, 0, reorderedItem);

      const ordernacaoPerguntas = perguntas
        ?.map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);

      await api.put(
        `comunicado-empresa/${comunicado?.id}/ordenar-perguntas`,
        ordernacaoPerguntas,
      );
    },
    [comunicado],
  );

  const handleComunicadoDragEnd = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setComunicados((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordenacaoComunicado = comunicados
        .map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);
      try {
        await api.put('comunicado-empresa/ordenar-comunicados', ordenacaoComunicado);
      } catch {
        toast.error('Erro ao ordernar checklists');
      }
    },
    [comunicados],
  );

  return (
    <>
      {category && (
        <ModalFormComunicado
          showModal={showModalForm || showModalFormViewOnly}
          onCloseModal={handleOnCloseModal}
          comunicado={comunicado}
          category={category}
          viewOnly={showModalFormViewOnly}
        />
      )}

      {comunicado && (
        <>
          <ModalFormComunicadoQuestion
            showModal={showModalFormQuestion}
            onCloseModal={handleOnCloseFormQuestion}
            comunicadoId={comunicado.id}
            category={category}
            question={question}
          />

          <ModalFormAgendamentoComunicado
            onClose={() => {
              setIsOpenModalFormAgendamento(false);
              setComunicado(undefined);
              fetchComunicado();
            }}
            isOpen={isOpenModalFormAgendamento}
            idComunicado={comunicado.id}
          />
        </>
      )}

      <Header className="mt-3">
        <Row>
          <Col md={6} className="col-spacing d-flex">
            <SiCircle size={35} />
            <div style={{ flex: 1 }}>
              <h5>
                Comunicados
                {' - '}
                {category?.nome}
              </h5>
              <p>Vamos colocar a mão na massa!</p>
            </div>
          </Col>
          {comunicado && comunicado.perguntas.length > 0 && (
            <>
              {!comunicado.liberado && (
                <>
                  <Col md={6} xs={6} className="d-flex align-items-center">
                    {comunicado.agendado ? (
                      <Button
                        style={{ backgroundColor: '#e22c2c' }}
                        type="button"
                        color="#fff"
                        className="mt-2"
                        onClick={handleDisableScheduler}
                      >
                        Cancelar lançamento
                      </Button>
                    )
                      : (
                        <Button
                          style={{ backgroundColor: '#43af56' }}
                          type="button"
                          color="#fff"
                          className="mt-2"
                          onClick={() => setIsOpenModalFormAgendamento(true)}
                        >
                          Programar lançamento
                        </Button>
                      )
                    }
                  </Col>
                </>
              )}
              {comunicado.liberado && (
                <Col md={6} xs={6} className="d-flex align-items-center">
                  <Button
                    type="button"
                    color="#fff"
                    className="mt-2"
                    onClick={handleDesativarComunicado}
                  >
                    Desativar Comunicado
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>
      </Header>
      <Row>
        <Col md={6}>
          <BoxItemGame
            header="Comunicados"
            icon={SiCircle}
            items={comunicados}
            onShowFormEdit={() => setShowModalForm(true)}
            onShowFormModal={() => {
              setComunicado(undefined);
              setShowModalForm(true);
            }}
            onDeleteItem={handleDeleteClimate}
            onSelecetItem={handleOnComunicadoSelect}
            selectedId={comunicado?.id}
            displayTitle={handleDisplayTitle}
            superiorSelecionado
            onDragEnd={handleComunicadoDragEnd}
            onPreview={() => setShowModalFormViewOnly(true)}
          />
        </Col>
        <Col md={6}>
          <BoxItemGame
            header="Perguntas"
            displayTitle={(item: any) => item.titulo}
            icon={SiCircle}
            items={comunicado ? comunicado.perguntas : []}
            onSelecetItem={(item) => {
              const q = comunicado?.perguntas.find((pergunta) => pergunta.id === item);
              setQuestion(q);
            }}
            onShowFormEdit={
              !(comunicado?.jaLancado ?? false)
                ? () => {
                  setShowModalFormQuestion(true);
                }
                : undefined
            }
            onShowFormModal={() => {
              setQuestion(undefined);
              setShowModalFormQuestion(true);
            }}
            onDeleteItem={handleDeleteQuestion}
            onDragEnd={handleOnQuestionDragEnd}
            superiorSelecionado={!!comunicado}
            disabledButtonAdd={!comunicado}
            onDuplicateWithId={
              !(comunicado?.liberado || comunicado?.agendado)
                ? handleDuplicateQuestion : undefined
            }
          />
        </Col>
      </Row>
    </>
  );
};
