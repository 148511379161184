import { darken, lighten } from 'polished';
import styled, { keyframes } from 'styled-components';

const animateOpacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  margin-left: 1.2rem;
  padding-left: 1.2rem;
`;

export const Content = styled.div`
  position: absolute;
  width: 16em;
  right: 0px;
  top: 100%;
  background: ${({ theme }) => theme.colors.main};;
  padding-bottom: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  animation: ${animateOpacity} 600ms ease forwards;

  > button {
    width: 100%;
    padding: 1rem;
    text-align: left;
    border: 0;
    background: ${({ theme }) => lighten(0.1, theme.colors.main)};
    color: ${darken(0.1, '#fff')};

    svg {
      margin-right: 0.5rem;
    }
  }

  footer {
    p {
      font-size: 0.8rem;
      color: #fff;
    }
  }
`;

export const ProfileData = styled.div`
  padding-bottom: 0.6rem;
  margin-bottom: 0.6rem;

  h5 {
    color: #fff;
  }

  p {
    color: ${darken(0.2, '#fff')};
  }

  span {
    color: ${darken(0.1, '#fff')};
    font-size: 0.7rem;
    text-transform: uppercase;
  }
`;
